import React from 'react'

import { PendingChecker } from '@/frontend/api'
import { Alert, ButtonLink } from '@/frontend/components'

export default function AccountItemsMappingMessageWithLink({
  sourceAccountItemUrl,
  pendingChecker,
}: {
  sourceAccountItemUrl: string
  pendingChecker: PendingChecker
}) {
  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <div className="font-bold">勘定科目の割当</div>
        <div className="flex justify-between items-center">
          <p>インポートした勘定科目の情報を保存します。</p>
          <div>
            <ButtonLink
              variant={
                pendingChecker.isCsvAccountItemPending ? 'primary' : 'secondary'
              }
              href={sourceAccountItemUrl}
            >
              勘定科目の割当
            </ButtonLink>
          </div>
        </div>
      </div>
      {pendingChecker.isCsvAccountItemPending ? (
        <Alert
          title="勘定科目の割当が完了していません。勘定科目の割当ボタンから設定を行ってください。"
          variant="warning"
        />
      ) : null}
    </div>
  )
}
