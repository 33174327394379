import React from 'react'
import { NodeApi } from 'react-arborist'
import {
  RiDeleteBinLine,
  RiEditBoxLine,
  RiFolderLine,
  RiFolderOpenLine,
} from 'react-icons/ri'

import { ClientFolder, Org } from '@/frontend/api/types'
import { Tooltip } from '@/frontend/components'
import { useDeleteFolder } from '@/frontend/features/clients/hooks'
import { Data } from '@/frontend/features/clients/types'

export function ClientFolderRow({
  node,
  style,
  creatable,
  destroyable,
  updateInitialOpenState,
  onFolderEdit,
  formAuthenticityToken,
  org,
}: {
  node: NodeApi<Data>
  style: React.CSSProperties
  creatable: boolean
  destroyable: boolean
  updateInitialOpenState: (id: string, isOpen: boolean) => void
  onFolderEdit: (folder: ClientFolder) => void
  formAuthenticityToken: string
  org: Org
}) {
  const item = node.data.item as ClientFolder
  const hasChildren = node.data.children && node.data.children.length > 0
  const { trigger } = useDeleteFolder(org, item, formAuthenticityToken)
  const deleteTooltipContent = destroyable
    ? !hasChildren
      ? null
      : 'フォルダまたはクライアントが含まれるため削除できません。'
    : 'フォルダを削除するためにはマネージャー以上の権限が必要です。'
  const editTooltipContent = creatable
    ? null
    : 'フォルダを編集するためにはマネージャー以上の権限が必要です。'

  return (
    <div className="flex text-gray-700">
      <div style={style} className="flex-none w-72 leading-10 truncate">
        <button
          type="button"
          onClick={() => {
            node.toggle()
            updateInitialOpenState(node.id, node.isOpen)
          }}
          className="inline-flex items-center flex-none w-full leading-7 text-left"
        >
          {node.state.isOpen ? (
            <RiFolderOpenLine className="mr-2 text-xl text-gray-500" />
          ) : (
            <RiFolderLine className="mr-2 text-xl text-gray-500" />
          )}
          <Tooltip content={`${item.name}`} mouseDownClosed={true}>
            {(ref) => (
              <div className="truncate w-full">
                <span ref={ref} className="text-gray-900">
                  {item.name}
                </span>
              </div>
            )}
          </Tooltip>
        </button>
      </div>
      <div className="flex-none w-96 text-ellipsis overflow-y-auto"></div>
      <div className="flex-none w-48 text-right pr-8"></div>
      <div className="flex-none w-40"></div>
      <div className="flex-none w-40"></div>
      <div className="flex-auto">
        <div className="flex items-center justify-end">
          <Tooltip content={editTooltipContent}>
            {(ref) => (
              <div className="btn-wrapper mr-5" ref={ref}>
                <div className="btn-wrapper">
                  <button
                    className="btn btn-xs btn-white"
                    disabled={!creatable}
                    onClick={() => onFolderEdit(item)}
                  >
                    <i className="ri-fw mr-2">
                      <RiEditBoxLine />
                    </i>
                    編集
                  </button>
                </div>
              </div>
            )}
          </Tooltip>
          <Tooltip content={deleteTooltipContent}>
            {(ref) => (
              <div className="btn-wrapper" ref={ref}>
                <button
                  className="btn btn-xs btn-white"
                  disabled={!destroyable || hasChildren}
                  onClick={() => {
                    const result = confirm('フォルダを削除しますか？')

                    if (result) {
                      trigger().finally(() => location.reload())
                    }
                  }}
                >
                  <i className="ri-fw mr-2">
                    <RiDeleteBinLine />
                  </i>
                  削除
                </button>
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
