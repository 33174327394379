import React from 'react'

import { Tab } from './Tab'

type Props = {
  items: {
    label: string
    href: string
    active?: boolean
    Icon?: React.ReactNode
  }[]
}

export function Tabs({ items }: Props) {
  return (
    <div className="flex w-full border-b border-gray-200 space-x-1 px-4">
      {items.map((item, i) => {
        return (
          <Tab key={i} href={item.href} active={item.active} Icon={item.Icon}>
            {item.label}
          </Tab>
        )
      })}
    </div>
  )
}
