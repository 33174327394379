import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'item',
    'budgetTagCheckBox',
    'bulkCheckBox',
    'bulkAllCheckBox',
    'budgetTagOrGroupIdsInput',
    'label'
  ];

  connect() {
    this._updateBulkAllCheckBoxes()
    this._updateBulkCheckBoxes()
    this._updateInput()
    this._updateLabel()
  }

  // NOTE: タグ・タググループ・未設定の右のチェックボックスをチェック
  check() {
    this._updateBulkAllCheckBoxes()
    this._updateBulkCheckBoxes()
    this._updateInput()
    this._updateLabel()
  }

  // NOTE: 「タグの一括選択」をチェック
  checkTagBulk(event) {
    const { checked } = event.target
    const subtreeIds = this._subtreeIds(event.target)
    this._getChildCheckBoxes(subtreeIds)
        .forEach((e) => e.checked = checked)
    this.check()
  }

  // NOTE: 「すべてのタグ」をチェック
  checkTagAll(event) {
    const { checked } = event.target
    this.budgetTagCheckBoxTargets.filter((e) => !e.disabled)
      .forEach((e) => e.checked = checked)
    this.check()
  }

  _updateInput() {
    const budgetTagOrGroupIdsInputValue = this._budgetTagOrGroupIdsInputValue()

    // すべてのタグが選択されている場合は、allにするよ
    if (budgetTagOrGroupIdsInputValue.length === 0) {
      this.budgetTagOrGroupIdsInputTarget.setAttribute('value', 'all')
      return
    }
    this.budgetTagOrGroupIdsInputTarget.setAttribute('value', JSON.stringify(budgetTagOrGroupIdsInputValue))
  }

  _updateLabel() {
    const budgetTagOrGroupNames = this._budgetTagOrGroupNames()
    this.labelTarget.textContent = budgetTagOrGroupNames
  }

  _budgetTagOrGroupIdsInputValue() {
    if (this._defalutStatus()) {
      return []
    } else {
      const budgetTagOrGroupIdsInputValue = this.itemTargets.map((e) => {
        const checkbox = e.querySelector('[data-action="multi-checkbox-select#check"]')
        if(checkbox.checked) {
          return checkbox.dataset.value
        }
      }).filter(e => e)
      return budgetTagOrGroupIdsInputValue
    }
  }

  _budgetTagOrGroupNames() {
    if (this._defalutStatus()) {
      return 'すべて'
    } else if (this._everyUnchecked()) {
      return '選択してください'
    } else {
      const budgetTagOrGroupNames = this.itemTargets.map((e) => {
        const checkbox = e.querySelector('[data-action="multi-checkbox-select#check"]')
        if(checkbox.checked) {
          const name = e.querySelector('[data-item-name]')
          return name.textContent
        }
      }).filter(e => e).join(',')
      return budgetTagOrGroupNames
    }
  }

  // タググループが全て選ばれておらず、タグ・未設定が選ばれた状態、つまり初回アクセス時の状態かどうか
  // ちなみに、列数制限の設定が入ってる場合は列数が切られるからこの状態にはならないはずだよ
  _defalutStatus() {
    return this.itemTargets.every((e) => {
      const checkbox = e.querySelector('[data-action="multi-checkbox-select#check"]')
      if (checkbox.disabled)
        return true
      // タググループの右のチェックボックスがチェックなし
      if (checkbox.dataset.value.match(/group/)) {
        return !checkbox.checked
      // タグor未設定の右のチェックボックスがチェックあり
      } else {
        return checkbox.checked
      }
    })
  }

  _everyUnchecked() {
    return this.itemTargets.every((e) => {
      const checkbox = e.querySelector('[data-action="multi-checkbox-select#check"]')
      return checkbox.disabled || !checkbox.checked
    })
  }

  _updateBulkAllCheckBoxes() {
    const element = this.bulkAllCheckBoxTarget
    const budgetTagElements = this.budgetTagCheckBoxTargets.filter((e) => !e.disabled)
    if (budgetTagElements.every((e) => e.checked)) {
      element.indeterminate = false
      element.checked = true
      return
    }
    if (budgetTagElements.some((e) => e.checked)) {
      element.indeterminate = true
      element.checked = false
      return
    }
    element.indeterminate = false
    element.checked = false
  }

  _updateBulkCheckBoxes() {
    this.bulkCheckBoxTargets.filter((e) => !e.disabled).forEach((element) => {
      const subtreeIds = this._subtreeIds(element)
      const checkBoxes = this._getChildCheckBoxes(subtreeIds)
      if (checkBoxes.every((e) => e.checked)) {
        element.indeterminate = false
        element.checked = true
        return
      }

      if (checkBoxes.some((e) => e.checked)) {
        element.indeterminate = true
        element.checked = false
        return
      }

      element.indeterminate = false
      element.checked = false
    })
  }

  _getChildCheckBoxes(subtreeIds) {
    return this.budgetTagCheckBoxTargets.filter((e) => {
      return subtreeIds.includes(parseInt(e.dataset.groupId)) && !e.disabled
    })
  }

  _subtreeIds(el) {
    return el.dataset.subtreeIdsStr.split(',').map((e) => parseInt(e))
  }
}
