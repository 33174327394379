import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['disabled', 'persisted', 'newRecord']

  onCheckEnabled (e) {
    this.disabledTargets.forEach(element => element.disabled = !e.currentTarget.checked)
  }

  onCheckCreate (e) {
    if (e.currentTarget.checked) {
      this.persistedTargets.forEach(element => element.hidden = true)
      this.newRecordTargets.forEach(element => element.hidden = false)
    } else {
      this.persistedTargets.forEach(element => element.hidden = false)
      this.newRecordTargets.forEach(element => element.hidden = true)
    }
  }
}
