document.addEventListener('click', (e) => {
  const target = e.target
  const clicked = target.closest('[data-report-general-comment-toggle]')
  if (!clicked) {
    return
  }

  const wrapperDom = document.querySelector('[data-report-general-comment-wrapper]')
  if (!wrapperDom) {
    return
  }
  wrapperDom.classList.toggle('hidden')

  const focusEvent = new Event('report-general-comments-focus')
  document.dispatchEvent(focusEvent)
})
