import React from 'react'
import { tv } from 'tailwind-variants'

type Props = {
  onGray?: boolean
  children: React.ReactNode
}

const cardRoot = tv({
  base: 'ring-1 ring-gray-200 rounded bg-white p-4',
  variants: {
    onGray: {
      true: 'ring-0',
    },
  },
})

export function CardRoot({ onGray = false, children }: Props) {
  return <div className={cardRoot({ onGray })}>{children}</div>
}
