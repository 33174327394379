import { lazyGetter } from '../utils'

export default class Row extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-budget-detail')
      return JSON.parse(jsonString)
    })
  }

  get id () {
    return this.data.id
  }

  get budgetDetailItemId () {
    return this.data.budgetDetailItemId
  }

  get parentId () {
    return this.data.parentId
  }

  get relatedItemClass () {
    return this.data.relatedItemClass
  }

  get accountItemId () {
    return this.data.accountItemId
  }

  get costTypeId () {
    return this.data.costTypeId
  }

  get rootAccountItemId () {
    return this.data.rootAccountItemId
  }

  get relatedCostTypeId () {
    return this.data.relatedCostTypeId
  }

  get categorizedTagIds () {
    return this.data.categorizedTagIds
  }

  get relatedIds () {
    return this.data.relatedIds
  }

  get actionForNonFinancialItem () {
    return this.data.actionForNonFinancialItem
  }
}
