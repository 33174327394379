import { useCallback, useState } from 'react'

import { ApiError, Org } from '@/frontend/api'
import {
  BugyoCloudApiUpdateCompanyPayload,
  updateBugyoCloudCompany,
} from '@/frontend/api/importer'

export function useUpdateBugyoCloudCompanyApi(org: Org) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const submit = useCallback(
    async (data: BugyoCloudApiUpdateCompanyPayload) => {
      setApiError(undefined)
      setIsSubmitting(true)
      const result = await updateBugyoCloudCompany(org.id, data)
      if (result.ok) {
        setApiError(undefined)
      } else {
        setApiError(result.err)
      }
      setIsSubmitting(false)
    },
    [org],
  )
  return {
    submit,
    isSubmitting,
    apiError,
  }
}
