import { useCallback, useState } from 'react'

import {
  ApiError,
  deleteNonFinancialItem,
  NonFinancialItem,
  Org,
} from '@/frontend/api'
import { useNonFinancialItemsSuspense } from './useNonFinancialItemsSuspense'

export function useDeleteNonFinancialItem(
  org: Org,
  nonFinancialItem: NonFinancialItem,
) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)
  const { data: nonFinancialItems, mutate } = useNonFinancialItemsSuspense(org)
  const submit = useCallback(async () => {
    setIsSubmitting(true)
    const result = await deleteNonFinancialItem(org.id, nonFinancialItem.id)
    if (result.ok) {
      setApiError(undefined)
      mutate(
        nonFinancialItems?.filter((x) => x.id !== nonFinancialItem.id),
        { revalidate: false },
      )
    } else {
      setApiError(result.err)
    }
    setIsSubmitting(false)
  }, [org, nonFinancialItem, nonFinancialItems, mutate])

  return {
    submit,
    isSubmitting,
    apiError,
  }
}
