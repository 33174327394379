import { Sortable, MultiDrag } from 'sortablejs'

document.addEventListener('turbolinks:load', init)
Sortable.mount(new MultiDrag())

function init() {
  document.querySelectorAll('[data-shared-sortable]').forEach((el) => {
    const group = el.dataset.sharedSortableGroup
    const selectedClass = el.dataset.sharedSortableSelectedClass
    const options = {
      multiDrag: true,
      selectedClass: selectedClass,
      group: group,
      animation: 150,
      filter: '.filtered',
      onMove: function (evt, originalEvent) {
        evt.dragged.dispatchEvent(customEvent(evt, 'onItemMove'))
      },
      onEnd: function (evt) {
        evt.item.dispatchEvent(customEvent(evt, 'onDropEnd'))
      },
    }
    new Sortable(el, options)
  })
}

// stimulusでイベント扱いたいため、カスタムイベントを再発行する
const customEvent = (evt, evtName) => {
  return new CustomEvent(evtName, {
    detail: {
      item: evt.item,
      from: evt.from,
      to: evt.to,
    },
  })
}
