import React from 'react'
import { $generateNodesFromDOM } from '@lexical/html'
// import { HeadingNode, QuoteNode } from '@lexical/rich-text'
// import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
// import { ListItemNode, ListNode } from '@lexical/list'
// import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
// import ToolbarPlugin from './lexicalPlugins/ToolbarPlugin'
import { $getRoot, $insertNodes, $nodesOfType, CommandListener } from 'lexical'
import { ZeroWidthNode, ZeroWidthPlugin } from 'lexical-beautiful-mentions'

import AutoLinkPlugin from './lexicalPlugins/AutoLinkPlugin'
// import { MentionsPlugin, MentionNode } from './lexicalPlugins/MentionPlugin'
import {
  MentionItem,
  MentionsPlugin,
  mentionsTheme,
  refreshBeautifulMentionNode,
  StyledExportDomMentionNode,
} from './lexicalPlugins/MentionsPlugin'
import { OnKeyDownPlugin } from './lexicalPlugins/OnKeyDownPlugin'
import ExampleTheme from './LexicalTheme'

type Props = {
  initialValue?: string
  onChangeContent: (content: string) => void
  onChangeMentions: (content: Array<number>) => void
  onChangeTextContent: (content: string) => void
  onSubmit: () => void
  suggestionContainerRef: React.RefObject<HTMLElement>
  commentMentionMembers: MentionItem[]
  memberId: number
}

export function commentEditorNodes(
  mentionItems: MentionItem[],
  myMentionIds: number[],
) {
  return [
    AutoLinkNode,
    LinkNode,
    // ↓ デシリアライズ時にメンションされているメンバーの名前をいいかんじに書き替えています
    ...refreshBeautifulMentionNode(mentionItems, myMentionIds),
    // カーソルが変になるのを防いでくれるヤツ
    // ZeroWidthPluginで使われる
    ZeroWidthNode,
  ]
}

export function CommentEditor({
  initialValue,
  onChangeContent,
  onChangeMentions,
  onChangeTextContent,
  onSubmit,
  suggestionContainerRef,
  commentMentionMembers,
  memberId,
}: Props) {
  const editorConfig = {
    namespace: 'comment',
    // The editor theme
    theme: {
      ...ExampleTheme,
      beautifulMentions: mentionsTheme,
    },
    // Handling of errors during update
    onError(error: Error) {
      throw error
    },
    // Any custom nodes go here
    nodes: commentEditorNodes(commentMentionMembers, [memberId]),
  }

  const onKeyDown: CommandListener<KeyboardEvent> = (e) => {
    // windows: ctrl + enter
    // mac: meta(command) + enter
    if (
      ((e.ctrlKey && !e.metaKey) || (!e.ctrlKey && e.metaKey)) &&
      e.key == 'Enter'
    ) {
      onSubmit()
      return true
    }
    return false
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div>
        {/*
          <ToolbarPlugin containerRef={containerRef} />
        */}
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={
              <ContentEditable className="border border-gray-300 rounded-md block w-full px-3 py-1 text-sm transition duration-150 ease-in-out focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 leading-6" />
            }
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {initialValue != undefined && (
            <InitialImportPlugin value={initialValue} />
          )}
          <OnChangePlugin
            onChange={(editorState, _) => {
              editorState.read(() => {
                // コンテンツを取得
                const contentJson = JSON.stringify(editorState.toJSON())
                onChangeContent(contentJson)
                onChangeTextContent($getRoot().getTextContent())
                // メンションされたメンバーのIDを取得
                onChangeMentions(
                  $nodesOfType(StyledExportDomMentionNode)
                    .map((node) => {
                      const data = node.exportJSON()?.data
                      return data?.id as number | undefined
                    })
                    .filter((id): id is NonNullable<typeof id> => {
                      return id != undefined
                    }),
                )
              })
            }}
          />
          <OnKeyDownPlugin onKeyDown={onKeyDown} />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ZeroWidthPlugin />
          <MentionsPlugin
            mentionItems={commentMentionMembers}
            suggestionContainerRef={suggestionContainerRef}
          />
        </div>
      </div>
    </LexicalComposer>
  )
}

function Placeholder() {
  return (
    <div className="absolute top-0 left-0 py-1 px-3 mt-px text-sm select-none pointer-events-none text-gray-400 leading-6">
      @でメンションができます
    </div>
  )
}

function InitialImportPlugin({ value }: { value: string }) {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (isJsonString(value)) {
      editor.update(() => {
        const editorState = editor.parseEditorState(value)
        editor.setEditorState(editorState)
      })
    } else {
      // 失敗したらhtmlとして読み込む(消してもいいかも)
      editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(value, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)
        $getRoot().select()
        $insertNodes(nodes)
      })
    }
  }, [editor, value])

  return null
}

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
