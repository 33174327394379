import React from 'react'
import dayjs from 'dayjs'

import { NonFinancialItem } from '@/frontend/api'
import {
  Budget,
  BudgetTagCategory,
  NonFinancialItemTable,
  NonFinancialItemTableRow,
} from '@/frontend/features/allocationDrivers'

export default function ShowPage({
  url,
  itemsOrderUrl,
  months,
  result,
  budget,
  nonFinancialItem,
  tableRows,
  primaryBudgetTagCategory,
  fiscalPeriodId,
}: {
  url: string
  itemsOrderUrl: string
  months: string[]
  result: boolean
  budget: Budget
  nonFinancialItem: NonFinancialItem
  tableRows: NonFinancialItemTableRow[]
  primaryBudgetTagCategory: BudgetTagCategory
  fiscalPeriodId: number
}) {
  return (
    <NonFinancialItemTable
      url={url}
      itemsOrderUrl={itemsOrderUrl}
      months={months.map((str) => dayjs(str, 'YYYY-MM'))}
      result={result}
      budget={budget}
      nonFinancialItem={nonFinancialItem}
      tableRows={tableRows}
      primaryBudgetTagCategory={primaryBudgetTagCategory}
      fiscalPeriodId={fiscalPeriodId}
    />
  )
}
