import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { RiMore2Line } from 'react-icons/ri'

type Props = {
  onEdit: () => void
  onDelete: () => void
}

export function CommentActionDropdown({ onEdit, onDelete }: Props) {
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button className="text-gray-500 hover:text-gray-700 flex items-center justify-center">
          <RiMore2Line className="h-5 w-5" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-30 absolute right-0 w-20 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onEdit}
                  className={`${
                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-700'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  編集
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onDelete}
                  className={`${
                    active ? 'text-gray-900 bg-gray-100' : 'text-gray-700'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  削除
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
