import React from 'react'

import type { SearchUserId } from '@/frontend/api/client'
import type { Client, Member, Org } from '@/frontend/api/types'
import { ClientFilterSelector } from './ClientFilterSelector'
import { CountLabel } from './CountLabel'
import { FolderRestrationButton } from './FolderRestrationButton'
import { InvitationButton } from './InvitationButton'

type Props = {
  org: Org
  clients: Client[] | undefined
  members: Member[] | undefined
  totalCount: number
  creatable: boolean
  handleSearchParamsAndInitialSelectedUserId: (value: SearchUserId) => void
  selectedUserId: SearchUserId | undefined
  onRegistrationButtonClick: () => void
}

export function ContentHeader({
  org,
  clients,
  creatable,
  members,
  handleSearchParamsAndInitialSelectedUserId,
  totalCount,
  selectedUserId,
  onRegistrationButtonClick,
}: Props) {
  const getNumerator = (clients: Client[] | undefined) => {
    return clients ? clients.length : 0
  }
  const isShownDenominator = !!selectedUserId

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <ClientFilterSelector
          members={members}
          handleSearchParamsAndInitialSelectedUserId={
            handleSearchParamsAndInitialSelectedUserId
          }
          selectedUserId={selectedUserId}
        />
        <CountLabel
          numerator={getNumerator(clients)}
          denominator={totalCount}
          isShownDenominator={isShownDenominator}
        />
      </div>
      <div className="flex justify-end space-x-3">
        <FolderRestrationButton
          creatable={creatable}
          onClick={onRegistrationButtonClick}
        />
        <InvitationButton org={org} creatable={creatable} />
      </div>
    </div>
  )
}
