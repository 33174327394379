import consumer from "./consumer"
import { Channel } from "actioncable"

type Metadata = { request_key: string }
type Data = {
  success: boolean,
  job: string,
  action: {
    method: 'none' | 'redirect'
    redirectTo: string | null
  } | null
  error: {
    messages: string[],
  } | null
  metadata: Metadata | null
}
type Callback = (data: Data) => void
type Callbacks = Callback[]

export default class UpdateCsvBudgetDetailJobChannel {
  receivedCallbacks: Callbacks
  subscription: Channel

  constructor ({ orgId = '', budgetId = '', budgetDetailId = '' }){
    this.receivedCallbacks = []

    const self = this

    this.subscription =
      consumer.subscriptions.create({
        channel: "UpdateCsvBudgetDetailJobChannel",
        org_id: orgId,
        budget_id: budgetId,
        budget_detail_id: budgetDetailId,
      }, {
        connected() { console.log('UpdateCsvBudgetDetailJobChannel connected') },
        disconnected() {},
        received(data: Data) {
          console.log('UpdateCsvBudgetDetailJobChannel received:', data)
          self._messageReceived(data)
        },
      })
  }

  onJobStatusChange(callback: Callback) {
    this.receivedCallbacks.push(callback)
  }

  _messageReceived(data: Data) {
    this.receivedCallbacks.forEach(callback => {
      callback(data)
    })
  }
}
