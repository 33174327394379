import { delegate } from 'tippy.js'

document.addEventListener('turbolinks:load', init)

function init () {
  initTooltip('[data-tooltip]')
}

function initTooltip (target) {
  delegate('body', {
    target,
    theme: 'tooltip',
    content: (e) => e.getAttribute('data-tooltip')
  })
}
