import React, { useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import { Button, InputGroup, Modal, Select } from '@/frontend/components'
import { AllocationVersionPayload } from '@/frontend/features/allocationVersions/Form'
import { AllocationDriver } from '@/frontend/features/allocationVersions/types'

const BudgetTypes = [
  { label: '計画は計画値、実績は実績値で計算', value: 'current' },
  { label: '常に計画値で計算', value: 'budget' },
  { label: '常に実績値で計算', value: 'result' },
] as const

type Props = {
  budgetTypeModalOpen: boolean
  onBudgetTypeModalClose: () => void
  periodIndex: number
  allocationDrivers: AllocationDriver[]
}

export function BudgetTypeModal({
  budgetTypeModalOpen,
  onBudgetTypeModalClose,
  periodIndex,
  allocationDrivers,
}: Props) {
  const closeButtonRef = useRef(null)

  return (
    <Modal
      open={budgetTypeModalOpen}
      onClose={() => onBudgetTypeModalClose()}
      initialFocus={closeButtonRef}
      title="配賦ドライバの基準設定"
      actions={
        <Button
          variant="outlined"
          onClick={onBudgetTypeModalClose}
          ref={closeButtonRef}
        >
          閉じる
        </Button>
      }
    >
      <BudgetTypeRows
        periodIndex={periodIndex}
        allocationDrivers={allocationDrivers}
      />
    </Modal>
  )
}

function BudgetTypeRows({
  periodIndex,
  allocationDrivers,
}: {
  periodIndex: number
  allocationDrivers: AllocationDriver[]
}) {
  const { register } = useFormContext<AllocationVersionPayload>()

  return (
    <div className="p-8 space-y-6">
      {allocationDrivers.map((allocationDriver) => {
        return (
          <InputGroup key={allocationDriver.id} label={allocationDriver.name}>
            {(props) => (
              <Select
                {...props}
                {...register(
                  `allocationPeriods.${periodIndex}.allocationDriverBudgetTypes.${allocationDriver.id.toString()}`,
                )}
              >
                {BudgetTypes.map((type) => {
                  return (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  )
                })}
              </Select>
            )}
          </InputGroup>
        )
      })}
    </div>
  )
}
