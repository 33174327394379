import React, { useEffect, useRef, useState } from 'react'
import { Popover, Portal } from '@headlessui/react'
import { usePopper } from 'react-popper'

import { CommentPanel } from './CommentPanel'
import { MentionItem } from './lexicalPlugins/MentionsPlugin'
import { ReportMeta } from './types'

type Props = {
  userId: number
  memberId: number
  title: string
  orgId: number
  reportMeta: ReportMeta
  initialCommentThreadId: number | null
  initialCommentThreadResolved: boolean | null
  creatableComment: boolean
  commentMentionMembers: MentionItem[]
  initialOpening: boolean
}

export function CommentOverlay({
  userId,
  memberId,
  title,
  orgId,
  reportMeta,
  initialCommentThreadId,
  initialCommentThreadResolved,
  creatableComment,
  commentMentionMembers,
  initialOpening,
}: Props) {
  const [commentThreadId, setCommentThreadId] = useState(initialCommentThreadId)
  const [commentThreadResolved, setCommentThreadResolved] = useState(
    initialCommentThreadResolved,
  )

  return (
    <div>
      <CommentOverlayContent
        userId={userId}
        memberId={memberId}
        title={title}
        orgId={orgId}
        reportMeta={reportMeta}
        commentThreadId={commentThreadId}
        commentThreadResolved={commentThreadResolved}
        setCommentThreadId={setCommentThreadId}
        setCommentThreadResolved={setCommentThreadResolved}
        creatableComment={creatableComment}
        commentMentionMembers={commentMentionMembers}
        initialOpening={initialOpening}
      />
    </div>
  )
}

function CommentOverlayContent({
  userId,
  memberId,
  title,
  orgId,
  reportMeta,
  commentThreadId,
  commentThreadResolved,
  setCommentThreadId,
  setCommentThreadResolved,
  creatableComment,
  commentMentionMembers,
  initialOpening,
}: {
  userId: number
  memberId: number
  title: string
  orgId: number
  reportMeta: ReportMeta
  commentThreadId: number | null
  commentThreadResolved: boolean | null
  setCommentThreadId: (commentThreadId: number | null) => void
  setCommentThreadResolved: (commentThreadResolved: boolean | null) => void
  creatableComment: boolean
  commentMentionMembers: MentionItem[]
  initialOpening: boolean
}) {
  const suggestionContainerRef = useRef<HTMLDivElement>(null)
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'right',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })

  useEffect(() => {
    if (commentThreadId && initialOpening) {
      triggerElement?.click()
    }
  }, [commentThreadId, initialOpening, triggerElement])

  if (!commentThreadId && !creatableComment) {
    return <></>
  }

  return (
    <Popover>
      <Popover.Button
        ref={setTriggerElement}
        className="group/trigger block absolute top-0 right-0"
      >
        {commentThreadId && !commentThreadResolved ? (
          <div>
            <div
              className="w-2 h-2 bg-orange-500 group-hover/trigger:bg-orange-600"
              style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%)' }}
            />
          </div>
        ) : (
          <div className="opacity-0 group-hover:opacity-100">
            <div
              className="w-2 h-2 bg-orange-400  group-hover/trigger:bg-orange-600"
              style={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%)' }}
            />
          </div>
        )}
      </Popover.Button>
      <Portal>
        <Popover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="absolute z-50"
        >
          <div>
            <CommentPanel
              userId={userId}
              memberId={memberId}
              title={title}
              orgId={orgId}
              reportMeta={reportMeta}
              commentThreadId={commentThreadId}
              commentThreadResolved={commentThreadResolved}
              onPostComment={(comment) => {
                if (commentThreadId == null) {
                  setCommentThreadId(comment.commentThreadId)
                }
              }}
              onUpdateCommentThread={(commentThread) => {
                setCommentThreadResolved(commentThread.resolved)
              }}
              onDeleteCommentThread={() => setCommentThreadId(null)}
              creatableComment={creatableComment}
              suggestionContainerRef={suggestionContainerRef}
              commentMentionMembers={commentMentionMembers}
            />
          </div>
          <div ref={suggestionContainerRef} />
        </Popover.Panel>
      </Portal>
    </Popover>
  )
}
