import React from 'react'
import { RiExternalLinkLine } from 'react-icons/ri'

import { Org } from '@/frontend/api/types'

export default function TagCategoryLink({ org }: { org: Org }) {
  return (
    <a
      href={`/orgs/${org.id}/tag_categories`}
      target="_blank"
      rel="noreferrer"
      className="text-blue-600 inline-flex flex-row items-center"
    >
      タグカテゴリを登録
      <RiExternalLinkLine />
    </a>
  )
}
