import React from 'react'

import { FiscalPeriod, Form, FormValue, Item } from './components'

type Props = {
  formAuthenticityToken: string
  formAction: string
  formMethod: string
  fiscalPeriods: FiscalPeriod[]
  leftItems: Item[]
  rightItems: Item[]
  defaultFormValue: FormValue
  fiscalPeriodsPath: string
}

export default function New({
  formAuthenticityToken,
  formAction,
  formMethod,
  fiscalPeriods,
  leftItems,
  rightItems,
  defaultFormValue,
  fiscalPeriodsPath,
}: Props) {
  return (
    <Form
      formAuthenticityToken={formAuthenticityToken}
      formAction={formAction}
      formMethod={formMethod}
      fiscalPeriods={fiscalPeriods}
      leftItems={leftItems}
      rightItems={rightItems}
      defaultFormValue={defaultFormValue}
      fiscalPeriodsPath={fiscalPeriodsPath}
    />
  )
}
