import { lazyGetter, numberWithDelimiter, stringToInteger } from '../utils'
import Decimal from 'decimal.js'

export default class Cell extends EventTarget {
  constructor({ element }) {
    super()
    this.element = element
    this.inComposition = false
    this.initializeAmount()
    this.addEventListeners()
  }

  initializeAmount() {
    if (this.isDebit) {
      this.debitAmount = this.displayedBalance
      this.creditAmount = 0
    } else {
      this.debitAmount = 0
      this.creditAmount = this.displayedBalance
    }
  }

  addEventListeners() {
    this.element.addEventListener(
      'compositionstart',
      this.onCompositionStart.bind(this),
    )
    this.element.addEventListener(
      'compositionend',
      this.onCompositionEnd.bind(this),
    )
    this.element.addEventListener('input', this.onInput.bind(this))
    this.element.addEventListener('change', this.onChange.bind(this))
  }

  onCompositionStart(e) {
    this.inComposition = true
  }

  onCompositionEnd(e) {
    const changeAmount = this.updateBalance()
    const event = new CustomEvent('input', { detail: { changeAmount } })
    this.dispatchEvent(event)
    this.inComposition = false
  }

  onInput(e) {
    if (this.inComposition) return
    const changeAmount = this.updateBalance()
    const event = new CustomEvent('input', { detail: { changeAmount } })
    this.dispatchEvent(event)
  }

  onChange(e) {
    this.updateHtml()
    const event = new CustomEvent('change')
    this.dispatchEvent(event)
  }

  updateBalance() {
    const val = this.inputElement.value
    const int = stringToInteger(val)
    if (isNaN(int)) return { debit: 0, credit: 0 }

    const changeAmount = this.calcChangeAmount(int)
    this.addAmount(changeAmount)

    return changeAmount
  }

  setBalance(num) {
    if (num == null) {
      this.setNaN()
    } else {
      if (this.isDebit) {
        this.debitAmount = num
        this.creditAmount = 0
      } else {
        this.debitAmount = 0
        this.creditAmount = num
      }
    }
  }

  setAmount({ debit, credit }) {
    if (debit == null || credit == null) {
      this.setNaN()
    } else {
      this.debitAmount = debit
      this.creditAmount = credit
    }
  }

  setNaN() {
    this.debitAmount = NaN
    this.creditAmount = NaN
  }

  addAmount({ debit, credit }) {
    this.debitAmount += debit
    this.creditAmount += credit
  }

  calcChangeAmount(val) {
    const changedAmount = val - this.balance

    return {
      debit: this.isDebit ? changedAmount : 0,
      credit: this.isDebit ? 0 : changedAmount,
    }
  }

  updateHtml() {
    if (this.isVariable) {
      var number = this.rate
    } else {
      var number = this.balance
    }
    const numberForDisplay = numberWithDelimiter(number)

    if (this.isInput) {
      return (this.inputElement.value = numberForDisplay)
    } else if (this.isLink) {
      return (this.linkElement.textContent = numberForDisplay)
    } else {
      return (this.element.textContent = numberForDisplay)
    }
  }

  get balance() {
    return this.isDebit
      ? this.debitAmount - this.creditAmount
      : this.creditAmount - this.debitAmount
  }

  get rate() {
    const str = this.isInput
      ? this.inputElement.value
      : this.element.textContent

    if (str.trim() === '' || isNaN(str)) {
      return new Decimal(0).toFixed(1)
    } else {
      return new Decimal(str.replace(/,/g, '')).toFixed(1)
    }
  }

  get displayedBalance() {
    const str = this.isInput
      ? this.inputElement.value
      : this.element.textContent
    return parseInt(str.replace(/,/g, ''))
  }

  set displayedBalance(val) {
    if (this.isInput) {
      return (this.inputElement.value = val)
    } else {
      return (this.element.textContent = val)
    }
  }

  get isInput() {
    return !!this.inputElement
  }

  get inputElement() {
    return lazyGetter(this, 'inputElement', () => {
      return this.element.querySelector('input')
    })
  }

  get isLink() {
    return !!this.linkElement
  }

  get linkElement() {
    return lazyGetter(this, 'linkElement', () => {
      return this.element.querySelector('a')
    })
  }

  get data() {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-pl-state')
      return JSON.parse(jsonString)
    })
  }

  get columnId() {
    return this.data.columnId
  }

  get rowId() {
    return this.data.rowId
  }

  get transactionType() {
    return this.data.transactionType
  }

  get isDebit() {
    return this.transactionType === 'debit'
  }

  get isCredit() {
    return this.transactionType === 'credit'
  }

  get isVariable() {
    return this.data.variable
  }

  get isCostRate() {
    return this.date.costTypeCell
  }

  get isVariableCostAccountItem() {
    return this.data.variableCostAccountItemCell
  }
}
