import { useCallback, useState } from 'react'

import { JobLogStatus } from '@/frontend/api'
import { ImportFileStatus } from '../types'

export default function useImportFileStatus(
  initialImportFileStatus: ImportFileStatus | null,
) {
  const [importFileStatus, setImportFileStatus] =
    useState<ImportFileStatus | null>(initialImportFileStatus)

  const updateOnlyStatusForImportFile = useCallback(
    (status: JobLogStatus) => {
      setImportFileStatus((prev) => {
        if (!prev) return null
        return {
          ...prev,
          status,
        }
      })
    },
    [setImportFileStatus],
  )

  const updateImportFileToInProgress = (files: File[]) => {
    setImportFileStatus({
      attachedFiles: files,
      status: 'in_progress',
    })
  }

  return {
    importFileStatus,
    setImportFileStatus,
    updateImportFileToInProgress,
    updateOnlyStatusForImportFile,
  }
}
