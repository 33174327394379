import { createErr, createOk, Result } from 'option-t/esm/PlainResult'

import { axios, z } from '@/frontend/utils'
import {
  checkAllCommentsUrl,
  createCustomReportCommentUrl,
  createPreparedReportCommentUrl,
  deleteCommentThreadUrl,
  getCommentUrl,
} from './routes'
import { ApiError, Comment } from './types'
import { handleAxiosError } from './utils'

export const createCommentSchema = z
  .object({
    content: z.string(),
    mentions: z.array(z.number().or(z.literal('all'))).optional(),
    textLength: z.number().optional(),
    textContent: z.string(),
  })
  .refine(
    (data) =>
      (data.textContent?.length ?? 0) >= 1 &&
      (data.textContent?.length ?? 0) <= 1000,
    {
      message: '1から1000文字以内の間で入力してください',
      path: ['content'],
    },
  )
export const updateCommentSchema = createCommentSchema

export type CreateCommentPayload = z.infer<typeof createCommentSchema>

export type UpdateCommentPayload = z.infer<typeof updateCommentSchema>

export type CommentThreadPosition = {
  customReportItemId: number | null
  comparisonTableColumnId: number | null
  allocationVersionId: number | null
  cellType: string | null
  firstDate: string | null
  lastDate: string | null
  customReportCommentThreadPositionBudgets: {
    borderDate: string | null
    budgetId: number | null
    pastResultKey: string | null
    position: string | null
  }[]
  customReportCommentThreadPositionBudgetTags: {
    budgetTagCategoryId: number | null
    budgetTagId: number | null
  }[]
}

export type PreparedReportCommentThreadPosition = {
  accountItemId: number | null
  account_item_group_id: number | null
  reserved_item_id: number | null
  ratio_item_name: string | null
  allocationVersionId: number | null
  cashFlowCategoryId: string
  borrowingId: number | null
  transactionType: number | null
  cellType: string | null
  firstDate: string | null
  lastDate: string | null
  preparedReportCommentThreadPositionBudgets: {
    borderDate: string | null
    budgetId: number | null
    pastResultKey: string | null
    position: string | null
  }[]
  preparedReportCommentThreadPositionBudgetTags: {
    budgetTagCategoryId: number | null
    budgetTagId: number | null
  }[]
}

export async function createCustomReportComment(
  orgId: number,
  customReportId: number,
  commentThreadId: number | null,
  commentThreadPosition: CommentThreadPosition,
  payload: CreateCommentPayload,
): Promise<Result<Comment, ApiError>> {
  const url = createCustomReportCommentUrl(orgId, customReportId)
  try {
    const searchQueryToken = getReportSearchQueryToken()
    const res = await axios.post(url, {
      ...payload,
      commentThreadId,
      customReportCommentThreadPosition: commentThreadPosition,
      searchQueryToken,
    })
    const comment = res.data as Comment
    return createOk(comment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function createPreparedReportComment(
  orgId: number,
  reportType: string,
  commentThreadId: number | null,
  commentThreadPosition: PreparedReportCommentThreadPosition,
  payload: CreateCommentPayload,
): Promise<Result<Comment, ApiError>> {
  const url = createPreparedReportCommentUrl(orgId)
  try {
    const searchQueryToken = getReportSearchQueryToken()
    const res = await axios.post(url, {
      ...payload,
      reportType,
      commentThreadId,
      preparedReportCommentThreadPosition: commentThreadPosition,
      searchQueryToken,
    })
    const comment = res.data as Comment
    return createOk(comment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function updateComment(
  orgId: number,
  commentThreadId: number,
  id: number,
  payload: UpdateCommentPayload,
): Promise<Result<Comment, ApiError>> {
  const url = getCommentUrl(orgId, commentThreadId, id)
  try {
    const res = await axios.put(url, payload)
    const comment = res.data as Comment
    return createOk(comment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function deleteComment(
  orgId: number,
  commentThreadId: number,
  id: number,
): Promise<Result<void, ApiError>> {
  const url = getCommentUrl(orgId, commentThreadId, id)
  try {
    await axios.delete(url)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function deleteCommentThread(
  orgId: number,
  commentThreadId: number,
): Promise<Result<void, ApiError>> {
  const url = deleteCommentThreadUrl(orgId, commentThreadId)
  try {
    await axios.delete(url)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function checkAllComments(
  orgId: number,
  commentThreadId: number,
): Promise<Result<number[], ApiError>> {
  const url = checkAllCommentsUrl(orgId, commentThreadId)
  try {
    const res = await axios.put(url)
    return createOk(res.data as number[])
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export const searchCommentSchema = z.object({
  mensionMemberIds: z.array(z.string()).optional(),
  senderMemberIds: z.array(z.string()).optional(),
  resolveType: z.enum(['resolved', 'unresolved', 'all']),
  contentSearchText: z.string().optional(),
  currentSearchFilter: z.boolean(),
})
export type SearchCommentPayload = z.infer<typeof searchCommentSchema>

const getReportSearchQueryToken = () => {
  const tokenElement = document.getElementById(
    'report-search-query-token',
  ) as HTMLFormElement
  return tokenElement.dataset.reportSearchQueryToken
}
