import React, { useRef } from 'react'

import { Button, ButtonLink, ModalWithIcon } from '@/frontend/components'

type Props = {
  open: boolean
  onClose: () => void
  onAcceptCancel: () => void
  allocationVersionsUrl: string
}

export function CancelDialog({
  open,
  onClose,
  onAcceptCancel,
  allocationVersionsUrl,
}: Props) {
  const cancelButtonRef = useRef(null)
  const handleAcceptCancelButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onAcceptCancel()

    const { href } = e.target as HTMLAnchorElement
    location.href = href
  }

  return (
    <ModalWithIcon
      open={open}
      variant="danger"
      onClose={() => onClose()}
      initialFocus={cancelButtonRef}
      title="編集した内容を破棄しますか？"
      actions={
        <>
          <Button variant="outlined" onClick={onClose} ref={cancelButtonRef}>
            キャンセル
          </Button>
          <ButtonLink
            onClick={handleAcceptCancelButton}
            variant="dangerPrimary"
            href={allocationVersionsUrl}
          >
            破棄
          </ButtonLink>
        </>
      }
    >
      ページを離れると入力した内容が破棄されます。
    </ModalWithIcon>
  )
}
