import React, { useState } from 'react'

import type {
  ClientsSearchParamsPayload,
  SearchUserId,
} from '@/frontend/api/client'
import type { ClientFolder, MemberWithAssign, Org } from '@/frontend/api/types'
import { PageContainer } from '@/frontend/components'
import {
  ContentHeader,
  DeleteDialog,
  Layout,
  PageHeader,
  Table,
  useClients,
  useDeleteClient,
  useMembers,
} from '@/frontend/features/clients'
import { BlankContent } from '@/frontend/features/clients/BlankContent'
import { FolderFormDialog } from '@/frontend/features/clients/FolderFormDialog'
import { useFolderFormDialog } from '@/frontend/features/clients/hooks'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'

type Props = {
  org: Org
  member: MemberWithAssign
  clientFolders: ClientFolder[]
  creatable: boolean
  assignable: boolean
  destroyable: boolean
  formAuthenticityToken: string
  settingAccessible?: boolean
  memberInvitationAccessible?: boolean
  contractAccessible?: boolean
  chargeAccessible?: boolean
  receiptAccessible?: boolean
}

const INITIAL_CLIENTS_SELECTED_USER_ID_KEY =
  'ClientsInitiaialSelectedUserId' as const

export default function IndexPage({
  org,
  member,
  clientFolders,
  creatable,
  assignable,
  destroyable,
  settingAccessible,
  memberInvitationAccessible,
  contractAccessible,
  chargeAccessible,
  receiptAccessible,
  formAuthenticityToken,
}: Props) {
  const [initialSelectedUserId, setInitialSelectedUserId] = useLocalStorage<
    SearchUserId | undefined
  >(INITIAL_CLIENTS_SELECTED_USER_ID_KEY)
  const [searchParamsPayload, setSearchParamsPayload] =
    useState<ClientsSearchParamsPayload>({
      searchUserId: initialSelectedUserId,
    })
  const {
    data: clientsResponse,
    error: clientsError,
    isLoading: clientsIsLoading,
    mutate: mutateClients,
  } = useClients(org, searchParamsPayload)
  const { data: members } = useMembers(org)

  const {
    isConfirmingDeletion,
    isDeleting,
    clientForDeletion,
    confirmDeletion,
    cancelDeletion,
    deleteClient,
    deleteApiError,
  } = useDeleteClient(org, clientsResponse, mutateClients)

  const clients = clientsResponse?.clients
  const clientsTotalCount = clientsResponse?.meta?.total || 0

  const folderDialog = useFolderFormDialog(org)
  const existsData = (clients && clients.length > 0) || clientFolders.length > 0

  const handleSearchParamsAndInitialSelectedUserId = (value: SearchUserId) => {
    setSearchParamsPayload({ searchUserId: value })
    setInitialSelectedUserId(value)
  }

  return (
    <PageContainer isLoading={clientsIsLoading} hasError={!!clientsError}>
      <PageHeader
        org={org}
        settingAccessible={settingAccessible}
        memberInvitationAccessible={memberInvitationAccessible}
        contractAccessible={contractAccessible}
        chargeAccessible={chargeAccessible}
        receiptAccessible={receiptAccessible}
      />
      <Layout>
        <ContentHeader
          org={org}
          clients={clients}
          members={members}
          creatable={creatable}
          totalCount={clientsTotalCount}
          handleSearchParamsAndInitialSelectedUserId={
            handleSearchParamsAndInitialSelectedUserId
          }
          selectedUserId={
            searchParamsPayload?.searchUserId || initialSelectedUserId
          }
          onRegistrationButtonClick={
            folderDialog.actions.onRegistrationFolderDialogOpen
          }
        />
        {existsData ? (
          <>
            <Table
              org={org}
              member={member}
              clients={clients || []}
              clientFolders={clientFolders}
              searchParamsPayload={searchParamsPayload}
              assignable={assignable}
              creatable={creatable}
              destroyable={destroyable}
              onDelete={confirmDeletion}
              onFolderEdit={folderDialog.actions.onEditFolderDialogOpen}
              formAuthenticityToken={formAuthenticityToken}
            />
            <DeleteDialog
              open={isConfirmingDeletion}
              onClose={cancelDeletion}
              onSubmit={deleteClient}
              client={clientForDeletion}
              isSubmitting={isDeleting}
              apiError={deleteApiError}
            />
          </>
        ) : (
          <BlankContent text={'クライアントが登録されていません'} />
        )}
        <FolderFormDialog
          {...folderDialog.dialogProps}
          onClose={folderDialog.actions.onClose}
          clientFolders={clientFolders}
          formAuthenticityToken={formAuthenticityToken}
          folderName={folderDialog.dialogProps.folderName}
        />
      </Layout>
    </PageContainer>
  )
}
