import { createErr, createOk, Result } from 'option-t/PlainResult'

import { axios, dayjs, z } from '@/frontend/utils'
import { importFreeApiUrl } from '../../routes'
import { ApiError, JobLog } from '../../types'
import { handleAxiosError } from '../../utils'

export const freeeApiImportSchema = z
  .object({
    budgetId: z.preprocess(
      (v) => (v === '' ? null : Number(v)),
      z.number().nullable(),
    ),
    firstDate: z.string().min(1, '開始日と終了日は入力必須です'),
    lastDate: z.string().min(1, '開始日と終了日は入力必須です'),
    tagCategories: z.string().array(),
  })
  .refine(
    (values) => {
      if (values.firstDate && values.lastDate) {
        return values.firstDate <= values.lastDate
      }
      return true
    },
    {
      message: '開始日が終了日より後になっています',
      path: ['period'],
    },
  )
  .refine(
    (values) => {
      if (values.firstDate && values.lastDate) {
        const firstDate = dayjs(values.firstDate)
        const lastDate = dayjs(values.lastDate)

        const oneYearLater = firstDate.add(1, 'year')
        const oneYearBeforeLast = lastDate.subtract(1, 'year')

        return !(
          oneYearLater.isBefore(lastDate) &&
          firstDate.isBefore(oneYearBeforeLast)
        )
      }
      return true
    },
    {
      message: '期間の終了日は開始日から1年以内の日付を指定してください',
      path: ['period'],
    },
  )

export type FreeeApiImportPayload = z.infer<typeof freeeApiImportSchema>

export async function importFreeeApi(
  orgId: number,
  payload: FreeeApiImportPayload,
): Promise<Result<JobLog, ApiError>> {
  const url = importFreeApiUrl(orgId)
  try {
    const res = await axios.post(url, payload)
    const jobLog = res.data as JobLog
    return createOk(jobLog)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
