import { createErr, createOk, Result } from 'option-t/PlainResult'

import { axios, z } from '@/frontend/utils'
import { bugyoCloudCompaniesApiUrl } from '../../routes'
import { ApiError } from '../../types'
import { handleAxiosError } from '../../utils'
import { createApiUpdateCompanySchema } from '../apiUpdateCompanyTypes'

export const bugyoCloudApiUpdateCompanySchema = createApiUpdateCompanySchema(
  z.string(),
)
export type BugyoCloudApiUpdateCompanyPayload = z.infer<
  typeof bugyoCloudApiUpdateCompanySchema
>

export async function updateBugyoCloudCompany(
  orgId: number,
  payload: BugyoCloudApiUpdateCompanyPayload,
): Promise<Result<undefined, ApiError>> {
  const url = bugyoCloudCompaniesApiUrl(orgId)
  try {
    await axios.put(url, payload)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
