import snakeCaseKeys from 'snakecase-keys'

import type { ClientsSearchParamsPayload } from '@/frontend/api/client'

export function getBudgetTagsUrl(
  orgId: number,
  budget_tag_category_id: number,
) {
  return `/api/orgs/${orgId}/budget_tags?budget_tag_category_id=${budget_tag_category_id}`
}

export function getMembersUrl(orgId: number) {
  return `/api/orgs/${orgId}/members`
}

export function getMemberInvitationBulkImportUrl(orgId: number) {
  return `/api/orgs/${orgId}/member_invitations/bulk_import`
}

export function getClientsUrl(
  orgId: number,
  params: ClientsSearchParamsPayload = {},
) {
  const base = `/api/orgs/${orgId}/accounting_firms/clients`
  const search = new URLSearchParams()

  const queries = Object.entries(snakeCaseKeys(params))
  queries.forEach(([key, value]) => {
    if (value == null || value === '') {
      return
    }
    search.append(key, value.toString())
  })

  const queryString = search.toString()
  return queryString ? base + '?' + queryString : base
}

export function importMfcApiUrl(orgId: number) {
  return `/orgs/${orgId}/result/mfc/api/imports/sources_imports`
}

export function importMfcCsvJournalUrl(orgId: number) {
  return `/orgs/${orgId}/result/mfc/csv/imports/source_journals_imports`
}

export function importMfcPlusApiUrl(orgId: number) {
  return `/orgs/${orgId}/result/mfc_plus/api/imports/sources_imports`
}

export function importFreeApiUrl(orgId: number) {
  return `/orgs/${orgId}/result/freee/api/imports/sources_imports`
}

export function freeeCompaniesApiUrl(orgId: number) {
  return `/api/orgs/${orgId}/importers/freee/api/companies`
}

export function importBugyoCloudApiUrl(orgId: number) {
  return `/orgs/${orgId}/result/bugyo_cloud/api/imports/sources_imports`
}

export function importMfcCsvSourceAccoutItemUrl(orgId: number) {
  return `/orgs/${orgId}/result/mfc/csv/imports/source_account_items_imports`
}

export function importSourcesPendingCheckerUrl(orgId: number) {
  return `/api/orgs/${orgId}/importers/sources/pending_checkers`
}

export function uploadGeneralCsvSourceAccountItemsUrl(orgId: number) {
  return `/orgs/${orgId}/result/general/csv/account_items_upload`
}

export function uploadGeneralCsvJournalsUrl(orgId: number) {
  return `/orgs/${orgId}/result/general/csv/journals_upload`
}

export function bugyoCloudCompaniesApiUrl(orgId: number) {
  return `/api/orgs/${orgId}/importers/bugyo_cloud/api/companies`
}

export function importBugyoICsvSourceAccoutItemUrl(orgId: number) {
  return `/orgs/${orgId}/result/bugyo_i/csv/imports/source_account_items_imports`
}

export function importYayoiCsvSourceAccoutItemUrl(orgId: number) {
  return `/orgs/${orgId}/result/yayoi/csv/imports/source_account_items_imports`
}

export function importYayoiCsvJournalUrl(orgId: number) {
  return `/orgs/${orgId}/result/yayoi/csv/imports/source_journals_imports`
}

export function importBugyoICsvJournalUrl(orgId: number) {
  return `/orgs/${orgId}/result/bugyo_i/csv/imports/source_journals_imports`
}

export function updateJournalsFromSourceUrl(orgId: number) {
  return `/api/orgs/${orgId}/importers/journals/bulk_update`
}

export function getClientUrl(orgId: number, id: number) {
  return `/api/orgs/${orgId}/accounting_firms/clients/${id}`
}

export function getClientFoldersUrl(orgId: number) {
  return `/orgs/${orgId}/client_folders`
}

export function getClientFolderUrl(orgId: number, id: number) {
  return `/orgs/${orgId}/client_folders/${id}`
}

export function getClientsOrderUrl(orgId: number) {
  return `/api/orgs/${orgId}/accounting_firms/clients/order`
}

export function getNonFinancialItemsUrl(orgId: number) {
  return `/api/orgs/${orgId}/non_financial_items`
}

export function getNonFinancialItemUrl(orgId: number, id: number) {
  return `/api/orgs/${orgId}/non_financial_items/${id}`
}

export function getAllocationVersionsUrl(orgId: number) {
  return `/api/orgs/${orgId}/allocation_versions`
}

export function getAllocationVersionUrl(orgId: number, id: number) {
  return `/api/orgs/${orgId}/allocation_versions/${id}`
}

export function getNotificationsUrl(
  orgId: number,
  params: { limit?: number; cursor?: number } = {},
) {
  const base = `/api/orgs/${orgId}/notifications`
  const search = new URLSearchParams()
  if (params.limit != null) {
    search.append('limit', params.limit.toString())
  }
  if (params.cursor != null) {
    search.append('cursor', params.cursor.toString())
  }

  return base + '?' + search.toString()
}

export function getJobLogUrl(orgId: number, id: number) {
  return `/api/orgs/${orgId}/job_logs/${id}`
}

export function checkAllNotificationsUrl(orgId: number) {
  return `/api/orgs/${orgId}/notifications/check_all`
}

export function createCustomReportCommentUrl(
  orgId: number,
  customReportId: number,
) {
  return `/api/orgs/${orgId}/reports/custom_reports/${customReportId}/comments`
}

export function createPreparedReportCommentUrl(orgId: number) {
  return `/api/orgs/${orgId}/reports/prepareds/comments`
}

export function createCustomReportGeneralCommentUrl(
  orgId: number,
  customReportId: number,
) {
  return `/api/orgs/${orgId}/reports/custom_reports/${customReportId}/report_general_comments`
}

export function updateCustomReportGeneralCommentUrl(
  orgId: number,
  customReportId: number,
  reportGeneralCommentId: number,
) {
  return `/api/orgs/${orgId}/reports/custom_reports/${customReportId}/report_general_comments/${reportGeneralCommentId}`
}

export function deleteCustomReportGeneralCommentUrl(
  orgId: number,
  customReportId: number,
  reportGeneralCommentId: number,
) {
  return updateCustomReportGeneralCommentUrl(
    orgId,
    customReportId,
    reportGeneralCommentId,
  )
}

export function createPreparedReportGeneralCommentUrl(orgId: number) {
  return `/api/orgs/${orgId}/reports/prepareds/report_general_comments`
}

export function updatePreparedReportGeneralCommentUrl(
  orgId: number,
  reportGeneralCommentId: number,
) {
  return `/api/orgs/${orgId}/reports/prepareds/report_general_comments/${reportGeneralCommentId}`
}

export function deletePreparedReportGeneralCommentUrl(
  orgId: number,
  reportGeneralCommentId: number,
) {
  return updatePreparedReportGeneralCommentUrl(orgId, reportGeneralCommentId)
}

export function getCommentsUrl(orgId: number, commentThreadId: number) {
  return `/api/orgs/${orgId}/reports/comment_threads/${commentThreadId}/comments`
}

export function getCommentUrl(
  orgId: number,
  commentThreadId: number,
  commentId: number,
) {
  return `/api/orgs/${orgId}/reports/comment_threads/${commentThreadId}/comments/${commentId}`
}

export function updateCommentThreadUrl(orgId: number, commentThreadId: number) {
  return `/api/orgs/${orgId}/reports/comment_threads/${commentThreadId}`
}

export function deleteCommentThreadUrl(orgId: number, commentThreadId: number) {
  return updateCommentThreadUrl(orgId, commentThreadId)
}

export function checkAllCommentsUrl(orgId: number, commentThreadId: number) {
  return `/api/orgs/${orgId}/reports/comment_threads/${commentThreadId}/check_all_comments`
}

export function getCustomReportCommentsUrl(
  orgId: number,
  customReportId: number,
) {
  return `/api/orgs/${orgId}/reports/custom_reports/${customReportId}/comments`
}

export function getPreparedReportCommentsUrl(orgId: number) {
  return `/api/orgs/${orgId}/reports/prepareds/comments`
}
