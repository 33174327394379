// タググループのチェックボックスクリック時
document.addEventListener('change', (event) => {
  const condition = event.target.hasAttribute('data-group-check-box')

  if (condition) bulkUncheck(event.target)
})

// 一括チェックボックスクリック時
document.addEventListener('change', (event) => {
  const changed = event.target

  if (!changed.hasAttribute('data-all-check-box')) return

  bulkUncheck(changed)
})

// ページ表示時
document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('[data-target-check-box]:checked')
  elements.forEach((e) => {
    bulkUncheck(e)
  })
  const element = document.querySelector('[data-all-check-box]:checked')
  if (element) bulkUncheck(element)
})

function bulkUncheck (element) {
  const childBoxes = getChildCheckBoxes(element)
  const descendantBoxes = getDescendantCheckBoxes(element)

  if (element.checked) {
    // ターゲット全部のチェックをはずしてdisabled
    childBoxes.forEach((e) => {
      e.checked = false
      e.disabled = true
      e.classList.add('bg-blue')
    })
    descendantBoxes.forEach((e) => {
      e.checked = false
      e.disabled = true
      e.classList.add('bg-blue')
    })
  } else {
    // ターゲット全部のdisabledを解除
    childBoxes.forEach((e) => {
      e.disabled = false
      e.classList.remove('bg-blue')
    })
    descendantBoxes.forEach((e) => {
      e.disabled = false
      e.classList.remove('bg-blue')
    })
  }
}

// 自身の子どものタグ及びタググループを取得する
function getChildCheckBoxes(element) {
  if (element.hasAttribute('data-all-check-box')) {
    // すべてのチェックボックスを取得する
    var selector = '[data-target-check-box]'
  } else {
    // 子どものチェックボックスを取得する
    const baseId = element.dataset.baseId
    var selector = '[data-parent-id="' + baseId + '"]'
  }
  const nodeList = document.querySelectorAll(selector)
  return Array.from(nodeList)
}

// 自身の子孫(子どもを含まない)のタグ及びタググループを取得する
function getDescendantCheckBoxes(element) {
  var arr = []
  if (element.hasAttribute('data-all-check-box')) {
    return arr
  } else {
    // 子孫のチェックボックスを取得する
    const baseId = element.dataset.baseId
    return getDescendantCheckBoxesRecursive(baseId)
  }
}

function getDescendantCheckBoxesRecursive (currentId) {
  let arr = []
  const selector = '[data-parent-id="' + currentId + '"]'
  const nodeList = document.querySelectorAll(selector)

  // 子どもがいなければ終了
  if (!nodeList.length) return arr

  nodeList.forEach((e) => {
    if (e.hasAttribute('data-group-check-box')) {
      // 子どもが持つ子どもを取得する
      const list = getChildCheckBoxes(e)
      const result = Array.from(list)
      arr = arr.concat(result)

      // 子どもが持つ子どものさらに子孫を再帰的に取得する
      const nextId = e.dataset.baseId
      const nextList = getDescendantCheckBoxesRecursive(nextId)
      const nextResult = Array.from(nextList)
      arr = arr.concat(nextResult)
    }
  })

  return arr
}
