import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'saveInput']
  static values = { storageKey: String, orgId: Number }
  
  formObject = {}
  
  connect() {
    this.loadFromLocalStorage()
    this.formTarget.addEventListener(
      'submit',
      this.saveToLocalStorage.bind(this),
    )
  }
  loadFromLocalStorage() {
    // Load data from local storage
    const storedData = localStorage.getItem(this.storageKeyValue)
    // If there is data in the LocalStorage
    if (storedData) {
      this.formObject = JSON.parse(storedData)
      // Check if there is a value for the orgId in the LocalStorage
      if (this.formObject[this.orgIdValue]) {
        this.saveInputTargets.forEach((element) => {
          const name = element.name
          if (name && this.formObject[this.orgIdValue][name] !== undefined) {
            element.value = this.formObject[this.orgIdValue][name]
          }
        })
      }  
    }
  }

  saveToLocalStorage() {
    //save data to local storage as a Object using the orgId as the key
    this.formObject[this.orgIdValue] = {}
    this.saveInputTargets.forEach((element) => {
      const name = element.name
      if (name) {
        this.formObject[this.orgIdValue][name] = element.value
      }
    })
    localStorage.setItem(this.storageKeyValue, JSON.stringify(this.formObject))
  }
}
