import Row from './Row'
import { lazyGetter } from '../utils'

export default class TreeAccordion extends EventTarget {
  constructor (target) {
    super()
    this.target = target
    this.rows = this.buildRows()
    this.addEventListeners()
    this.initialize()
    this.openCollapsedRows()
  }

  get rowMap () {
    return lazyGetter(this, 'rowMap', () => {
      return this.rows.reduce((map, row) => {
        map[row.path] = row
        return map
      }, {})
    })
  }

  get childRowsMap () {
    return lazyGetter(this, 'childRowsMap', () => {
      return this.rows.reduce((map, row) => {
        if(!map[row.ancestors]) map[row.ancestors] = []
        map[row.ancestors].push(row)
        return map
      }, {})
    })
  }

  buildRows () {
    return Array.from(
      this.target.querySelectorAll('[data-tree-accordion]'),
      e => new Row(e)
    )
  }

  addEventListeners () {
    this.rows.forEach(row => {
      row.addEventListener('collapse', this.onRowCollapse.bind(this))
      row.addEventListener('open', this.onRowOpen.bind(this))
      row.addEventListener('filter:hit', this.onFilterHit.bind(this))
    })
  }

  onRowCollapse ({ target: row }) {
    const childRows = this.findChildRows(row)
    this.hideRowsRecursive(childRows)
  }

  onRowOpen ({ target: row }) {
    const childRows = this.findChildRows(row)
    this.showRowsRecursive(childRows)
  }

  onFilterHit ({ target: row }) {
    const parentRow = this.findParentRow(row)
    if (parentRow) this.hitParentRowRecursive(parentRow)
  }

  hideRowsRecursive (rows) {
    rows.forEach(row => {
      row.onAncestorCollapsed()

      const childRows = this.findChildRows(row)
      this.hideRowsRecursive(childRows)
    })
  }

  showRowsRecursive (rows) {
    rows.forEach(row => {
      row.onAncestorUncollapsed()

      if (!row.isCollapsed) {
        const childRows = this.findChildRows(row)
        this.showRowsRecursive(childRows)
      }
    })
  }

  hitParentRowRecursive (row) {
    row.isFilteredOut = false
    row.open()
    row.updateVisibility()

    const parentRow = this.findParentRow(row)
    if (parentRow) this.hitParentRowRecursive(parentRow)
  }

  findRowByPath (path) {
    return this.rowMap[path]
  }

  findChildRows (row) {
    return this.childRowsMap[row.path] || []
  }

  findParentRow (row) {
    return this.findRowByPath(row.ancestors)
  }

  initialize () {
    this.rows.forEach(row => row.initialize())
  }

  openCollapsedRows () {
    this.openTargetRows().forEach(row => {
      row.open()
      this.onRowOpen({ target: row })
    })
  }

  openTargetRows () {
    return this.rows.filter(({ data }) => data.collapsed && data.openState)
  }
}
