import { lazyGetter, numberWithDelimiter, stringToInteger } from '../utils'

export default class InstantSimulatorCell extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
    this.addEventListeners()
  }

  addEventListeners () {
    this.element.addEventListener('input', this.onInput.bind(this))
    this.element.addEventListener('change', this.onChange.bind(this))
  }

  onInput (_e) {
    if (this.inComposition) return
    this.updateValue()
    const event = new CustomEvent('input')
    this.dispatchEvent(event)
  }

  onChange (e) {
    this.updateHtml()
    const event = new CustomEvent('change')
    this.dispatchEvent(event)
  }

  updateValue () {
    const strVal = this.inputElement.value
    const value = stringToInteger(strVal)
    if (isNaN(value)) {
      this.value = ''

    } else {
      this.value = value

    }
  }

  updateHtml () {
    const numberForDisplay = numberWithDelimiter(this.value)

    if (this.isInput) {
      return this.inputElement.value = numberForDisplay
    } else {
      return this.element.textContent = numberForDisplay
    }
  }

  get isInput () {
    return !!this.inputElement
  }

  get inputElement () {
    return lazyGetter(this, 'inputElement', () => {
      return this.element.querySelector('input')
    })
  }
}
