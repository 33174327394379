import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'trigger',
    'target', // 対象のチェックボックス
  ]

  connect () {
    this.triggerTarget.addEventListener('change', this.bulkToggle.bind(this))
    this.targetTargets.forEach(target => {
      target.addEventListener('change', this.updateTriggerStatus.bind(this))
    })

    this.updateTriggerStatus()
  }

  bulkToggle (e) {
    this.targetTargets.forEach(element => {
      element.checked = e.currentTarget.checked
      element.dispatchEvent(new Event('change'))
    })
  }

  updateTriggerStatus () {
    if (this.isAllChecked()) {
      this.triggerTarget.indeterminate = false
      this.triggerTarget.checked = true
    } else if (this.isAllUnchecked()) {
      this.triggerTarget.indeterminate = false
      this.triggerTarget.checked = false
    } else {
      this.triggerTarget.indeterminate = true
    }
  }

  isAllChecked () {
    return this.targetTargets.every(target => target.checked)
  }

  isAllUnchecked () {
    return this.targetTargets.every(target => !target.checked)
  }
}
