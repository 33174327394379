import React from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'

import { Comment } from '@/frontend/api'
import { Spinner } from '@/frontend/components'
import { ReportMetaId } from '.'
import { CommentItem } from './CommentItem'
import { MentionItem } from './lexicalPlugins/MentionsPlugin'

type Props = {
  userId: number
  memberId: number
  orgId: number
  reportMetaId: ReportMetaId
  comments: Comment[]
  onUpdate: (comment: Comment) => void
  onDelete: (comment: Comment) => void
  onDeleteThread: () => void
  creatableComment: boolean
  suggestionContainerRef: React.RefObject<HTMLElement>
  commentMentionMembers: MentionItem[]
  status: 'error' | 'loading' | 'success'
  withLink: boolean
}

export function CommentList({
  userId,
  memberId,
  orgId,
  reportMetaId,
  comments,
  onUpdate,
  onDelete,
  onDeleteThread,
  creatableComment,
  suggestionContainerRef,
  commentMentionMembers,
  status,
  withLink,
}: Props) {
  const Container = ({ children }: { children: React.ReactNode }) => {
    return (
      <div className="divide-y divide-gray-200 border-t border-gray-200">
        {children}
      </div>
    )
  }

  const getHref = (comment: Comment, reportMetaId: ReportMetaId) => {
    if (withLink) {
      const reportSearchQuery = comment.reportSearchQuery
      if (reportSearchQuery) {
        const baseUrl = getBaseUrl(reportMetaId)
        const url = new URL(
          baseUrl,
          window.location.protocol + '//' + window.location.host,
        )
        const urlSearchParams = new URLSearchParams({
          token: reportSearchQuery.token,
          initial_opening_comment_id: comment.id.toString(),
        })
        url.search = urlSearchParams.toString()
        return url.toString()
      }
      return '#'
    } else {
      return null
    }
  }

  const getBaseUrl = (reportMetaId: ReportMetaId) => {
    switch (reportMetaId.type) {
      case 'CustomReport':
        return `/orgs/${orgId}/reports/custom_reports/${reportMetaId.id}`
      case 'PreparedReport': {
        switch (reportMetaId.reportType) {
          case 'cash_flow':
            return `/orgs/${orgId}/reports/cash_flow`
          case 'comparison_table':
            return `/orgs/${orgId}/reports/comparison_table`
          case 'simulation_table':
            return `/orgs/${orgId}/reports/simulation_table`
        }
      }
    }
  }

  if (status === 'error') {
    return (
      <Container>
        <div className="py-6 flex text-red-600 items-center justify-center text-sm">
          <RiErrorWarningLine className="text-red-400 mr-1" />
          コメントの読み込みに失敗しました
        </div>
      </Container>
    )
  }

  if (comments.length) {
    return (
      <Container>
        <>
          {comments.map((comment, i) => (
            <CommentItem
              key={comment.id}
              href={getHref(comment, reportMetaId)}
              userId={userId}
              memberId={memberId}
              orgId={orgId}
              comment={comment}
              commentIndex={i}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onDeleteThread={onDeleteThread}
              creatableComment={creatableComment}
              suggestionContainerRef={suggestionContainerRef}
              commentMentionMembers={commentMentionMembers}
            />
          ))}
        </>
      </Container>
    )
  }

  if (status === 'loading') {
    return (
      <Container>
        <div className="py-6 flex items-center justify-center">
          <Spinner />
        </div>
      </Container>
    )
  }
  return null
}
