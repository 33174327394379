import { z } from '../../../../../utils/zod'

export const DefinitionsFormSchema = z.object({
  definitions: z.record(
    z.string(),
    z
      .object({
        plan: z.enum(['M', 'A']),
        id: z.string().nullable(),
        accountItemId: z.string().nonempty(),
        nonfund: z.boolean(),
        ratios: z.array(
          z.object({
            id: z.string().nullable(),
            accountItemId: z.string().nullable(),
            cashingMonthOffset: z.number().int().nullable(),
            percentage: z.number().int().nullable(),
            memo: z.string().nullable(),
            isCash: z.boolean(), // 現金科目かどうか。バリデーションに使うのみ。
          }),
        ),
      })
      .superRefine((val, ctx) => {
        // 割合合計が100%かどうか検証
        // 非資金項目の時は検証しない
        if (!val.nonfund) {
          const totalPercentage = val.ratios.reduce<number>(
            (n, ratio) => n + (ratio.percentage || 0),
            0,
          )
          if (totalPercentage !== 100) {
            ctx.addIssue({
              path: ['ratios'],
              code: z.ZodIssueCode.custom,
              message: '割合の合計値を100%にしてください。',
            })
          }
        }

        val.ratios.forEach((ratio, index) => {
          // 相手科目
          // Aプランでは入力しない
          // 非資金項目の時は1行目だけ必須
          // 非資金項目じゃない時は全行必須
          if (
            val.plan === 'M' &&
            !ratio.accountItemId &&
            (val.nonfund ? index === 0 : true)
          ) {
            ctx.addIssue({
              path: [`ratios.${index}.accountItemId`],
              code: z.ZodIssueCode.custom,
              message: '選択してください。',
            })
          }

          // 現金化タイミング
          // 非資金項目じゃない時は必須
          // 非資金項目じゃない時は-12以上の数であること
          // 非資金項目じゃない時は12以下の数であること
          if (!val.nonfund && !ratio.isCash) {
            const message = '-12から12の範囲で数値を入力してください。'
            if (ratio.cashingMonthOffset == null) {
              ctx.addIssue({
                path: [`ratios.${index}.cashingMonthOffset`],
                code: z.ZodIssueCode.custom,
                message,
              })
            } else if (ratio.cashingMonthOffset < -12) {
              ctx.addIssue({
                path: [`ratios.${index}.cashingMonthOffset`],
                code: z.ZodIssueCode.too_small,
                type: 'number',
                minimum: -12,
                inclusive: true,
                message,
              })
            } else if (ratio.cashingMonthOffset > 12) {
              ctx.addIssue({
                path: [`ratios.${index}.cashingMonthOffset`],
                code: z.ZodIssueCode.too_big,
                type: 'number',
                maximum: 12,
                inclusive: true,
                message,
              })
            }
          }

          // 割合
          // 非資金項目じゃない時は必須
          // 非資金項目じゃない時は1以上
          // 非資金項目じゃない時は100以下
          if (!val.nonfund) {
            const message = '1から100の範囲で数値を入力してください。'
            if (ratio.percentage == null) {
              ctx.addIssue({
                path: [`ratios.${index}.percentage`],
                code: z.ZodIssueCode.custom,
                message,
              })
            } else if (ratio.percentage < 1) {
              ctx.addIssue({
                path: [`ratios.${index}.percentage`],
                code: z.ZodIssueCode.too_small,
                type: 'number',
                minimum: 1,
                inclusive: true,
                message,
              })
            } else if (ratio.percentage > 100) {
              ctx.addIssue({
                path: [`ratios.${index}.percentage`],
                code: z.ZodIssueCode.too_big,
                type: 'number',
                maximum: 100,
                inclusive: true,
                message,
              })
            }
          }

          // メモ
          // 非資金項目じゃない時は20文字以下
          if (!val.nonfund && ratio.memo && ratio.memo.length > 20) {
            ctx.addIssue({
              path: [`ratios.${index}.memo`],
              code: z.ZodIssueCode.too_big,
              type: 'string',
              maximum: 20,
              inclusive: true,
              message: '20文字以内で入力してください。', // TODO: 後でデフォルトのメッセージを更新する
            })
          }
        })
      }),
  ),
})

export type DefinitionsForm = z.infer<typeof DefinitionsFormSchema>
export type DefinitionInput = DefinitionsForm['definitions'][string]
