import { lazyGetter } from '../utils'

export default class Row extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-cash-flow')
      return JSON.parse(jsonString)
    })
  }

  get id () {
    return this.data.id
  }

  get parentId () {
    return this.data.parentId
  }

  get accountItemId () {
    return this.data.accountItemId
  }

  get accountItemIds () {
    return this.data.accountItemIds
  }

  get transactionTypes () {
    return this.data.transactionTypes
  }

  get borrowingId() {
    return this.data.borrowingId
  }

  get amountSign () {
    return this.data.amountSign
  }
}
