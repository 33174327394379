import React from 'react'
import { RiAlertLine } from 'react-icons/ri'

export function BlankContent({ text }: { text: string }) {
  return (
    <div className="flex flex-col justify-center items-center py-24">
      <div className="text-gray-400">
        <RiAlertLine className="text-6xl" />
      </div>
      <div className="mt-4">
        <div className="text-gray-500 text-center">{text}</div>
      </div>
    </div>
  )
}
