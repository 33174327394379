import Form from './Form'

document.addEventListener('turbolinks:load', () => {
  const forms = Array.from(
    document.querySelectorAll('form'),
    (e) => new Form(e),
  )

  forms.forEach((form) => form.report())
})
