import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import {
  AccountingSoftwareKey,
  DataFormat,
  OrgImportSettings,
  STORED_IMPORT_SETTINGS_STORAGE_KEY,
} from '../types'

const useStoredImportSettingValue = (
  orgId: number,
  accountingSoftwareKey: AccountingSoftwareKey,
) => {
  const [storedImportSettings, setStoredImportSettings] =
    useLocalStorage<OrgImportSettings>(STORED_IMPORT_SETTINGS_STORAGE_KEY)

  const storedImportSettingValue =
    storedImportSettings?.[orgId]?.[accountingSoftwareKey]

  const updateStoredImportSettingValue = (
    key: string,
    value: string | DataFormat,
  ) => {
    setStoredImportSettings((prev) =>
      prev
        ? {
            ...prev,
            [orgId]: {
              ...prev[orgId],
              [accountingSoftwareKey]: {
                ...prev[orgId]?.[accountingSoftwareKey],
                [key]: value,
              },
            },
          }
        : {
            [orgId]: {
              [accountingSoftwareKey]: {
                [key]: value,
              },
            },
          },
    )
  }

  return {
    storedImportSettingValue,
    updateStoredImportSettingValue,
  }
}

export default useStoredImportSettingValue
