import ConditionalItem from './ConditionalItem'
import { lazyGetter } from '../utils'

export default class Row extends EventTarget {
  constructor (element) {
    super()
    this.element = element
    this.conditionalItems = this.buildConditionalItems()
    this.isInCollapsed = false
    this.isFilteredOut = false
    this.addEventListeners()
  }

  get triggerElement () {
    return lazyGetter(this, 'triggerElement', () => {
      return this.element.querySelector('[data-tree-accordion-trigger]')
    })
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      return JSON.parse(this.element.getAttribute('data-tree-accordion'))
    })
  }

  get path () {
    return this.data.path
  }

  get ancestors () {
    return lazyGetter(this, 'ancestors', () => {
      return this.path.slice(0, -1)
    })
  }

  get isCollapsed () {
    if (this._isCollapsed === undefined) this._isCollapsed = this.data.collapsed

    return this._isCollapsed
  }
  set isCollapsed (val) {
    this._isCollapsed = val
  }

  get isVisible () {
    return !this.isInCollapsed && !this.isFilteredOut
  }

  buildConditionalItems () {
    return Array.from(
      this.element.querySelectorAll('[data-tree-accordion-when]'),
      e => new ConditionalItem(e)
    )
  }

  addEventListeners () {
    if (this.triggerElement) {
      this.triggerElement.addEventListener('click', this.toggle.bind(this))
    }
    this.element.addEventListener('treeFilter:reset', this.onFilterReset.bind(this))
    this.element.addEventListener('treeFilter:start', this.onFilterStart.bind(this))
    this.element.addEventListener('treeFilter:hit', this.onFilterHit.bind(this))
  }

  toggle () {
    if (this.isCollapsed) {
      this.open()
    } else {
      this.collapse()
    }
  }

  collapse () {
    this.isCollapsed = true
    this.conditionalItems.forEach(item => item.onCollapse())
    const event = new CustomEvent('collapse', { target: this })
    this.dispatchEvent(event)
  }

  open () {
    this.isCollapsed = false
    this.conditionalItems.forEach(item => item.onOpen())
    const event = new CustomEvent('open', { target: this })
    this.dispatchEvent(event)
  }

  initialize () {
    if (this.isCollapsed) {
      this.collapse()
    } else {
      this.open()
    }
  }

  onAncestorCollapsed () {
    this.isInCollapsed = true
    this.updateVisibility()
  }

  onAncestorUncollapsed () {
    this.isInCollapsed = false
    this.updateVisibility()
  }

  onFilterReset () {
    this.isFilteredOut = false
    this.updateVisibility()
  }

  onFilterStart () {
    this.isFilteredOut = true
  }

  onFilterHit () {
    this.isFilteredOut = false
    this.collapse()
    this.updateVisibility()
    const event = new CustomEvent('filter:hit')
    this.dispatchEvent(event)
  }

  updateVisibility () {
    this.isVisible ? this._show() : this._hide()
  }

  _hide () {
    this.element.style.display = 'none'
  }

  _show () {
    this.element.style.display = ''
  }
}
