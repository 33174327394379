import React from 'react'
import { tv } from 'tailwind-variants'

import StatusLabelContent from './StatusLabelContent'
import { StatusLabelLoadingOverlay } from './StatusLabelLoadingOverlay'

type Variant = 'empty' | 'notYet' | 'completed' | 'error'

type Props = {
  variant: Variant
  children: React.ReactNode
  loading?: boolean
}

export type Status = Variant

export const statusLabel = tv({
  base: [
    'relative inline-flex items-center justify-center',
    'py-1 px-2',
    'rounded-xl',
    'border',
    'text-xs leading-4 font-bold',
  ].join(' '),
  variants: {
    variant: {
      empty: 'border-gray-500 text-gray-500',
      notYet: 'border-orange-500 text-orange-500',
      completed: 'border-green-500 text-green-500',
      error: 'border-red-500 text-red-500',
    },
    loading: {
      true: 'border-none bg-gray-100',
    },
  },
})

export function StatusLabel({ variant, children, loading }: Props) {
  return (
    <span className={statusLabel({ variant, loading })}>
      <StatusLabelLoadingOverlay loading={loading}>
        <StatusLabelContent withIcon={variant === 'completed'}>
          {children}
        </StatusLabelContent>
      </StatusLabelLoadingOverlay>
    </span>
  )
}
