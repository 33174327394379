import React, { useEffect, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm, useWatch } from 'react-hook-form'

import {
  CreateAssignMembersPayload,
  createAssignMembersSchema,
} from '@/frontend/api'
import { Client, MemberWithAssign, Org } from '@/frontend/api/types'
import { ErrorMessage } from '@/frontend/components'
import { AssignMembersSelect } from '@/frontend/features/clients/AssignMembersSelect'
import { useAssignMembers } from '@/frontend/features/clients/hooks'
import { useDebounce } from '@/frontend/hooks/useDebounce'

export function AssignMembersForm({
  org,
  client,
  assignable,
  onAssignMembers,
}: {
  org: Org
  client: Client
  assignable: boolean
  // NOTE: 今は未使用だが、ログインボタンの活性・非活性の制御に使う予定
  onAssignMembers?: (assignedMembers: MemberWithAssign[]) => void
}) {
  const isInitialized = useRef(false)
  const { isAssigning, assignMembers, assignApiError } = useAssignMembers(
    org,
    client,
  )
  const [isEditing, setIsEditing] = useState(false)

  const { handleSubmit, control } = useForm<CreateAssignMembersPayload>({
    resolver: zodResolver(createAssignMembersSchema),
    defaultValues: {
      userIds: client.members
        .filter((member: MemberWithAssign) => member.assigned)
        .map((member: MemberWithAssign) => member.user.id),
    },
  })

  const watchControl = useWatch({ control })
  const debouncedWatchControl = useDebounce(watchControl, 500)
  useEffect(() => {
    if (isEditing) {
      return
    }
    if (!isInitialized.current) {
      isInitialized.current = true
      return
    }
    handleSubmit(_onSubmit)()
  }, [handleSubmit, debouncedWatchControl, isEditing])

  const _onSubmit = async (payload: CreateAssignMembersPayload) => {
    if (onAssignMembers) {
      const assignedMembers = client.members.filter((member) => {
        if (!payload || !payload.userIds) {
          return false
        }
        return payload.userIds.includes(member.user.id)
      })
      onAssignMembers(assignedMembers)
    }
    await assignMembers(payload)
  }

  return (
    <form onSubmit={handleSubmit(_onSubmit)}>
      <Controller
        name="userIds"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <AssignMembersSelect
            name={name}
            value={value}
            members={client.members}
            onChange={(value) => {
              onChange(value)
            }}
            onMenuOpen={() => setIsEditing(true)}
            onMenuClose={() => setIsEditing(false)}
            disabled={isAssigning || !assignable}
          />
        )}
      />
      {assignApiError && (
        <ErrorMessage>担当者の登録ができませんでした</ErrorMessage>
      )}
    </form>
  )
}
