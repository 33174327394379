import React, { forwardRef } from 'react'

import { InputCheckBox } from './InputCheckBox'
import { Label } from './Label'

type Props = Omit<
  React.ComponentProps<typeof InputCheckBox>,
  'className' | 'type'
> & {
  children: React.ReactNode
}

export const CheckBox = forwardRef<HTMLInputElement, Props>(function CheckBox(
  { children, ...props },
  ref,
) {
  return (
    <div className="inline-flex items-center space-x-2">
      <InputCheckBox ref={ref} {...props} />
      <Label htmlFor={props.id} disabled={props.disabled}>
        {children}
      </Label>
    </div>
  )
})
