import { z } from '@/frontend/utils'

type CompanyIdAllowedTypes = number | string

export function createApiUpdateCompanySchema<
  T extends z.ZodType<CompanyIdAllowedTypes>,
>(type: T): z.ZodObject<{ companyId: T }> {
  return z.object({ companyId: type })
}

export type ApiUpdateCompanyFieldValues = { companyId: CompanyIdAllowedTypes }
