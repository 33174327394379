import { useCallback, useState } from 'react'

import { ApiError, Org } from '@/frontend/api'
import {
  GeneralCsvSourceAccountItemPayload,
  uploadGeneralCsvSourceAccountItems,
} from '@/frontend/api/importer'

export function useUploadGeneralCsvSourceAccountItems(org: Org) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const submit = useCallback(
    async (data: GeneralCsvSourceAccountItemPayload) => {
      setIsSubmitting(true)
      const result = await uploadGeneralCsvSourceAccountItems(org.id, data)
      if (result.ok) {
        setApiError(undefined)
        setIsSubmitting(false)
        return result.val
      } else {
        setApiError(result.err)
        setIsSubmitting(false)
        return null
      }
    },
    [org],
  )
  return {
    submit,
    isSubmitting,
    apiError,
  }
}
