import { useCallback, useMemo } from 'react'

import {
  AllocationDriverBudgetType,
  AllocationDriverInBulkMapping,
  ApiAllocationVersionPayload,
  createAllocationVersion,
  Org,
  updateAllocationVersion,
} from '@/frontend/api'
import { AllocationVersionPayload } from './Form'
import { AccountItem, ReservedItem } from './types'

const getApiData = (
  data: AllocationVersionPayload,
): ApiAllocationVersionPayload => {
  const apiAllocationPeriods = data.allocationPeriods.map((e) => {
    const { allocationMethod, allocationDriverBudgetTypes } = e

    const apiAllocationDriverBudgetTypes = Object.keys(
      allocationDriverBudgetTypes,
    ).map((key) => {
      return {
        allocationDriverId: key,
        type: allocationDriverBudgetTypes[key],
      } as AllocationDriverBudgetType
    })
    return {
      ...e,
      allocationDriverBudgetTypes: apiAllocationDriverBudgetTypes,
      allocationSteps: e.allocationSteps.map((e) => {
        const {
          allocationDriverIndividuallyMappings,
          allocationDriverInBulkMapping,
          ...other
        } = e
        const apiAllocationDriverIndividuallyMappings = Object.keys(
          allocationDriverIndividuallyMappings,
        ).map((key) => {
          return {
            accountItemId: key,
            allocationDriverId: allocationDriverIndividuallyMappings[key],
          }
        })

        switch (allocationMethod) {
          case 'individually':
            return {
              ...other,
              allocationDriverIndividuallyMappings:
                apiAllocationDriverIndividuallyMappings,
            }
          case 'in_bulk':
            return {
              ...other,
              allocationDriverInBulkMapping:
                allocationDriverInBulkMapping as AllocationDriverInBulkMapping,
            }
        }
      }),
    }
  })
  return { ...data, allocationPeriods: apiAllocationPeriods }
}

export function useCreateAllocationVersion(org: Org) {
  const createItem = useCallback(
    async (data: AllocationVersionPayload) => {
      return await createAllocationVersion(org.id, getApiData(data))
    },
    [org],
  )

  return {
    createItem,
  }
}

export function useUpdateAllocationVersion(org: Org) {
  const updateItem = useCallback(
    async (id: number, data: AllocationVersionPayload) => {
      return await updateAllocationVersion(org.id, id, getApiData(data))
    },
    [org],
  )

  return {
    updateItem,
  }
}

export function useGetAccountItemsByReservedItem(accountItems: AccountItem[]) {
  const accountItemMap = useMemo(() => {
    const map = new Map<number, AccountItem[]>()
    accountItems.forEach((accountItem) => {
      const arr = map.get(accountItem.reservedItemId) || []
      map.set(accountItem.reservedItemId, [...arr, accountItem])
    })
    return map
  }, [accountItems])

  return useCallback(
    (reservedItem: ReservedItem) => {
      return accountItemMap.get(reservedItem.id) || []
    },
    [accountItemMap],
  )
}
