// 子チェックボックスクリック時
document.addEventListener('change', (event) => {
  const condition = event.target.hasAttribute('data-target-check-box')

  if (condition) updateBulkCheckBoxes()
})

// 一括チェックボックスクリック時
document.addEventListener('change', (event) => {
  const changed = event.target
  if (!changed.hasAttribute('data-bulk-check-box')) return

  bulkCheck(changed)
})

// 超一括チェックボックスクリック時
document.addEventListener('change', (event) => {
  const changed = event.target
  if (!changed.hasAttribute('data-super-bulk-check-box')) return

  bulkCheck(changed)
})

// ページ表示時
document.addEventListener('turbolinks:load', () => {
  updateBulkCheckBoxes()
})

function bulkCheck (element) {
  const childBoxes = getChildCheckBoxes(element)

  const { checked } = element
  // disabledではないターゲットのチェックを変更する
  childBoxes.forEach((e) => {
    if (e.disabled) { return }

    e.checked = checked
  })

  updateBulkCheckBoxes()
}

function updateBulkCheckBoxes () {
  const elements = document.querySelectorAll('[data-bulk-check-box], [data-super-bulk-check-box]')
  elements.forEach((element) => {
    const children = getChildCheckBoxes(element)
    if (children.length === 0) {
      if (element.closest('td')) {
        element.closest('td').style.visibility = 'hidden'
      }
      element.disabled = true
    } else if (isAllChecked(children)) {
      element.indeterminate = false
      element.checked = true
    } else if (isAllUnchecked(children)) {
      element.indeterminate = false
      element.checked = false
    } else {
      element.indeterminate = true
    }
  })
}

// インデント戦略で参る
function getChildCheckBoxes(element) {
  if (element.hasAttribute('data-super-bulk-check-box')) {
    const selector = element.dataset.superBulkCheckBox
    const table = document.querySelector(selector)
    const nodeList = table.querySelectorAll('[data-target-check-box]')
    return Array.from(nodeList).filter(Boolean)
  } else {
    // 親(Oya)のtrを見つける
    const baseTr = element.closest('tr')
    const targetCheckbox = baseTr.querySelector('[data-target-check-box]')

    // おんなじindentナンバーが出現する/テーブルが終わるかまで繰り返す
    return getTargetCheckBoxesRecursive(baseTr, baseTr, [targetCheckbox]).filter(Boolean)
  }
}

function getTargetCheckBoxesRecursive (baseTr, currentTr, arr) {
  const nextTr = currentTr.nextElementSibling
  if (!nextTr) return arr 

  const baseIndent = baseTr.dataset.indent
  const nextIndent = nextTr.dataset.indent
  if (baseIndent < nextIndent) {
    const targetCheckbox = nextTr.querySelector('[data-target-check-box]')
    return getTargetCheckBoxesRecursive(baseTr, nextTr, [...arr, targetCheckbox])
  } else {
    return arr
  }
}

function isAllChecked(checkBoxes) {
  return checkBoxes.every((checkBox) => checkBox.checked)
}

function isAllUnchecked(checkBoxes) {
  return checkBoxes.every((checkBox) => !checkBox.checked)
}
