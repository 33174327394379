import * as React from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'

import { SharedHistoryContext } from './context/SharedHistoryContext'
import EditorNodes from './nodes/EditorNodes'
import EditorTheme from './themes/EditorTheme'

type Props = {
  children: React.ReactNode
}

export function EditorContext(props: Props) {
  const initialConfig = {
    namespace: 'reportGeneralComment',
    nodes: [...EditorNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: EditorTheme,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <div className="editor-shell shadow rounded-lg bg-white">
          {props.children}
        </div>
      </SharedHistoryContext>
    </LexicalComposer>
  )
}
