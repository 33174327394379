import { createErr, createOk, Result } from 'option-t/esm/PlainResult'

import { axios, z } from '@/frontend/utils'
import {
  ApiError,
  createCustomReportGeneralCommentUrl,
  createPreparedReportGeneralCommentUrl,
  deleteCustomReportGeneralCommentUrl,
  deletePreparedReportGeneralCommentUrl,
  PreparedReportType,
  updateCustomReportGeneralCommentUrl,
  updatePreparedReportGeneralCommentUrl,
} from '.'
import type { ReportGeneralComment } from '.'
import { handleAxiosError } from './utils'

export const createReportGeneralCommentSchema = z.object({
  content: z.string(),
  textContent: z.string(),
})

export const updateReportGeneralCommentSchema = createReportGeneralCommentSchema

export type CreateReportGeneralCommentPayload = z.infer<
  typeof createReportGeneralCommentSchema
>
export type UpdateReportGeneralCommentPayload = z.infer<
  typeof updateReportGeneralCommentSchema
>

export async function createCustomReportGeneralComment(
  orgId: number,
  customReportId: number,
  payload: CreateReportGeneralCommentPayload,
): Promise<Result<ReportGeneralComment, ApiError>> {
  const url = createCustomReportGeneralCommentUrl(orgId, customReportId)
  try {
    const res = await axios.post(url, {
      ...payload,
      searchQueryToken: getReportSearchQueryToken(),
    })
    const comment = res.data as ReportGeneralComment
    return createOk(comment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function createPreparedReportGeneralComment(
  orgId: number,
  reportType: PreparedReportType,
  payload: CreateReportGeneralCommentPayload,
): Promise<Result<ReportGeneralComment, ApiError>> {
  const url = createPreparedReportGeneralCommentUrl(orgId)
  try {
    const res = await axios.post(url, {
      ...payload,
      reportType,
      searchQueryToken: getReportSearchQueryToken(),
    })
    const comment = res.data as ReportGeneralComment
    return createOk(comment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function updateCustomReportGeneralComment(
  orgId: number,
  customReportId: number,
  reportGeneralCommentId: number,
  payload: UpdateReportGeneralCommentPayload,
): Promise<Result<ReportGeneralComment, ApiError>> {
  const url = updateCustomReportGeneralCommentUrl(
    orgId,
    customReportId,
    reportGeneralCommentId,
  )
  try {
    const res = await axios.put(url, payload)
    const reportGeneralComment = res.data as ReportGeneralComment
    return createOk(reportGeneralComment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function deleteCustomReportGeneralComment(
  orgId: number,
  customReportId: number,
  reportGeneralCommentId: number,
): Promise<Result<void, ApiError>> {
  const url = deleteCustomReportGeneralCommentUrl(
    orgId,
    customReportId,
    reportGeneralCommentId,
  )
  try {
    await axios.delete(url)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function updatePreparedReportGeneralComment(
  orgId: number,
  reportGeneralCommentId: number,
  payload: UpdateReportGeneralCommentPayload,
): Promise<Result<ReportGeneralComment, ApiError>> {
  const url = updatePreparedReportGeneralCommentUrl(
    orgId,
    reportGeneralCommentId,
  )
  try {
    const res = await axios.put(url, payload)
    const reportGeneralComment = res.data as ReportGeneralComment
    return createOk(reportGeneralComment)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function deletePreparedReportGeneralComment(
  orgId: number,
  reportGeneralCommentId: number,
): Promise<Result<void, ApiError>> {
  const url = deletePreparedReportGeneralCommentUrl(
    orgId,
    reportGeneralCommentId,
  )
  try {
    await axios.delete(url)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

const getReportSearchQueryToken = () => {
  const tokenElement = document.getElementById(
    'report-search-query-token',
  ) as HTMLFormElement
  return tokenElement.dataset.reportSearchQueryToken
}
