import Sortable from 'sortablejs';

// データの保持が役目
// HTMLの更新などは一切行わない
class CustomReportDecorationContainer {
  constructor(decorationJson) {
    this._currentLineId = null
    this._decorationJson = decorationJson
    // 行未選択時に操作された場合の更新する書式設定JSON
    // NullObjectパターンなので更新はするけど使いません
    this.currentDecorationNullObject = {}
  }

  get decorationJson() {
    return this._decorationJson
  }

  // 現在の選択行（行数ではなくCustomReportItemのID）
  get currentLineId() {
    return this._currentLineId
  }

  set currentLineId(currentLineId) {
    this._currentLineId = currentLineId
  }

  get bold() {
    return this.currentDecoration['font_bold']
  }

  toggleBold() {
    this.currentDecoration['font_bold'] = !this.currentDecoration['font_bold']
  }

  get underline() {
    return this.currentDecoration['font_underline']
  }

  toggleUnderline() {
    this.currentDecoration['font_underline'] = !this.currentDecoration['font_underline']
  }

  get underline() {
    return this.currentDecoration['font_underline']
  }

  toggleUnderline() {
    this.currentDecoration['font_underline'] = !this.currentDecoration['font_underline']
  }

  get italic() {
    return this.currentDecoration['font_italic']
  }

  toggleItalic() {
    this.currentDecoration['font_italic'] = !this.currentDecoration['font_italic']
  }

  get fontColor() {
    return this.currentDecoration['font_color']
  }

  set fontColor(fontColor) {
    this.currentDecoration['font_color'] = fontColor
  }

  get backgroundColor() {
    return this.currentDecoration['background_color']
  }

  set backgroundColor(backgroundColor) {
    this.currentDecoration['background_color'] = backgroundColor
  }

  get underRuledLine() {
    return this.currentDecoration['under_ruled_line']
  }

  set underRuledLine(underRuledLine) {
    this.currentDecoration['under_ruled_line'] = underRuledLine
  }

  get indentLevel() {
    return this.currentDecoration['indent'] || 0
  }

  indent() {
    if(!this.isMaxIndentLevel()) {
      this.currentDecoration['indent'] = this.indentLevel + 1
    }
  }

  outdent() {
    if(!this.isMinIndentLevel()) {
      this.currentDecoration['indent'] = this.indentLevel - 1
    }
  }

  isMaxIndentLevel() {
    return 6 <= this.indentLevel
  }

  isMinIndentLevel() {
    return this.indentLevel <= 0
  }

  isNotSelectedLine() {
    return this.currentLineId === null || this.currentLineId === undefined
  }

  get currentDecoration() {
    // 行未選択時はNullObjectを返す
    if(this.isNotSelectedLine()) {
      return this.currentDecorationNullObject
    }
    return this.decorationJson[this.currentLineId]
  }
}

// 対象行のHTMLを更新するクラス
// JS内で保持している装飾JSONの内容は変更しない
// 行ごとにインスタンスを作ってメモリ使用量を増やすほどのメリットはないので
// インスタンスは使い回す
// 実質ただのネームスペース
class LineHtmlDecorator {
  constructor(decorationContainer) {
    this.decorationContainer = decorationContainer

    this.indentClasses = [...Array(10)].map((_, i) => `tr-depth-${i}` )
    this.underRuledLineClasses = ['1', '2', '3', '4', 'dashed', 'dotted', 'double'].map((style) => `decoration-under-ruled-line-tr-${style}`)
    // 更新対象の行のHTMLのNullObject
    this.targetLineHtmlNullObject = document.createElement('tr')
    this.colorConverter = new ColorConverter()
  }

  // 更新対象の行のHTML
  targetLineHtml() {
    if(this.decorationContainer.isNotSelectedLine()) {
      return this.targetLineHtmlNullObject
    }
    return document.querySelector(`[data-custom-report-item='${this.decorationContainer.currentLineId}']`)
  }

  // 対象行の太字切り替え
  decorateBold() {
    if(this.decorationContainer.bold) {
      this.targetLineHtml().classList.add('decoration-font-bold-tr')
    } else {
      this.targetLineHtml().classList.remove('decoration-font-bold-tr')
    }
  }

  //対象行の下線切り替え
  decorateUnderline() {
    if(this.decorationContainer.underline) {
      this.targetLineHtml().classList.add('decoration-font-underline-tr')
    } else {
      this.targetLineHtml().classList.remove('decoration-font-underline-tr')
    }
  }

  //対象行の斜体切り替え
  decorateItalic() {
    if(this.decorationContainer.italic) {
      this.targetLineHtml().classList.add('decoration-font-italic-tr')
    } else {
      this.targetLineHtml().classList.remove('decoration-font-italic-tr')
    }
  }

  // 対象行の文字色変更
  // 色は選択肢が固定ではないのでCSSを用意できない
  decorateFontColor() {
    this.targetLineHtml().style.color = this.colorConverter.hexToTextRgba(this.decorationContainer.fontColor)
  }

  // 対象行の背景色変更
  // 色は選択肢が固定ではないのでCSSを用意できない
  decorateBackgroundColor() {
    const bgColor = this.decorationContainer.backgroundColor
    // th tdに対して背景色のCSS指定があるのでtrの各子要素に対してstyleを適用している
    this.targetLineHtml().querySelectorAll('th, td').forEach((element) => {
      // 並び替えハンドルの背景は変更しないので除外する
      if(element.classList.contains('sortable-column')) {
        return;
      }
      element.style.backgroundColor = this.colorConverter.hexToBgRgba(bgColor)
    })
  }

  // 対象行の下罫線変更
  decorateUnderRuledLine() {
    const style = this.decorationContainer.underRuledLine
    this.targetLineHtml().classList.remove(...this.underRuledLineClasses)
    this.targetLineHtml().classList.add(`decoration-under-ruled-line-tr-${style}`)
  }

  // 対象行のインデントレベル変更
  decorateIndent() {
    this.targetLineHtml().classList.remove(...this.indentClasses)
    this.targetLineHtml().classList.add(`tr-depth-${this.decorationContainer.indentLevel}`)
  }
}

// 書式設定UI
// HTMLの操作も担う
class FormattingUI {
  constructor(decorationContainer) {
    this.decorationContainer = decorationContainer
    this.lineHtmlDecorator = new LineHtmlDecorator(decorationContainer)
    this.underRuledLineClasses = ['1', '2', '3', '4', 'dashed', 'dotted', 'double'].map((style) => `under-ruled-line-${style}`)
  }

  // 書式設定UIの表示を変える（太字選択済など）
  updateUi() {
    // 行ごとに情報を取得して書式設定UIの見た目を更新する
    this.updateBoldUi()
    this.updateUnderlineUi()
    this.updateItalicUi()
    this.updateFontColorUi()
    this.updateBackgroundColorUi()
    this.updateUnderRuledLineUi()
    this.updateIndentUi()
    this.updateOutdentUi()
  }

  updateBoldUi() {
    const boldButton = document.querySelector('[data-custom-report-formatting-ui-font-bold]')
    this.updateToggleUi(boldButton, this.decorationContainer.bold)
  }

  updateUnderlineUi() {
    const underlineButton = document.querySelector('[data-custom-report-formatting-ui-font-underline]')
    this.updateToggleUi(underlineButton, this.decorationContainer.underline)
  }

  updateItalicUi() {
    const italicButton = document.querySelector('[data-custom-report-formatting-ui-font-italic]')
    this.updateToggleUi(italicButton, this.decorationContainer.italic)
  }

  updateFontColorUi() {
    const fontColorUi = document.querySelector('[data-custom-report-formatting-ui-font-color]')
    if (this.decorationContainer.isNotSelectedLine()) {
      fontColorUi.disabled = true
      fontColorUi.value = '#333333'
      return
    }
    fontColorUi.disabled = false
    fontColorUi.value = this.decorationContainer.fontColor || '#333333'
  }

  updateBackgroundColorUi() {
    const backgroundColorUi = document.querySelector('[data-custom-report-formatting-ui-background-color]')
    if (this.decorationContainer.isNotSelectedLine()) {
      backgroundColorUi.disabled = true
      backgroundColorUi.value = '#FFFFFF'
      return
    }
    backgroundColorUi.disabled = false
    backgroundColorUi.value = this.decorationContainer.backgroundColor || '#FFFFFF'
  }

  updateUnderRuledLineUi() {
    const underRuledLineUi = document.querySelector('[data-custom-report-formatting-ui-under-ruled-line-dropdown]')
    const selectedUnderRuledLine = document.querySelector('[data-selected-under-ruled-line]')
    selectedUnderRuledLine.classList.remove(...this.underRuledLineClasses)
    underRuledLineUi.disabled = this.decorationContainer.isNotSelectedLine()
    const value = this.decorationContainer.isNotSelectedLine() ? '1' : this.decorationContainer.underRuledLine
    selectedUnderRuledLine.classList.add(`under-ruled-line-${value}`)
  }

  updateIndentUi() {
    const indentUi = document.querySelector('[data-custom-report-formatting-ui-indent]')
    if (this.decorationContainer.isNotSelectedLine()) {
      indentUi.disabled = true
      return
    }
    indentUi.disabled = this.decorationContainer.isMaxIndentLevel()
  }

  updateOutdentUi() {
    const outdentUi = document.querySelector('[data-custom-report-formatting-ui-outdent]')
    if (this.decorationContainer.isNotSelectedLine()) {
      outdentUi.disabled = true
      return
    }
    outdentUi.disabled = this.decorationContainer.isMinIndentLevel()
  }

  updateToggleUi(uiButton, value) {
    if (this.decorationContainer.isNotSelectedLine()) {
      uiButton.classList.remove('btn-white-on')
      uiButton.disabled = true
      return
    }

    uiButton.disabled = false
    if(value) {
      uiButton.classList.add('btn-white-on')
    } else {
      uiButton.classList.remove('btn-white-on')
    }
  }

  // HTMLに書式設定情報をダンプする
  dumpJson() {
    const jsonField = document.querySelector('[data-custom-report-decoration-form]').elements.decoration_json
    jsonField.value = JSON.stringify(this.decorationContainer.decorationJson)
  }

  afterFormattingChange() {
    this.dumpJson()
    this.updateUi()
  }

  bold() {
    this.decorationContainer.toggleBold()
    this.lineHtmlDecorator.decorateBold()
    this.afterFormattingChange()
  }

  underline() {
    this.decorationContainer.toggleUnderline()
    this.lineHtmlDecorator.decorateUnderline()
    this.afterFormattingChange()
  }

  italic() {
    this.decorationContainer.toggleItalic()
    this.lineHtmlDecorator.decorateItalic()
    this.afterFormattingChange()
  }

  fontColor(eventObject) {
    this.decorationContainer.fontColor = eventObject.target.value
    this.lineHtmlDecorator.decorateFontColor()
    this.afterFormattingChange()
  }

  backgroundColor(eventObject) {
    this.decorationContainer.backgroundColor = eventObject.target.value
    this.lineHtmlDecorator.decorateBackgroundColor()
    this.afterFormattingChange()
  }

  checkedUnderRuledLine() {
    document.querySelectorAll('[data-under-ruled-line-option] [data-check-mark]').forEach((e)=> e.classList.add('invisible'))
    document.querySelector(`[data-under-ruled-line-option] [data-check-mark='${this.decorationContainer.underRuledLine}']`).classList.remove('invisible')
  }

  underRuledLine(eventObject) {
    this.decorationContainer.underRuledLine = eventObject.currentTarget.dataset.customReportFormattingUiUnderRuledLine
    this.lineHtmlDecorator.decorateUnderRuledLine()
    this.afterFormattingChange()
  }

  indent() {
    this.decorationContainer.indent()
    this.lineHtmlDecorator.decorateIndent()
    this.afterFormattingChange()
  }

  outdent() {
    this.decorationContainer.outdent()
    this.lineHtmlDecorator.decorateIndent()
    this.afterFormattingChange()
  }
}

class ColorConverter {
  // hexColorStringは#01ABFFみたいな文字列
  // '#01ABFF' => [1, 171, 255]
  // というような変換をするメソッド
  hexToDecimal(hexColorString) {
    const hexStringList = hexColorString.match(/[0-9a-fA-F]{2}/g)
    return hexStringList.map((hex) => parseInt(hex, 16))
  }

  // hexColorStringは#01ABFFみたいな文字列
  // '#01ABFF' => 'rgba(1, 171, 255, var(--tw-text-opacity))'
  // というような変換をするメソッド
  hexToTextRgba(hexColorString) {
    const decimalList = this.hexToDecimal(hexColorString)
    return `rgba(${decimalList[0]}, ${decimalList[1]}, ${decimalList[2]}, var(--tw-text-opacity))`
  }

  hexToBgRgba(hexColorString) {
    const decimalList = this.hexToDecimal(hexColorString)
    return `rgba(${decimalList[0]}, ${decimalList[1]}, ${decimalList[2]}, var(--tw-bg-opacity))`
  }
}

class BlankLineUi {
  updateUi() {
    this.insertBlankLineAbove()
    this.insertBlankLineBelow()
    this.removeBlankLine()
  }

  get tbody() {
    return document.querySelector('[data-custom-report-sort-container]')
  }

  blankLine() {
    const originBlankLineTr = document.querySelector('#blank-line-tr')
    const clonBlankLineTr = originBlankLineTr.cloneNode(true)
    clonBlankLineTr.classList.remove('hidden')
    return clonBlankLineTr
  }

  targetTr(btn) {
    const targetTrId = btn.dataset['customReportItemInsertBlankLineAbove'] || btn.dataset['customReportItemInsertBlankLineBelow']
    const targetTr = this.tbody.querySelector(`[data-custom-report-item='${targetTrId}']`)
    return targetTr
  }

  insertBlankLineAbove() {
    document.querySelectorAll('[data-custom-report-blank-line-insert]').forEach((dropdownBtn) => {
      // clickedでドロップダウンを開くごとに新たなクリックイベントを付与しないようにしている
      let clicked = false
      dropdownBtn.addEventListener('click', () => {
        if (clicked) {
          return
        }
        const insertAboveBtn = document.querySelector('[data-custom-report-item-insert-blank-line-above]')
        if (insertAboveBtn) {
          insertAboveBtn.addEventListener('click', () => {
            const targetTr = this.targetTr(insertAboveBtn)
            const blankLineTr = this.blankLine()
            this.tbody.insertBefore(blankLineTr, targetTr)
            this.updateSort()

          })
        }
        clicked = true
      })
    })
  }

  insertBlankLineBelow() {
    document.querySelectorAll('[data-custom-report-blank-line-insert]').forEach((dropdownBtn) => {
      // clickedでドロップダウンを開くごとに新たなクリックイベントを付与しないようにしている
      let clicked = false
      dropdownBtn.addEventListener('click', () => {
        if (clicked) {
          return
        }
        const insertBelowBtn = document.querySelector('[data-custom-report-item-insert-blank-line-below]')
        if (insertBelowBtn) {
          insertBelowBtn.addEventListener('click', () => {
            const targetTr = this.targetTr(insertBelowBtn)
            const blankLineTr = this.blankLine()
            this.tbody.insertBefore(blankLineTr, targetTr.nextSibling)
            this.updateSort()
          })
        }
        clicked = true
      })
    })
  }

  removeBlankLine() {
    document.addEventListener('click', (e) => {
      const clicked = e.target.closest('[data-custom-report-item-remove-button]')
      if (clicked) {
        const targetTr = clicked.closest('tr')
        targetTr.remove();
        this.updateSort()
      }
    })
  }

  updateSort() {
    document.querySelectorAll('[data-custom-report-sort-container]').forEach(el => {
      updatePositionField(el)
    })
  }
}

// エントリポイント
class CustomReportDecoration {
  constructor() {

    // 書式設定フォームがなければ何もしない
    if(!document.querySelector('[data-custom-report-formatting-ui]')) {
      return
    }

    const decorationContainer = new CustomReportDecorationContainer(this.getDecorationJson())
    const formattingUi = new FormattingUI(decorationContainer)
    formattingUi.updateUi() // デフォルト表示させる
    this.setEvent(decorationContainer, formattingUi)
    this.makeSorable()
    this.updateBlankLineUi()
  }

  getDecorationJson() {
    const value = document.querySelector('[data-custom-report-decoration-form]').elements.decoration_json.value
    return JSON.parse(value)
  }

  setEvent(decorationContainer, formattingUi) {
    document.querySelector('[data-custom-report-formatting-ui-font-bold]').addEventListener('click', (_eventObject) => {
      formattingUi.bold()
    })
    document.querySelector('[data-custom-report-formatting-ui-font-underline]').addEventListener('click', (_eventObject) => {
      formattingUi.underline()
    })
    document.querySelector('[data-custom-report-formatting-ui-font-italic]').addEventListener('click', (_eventObject) => {
      formattingUi.italic()
    })
    document.querySelector('[data-custom-report-formatting-ui-font-color]').addEventListener('change', (eventObject) => {
      formattingUi.fontColor(eventObject)
    })
    document.querySelector('[data-custom-report-formatting-ui-background-color]').addEventListener('change', (eventObject) => {
      formattingUi.backgroundColor(eventObject)
    })

    // ドロップダウンの選択肢はドロップダウンをクリックするまでelementが存在しないので、
    // クリックするたびにeventを追加する方法をとっている
    // Event Delegationしようかとも思ったが選択肢がbody直下に作られるようなので、
    // bodyのclickイベントを全部チェックするのもなぁと思ってこうした
    // clickedでドロップダウンを開くごとに新たなクリックイベントを付与しないようにしている
    let clicked = false
    document.querySelector('[data-custom-report-formatting-ui-under-ruled-line-dropdown]').addEventListener('click', (_eventObject) => {
      formattingUi.checkedUnderRuledLine()
      if (clicked) {
        return
      }
      document.querySelectorAll('[data-custom-report-formatting-ui-under-ruled-line]').forEach((element) => {
        element.addEventListener('click', (eventObject) => {
          formattingUi.underRuledLine(eventObject)
        })
      })
      clicked = true
    })
    document.querySelector('[data-custom-report-formatting-ui-indent]').addEventListener('click', () => {
      formattingUi.indent()
    })
    document.querySelector('[data-custom-report-formatting-ui-outdent]').addEventListener('click', () => {
      formattingUi.outdent()
    })

    // 行選択
    document.querySelectorAll('[data-custom-report-item]').forEach((item) => {
      item.addEventListener('click', (e) => {
        const removeButton = e.target.closest('[data-custom-report-item-remove-button]')
        if (removeButton) {
          return
        }
        if (e.target.closest('.sortable-column')) {
          return
        }
        if(!decorationContainer.isNotSelectedLine()) {
          document.querySelector(`[data-custom-report-item='${decorationContainer.currentLineId}']`).classList.remove('selected-tr')
        }
        if(decorationContainer.currentLineId == item.dataset['customReportItem']) {
          decorationContainer.currentLineId = null
        } else {
          decorationContainer.currentLineId = item.dataset['customReportItem']
          document.querySelector(`[data-custom-report-item='${decorationContainer.currentLineId}']`).classList.add('selected-tr')
        }
        formattingUi.updateUi()
      })
    })
  }

  makeSorable() {
    document.querySelectorAll('[data-custom-report-sort-container]').forEach(el => {
      const options = {
        dataIdAttr: 'data-custom-report-item',
        handle: '[data-custom-report-item-sort-handle]',
        onEnd: () => updatePositionField(el)
      }

      new Sortable(el, options)
    })
  }

  updateBlankLineUi() {
    const blankLineUi = new BlankLineUi()
    blankLineUi.updateUi()
  }
}

document.addEventListener('turbolinks:load', () => {
  new CustomReportDecoration()
})

function updatePositionField(el) {
  const sortable = Sortable.get(el)
  const sortedIds = sortable.toArray()
  const positionsField = document.querySelector('[data-custom-report-decoration-form]').elements.positions
  positionsField.value = JSON.stringify(sortedIds)
}
