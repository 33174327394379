import React from 'react'

import { ButtonLink } from '@/frontend/components'

type Props = {
  href: string
}

export default function Setting({ href }: Props) {
  return (
    <ButtonLink variant="outlined" href={href}>
      編集
    </ButtonLink>
  )
}
