export default class CellFormatter {
  constructor({ table, rowMap }) {
    this.table = table
    this.rowMap = rowMap
  }

  format(rowId, val) {
    return this._formatCellSign(rowId, val)
  }

  _formatCellSign(rowId, val) {
    const amountSign = this._amountSign(rowId)
    if (amountSign === 'positive') {
      return Math.abs(val)
    }
    if (amountSign === 'negative') {
      return -Math.abs(val)
    }

    return val
  }

  _findRow(rowId) {
    return this.rowMap[rowId]
  }

  _amountSign(rowId) {
    return this._findRow(rowId).amountSign
  }
}
