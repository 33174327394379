import React from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { tv } from 'tailwind-variants'

type Props = {
  items: {
    label: string
    href?: string
  }[]
}

const breadcrumbItem = tv({
  base: 'text-xs text-gray-500 leading-4 font-medium',
  // NOTE: 旧デザインと統一するためにボールドを解除しています
  //       新デザインになったら有効化してください
  // variants: {
  //   active: {
  //     true: 'font-bold',
  //   },
  // },
})

export function Breadcrumbs(props: Props) {
  return (
    <nav>
      <ol className="flex items-center space-x-2">
        {props.items.map((item, index) => (
          <React.Fragment key={index}>
            <li
              className={breadcrumbItem({
                // NOTE: 旧デザインと統一するためにボールドを解除しています
                //       新デザインになったら有効化してください
                // active: index == props.items.length - 1,
              })}
            >
              {item.href ? <a href={item.href}>{item.label}</a> : item.label}
            </li>
            <li>
              {index < props.items.length - 1 && (
                <RiArrowRightSLine className="w-5 h-5 text-gray-400" />
              )}
            </li>
          </React.Fragment>
        ))}
      </ol>
    </nav>
  )
}
