import { createErr, createOk, Result } from 'option-t/esm/PlainResult'
import { z } from 'zod'

import { axios } from '@/frontend/utils'
import { getMemberInvitationBulkImportUrl } from '../routes'
import { ApiError } from '../types'
import { handleAxiosError } from '../utils'

export const memberInvitationsSchema = z.object({
  memberInvitations: z.array(
    z.object({
      email: z.string().email().max(255),
      role: z.enum(['accounting_firm_manager', 'accounting_firm_staff']),
    }),
  ),
})

export type MemberInvitationsPayload = z.infer<typeof memberInvitationsSchema>

export async function createMemberInvitations(
  orgId: number,
  payload: MemberInvitationsPayload,
): Promise<Result<void, ApiError>> {
  const url = getMemberInvitationBulkImportUrl(orgId)
  try {
    await axios.post(url, payload)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
