// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log(
//   'Visit the guide for more information: ',
//   'https://vite-ruby.netlify.app/guide/rails',
// )

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// NOTE: RailsUJSとReactRailsUJSは上記処理が終わってからでないと本番ビルドで動かない
import RailsUJS from '@rails/ujs'

// NOTE: rubyの世界からは `frontend/pages` 以下のコンポーネントしか呼び出さない
import ReactRailsUJS from 'react_ujs'

import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import '@fortawesome/fontawesome-free/js/all'

import '../src/js/application.js'
import '../controllers'

// tooltip style
import 'react-tooltip/dist/react-tooltip.css'

import 'remixicon/fonts/remixicon.css'

if (!window._rails_loaded) RailsUJS.start()
Turbolinks.start()
Turbolinks.setProgressBarDelay(0)

ActiveStorage.start()

window.FontAwesome.config.mutateApproach = 'sync'

// https://github.com/reactjs/react-rails#getconstructor
const pages = import.meta.globEager('../frontend/pages/**/*.tsx')
Object.keys(pages).forEach((k) => {
  const page = pages[k]
  const path = k.replace('../frontend/pages/', '').replace('.tsx', '')
  window[path] = page
})

// https://github.com/reactjs/react-rails#event-handling
window.Turbolinks = Turbolinks
ReactRailsUJS.detectEvents()
