import { Controller } from 'stimulus'
import Chart from 'chart.js/auto'
import {
  BUDGET2_OPACITY,
  BAR_OPACITY_WITH_LINE,
  SIMPLE_BACKGROUND_COLORS,
  STACKED_BAR_BACKGROUND_COLORS,
  findMaxDataset,
  formatNumber,
} from './base.js'

let copyYAxisCalled = false

export default class extends Controller {
  static values = {
    labels: Array,
    datasets: Array,
    firstBudgetColspan: Number,
    emptyLabel: String, // 余白入れるようのラベル名。(see: https://knowledgelabo.esa.io/posts/1704)
    // type axisOpts =
    //   {
    //     leftMin?: number,
    //     leftMax?: number,
    //     leftStepSize?: number,
    //     rightMin?: number,
    //     rightMax?: number,
    //     rightStepSize?: number,
    //   }
    axisOpts: Object,
    options: Object,
  }

  connect() {
    const labels = this.labelsValue
    const decoratedDatasets = this.datasetsValue.map((dataset, dataIdx) => {
      dataset.backgroundColor = this._findBackgroundColors(dataset, dataIdx)
      dataset.borderColor = this._findBackgroundColors(dataset, dataIdx)
      return {
        ...dataset,
        borderWidth: 1,
      }
    })

    // スクロール対応
    // こちら参照: https://qiita.com/yutake27/items/fa7b1f6b3c7c65e9d69b
    const xAxisLabelMinWidth = this.datasetsValue.length * 12 // データ当たりの幅を設定
    const graphWidth = this.datasetsValue[0].data.length * xAxisLabelMinWidth // グラフ全体の幅を計算
    const windowWidth = window.innerWidth // グラフ画面幅
    const isXScroll = windowWidth < graphWidth
    // グラフの幅を設定
    if (isXScroll) {
      this.element.style.width = `${graphWidth}px`
    } else {
      this.element.style.width = '100%'
    }
    //　グラフの高さを設定
    this.element.style.height = '20rem'

    const chart = new Chart(this.element, {
      type: 'bar',
      data: {
        labels,
        datasets: decoratedDatasets,
      },
      plugins: [
        {
          afterRender: (chart, args, options) => {
            this._isUsingYAxisID('left') &&
              isXScroll &&
              this._copyYAxisImage(chart)
          },
        },
      ],
      options: {
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxHeight: 9,
              font: {
                size: 12,
              },
              filter: (items) => items.text != this.emptyLabelValue,
            },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem, data) => {
                return formatNumber(
                  tooltipItem.parsed.y,
                  tooltipItem.dataset.meta.unit_type,
                )
              },
            },
          },
        },
        responsive: false,
        scales: { ...this._yAxis('left'), ...this._yAxis('right') },
        maintainAspectRatio: false,
        ...this.optionsValue,
      },
    })
    window.dispatchEvent(new CustomEvent('chartRendered'))
    // NOTE: 横スクロール対応でresponsive: trueにできないため、
    //       横スクロールが未表示のときはobserverでグラフをリサイズする
    if (!isXScroll) {
      this._observeChartResize(chart)
    }
  }

  _observeChartResize(chart) {
    const observer = new ResizeObserver(() => chart.resize())
    observer.observe(this.element.parentNode)
    // 遷移時
    document.addEventListener('turbolinks:before-visit', () => {
      observer.disconnect()
    })
    // リロード時
    window.addEventListener('beforeunload', () => {
      observer.disconnect()
    })
  }

  _yAxis(position) {
    return (
      this._isUsingYAxisID(position) && {
        [position]: {
          id: position,
          type: 'linear',
          position: position,
          suggestedMax: this._suggestedMax(position),
          beginAtZero: true,
          min: this._min(position),
          max: this._max(position),
          grid: {
            display: position == 'left',
          },
          ticks: {
            stepSize: this._stepSize(position),
            callback: function (label, index, labels) {
              let unitType
              const suggestedMax =
                this.chart.options.scales[position].suggestedMax
              switch (suggestedMax) {
                case undefined:
                  const maxDataset = findMaxDataset(
                    this.chart,
                    this.chart.config.data.datasets,
                    position,
                  )
                  if (maxDataset) {
                    unitType = maxDataset.meta.unit_type
                  } else {
                    unitType = 'other'
                  }
                  break
                case 10000:
                  unitType = 'other'
                  break
                case 100:
                  unitType = 'percentage'
                  break
              }
              return formatNumber(label, unitType)
            },
          },
        },
      }
    )
  }

  _isUsingYAxisID(yAxisID) {
    return this.datasetsValue.some((e) => e.yAxisID === yAxisID)
  }

  _suggestedMax(yAxisID) {
    const datasets = this.datasetsValue
      .filter((e) => e.yAxisID === yAxisID)
      .flatMap((e) => e.data)
    if (datasets.some((value) => value > 0 || 0 < value)) return undefined

    const isExistsOtherUnitType = this.datasetsValue
      .filter((e, idx) => e.yAxisID === yAxisID)
      .some((e) => e.meta.unit_type === 'other')

    return isExistsOtherUnitType ? 10000 : 100
  }

  _findBackgroundColors(dataset, dataIdx) {
    return dataset.data.map((data, columnIndex) => {
      const backgroundColor =
        dataset.meta.graph_item_type == 'stacked_bar'
          ? STACKED_BAR_BACKGROUND_COLORS[dataset.meta.graph_idx][
              dataset.meta.series_idx
            ]
          : SIMPLE_BACKGROUND_COLORS[dataset.meta.graph_idx]

      // NOTE: 「予算②」は「予算①」より薄く表示
      if (
        this.firstBudgetColspanValue &&
        columnIndex >= this.firstBudgetColspanValue
      ) {
        return this._changeOpacity(backgroundColor, BUDGET2_OPACITY)
      }

      // NOTE: 棒グラフと折線が存在する時は棒グラフを透明にして、折線が表示できるようにする
      if (this._existLineAndBar(dataset)) {
        return this._changeOpacity(backgroundColor, BAR_OPACITY_WITH_LINE)
      }

      return backgroundColor
    })
  }

  _existLineAndBar(dataset) {
    return dataset.type == 'bar' && this._lineGraphExists()
  }

  _lineGraphExists() {
    return this.datasetsValue.some((dataset) => dataset.type == 'line')
  }

  _changeOpacity(rgba, opacity) {
    rgba = rgba.replace(/rgba|\(|\)/g, '').split(',')
    rgba[rgba.length - 1] = opacity
    rgba = `rgba(${rgba})`
    return rgba
  }

  // Y軸をスクロール時に固定させる
  // see: https://qiita.com/okatako/items/94a769a0925337c79483
  _copyYAxisImage(chart) {
    if (copyYAxisCalled) return
    copyYAxisCalled = true

    const cvsChart = document.getElementById('graph')
    const ctxChart = cvsChart.getContext('2d')
    const cvsYAxis = document.getElementById('yAxis')
    cvsYAxis.style.backgroundColor = 'white'
    const ctxYAxis = cvsYAxis.getContext('2d')

    // グラフ描画後は、canvas.width(height):canvas.style.width(height) 比は、下記 scale の値になっている
    const scale = window.devicePixelRatio

    // Y軸のスケール情報
    const yAxScale = chart.scales.left

    // Y軸部分としてグラフからコピーすべき幅 (TODO: 良く分かっていない)
    const yAxisStyleWidth0 = yAxScale.width - 10

    // canvas におけるコピー幅(yAxisStyleWidth0を直接使うと微妙にずれるので、整数値に切り上げる)
    const copyWidth = Math.ceil(yAxisStyleWidth0 * scale)
    // Y軸canvas の幅(右側に少し空白部を残す)
    const yAxisCvsWidth = copyWidth + 4
    // 実際の描画幅(styleに設定する)
    const yAxisStyleWidth = yAxisCvsWidth / scale

    // Y軸部分としてグラフからコピーすべき高さ (TODO: 良く分かっていない) ⇒これを実際の描画高とする(styleに設定)
    const yAxisStyleHeight = yAxScale.height + yAxScale.top + 10
    // canvas におけるコピー高
    const copyHeight = yAxisStyleHeight * scale
    // Y軸canvas の高さ
    const yAxisCvsHeight = copyHeight

    // 下記はやってもやらなくても結果が変わらないっぽい
    // ctxYAxis.scale(scale, scale);

    // Y軸canvas の幅と高さを設定
    cvsYAxis.width = yAxisCvsWidth
    cvsYAxis.height = yAxisCvsHeight

    // Y軸canvas.style(実際に描画される大きさ)の幅と高さを設定
    cvsYAxis.style.width = `${yAxisStyleWidth}px`
    cvsYAxis.style.height = `${yAxisStyleHeight}px`

    // グラフcanvasからY軸部分のイメージをコピーする
    ctxYAxis.drawImage(
      cvsChart,
      0,
      0,
      copyWidth,
      copyHeight,
      0,
      0,
      copyWidth,
      copyHeight,
    )

    // 軸ラベルのフォント色を透明に変更して、以降、再表示されても見えないようにする
    chart.options.scales.left.ticks.fontColor = 'rgba(0,0,0,0)'
    chart.update()
    // 最初に描画されたグラフのY軸ラベル部分をクリアする
    ctxChart.clearRect(0, 0, yAxisStyleWidth, yAxisStyleHeight)
  }

  _min(position) {
    switch (position) {
      case 'left':
        return this.axisOptsValue.leftMin
      case 'right':
        return this.axisOptsValue.rightMin
      default:
        throw new Error(`Unexpected: ${position}`)
    }
  }

  _max(position) {
    switch (position) {
      case 'left':
        return this.axisOptsValue.leftMax
      case 'right':
        return this.axisOptsValue.rightMax
      default:
        throw new Error(`Unexpected: ${position}`)
    }
  }

  _stepSize(position) {
    switch (position) {
      case 'left':
        return this.axisOptsValue.leftStepSize
      case 'right':
        return this.axisOptsValue.rightStepSize
      default:
        throw new Error(`Unexpected: ${position}`)
    }
  }
}
