document.addEventListener('input', (event) => {
  const input = event.target.closest('[data-dropdown-search-input]')
  if (input) {
    const id = input.getAttribute('data-dropdown-search-input')
    const target = document.getElementById(id)
    const keyword = event.target.value
    const reg = new RegExp(keyword)

    target.querySelectorAll('[data-dropdown-search-link-item]').forEach((element) => {
      if (element.text.match(reg)) {
        element.style.display = ""
      } else {
        element.style.display = "none"
      }
    })
  }
})
