import { useCallback } from 'react'

import { Org } from '@/frontend/api'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'

type Period = {
  firstDate: string
  lastDate: string
}
type TagCategories = {
  tagCategories: string[]
}

export type BaseApiFormValuesType = TagCategories & Period
export type BaseCsvFormValuesType = Period

type StorageKeyForApi = 'MfcApi' | 'MfcPlusApiI' | 'FreeeApi' | 'BugyoCloudApi'
type StorageKeyForCsv = 'MfcCsvJournal' | 'YayoiCsvJournal' | 'BugyoICsvJournal'

export default function useStoredFormValues<T>(
  org: Org,
  storageKey: StorageKeyForApi | StorageKeyForCsv,
) {
  const [storedValue, setStoreValues] = useLocalStorage<{
    [id: number]: T
  }>(storageKey)

  const storeImportFormValues = useCallback(
    (data: T) => {
      setStoreValues((prev) => {
        return {
          ...prev,
          [org.id]: {
            ...data,
          },
        }
      })
    },
    [org.id, setStoreValues],
  )

  const storedFormValues = storedValue?.[org.id]

  return {
    storedFormValues,
    storeImportFormValues,
  }
}
