import React from 'react'
import { RiExternalLinkLine } from 'react-icons/ri'

import { Org } from '@/frontend/api'

export default function FiscalPeriodLink({ org }: { org: Org }) {
  return (
    <a
      href={`/orgs/${org.id}/fiscal_periods`}
      target="_blank"
      rel="noreferrer"
      className="text-blue-600 flex flex-row items-center w-fit"
    >
      会計期間を登録する
      <RiExternalLinkLine />
    </a>
  )
}
