import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'target'
  ]

  onChange (e) {
    const select = e.currentTarget;
    const val = select.value;

    this.targetTargets.forEach(el => {
      if (el.dataset.value === val) {
        el.disabled = false
        el.hidden = false
      } else {
        el.disabled = true
        el.hidden = true
      }
    })
  }
}
