import React from 'react'

import { FiscalPeriod } from '@/frontend/features/allocationVersions/types'
import { AllocationVersion, IndexList, SubProps } from './components'

type Props = {
  updateOrderUrl: string
  fiscalPeriods: FiscalPeriod[]
  isSortable: boolean
  items: Item[]
  subProps: SubProps
}

type Item = AllocationVersion

export default function IndexPage({
  updateOrderUrl,
  fiscalPeriods,
  items,
  subProps,
  isSortable,
}: Props) {
  return (
    <IndexList
      updateOrderUrl={updateOrderUrl}
      fiscalPeriods={fiscalPeriods}
      isSortable={isSortable}
      items={items}
      subProps={subProps}
    />
  )
}
