import React from 'react'

import { FiscalPeriod } from './types'

type Props = {
  fiscalPeriods: FiscalPeriod[]
  value: string
  onChange: (value: string) => void
}

type Option = {
  value: string
  label: string
  disabled: boolean
}

export function DateSelect({ fiscalPeriods, value, onChange }: Props) {
  const options = getOptions(fiscalPeriods, value)
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value)
  }

  return (
    <select
      defaultValue={value}
      onChange={handleChange}
      className="form-select py-1 text-sm border-gray-300 focus:outline-none focus:ring-blue-200 focus:border-blue-300"
    >
      <option hidden>選択してください</option>
      {options.map(({ value, label, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {label}
        </option>
      ))}
    </select>
  )
}

function getOptions(fiscalPeriods: FiscalPeriod[], value: string): Option[] {
  const firstDates = fiscalPeriods.map((x) => x.firstDate)

  const fiscalPeriodOptions = fiscalPeriods.map(
    ({ firstDate, name, disabled }) => {
      return {
        value: firstDate,
        label: `${formatDate(firstDate)}（${name}の期首日）`,
        disabled,
      }
    },
  )

  if (!value || firstDates.includes(value)) {
    return fiscalPeriodOptions
  } else {
    const valueOption = {
      value,
      label: formatDate(value),
      disabled: false,
    }
    return [...fiscalPeriodOptions, valueOption].sort((a, b) =>
      a.value < b.value ? -1 : 1,
    )
  }
}

function formatDate(str: string) {
  const x = new Date(str)
  const y = x.getFullYear()
  const m = (x.getMonth() + 1).toString().padStart(2, '0')
  const d = x.getDate().toString().padStart(2, '0')
  return `${y}/${m}/${d}`
}
