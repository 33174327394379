import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkBoxForm', 'submit']

  connect() {
    this.check()
  }

  check() {
    this.changeSubmit()
    this.changeUrl()
  }

  changeSubmit() {
    if (this.isAnyCheck()) {
      this.submitTarget.classList.remove('disabled')
    } else {
      this.submitTarget.classList.add('disabled')
    }
  }

  changeUrl() {
    const url = new URL(this.submitTarget.href)
    this.checkBoxFormTargets.forEach((formTarget) => {
      url.searchParams.delete(formTarget.name)
      url.searchParams.append(formTarget.name, formTarget.checked)
    })
    this.submitTarget.href = url.toString()
  }

  isAnyCheck() {
    return this.checkBoxFormTargets.some((e) => e.checked)
  }
}
