import { JobLogWithAttachedFile } from './types'

export function getInitialImportFileStatus(
  jobLogWithAttachedFile: JobLogWithAttachedFile | null,
) {
  if (
    !jobLogWithAttachedFile ||
    jobLogWithAttachedFile?.attachedFiles.length == 0
  )
    return null
  return {
    attachedFiles: jobLogWithAttachedFile.attachedFiles,
    status: jobLogWithAttachedFile.status,
  }
}
