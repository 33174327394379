import { createErr, createOk, Result } from 'option-t/PlainResult'

import { axios, z } from '@/frontend/utils'
import { getNonFinancialItemsUrl, getNonFinancialItemUrl } from './routes'
import { ApiError, NonFinancialItem } from './types'
import { handleAxiosError } from './utils'

export const nonFinancialItemSchema = z.object({
  budgetTagId: z.preprocess(
    (v) => (v === '' ? null : Number(v)),
    z.number().nullable(),
  ),
  name: z.string().min(1).max(20),
  numberType: z.enum(['integer', 'decimal', 'percentage']),
  roundType: z.enum(['round_down', 'round', 'round_up']),
  sumUp: z.coerce.boolean(),
  stockFlowType: z.enum(['undefined', 'flow', 'stock']),
  memo: z.string(),
})

export const updateNonFinancialItemSchema = nonFinancialItemSchema.omit({
  budgetTagId: true,
  numberType: true,
})

export type NonFinancialItemPayload = z.infer<typeof nonFinancialItemSchema>

export type UpdateNonFinancialItemPayload = z.infer<
  typeof updateNonFinancialItemSchema
>

export async function createNonFinancialItem(
  orgId: number,
  payload: NonFinancialItemPayload,
): Promise<Result<NonFinancialItem, ApiError>> {
  const url = getNonFinancialItemsUrl(orgId)
  try {
    const res = await axios.post(url, payload)
    const nonFinancialItem = res.data as NonFinancialItem
    return createOk(nonFinancialItem)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function updateNonFinancialItem(
  orgId: number,
  id: number,
  payload: UpdateNonFinancialItemPayload,
): Promise<Result<NonFinancialItem, ApiError>> {
  const url = getNonFinancialItemUrl(orgId, id)
  try {
    const res = await axios.put(url, payload)
    const nonFinancialItem = res.data as NonFinancialItem
    return createOk(nonFinancialItem)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function deleteNonFinancialItem(
  orgId: number,
  id: number,
): Promise<Result<void, ApiError>> {
  const url = getNonFinancialItemUrl(orgId, id)
  try {
    await axios.delete(url)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
