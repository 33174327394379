import React, { useState } from 'react'

import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import ApiAuthButton from '../components/ApiAuthButton'
import ApiAuthContainer from '../components/ApiAuthContainer'
import AuthedAccountingSoftwareLabel from '../components/AuthedAccountingSoftwareLabel'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { AccountingSoftware, JobLogWithAttachedFile } from '../types'
import ApiImportFormContainer from './ApiImportFormContainer'
import JournalImportFormContainer from './JournalImportFormContainer'
import MasterImportFormContainer from './MasterImportFormContainer'

export type DataSourceSectionProps = {
  org: Org
  accountingSoftware: AccountingSoftware
  apiAuthorizeUrl: string
  lastApiImportJobLog: JobLog | null
  lastInProgressApiImportJobId: number | null
  lastCsvAccountItemsImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvAccountItemsImportJobId: number | null
  lastCsvJournalImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvJournalImportJobId: number | null
  isFirstImportedAccountItems: boolean
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  accountingSoftware,
  apiAuthorizeUrl,
  lastApiImportJobLog,
  lastInProgressApiImportJobId,
  lastCsvAccountItemsImportJobLog,
  lastInProgressCsvAccountItemsImportJobId,
  lastCsvJournalImportJobLog,
  lastInProgressCsvJournalImportJobId,
  isFirstImportedAccountItems,
  setPendingChecker,
}: DataSourceSectionProps) {
  const [isJournalImportable, setIsJournalImportable] = useState(
    isFirstImportedAccountItems,
  )
  const {
    importSetting: { dataFormat },
  } = useImportSettingContext()

  return (
    <>
      <Section onGray>
        <Section.Title>
          <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
        </Section.Title>
        {/* APIインポート */}
        {dataFormat === 'api' && (
          <div className="p-6 border space-y-4 text-sm rounded-md">
            <ApiAuthContainer description="会計ソフトとAPI連携を行います。">
              <ApiAuthButton
                accountingSoftware={accountingSoftware}
                apiAuthorizeUrl={apiAuthorizeUrl}
              />
              <AuthedAccountingSoftwareLabel
                accountingSoftware={accountingSoftware}
              />
            </ApiAuthContainer>

            {accountingSoftware.isApiAuthed && (
              <ApiImportFormContainer
                org={org}
                lastApiImportJobLog={lastApiImportJobLog}
                lastInProgressApiImportJobId={lastInProgressApiImportJobId}
                setPendingChecker={setPendingChecker}
              />
            )}
          </div>
        )}

        {/* CSVインポート */}
        {dataFormat === 'csv' && (
          <div className="p-6 border space-y-12 text-sm rounded-md">
            {/* マスタインポート */}
            <MasterImportFormContainer
              org={org}
              jobLogWithAttachedFile={lastCsvAccountItemsImportJobLog}
              initialInProgressJobLogId={
                lastInProgressCsvAccountItemsImportJobId
              }
              setIsJournalImportable={setIsJournalImportable}
              setPendingChecker={setPendingChecker}
            />

            {/* 仕訳インポート */}
            <JournalImportFormContainer
              org={org}
              jobLogWithAttachedFile={lastCsvJournalImportJobLog}
              initialInProgressJobLogId={lastInProgressCsvJournalImportJobId}
              isJournalImportable={isJournalImportable}
            />
          </div>
        )}
      </Section>
    </>
  )
}
