import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'leftBudgetInput',
    'leftBudgetName',
    'dropdownLeftBudgetItem',
    'rightBudgetInput',
    'rightBudgetName',
    'dropdownRightBudgetItem',
    'fiscalPeriodInput',
    'fiscalPeriodName',
    'dropdownFiscalPeriodItem',
    'allocationVersionIdInput',
    'allocationVersionName',
    'dropdownAllocationVersionItem',
  ]

  updateLeftBudget(e) {
    this.leftBudgetInputTarget.value = e.currentTarget.dataset.value
    this.leftBudgetNameTarget.textContent = e.currentTarget.textContent
    this.removeActiveClass(this.dropdownLeftBudgetItemTargets)
    e.currentTarget.classList.add('active')
  }

  updateRightBudget(e) {
    this.rightBudgetInputTarget.value = e.currentTarget.dataset.value
    this.rightBudgetNameTarget.textContent = e.currentTarget.textContent
    this.removeActiveClass(this.dropdownRightBudgetItemTargets)
    e.currentTarget.classList.add('active')
  }

  updateFiscalPeriod(e) {
    this.fiscalPeriodInputTarget.value = e.currentTarget.dataset.value
    this.fiscalPeriodNameTarget.textContent = e.currentTarget.textContent
    this.removeActiveClass(this.dropdownFiscalPeriodItemTargets)
    e.currentTarget.classList.add('active')
  }

  removeActiveClass(targets) {
    targets.forEach((target) => {
      target.classList.remove('active')
    }) 
  }

  updateAllocationVersionParam(e) {
    const value = e.currentTarget.dataset.value
    if (value) {
      this.allocationVersionIdInputTarget.setAttribute('value', value)
    } else {
      this.allocationVersionIdInputTarget.removeAttribute('value')
    }
    this._updateAllocationVersionLable(e)
    this._updateActiveAllocationVersion(e)
  }

  _updateAllocationVersionLable(e) {
    this.allocationVersionNameTarget.textContent = e.currentTarget.textContent
  }

  _updateActiveAllocationVersion(e) {
    this.dropdownAllocationVersionItemTargets.forEach((el) => {
      el.classList.remove('active')
    })
    e.currentTarget.classList.add('active')
  }
}
