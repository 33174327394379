import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [
    'nodeTrigger',
    'leafTrigger',
    'nodeContent'
  ]

  static values = {
  }

  connect () {
    this.element.addEventListener('mount', this._onMount.bind(this))
  }

  onNodeClick (e) {
    const { nodeValue } = e.currentTarget.dataset
    this._updateActiveNode(nodeValue)
    this._changeNodeContent(nodeValue)
  }

  onLeafClick (e) {
    const { nodeValue, leafValue, nodeLabel, leafLabel } = e.currentTarget.dataset
    this._updateActiveLeaf(nodeValue, leafValue)
    this._emitChangeEvent(nodeValue, leafValue, nodeLabel, leafLabel)
  }

  _onMount ({ detail: { element, nodeValue, leafValue } }) {
    this.reference = element
    this._updateActiveNode(nodeValue)
    this._updateActiveLeaf(nodeValue, leafValue)
    this._changeNodeContent(nodeValue)
  }

  // 表示するノードのコンテンツを切り替える
  _changeNodeContent (nodeValue) {
    this.nodeContentTargets.forEach((element) => {
      element.hidden = element.dataset.nodeValue !== nodeValue
    })
  }

  _updateActiveNode (nodeValue) {
    this.nodeTriggerTargets.forEach((element) => {
      this._toggleActiveClass(
        element,
        element.dataset.nodeValue === nodeValue
      )
    })
  }

  _updateActiveLeaf (nodeValue, leafValue) {
    this.leafTriggerTargets.forEach((element) => {
      this._toggleActiveClass(
        element,
        element.dataset.nodeValue == nodeValue &&
        element.dataset.leafValue == leafValue
      )
    })
  }

  _toggleActiveClass (element, isActive) {
    if (isActive) {
      element.classList.add('active')
    } else {
      element.classList.remove('active')
    }
  }

  _emitChangeEvent (nodeValue, leafValue, nodeLabel, leafLabel) {
    const event = new CustomEvent('change', {
      detail: { nodeValue, leafValue, nodeLabel, leafLabel }
    })
    this.reference.dispatchEvent(event)
  }
}
