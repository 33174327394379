import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]
  static values = {
    registeredPlans: Object,
    availableAttrs: Array,
  }
  static PLAN_ATTR_KEY = 'plan_key'

  attrKeyOfPlanKey() {
    return this.constructor.PLAN_ATTR_KEY
  }

  connect () {
    this.attributeInputElement = {}

    this._whenInput(this.attrKeyOfPlanKey(), (element) => {
      this._updateValueToRegisteredValues(element.value)

      this.boundHandleChangePlanKey = this._handleChangePlanKey.bind(this)
      element.addEventListener('change', this.boundHandleChangePlanKey)
    })
  }

  disconnect () {
    this._whenInput(this.attrKeyOfPlanKey(), (element) => {
      element.removeEventListener('change', this.boundHandleChangePlanKey)
    })
  }

  _whenInput(key, funcYes = () => true, funcNo = () => false) {
    const elm = this._inputElement(key)
    return elm ? funcYes(elm) : funcNo()
  }

  _inputElement(attributeKey) {
    if (this.attributeInputElement[attributeKey] !== undefined) {
      return this.attributeInputElement[attributeKey]
    }

    this.attributeInputElement[attributeKey] = this._detectInputElement(attributeKey)
    return this.attributeInputElement[attributeKey]
  }

  _detectInputElement(attributeKey) {
    const targetField = this.fieldTargets.find((e) => e.dataset.fieldKind === attributeKey)
    return targetField?.querySelector(`#customized_plan_${attributeKey}`)
  }

  _handleChangePlanKey (e) {
    this._updateValueToRegisteredValues(e.currentTarget.value)
  }

  _updateValueToRegisteredValues(planKey) {
    const registered = this.registeredPlansValue[planKey]

    this.availableAttrsValue.forEach((attrKey) => {
      // プランキーは更新しない
      if (attrKey === this.attrKeyOfPlanKey()) return;

      this._whenInput(attrKey, (element) => {
        element.value = registered ? registered[attrKey] : '' // 登録されてなかったら空にする
      })
    })
  }
}
