import React from 'react'

import { useRender } from './hooks/useRender'

export type Props = {
  content: string
}

export function GeneralCommentView({ content }: Props) {
  const renderedHtml = useRender(content)

  return <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />
}
