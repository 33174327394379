import React from 'react'

import {
  InputGroup,
  Radio,
  Card as Section,
  Select,
  SelectionGroup,
} from '@/frontend/components'
import { ImportFormat, ImportFormatMap } from '../getImportFormatMap'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { Budget, DataFormat, ImportSetting } from '../types'

type Props = {
  budgets: Budget[]
  importFormatMap: ImportFormatMap
  onChange: (key: string, value: string) => void
}

export default function ImportSettingForm({
  // budgets,
  importFormatMap,
  onChange,
}: Props) {
  const { importSetting } = useImportSettingContext()
  return (
    <Section onGray>
      <Section.Title>データ選択</Section.Title>
      <div className="p-6 border rounded-md">
        <>
          {/* 計画/実績 */}
          {/* NOTE: サブメニューで計画/実績の導線を分けている間、「インポート先の実績」メニューは非表示にする
          <div className="w-1/3">
            <BudgetSelect
              budgets={budgets}
              initialBudgetId={importSetting.budgetId}
              onChange={onChange}
            />
          </div>
          */}
          {/* データ形式 */}
          <div className="w-1/3">
            <DataFormatSelect
              importFormatMap={importFormatMap}
              initialDataFormat={importSetting.dataFormat}
              onChange={onChange}
            />
          </div>
          {/* インポート形式 */}
          <div className="w-1/3 pt-4">
            <ImportFormatSelect
              importFormatMap={importFormatMap}
              importSetting={importSetting}
              initialImportFormat={importSetting.importFormat}
              onChange={onChange}
            />
          </div>
        </>
      </div>
    </Section>
  )
}

function _BudgetSelect({
  budgets,
  initialBudgetId,
  onChange,
}: {
  budgets: Budget[]
  initialBudgetId: string
  onChange: Props['onChange']
}) {
  return (
    <InputGroup label={'インポート先の実績'}>
      {(props) => (
        <Select
          {...props}
          value={initialBudgetId}
          onChange={(e) => {
            onChange('budgetId', e.target.value)
          }}
        >
          {budgets.map((budget) => (
            <option key={budget.id} value={budget.id}>
              {budget.name}
            </option>
          ))}
        </Select>
      )}
    </InputGroup>
  )
}

function DataFormatSelect({
  importFormatMap,
  initialDataFormat,
  onChange,
}: {
  importFormatMap: ImportFormatMap
  initialDataFormat: DataFormat
  onChange: Props['onChange']
}) {
  const defaultDataFormats = ['api', 'csv'] as DataFormat[]

  const availableDataFormats = Object.keys(importFormatMap) as DataFormat[]

  return (
    <InputGroup label={'データ形式'} name="dataFormat">
      {({ id, name }) => (
        <SelectionGroup>
          {defaultDataFormats.map((defaultDataFormat) => (
            <Radio
              key={defaultDataFormat}
              id={`${id}-${defaultDataFormat}`}
              name={name}
              disabled={!availableDataFormats.includes(defaultDataFormat)}
              value={defaultDataFormat}
              defaultChecked={initialDataFormat === defaultDataFormat}
              onChange={(e) => {
                const dataFormat = e.target.value as DataFormat
                const importFormat = importFormatMap[dataFormat]?.[0]
                  ?.value as ImportFormat
                onChange('dataFormat', dataFormat)
                onChange('importFormat', importFormat)
              }}
            >
              {defaultDataFormat.toUpperCase()}
            </Radio>
          ))}
        </SelectionGroup>
      )}
    </InputGroup>
  )
}

function ImportFormatSelect({
  importFormatMap,
  importSetting,
  initialImportFormat,
  onChange,
}: {
  importFormatMap: ImportFormatMap
  importSetting: ImportSetting
  initialImportFormat: ImportFormat
  onChange: Props['onChange']
}) {
  return (
    <InputGroup label={'インポート形式'}>
      {(props) => (
        <Select
          {...props}
          value={initialImportFormat}
          onChange={(e) => onChange('importFormat', e.target.value)}
        >
          {importFormatMap[importSetting.dataFormat].map((format) => (
            <option key={format.value} value={format.value}>
              {format.label}
            </option>
          ))}
        </Select>
      )}
    </InputGroup>
  )
}
