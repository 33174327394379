import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'yAxis',
    'leftAxisSetting',
    'rightAxisSetting',
  ]

  static values = {
    url: String
  }

  connect() {
    // 左軸右軸の間隔設定を初期化
    this.updateAxisSetting()
  }

  changeType(e) {
    const { target } = e
    const trEl = target.closest('tr')
    const position = trEl.querySelector('#position').value
    const urlParams = new URLSearchParams(new FormData(target.closest('form')))
    urlParams.append('position', position)
    axios.get(`${this.urlValue}?${urlParams.toString()}`)
      .then(res => {
        trEl.innerHTML = res.data
        this.updateAxisSetting()
      })
  }

  changeYAxis() {
    this.updateAxisSetting()
  }

  // 左軸右軸の間隔設定を更新
  updateAxisSetting() {
    const leftYAxisTargets = this.yAxisTargets.filter((el) => el.value === 'left')
    if (leftYAxisTargets.length > 0) {
      this.leftAxisSettingTargets.forEach(el => {
        el.disabled = false
      })
    } else {
      this.leftAxisSettingTargets.forEach(el => {
        el.disabled = true
      })
    }
    const rightYAxisTargets = this.yAxisTargets.filter((el) => el.value === 'right')
    if (rightYAxisTargets.length > 0) {
      this.rightAxisSettingTargets.forEach(el => {
        el.disabled = false
      })
    } else {
      this.rightAxisSettingTargets.forEach(el => {
        el.disabled = true
      })
    }
  }
}
