import React from 'react'
import { Result } from 'option-t/esm/PlainResult'
import { RiCheckLine } from 'react-icons/ri'

import { ApiError, Comment, CommentThread } from '@/frontend/api'
import { ReportMetaId } from '..'
import { MentionItem } from '.././lexicalPlugins/MentionsPlugin'
import { CommentList } from '../CommentList'
import { useUpdateCommentThread } from '../hooks'

type Props = {
  userId: number
  memberId: number
  orgId: number
  reportMetaId: ReportMetaId
  comments: Comment[]
  commentThread: CommentThread
  onUpdate: (comment: Comment) => void
  onDelete: (comment: Comment) => void
  onDeleteThread: () => void
  creatableComment: boolean
  suggestionContainerRef: React.RefObject<HTMLElement>
  commentMentionMembers: MentionItem[]
  onUpdateCommentThread: (commentThread: CommentThread) => void
  onToggleResolve: (
    apiResult: Result<CommentThread, ApiError> | undefined,
  ) => Promise<boolean | undefined>
}

export function CommentThreadBox({
  userId,
  memberId,
  orgId,
  reportMetaId,
  comments,
  commentThread,
  onUpdate,
  onDelete,
  onDeleteThread,
  creatableComment,
  suggestionContainerRef,
  commentMentionMembers,
  onUpdateCommentThread,
  onToggleResolve,
}: Props) {
  const updateCommentThread = useUpdateCommentThread(
    orgId,
    commentThread.id,
    onUpdateCommentThread,
  )

  const _onToggleResolve = async () => {
    const result = await updateCommentThread({
      resolved: !commentThread.resolved,
    })
    onToggleResolve(result)
  }

  const Container = ({ children }: { children: React.ReactNode }) => {
    return (
      <>
        <div className="bg-gray-100 flex justify-between px-2 pt-3 pb-2">
          <div className="min-w-0">
            <div className="text-gray-900 font-bold truncate">
              <span>{commentThread.title}</span>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <>
              {creatableComment && (
                <button
                  onClick={_onToggleResolve}
                  className={
                    commentThread.resolved
                      ? 'ml-2 text-green-500'
                      : 'ml-2 text-gray-500'
                  }
                >
                  <RiCheckLine className="text-xl" />
                </button>
              )}
            </>
          </div>
        </div>
        <div className="divide-y divide-gray-200 border-t border-gray-200">
          {children}
        </div>
      </>
    )
  }

  if (comments.length) {
    return (
      <Container>
        <>
          <CommentList
            userId={userId}
            memberId={memberId}
            orgId={orgId}
            reportMetaId={reportMetaId}
            comments={comments}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onDeleteThread={onDeleteThread}
            creatableComment={creatableComment}
            suggestionContainerRef={suggestionContainerRef}
            commentMentionMembers={commentMentionMembers}
            status={'success'}
            withLink={true}
          />
        </>
      </Container>
    )
  }

  return null
}
