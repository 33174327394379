import React from 'react'

import type { SearchUserId } from '@/frontend/api/client'
import type { Member } from '@/frontend/api/types'
import { Select } from '@/frontend/components'

type Props = {
  members: Member[] | undefined
  handleSearchParamsAndInitialSelectedUserId: (value: SearchUserId) => void
  selectedUserId: SearchUserId | undefined
}

export function ClientFilterSelector({
  members,
  handleSearchParamsAndInitialSelectedUserId,
  selectedUserId,
}: Props) {
  const options = [
    <option key="all" value="">
      すべて
    </option>,
    ...(members?.map((member) => (
      <option key={member.id} value={member.user.id}>
        {member.user.name}
      </option>
    )) || []),
  ]

  return (
    <>
      <p className="text-sm text-gray-700">担当者で絞り込む</p>
      <div className="min-w-[12rem] ml-2 text-gray-700">
        <Select
          onChange={({ target: { value } }) =>
            handleSearchParamsAndInitialSelectedUserId(
              value === '' ? '' : Number(value),
            )
          }
          value={selectedUserId}
        >
          {options}
        </Select>
      </div>
    </>
  )
}
