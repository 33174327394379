import { useCallback, useState } from 'react'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  ApiError,
  Client,
  CreateAssignMembersPayload,
  Org,
} from '@/frontend/api'

export function useAssignMembers(org: Org, client: Client) {
  const [assignApiError, setAssignApiError] = useState<ApiError | undefined>(
    undefined,
  )
  const [isAssigning, setIsAssigning] = useState(false)

  const assignMembers = useCallback(
    async (payload: CreateAssignMembersPayload) => {
      setIsAssigning(true)
      const result = await _assignMembers(org.id, client.id, payload)
      if (result.ok) {
        setAssignApiError(undefined)
      } else {
        setAssignApiError(result.err)
      }
      setIsAssigning(false)
    },
    [org, client],
  )

  return {
    assignMembers,
    assignApiError,
    isAssigning,
  }
}
