import React from 'react'

import {
  GeneralCommentView,
  Props,
} from '@/frontend/features/reportGeneralComments/GeneralCommentView'

export default function ReportGeneralCommentView({ content }: Props) {
  return <GeneralCommentView content={content} />
}
