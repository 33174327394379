import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'item',
    'budgetTagCheckBox',
    'bulkCheckBox',
    'bulkAllCheckBox',
    'label'
  ]

  connect() {
    this._updateBulkAllCheckBoxes()
    this._updateBulkCheckBoxes()
    this._updateInput()
    this._updateLabel()
  }

  // NOTE: タグ・タググループ・未設定の右のチェックボックスをチェック
  check() {
    this._updateBulkAllCheckBoxes()
    this._updateBulkCheckBoxes()
    this._updateInput()
    this._updateLabel()
  }

  // NOTE: 「タグの一括選択」をチェック
  checkTagBulk(event) {
    const { checked } = event.target
    const subtreeIds = this._subtreeIds(event.target)
    this._getChildCheckBoxes(subtreeIds).forEach((e) => (e.checked = checked))
    this.check()
  }

  // NOTE: 「すべてのタグ」をチェック
  checkTagAll(event) {
    const { checked } = event.target
    this.budgetTagCheckBoxTargets.filter((e) => !e.disabled)
      .forEach((e) => e.checked = checked)
    this.check()
  }

  _updateInput() {
    const budgetTagIdsInputValue = this._budgetTagIdsInputValue()
    this.element.querySelectorAll('[name="primary_tag_ids[]"]').forEach(e => e.remove())
    this.element.querySelectorAll('[name="primary_tag_ids"]').forEach(e => e.remove())

    // すべてのタグが選択されている場合は、allにするよ
    if (budgetTagIdsInputValue.length === 0) {
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = 'primary_tag_ids'
      hiddenInput.value = 'all'
      this.element.append(hiddenInput)
      return
    }

    budgetTagIdsInputValue.forEach(v => {
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = 'primary_tag_ids[]'
      hiddenInput.value = v
      this.element.append(hiddenInput)
    })
  }

  _updateLabel() {
    const budgetTagNames = this._budgetTagNames()
    this.labelTarget.textContent = budgetTagNames
  }

  _budgetTagIdsInputValue() {
    if (this._defalutStatus()) {
      return []
    } else {
      const budgetTagIdsInputValue = this.itemTargets.map((e) => {
        const checkbox = e.querySelector('[data-action="multi-tag-checkbox-select#check"]')
        if(checkbox.checked) {
          return checkbox.dataset.value
        }
      }).filter(e => e)
      return budgetTagIdsInputValue
    }
  }

  _budgetTagNames() {
    if (this._defalutStatus()) {
      return 'すべて'
    } else if (this._everyUnchecked()) {
      return '選択してください'
    } else {
      const budgetTagNames = this.itemTargets.map((e) => {
        const checkbox = e.querySelector('[data-action="multi-tag-checkbox-select#check"]')
        if(checkbox.checked) {
          const name = e.querySelector('[data-item-name]')
          return name.textContent
        }
      }).filter(e => e).join(',')
      return budgetTagNames
    }
  }

  // タググループが全て選ばれておらず、タグ・未設定が選ばれた状態、つまり初回アクセス時の状態かどうか
  // ちなみに、列数制限の設定が入ってる場合は列数が切られるからこの状態にはならないはずだよ
  _defalutStatus() {
    return this.itemTargets.every((e) => {
      const checkbox = e.querySelector('[data-action="multi-tag-checkbox-select#check"]')
      return checkbox.disabled || checkbox.checked
    })
  }

  _everyUnchecked() {
    return this.itemTargets.every((e) => {
      const checkbox = e.querySelector('[data-action="multi-tag-checkbox-select#check"]')
      return checkbox.disabled || !checkbox.checked
    })
  }

  _updateBulkAllCheckBoxes() {
    const element = this.bulkAllCheckBoxTarget
    const budgetTagElements = this.budgetTagCheckBoxTargets.filter((e) => !e.disabled)
    if (budgetTagElements.every((e) => e.checked)) {
      element.indeterminate = false
      element.checked = true
      return
    }
    if (budgetTagElements.some((e) => e.checked)) {
      element.indeterminate = true
      element.checked = false
      return
    }
    element.indeterminate = false
    element.checked = false
  }

  _updateBulkCheckBoxes() {
    this.bulkCheckBoxTargets.filter((e) => !e.disabled).forEach((element) => {
      const subtreeIds = this._subtreeIds(element)
      const checkBoxes = this._getChildCheckBoxes(subtreeIds)
      if (checkBoxes.every((e) => e.checked)) {
        element.indeterminate = false
        element.checked = true
        return
      }

      if (checkBoxes.some((e) => e.checked)) {
        element.indeterminate = true
        element.checked = false
        return
      }

      element.indeterminate = false
      element.checked = false
    })
  }

  _getChildCheckBoxes(subtreeIds) {
    return this.budgetTagCheckBoxTargets.filter((e) => {
      return subtreeIds.includes(parseInt(e.dataset.groupId)) && !e.disabled
    })
  }

  _subtreeIds(el) {
    return el.dataset.subtreeIdsStr.split(',').map((e) => parseInt(e))
  }
}
