import { lazyGetter } from '../utils'

export default class Column extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-cash-flow')
      return JSON.parse(jsonString)
    })
  }

  get id () {
    return this.data.id
  }

  get nextColumnId () {
    return this.data.nextColumnId
  }

  get firstDate () {
    return this.data.firstDate
  }

  get lastDate () {
    return this.data.lastDate
  }
}
