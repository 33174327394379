import { useState } from 'react'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  ClientFolder,
  getClientFoldersUrl,
  getClientFolderUrl,
  Org,
} from '@/frontend/api'

export function useFolderFormDialog(org: Org) {
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: 'フォルダの登録',
    uri: `/orgs/${org.id}/client_folders`,
    folderName: '',
    edit: false,
  })
  const onClose = () => setDialogProps((state) => ({ ...state, open: false }))
  const onRegistrationFolderDialogOpen = () =>
    setDialogProps((preState) => ({
      ...preState,
      title: 'フォルダの登録',
      uri: getClientFoldersUrl(org.id),
      open: true,
      edit: false,
    }))
  const onEditFolderDialogOpen = (folder: ClientFolder) => {
    setDialogProps((preState) => ({
      ...preState,
      title: 'フォルダの編集',
      uri: getClientFolderUrl(org.id, folder.id),
      open: true,
      folderName: folder.name,
      edit: true,
    }))
  }
  const actions = {
    onClose,
    onRegistrationFolderDialogOpen,
    onEditFolderDialogOpen,
  }

  return {
    dialogProps,
    actions,
  }
}
