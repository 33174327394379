import { Controller } from 'stimulus'

// A4のPDFの縦横 -90は余白分
const PRINT_A4_WIDTH_PICEL = 1122.5196850394 - 90
const PRINT_A4_HEIGHT_PICEL = 793.7007874015 - 90
const MAX_ZOOM_PERCENTAGE = 1.3

export default class extends Controller {
  static targets = [
    'table',
    'graphCanvas',
    'preparedReportFirstPage',
    'preparedReportFirstPageZoom',
  ]

  connect() {
    this.changeZoom(this.tableTargets)
    this.changeZoomPreparedReportFirstPage()
  }

  onChartRender() {
    // NOTE : canvasに「width: 100%」設定しているのでzoomを変更してもうまく、反映されない。
    //        zoom変更前に横幅を固定化する
    this.graphCanvasTargets.forEach((e) => {
      e.style.width = `${e.offsetWidth}px`
    })

    this.changeZoom(this.graphCanvasTargets)
  }

  changeZoom(targets) {
    const widthFullZoomPercentage =
      PRINT_A4_WIDTH_PICEL / Math.max(...targets.map((e) => e.offsetWidth))
    // NOTE : 列が少ないレポート(ex: 年次推移表2列)の時に拡大されすぎると見た目が崩れるため、拡大が大きい時はそのまま出力する
    const zoomPercentage =
      widthFullZoomPercentage > MAX_ZOOM_PERCENTAGE
        ? 1
        : widthFullZoomPercentage
    targets.forEach((target) => {
      target.style.zoom = zoomPercentage
    })
  }

  // スコアカードとグラフを1ページ目に収める
  changeZoomPreparedReportFirstPage() {
    const firstPageZoom = this.preparedReportFirstPageZoomTarget
    const firstPage = this.preparedReportFirstPageTarget
    if (!firstPageZoom || !firstPage) {
      return
    }
    const diffPdfHeihght = firstPage.offsetHeight - PRINT_A4_HEIGHT_PICEL
    const adjustingHeight = firstPageZoom.offsetHeight - diffPdfHeihght
    firstPageZoom.style.zoom = adjustingHeight / firstPageZoom.offsetHeight
    firstPage.style.width = `${PRINT_A4_WIDTH_PICEL}px`
  }
}
