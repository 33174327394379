import React, { useState } from 'react'
import { RiAddBoxLine, RiCheckboxIndeterminateLine } from 'react-icons/ri'

import {
  BudgetTag,
  BudgetTagCategory,
  NonFinancialItem,
  Org,
} from '@/frontend/api'
import { Tooltip } from '@/frontend/components'
import { TableRowActionDelete } from './TableRowActionDelete'
import { TableRowActionEdit } from './TableRowActionEdit'

type Props = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  budgetTag: BudgetTag | null
  nonFinancialItems: NonFinancialItem[]
  editableById: { [id: number]: boolean }
  destroyableById: { [id: number]: boolean }
}

export function TableRowsOfTag({
  org,
  primaryBudgetTagCategory,
  budgetTag,
  nonFinancialItems,
  editableById,
  destroyableById,
}: Props) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <tr>
        <td className="py-4 px-4">
          <div className="flex items-center space-x-2">
            <button
              className="block"
              onClick={() => setOpen((prevState) => !prevState)}
            >
              {open ? (
                <RiCheckboxIndeterminateLine className="h-4 w-4" />
              ) : (
                <RiAddBoxLine className="h-4 w-4" />
              )}
            </button>
            <div>{budgetTag ? budgetTag.name : '全社'}</div>
          </div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {open &&
        nonFinancialItems.map((item: NonFinancialItem, index: number) => {
          const editable = editableById[item.id]
          const destroyable = destroyableById[item.id]
          return (
            <tr key={index}>
              <td className="py-3 pl-16 pr-3">{item.name}</td>
              <td className="p-3">{toNumberTypeLabel(item)}</td>
              <td className="p-3">{toRoundTypeLabel(item)}</td>
              <td className="p-3">{toSumUpLabel(item)}</td>
              <td className="p-3">{toStockFlowTypeLabel(item)}</td>
              <td className="p-3 max-w-0">
                {item.memo ? (
                  <Tooltip variant="long" content={item.memo}>
                    {(ref) => (
                      <div className="truncate" ref={ref}>
                        {item.memo}
                      </div>
                    )}
                  </Tooltip>
                ) : (
                  '-'
                )}
              </td>
              <td className="py-3 pl-3 pr-6">
                <div className="inline-flex items-center space-x-3">
                  {editable && (
                    <TableRowActionEdit
                      org={org}
                      primaryBudgetTagCategory={primaryBudgetTagCategory}
                      nonFinancialItem={item}
                    />
                  )}
                  {destroyable && (
                    <TableRowActionDelete org={org} nonFinancialItem={item} />
                  )}
                </div>
              </td>
            </tr>
          )
        })}
    </>
  )
}

function toRoundTypeLabel(item: NonFinancialItem): string {
  const roundType = item.roundType
  switch (roundType) {
    case 'round_down':
      return '切り捨て'
    case 'round':
      return '四捨五入'
    case 'round_up':
      return '切り上げ'
  }
}

function toNumberTypeLabel(item: NonFinancialItem): string {
  const numberType = item.numberType
  switch (numberType) {
    case 'integer':
      return '整数'
    case 'decimal':
      return '小数'
    case 'percentage':
      return '百分率'
  }
}

function toSumUpLabel(item: NonFinancialItem): string {
  const numberType = item.sumUp
  if (numberType) {
    return 'する'
  } else {
    return 'しない'
  }
}

function toStockFlowTypeLabel(item: NonFinancialItem): string {
  const stockFlowType = item.stockFlowType
  switch (stockFlowType) {
    case 'undefined':
      return '表示しない'
    case 'flow':
      return '期間の合算値'
    case 'stock':
      return '末時点の数値'
  }
}
