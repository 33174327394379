import { enter, leave } from './transitions'

document.addEventListener('click', (e) => {
  const clicked = e.target.closest('[data-slideover-open]')

  if (clicked) {
    const targetSelector = clicked.getAttribute('data-slideover-open')
    const target = document.querySelector(targetSelector)
    open(target)
  }
})

document.addEventListener('click', (e) => {
  const clicked = e.target.closest('[data-slideover-close]')

  if (clicked) {
    const targetSelector = clicked.getAttribute('data-slideover-close')

    if (targetSelector) {
      const target = document.querySelector(targetSelector)
      close(target)
    } else {
      const target = clicked.closest('[data-slideover]')
      close(target)
    }
  }
})

document.addEventListener('click', (e) => {
  const overlay = e.target.closest('#slideover-overlay')

  if (overlay) {
    document.querySelectorAll('[data-slideover]').forEach((element) => {
      close(element)
    })
  }
})

function open (target) {
  const overlay = document.getElementById('slideover-overlay')

  if (target) {
    enter(target, {
      active: ['transform', 'transition', 'ease-in-out', 'duration-200'],
      from: ['translate-x-full'],
      to: ['translate-x-0']
    })
    enter(overlay, {
      active: ['transition-opacity', 'ease-in-out', 'duration-200'],
      from: ['opacity-0'],
      to: ['opacity-100']
    })
    document.body.classList.add('overflow-hidden')
  }
}

function close (target) {
  const overlay = document.getElementById('slideover-overlay')

  if (target) {
    leave(target, {
      start: ['transform', 'transition', 'ease-in-out', 'duration-200'],
      from: ['translate-x-0'],
      to: ['translate-x-full']
    })
    leave(overlay, {
      start: ['transition-opacity', 'ease-in-out', 'duration-200'],
      from: ['opacity-100'],
      to: ['opacity-0']
    })
    document.body.classList.remove('overflow-hidden')
  }
}
