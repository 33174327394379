import React, { useState } from 'react'

import {
  ApiError,
  CreateReportGeneralCommentPayload,
  ReportGeneralComment,
  UpdateReportGeneralCommentPayload,
} from '@/frontend/api'
import { GeneralCommentItem } from './GeneralCommentItem'
import { useReportGeneralComment } from './hooks/useReportGeneralComment'
import { ReportMeta } from './types'

type Props = {
  orgId: number
  reportMeta: ReportMeta
  reportGeneralComment?: ReportGeneralComment
}

export function GeneralComment({
  orgId,
  reportMeta,
  reportGeneralComment,
}: Props) {
  const [apiError, setApiError] = useState<ApiError | null>(null)
  const { generalComment, create, update, destroy, isNew } =
    useReportGeneralComment(orgId, reportMeta, reportGeneralComment)
  const [isEditing, setIsEditing] = useState(isNew())

  const handleSubmit = async (
    data: CreateReportGeneralCommentPayload | UpdateReportGeneralCommentPayload,
  ) => {
    setApiError(null)
    const result = isNew()
      ? await create(data)
      : await update(generalComment as ReportGeneralComment, data)
    if (result && result.ok) {
      setIsEditing(false)
      dispatchActionEvent('Saved')
      return true
    } else {
      setApiError(result?.err || null)
      return false
    }
  }

  const handleDelete = async () => {
    setApiError(null)
    const result = await destroy(generalComment as ReportGeneralComment)
    if (result && result.ok) {
      setIsEditing(true)
      hideWrapper()
      dispatchActionEvent('Deleted')
      return true
    } else {
      setApiError(result?.err || null)
      return false
    }
  }

  // NOTE: PDFの出力チェックボックス切り替えのために....
  const dispatchActionEvent = (eventName: 'Saved' | 'Deleted') => {
    document.dispatchEvent(new CustomEvent(`reportGeneralComment${eventName}`))
  }

  const handleCancel = () => {
    if (isNew()) {
      hideWrapper()
    } else {
      setIsEditing(false)
    }
  }

  const hideWrapper = () => {
    // NOTE: 負けた気がする....
    // Reactの外から出し分けしているので非表示に...
    const wrapperDom = document.querySelector(
      '[data-report-general-comment-wrapper]',
    )
    if (wrapperDom) {
      wrapperDom.classList.add('hidden')
    }
  }

  return (
    <GeneralCommentItem
      content={generalComment?.content || null}
      isEditing={isEditing}
      onEdit={() => setIsEditing(true)}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onCancel={handleCancel}
      apiError={apiError}
    />
  )
}
