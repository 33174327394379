import { useState } from 'react'

import { JobLog, JobLogErrorDetail, JobLogStatus } from '@/frontend/api'

export type AlertStatus = {
  status: JobLogStatus
  errorDetail?: JobLogErrorDetail
}

export default function useAlertStatus(initialAlertStatus: AlertStatus | null) {
  const initialValue =
    initialAlertStatus?.status === 'failed'
      ? {
          status: initialAlertStatus.status,
          errorDetail: initialAlertStatus.errorDetail,
        }
      : null

  const [alertStatus, setAlertStatus] = useState<AlertStatus | null>(
    initialValue,
  )

  const resetAlertStatus = () => setAlertStatus(null)

  const updateAlertStatusFromJobLog = (jobLog: JobLog) => {
    setAlertStatus({
      status: jobLog.status,
      errorDetail: jobLog.errorDetail,
    })
  }
  return {
    alertStatus,
    setAlertStatus,
    resetAlertStatus,
    updateAlertStatusFromJobLog,
  }
}
