import { createErr, createOk, Result } from 'option-t/PlainResult'

import { axios } from '@/frontend/utils'
import { getAllocationVersionsUrl, getAllocationVersionUrl } from './routes'
import { AllocationVersion, ApiError } from './types'
import { handleAxiosError } from './utils'

export type ApiAllocationVersionPayload = {
  name: string
  allocationPeriods: {
    allocationTarget: 'both' | 'result' | 'budget'
    allocationMethod: 'individually' | 'in_bulk'
    periodType: 'indefinite' | 'fiscal_period' | 'any'
    firstYearMonth: string | null
    lastYearMonth: string | null
    fiscalPeriodId: string | null
    allocationDriverBudgetTypes: AllocationDriverBudgetType[]
    allocationSteps: {
      sourceBudgetTagIds: string[]
      destinationBudgetTagIds: string[]
      allocationDriverIndividuallyMappings?: AllocationDriverIndividuallyMappings
      allocationDriverInBulkMapping?: AllocationDriverInBulkMapping
    }[]
  }[]
}

export type AllocationDriverBudgetType = {
  allocationDriverId: string
  type: 'current' | 'budget' | 'result'
}

type AllocationDriverIndividuallyMappings = {
  accountItemId: string
  allocationDriverId: string | undefined
}[]

export type AllocationDriverInBulkMapping = {
  allocationDriverId: string
  destinationAccountItemId: string
  sourceItem: {
    id: string
    type: string
  }
}

export type ApiResultAllocationVersion = Result<AllocationVersion, ApiError>

export async function createAllocationVersion(
  orgId: number,
  payload: ApiAllocationVersionPayload,
): Promise<ApiResultAllocationVersion> {
  const url = getAllocationVersionsUrl(orgId)
  try {
    const res = await axios.post(url, payload)
    const allocationVersion = res.data as AllocationVersion
    return createOk(allocationVersion)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function updateAllocationVersion(
  orgId: number,
  id: number,
  payload: ApiAllocationVersionPayload,
): Promise<ApiResultAllocationVersion> {
  const url = getAllocationVersionUrl(orgId, id)
  try {
    const res = await axios.put(url, payload)
    const allocationVersion = res.data as AllocationVersion
    return createOk(allocationVersion)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
