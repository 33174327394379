import { createErr, createOk, Result } from 'option-t/esm/PlainResult'

import { axios } from '@/frontend/utils'
import { checkAllNotificationsUrl, getNotificationsUrl } from './routes'
import { ApiError, Notification } from './types'
import { handleAxiosError } from './utils'

export type NotificationsResponse = {
  notifications: Notification[]
  meta: {
    nextCursor: number
    hasMore: boolean
    uncheckedCount: number
  }
}

export async function getNotifications(
  orgId: number,
  params: { limit?: number; cursor?: number },
): Promise<Result<NotificationsResponse, ApiError>> {
  const url = getNotificationsUrl(orgId, params)
  try {
    const res = await axios.get(url)
    const notifications = res.data as NotificationsResponse
    return createOk(notifications)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export async function checkAllNotifications(
  orgId: number,
): Promise<Result<number[], ApiError>> {
  const url = checkAllNotificationsUrl(orgId)
  try {
    const res = await axios.put(url)
    return createOk(res.data as number[])
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
