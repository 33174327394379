import React from 'react'
import { RiRefreshLine } from 'react-icons/ri'

export function ButtonLoadingOverlay({
  loading,
  children,
}: {
  loading: boolean
  children: React.ReactNode
}) {
  if (!loading) return children

  return (
    <>
      <div className="absolute inset-0 flex items-center justify-center">
        <RiRefreshLine className="animate-spin text-gray-400 h-5 w-5" />
      </div>
      <div className="invisible">{children}</div>
    </>
  )
}
