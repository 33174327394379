/*
 * 詳細計画の削除時の仕訳削除ラジオボタンとformのsubmitボタンの制御
 */
document.addEventListener('change', (ev) => {
  const element = ev.target.closest('.destroy-budget-detail-item-journal-option')
  if(!element) {
    return
  }
  const submitButton = document.querySelector('.destroy-budget-detail-item-submit')
  submitButton.classList.remove('disabled')
  submitButton.removeAttribute('tabindex')
})
