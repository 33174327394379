import React from 'react'

import { CustomReport, CustomReportFolder, SubProps } from '../components'
import { IndexList } from './components/IndexList'

type Props = {
  orgId: number
  updateOrderUrl: string
  isSortable: boolean
  items: Item[]
  subProps: SubProps
}

type Item = CustomReportFolder | CustomReport

export default function IndexPage({
  orgId,
  updateOrderUrl,
  items,
  subProps,
  isSortable,
}: Props) {
  return (
    <IndexList
      orgId={orgId}
      updateOrderUrl={updateOrderUrl}
      isSortable={isSortable}
      items={items}
      subProps={subProps}
    />
  )
}
