import UpdateBudgetDetailChannel from '@/channels/update_budget_detail_channel'
import { lazyGetter, numberWithDelimiter } from '../utils'
import Table from './Table'
import Column from './Column'
import Row from './Row'
import Cell from './Cell'
import NonFinancialCell from './NonFinancialCell'
import axios from '../axios'
import snakeCaseKeys from 'snakecase-keys'

export default class Main {
  constructor () {
    if (this.hasTable) {
      this.table = this.buildTable()
      this.columns = this.buildColumns()
      this.rows = this.buildRows()
      this.cells = this.buildCells()
      this.nonFinancialCells = this.buildNonFinancialCells()
      this.cautionText = document.querySelector("[data-caution-text]")
      this.submitBtn = document.querySelector("[data-bulk-update]")
      this.submitBtnInitialInnerHtml = document.querySelector("[data-bulk-update]").innerHTML
      this.updateBudgetDetailChannel = this.buildUpdateBudgetDetailChannel()
      this.temporaryParamsPoolPerRequest = {}
      this.addEventListeners()
    }

    this.initParamsPool()
    this.isRequesting = false
  }

  get hasTable () {
    return !!this.tableElement
  }

  get tableElement () {
    return lazyGetter(this, 'tableElement', () => {
      return document.querySelector('[data-dynamic-budget-detail-type="Table"]')
    })
  }

  get columnMap () {
    return lazyGetter(this, 'columnMap', () => {
      return this.columns.reduce((map, column) => {
        map[column.id] = column
        return map
      }, {})
    })
  }

  get rowMap () {
    return lazyGetter(this, 'rowMap', () => {
      return this.rows.reduce((map, row) => {
        map[row.id] = row
        return map
      }, {})
    })
  }

  get cellMap () {
    return lazyGetter(this, 'cellMap', () => {
      return this.cells.reduce((map, cell) => {
        map[[cell.columnId, cell.rowId]] = cell
        return map
      }, {})
    })
  }

  get nonFinancialCellMap () {
    return lazyGetter(this, 'nonFinancialCellMap', () => {
      return this.nonFinancialCells.reduce((map, nonFinancialCell) => {
        map[[nonFinancialCell.columnId, nonFinancialCell.rowId]] = nonFinancialCell
        return map
      }, {})
    })
  }

  get childRowsMap () {
    return lazyGetter(this, 'childRowsMap', () => {
      return this.rows.reduce((map, row) => {
        map[row.parentId] ||= []
        map[row.parentId].push(row)
        return map
      }, {})
    })
  }

  get isActivatedBtn() {
    // リスエストをしていない かつ 送信すべきものがまだある。
    return !this.isRequesting && this.paramsPool.length > 0
  }

  // ページ離脱されたら困る時判定
  get isIncomplete() {
    return this.isRequesting || this.paramsPool.length > 0
  }

  buildTable () {
    return new Table({ element: this.tableElement })
  }

  buildColumns () {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-budget-detail-type="Column"]'),
      element => new Column({ element })
    )
  }

  buildRows () {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-budget-detail-type="Row"]'),
      element => new Row({ element })
    )
  }

  buildCells () {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-budget-detail-type="Cell"]'),
      element => new Cell({ element })
    )
  }

  buildNonFinancialCells () {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-budget-detail-type="NonFinancialCell"]'),
      element => new NonFinancialCell({ element })
    )
  }

  buildUpdateBudgetDetailChannel () {
    const channel =  new UpdateBudgetDetailChannel({
      orgId: this.table.inform.orgId,
      budgetId: this.table.inform.budgetId,
      budgetDetailId: this.table.inform.budgetDetailId,
    })
    channel.subscribeReceived(this.notifyReceivedHandler.bind(this))

    return channel
  }

  notifyReceivedHandler(data) {
    if ( data.success ) {
      this.propagateToFormula({ response: { data: data.result } })
      this.isRequesting = false
      this.changeDisabledSubmitButton(!this.isActivatedBtn, this.submitBtnInitialInnerHtml)
    }else{
      console.warn('error:', data.error)
      let msgs = []
      if (data.error.type === 'unprocessable_entity') {
        msgs.push('不正な値が送信されたため保存に失敗しました。')
        msgs.push(...data.error.messages)
        this.isRequesting = false
        this.restoreTemporaryParamsPool(data.metadata.request_key)
        this.changeDisabledSubmitButton(!this.isActivatedBtn, this.submitBtnInitialInnerHtml)
      } else {
        msgs.push('値の保存に失敗しました。')
        msgs.push('画面を再読み込みする、しばらく時間を置いてから試す、等の操作をしても解決されない場合はサポートまでお問い合わせください。')
        this.isRequesting = false
        this.restoreTemporaryParamsPool(data.metadata.request_key)
        this.changeDisabledSubmitButton(!this.isActivatedBtn, this.submitBtnInitialInnerHtml)
      }
      alert(msgs.join('\n'))
    }
  }

  addEventListeners () {
    this.cells.forEach(cell => {
      cell.addEventListener('input', this.onCellInput.bind(this))
      cell.addEventListener('change', this.onCellChange.bind(this))
    })
    this.nonFinancialCells.forEach(nonFinancialCell => {
      nonFinancialCell.addEventListener('input', this.onNonFinancialCellInput.bind(this))
      nonFinancialCell.addEventListener('change', this.onNonFinancialCellChange.bind(this))
    })
    this.submitBtn.addEventListener('click', () => {
      this.changeDisabledSubmitButton(true, this.submitBtn.dataset.disableWith)
      this.isRequesting = true
      this.submit(this.table.action, this.paramsPool)
    })
  }

  initParamsPool() {
    this.paramsPool = []
  }

  onCellInput ({ target: cell, detail: { changeAmount } }) {
    this.afterUpdateCell({ cell, changeAmount })
  }

  onCellChange ({ target: cell }) {
    const column = this.findColumn(cell.columnId)
    const row = this.findRow(cell.rowId)

    this.poolParams({
      budgetDetailItemId: row.id,
      firstDate: column.firstDate,
      lastDate: column.lastDate,
      amount: cell.balance,
      categorizedTagIds: row.categorizedTagIds
    })
    if (this.isActivatedBtn) {
      this.changeDisabledSubmitButton(false, this.submitBtnInitialInnerHtml)
    }
  }

  onNonFinancialCellInput ({ target: cell }) {
    this.afterUpdateNonFinancialCell(cell)
  }

  onNonFinancialCellChange ({ target: nonFinancialCell }) {
    const column = this.findColumn(nonFinancialCell.columnId)
    const row = this.findRow(nonFinancialCell.rowId)

    this.poolParams({
      budgetDetailItemId: row.id,
      firstDate: column.firstDate,
      lastDate: column.lastDate,
      amount: nonFinancialCell.value,
    })
    if (this.isActivatedBtn) {
      this.changeDisabledSubmitButton(false, this.submitBtnInitialInnerHtml)
    }
  }

  afterUpdateCell ({ cell, changeAmount }) {
    this.propagateToParentRecursive({ cell: cell, changeAmount })
    this.propagateToTotal({ cell, changeAmount })
    this.propagateToRelated({ cell, changeAmount })
    // 売上高の場合だけ変動費率の科目を更新する
    if (cell.rowId == 'ri-sales') {
      this.afterUpdateRiSalesCell (cell)
    }
  }

  afterUpdateNonFinancialCell (cell) {
    // 入力が空とか数字じゃない時は合計列の再計算は走らないようにした
    if (!cell.validInput()) {
      return
    }

    if (cell.stockFlowType === 'flow') {
      this.propagateToNonFinancialTotal(cell)
    } else if (cell.stockFlowType === 'stock' && cell.isEndCell) {
      this.propagateToNonFinancialTotal(cell)
    }
  }

  afterUpdateRiSalesCell (riSalesCell) {
    this.cells.forEach(cell => {
      if (cell.isVariableCostAccountItem && cell.columnId == riSalesCell.columnId ) {
        // cellは変動費として設定された勘定科目のセル
        const costRateCell = this.relatedCostRateCellOf (cell)
        this.updateVariableCostAccountItemCell(cell, costRateCell, riSalesCell)
      }
    })
  }

  relatedCostRateCellOf (variableCostAccountItemCell) {
    const row = this.findRow(variableCostAccountItemCell.rowId)
    const relatedCostTypeRow = this.findRow(row.relatedCostTypeId)
    if (relatedCostTypeRow) {
      return this.findCell(variableCostAccountItemCell.columnId, relatedCostTypeRow.id)
    } else {
      return null
    }
  }

  // 変動費率を元に変動費を計算する
  afterUpdateCostRateCell (costRateCell) {
    const rootAccountItemCell = this.rootAccountItemCellOf (costRateCell)
    const riSalesCell = this.findCell(costRateCell.columnId,'ri-sales')
    this.updateVariableCostAccountItemCell (rootAccountItemCell, costRateCell, riSalesCell)
  }

  updateVariableCostAccountItemCell (variableCostAccountItemCell, costRateCell, riSalesCell) {
    const rate = Math.round( costRateCell.rate * 10 ) / 10
    const calcBalance = Math.round( riSalesCell.displayedBalance * rate / 100 )
    const diffBalance = calcBalance - variableCostAccountItemCell.displayedBalance
    variableCostAccountItemCell.displayedBalance = numberWithDelimiter(calcBalance)
    const changeAmount = this.setChangeAmount(variableCostAccountItemCell, diffBalance)
    this.afterUpdateCell({ cell: variableCostAccountItemCell, changeAmount: changeAmount })
  }

  setChangeAmount(rootAccountItemCell, diffBalance) {
    if (rootAccountItemCell.isDebit) {
      return { debit: diffBalance, credit: 0 }
    } else {
      return { debit: 0, credit: diffBalance }
    }
  }

  rootAccountItemCellOf (costRateCell) {
    const row = this.findRow(costRateCell.rowId)
    const rootAccountItemRow = this.findRow(row.rootAccountItemId)
    if (rootAccountItemRow) {
      return this.findCell(costRateCell.columnId, rootAccountItemRow.id)
    } else {
      return null
    }
  }

  // 親へ値を再帰的に伝搬する
  propagateToParentRecursive ({ cell, changeAmount }) {
    const parentCell = this.parentCellOf(cell)

    if (parentCell) {
      parentCell.addAmount(changeAmount)
      parentCell.updateHtml()
      this.afterUpdateCell({ cell: parentCell, changeAmount })
    }
  }

  // 合計行へ反映する（勘定科目）
  propagateToTotal({ cell, changeAmount }) {
    const totalCell = this.totalCellOf(cell)

    if (totalCell) {
      totalCell.addAmount(changeAmount)
      totalCell.updateHtml()
    }
  }

  // 合計行へ反映する（非財務項目）
  propagateToNonFinancialTotal(cell) {
    const totalNonFinancialCell = this.totalNonFinancialCellOf(cell)
    if (totalNonFinancialCell) {
      if (cell.stockFlowType === 'flow') {
        // 合計列の合算値を計算
        // NOTE: 勘定科目は整数なのでは差分を足していってると思うが、非財務は都度その行の合計を計算するようにした
        const commonColumns = this.columns.filter(column => column.id != 'total')
        const accumulator = commonColumns.reduce((accum, column) => {
          const targetCell = this.findNonFinancialCell(column.id, cell.rowId)
          const addValue = isNaN(targetCell.displayedValue) ? 0 : Number(targetCell.displayedValue)
          // https://blog.apar.jp/program/8900/
          // 上記記事を参考に少数や百分率は少数点代二位のため100倍して100で割っています。
          return (accum * 100 + addValue * 100) / 100
        }, 0)
        totalNonFinancialCell.applyValue(accumulator)
        totalNonFinancialCell.updateHtml()
      } else {
        const endCellValue = isNaN(cell.displayedValue) ? 0 : cell.displayedValue
        totalNonFinancialCell.applyValue(endCellValue)
        totalNonFinancialCell.updateHtml()
      }
    }
  }

  // 関連行へ反映する
  propagateToRelated({ cell, changeAmount }) {
    const relatedCells = this.relatedCellsOf(cell)

    relatedCells.forEach(cell => {
      cell.addAmount(changeAmount)
      cell.updateHtml()
      this.afterUpdateCell({ cell, changeAmount })
    })
  }

  revaluateAncestors(cell) {
    const parentCell = this.parentCellOf(cell)
    if (!parentCell) return

    const childCells = this.childCellsOf(parentCell)
    const debit = childCells.reduce((a, c) => a + c.debitAmount, 0)
    const credit = childCells.reduce((a, c) => a + c.creditAmount, 0)

    parentCell.setAmount({ debit, credit })
    parentCell.updateHtml()

    this.revaluateAncestors(parentCell);
  }

  revaluateTotal(row) {
    const totalCell = this.findCell('total', row.id)
    if (!totalCell) return

    const commonColumns = this.columns.filter(column => column.id != 'total')
    const commonCells = commonColumns.map(column => this.findCell(column.id, row.id))

    const debit = commonCells.reduce((a, c) => a + c.debitAmount, 0)
    const credit = commonCells.reduce((a, c) => a + c.creditAmount, 0)

    totalCell.setAmount({ debit, credit })
    totalCell.updateHtml()

    this.revaluateAncestors(totalCell)
  }

  poolParams(params) {
    const { budgetDetailItemId, firstDate, lastDate } = params

    // NOTE : 同じセルを２度書き換えた時に、値を二つ送信しないように最後の値だけ保持する
    const sameCellPoolIdx = this.paramsPool.findIndex((e) => e.budgetDetailItemId == budgetDetailItemId
				&& e.firstDate == firstDate
				&& e.lastDate == lastDate)
    if (!(sameCellPoolIdx == -1)) {
      this.paramsPool.splice(sameCellPoolIdx, 1)
    }
    this.paramsPool.push(params)
  }

  storeTemporaryParamsPool() {
    const key = crypto.randomUUID()
    this.temporaryParamsPoolPerRequest[key] = this.paramsPool
    return key
  }

  clearTemporaryParamsPool(key) {
    this.storeTemporaryParamsPool[key] = null
  }

  restoreTemporaryParamsPool(key) {
    const stored = this.storeTemporaryParamsPool[key]
    if (stored) {
      this.paramsPool = [...stored, ...this.paramsPool]
      this.clearTemporaryParamsPool(key)
      return true
    }
    return false
  }

  submit (action, params) {
    const storedKey = this.storeTemporaryParamsPool()
    this.initParamsPool()
    return axios.put(action, {
      params: snakeCaseKeys(params),
      metadata: snakeCaseKeys({ requestKey: storedKey })
    })
      .then(
        _ => {},
        error => {
          if (error.response) {
            let msgs = []
            if (error.response.status === 422) {
              msgs.push('不正な値が送信されたため保存に失敗しました。')
              msgs.push(...error.response.data.errors)
              this.isRequesting = false
              this.restoreTemporaryParamsPool(storedKey)
              this.changeDisabledSubmitButton(!this.isActivatedBtn, this.submitBtnInitialInnerHtml)
            } else if (error.response.status === 504) {
              msgs.push('計算処理を継続中です。')
              msgs.push('しばらく時間を置いてから画面を再読み込みしてください。')
              msgs.push('変更内容が反映されない場合は、お手数ですが再入力、再送信してください。')
              this.isRequesting = false
              if(this.paramsPool.length > 0) {
                this.changeDisabledSubmitButton(!this.isActivatedBtn, this.submitBtnInitialInnerHtml)
              }
            } else {
              msgs.push('値の保存に失敗しました。')
              msgs.push('画面を再読み込みする、しばらく時間を置いてから試す、等の操作をしても解決されない場合はサポートまでお問い合わせください。')
              this.isRequesting = false
              this.restoreTemporaryParamsPool(storedKey)
              this.changeDisabledSubmitButton(!this.isActivatedBtn, this.submitBtnInitialInnerHtml)
            }
            alert(msgs.join('\n'))
          }
        }
      )
  }

  changeVisibledCautionText (isVisible) {
    const target = this.cautionText
    if (isVisible) {
      target.classList.remove('hidden')
    } else {
      target.classList.add('hidden')
    }
  }

  changeDisabledSubmitButton (disabled, innerHTML) {
    this.changeVisibledCautionText (!disabled)

    const target = this.submitBtn
    target.disabled = disabled
    target.innerHTML = innerHTML
  }

  propagateToFormula ({response}) {
    const data = response.data
    const rowIds = Object.keys(data)
    rowIds.forEach(rowId => {
      const values = data[rowId]
      const row = this.findRow(rowId)
      if (row != undefined) {
        values.forEach(value => {
          const cell = this.findFromAllCell(value[0], row.id)
          if (cell != undefined) {
            const column = this.findColumn(cell.columnId)
            if (column.isBudgetColumn) {
              this.replaceToChangedValue(cell, value[1])
            }
          }
        })
        this.revaluateTotal(row);
      }
    })
  }

  replaceToChangedValue (cell, value) {
    const budgetDetailType = cell.element.dataset.dynamicBudgetDetailType
    switch (budgetDetailType) {
      case 'NonFinancialCell':
        cell.applyValue(value)
        cell.updateHtml()
        break
      case 'Cell':
        cell.setBalance(value)
        cell.updateHtml()
        this.revaluateAncestors(cell);
        break
    }
  }

  parentCellOf (cell) {
    const row = this.findRow(cell.rowId)
    const parentRow = this.findRow(row.parentId)

    if (parentRow) {
      return this.findCell(cell.columnId, parentRow.id)
    } else {
      return null
    }
  }

  totalCellOf (cell) {
    return this.findCell('total', cell.rowId)
  }

  relatedCellsOf (cell) {
    const row = this.findRow(cell.rowId)

    return row.relatedIds.reduce((cells, id) => {
      const relatedRow = this.findRow(id)

      if (relatedRow) {
        return [
          ...cells,
          this.findCell(cell.columnId, relatedRow.id)
        ]
      } else {
        return cells
      }
    }, [])
  }

  findColumn (id) {
    return this.columnMap[id]
  }

  findRow (id) {
    return this.rowMap[id]
  }

  findCell (columnId, rowId) {
    return this.cellMap[[columnId, rowId]]
  }

  childCellsOf(parent) {
    const childRows = this.childRowsMap[parent.rowId]
    return childRows.map(row => {
      return this.findCell(parent.columnId, row.id);
    })
  }

  findFromAllCell(columnId, rowId) {
    const row = this.findRow(rowId)
    switch (row.relatedItemClass) {
      case 'budgetDetailItemAccountItem':
      case 'budgetDetailItemReservedItem':
        return this.findCell(columnId, rowId)
      case 'budgetDetailItemNonFinancialItem':
        return this.findNonFinancialCell(columnId, rowId)
      default:
        throw new Error(`不正な型です${row.relatedItemClass}`)
    }
  }

  findNonFinancialCell (columnId, rowId) {
    return this.nonFinancialCellMap[[columnId, rowId]]
  }

  totalNonFinancialCellOf (cell) {
    return this.findNonFinancialCell('total', cell.rowId)
  }
}
