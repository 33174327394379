import React from 'react'
import {
  AutoLinkPlugin,
  LinkMatcher,
} from '@lexical/react/LexicalAutoLinkPlugin'

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const MATCHERS: LinkMatcher[] = [
  (text) => {
    const match = URL_MATCHER.exec(text)
    if (!match) return null
    const m = match[0] as string
    return {
      index: match.index,
      length: m.length,
      text: m,
      url: m,
      attributes: { target: '_blank' },
    }
  },
  (text) => {
    const match = EMAIL_MATCHER.exec(text)
    if (!match) return null
    const m = match[0] as string
    return {
      index: match.index,
      length: m.length,
      text: m,
      url: `mailto:${m}`,
    }
  },
]

export default function PlaygroundAutoLinkPlugin() {
  return <AutoLinkPlugin matchers={MATCHERS} />
}
