import React, { forwardRef } from 'react'
import { tv } from 'tailwind-variants'

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type' | 'className'
> & {
  invalid?: boolean // 使っていないが他のInputと合わせるため
}

const input = tv({
  base: [
    'ReactComponentInput', // 全体の disabled スタイルを回避するため app/javascript/src/scss/_input.scss
    'h-4 w-4 text-blue-600',
    'border-gray-300',
    'transition duration-150 ease-in-out',
    'focus:text-blue-700 focus:outline focus:outline-blue-100 focus:outline-offset-0 focus:outline-[3px] focus:ring-gray-300 focus:ring-inset focus:shadow-none focus:ring-0 focus:ring-offset-0',
  ].join(' '),
  variants: {
    disabled: {
      true: 'border-none bg-gray-100 text-gray-300 ring-0 pointer-events-none',
    },
  },
})

export const InputRadio = forwardRef<HTMLInputElement, Props>(function Input(
  { invalid: _invalid, ...props },
  ref,
) {
  return (
    <input
      className={input({ disabled: props.disabled })}
      ref={ref}
      type="radio"
      {...props}
    />
  )
})
