import { Controller } from 'stimulus'

// NOTE: レポートコメントはReactで動的にコメントが生成・削除されるためイベントでチェックを切り替え....
export default class extends Controller {
  static targets = ['reportGeneralCommentCheckbox']

  connect() {
    document.addEventListener(
      'reportGeneralCommentSaved',
      this.displayingGeneralComment.bind(this),
    )
    document.addEventListener(
      'reportGeneralCommentDeleted',
      this.disabledGeneralComment.bind(this),
    )
  }

  disconnect() {
    document.removeEventListener(
      'reportGeneralCommentSaved',
      this.displayingGeneralComment.bind(this),
    )
    document.removeEventListener(
      'reportGeneralCommentDeleted',
      this.disabledGeneralComment.bind(this),
    )
  }

  displayingGeneralComment() {
    const checkbox = this.reportGeneralCommentCheckboxTarget
    checkbox.disabled = false
    checkbox.checked = true
    this.dispatchInputEvent(checkbox)
  }

  disabledGeneralComment() {
    const checkbox = this.reportGeneralCommentCheckboxTarget
    checkbox.disabled = true
    checkbox.checked = false
    this.dispatchInputEvent(checkbox)
  }

  dispatchInputEvent(target) {
    // NOTE: inputイベントを発火させることで、anchor_form_controller.jsくんを動かすよ
    //       aタグのhrefが変更されて、パラメータが変るよ
    target.dispatchEvent(new Event('input'))
  }
}
