import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'hiddenFieldTag',
    'subject',
    'dropdownItem'
  ]

  updateHiddenFileds(e) {
    const paramsObj = JSON.parse(e.currentTarget.dataset.params)
    this.hiddenFieldTagTargets.forEach((el) => {
      const nameKey = el.getAttribute('name')
      const value = paramsObj[nameKey]
      if (!value) {
        el.disabled = true
        el.removeAttribute('value')
      } else {
        el.disabled = false
        el.setAttribute('value', value)
      }
    })
    this.updateSubject(e)
    this.updateActiveItem(e)
  }

  // ラベルの更新
  updateSubject(e) {
    const option = e.currentTarget.textContent
    // 集計対象の"部門▷本部"のような選択肢の場合
    if (e.currentTarget.dataset.category) {
      const currentCategory = e.currentTarget.dataset.category
      this.subjectTarget.innerHTML = `${currentCategory}<i class="ri-arrow-right-s-fill mx-1"></i>${option}`
    } else {
      this.subjectTarget.textContent = option
    }
  }

  // 選択中のタグ・タググループを変更
  updateActiveItem(e) {
    this.dropdownItemTargets.forEach((el) => {
      el.classList.remove('active')
    })
    e.currentTarget.classList.add('active')
  }
}
