import React from 'react'

import {
  ApiError,
  CreateReportGeneralCommentPayload,
  UpdateReportGeneralCommentPayload,
} from '@/frontend/api'
import { GeneralCommentActionDropdown } from './GeneralCommentActionDropdown'
import { GeneralCommentForm } from './GeneralCommentForm'
import { GeneralCommentView } from './GeneralCommentView'

type Props = {
  content: string | null
  isEditing: boolean
  onEdit: () => void
  onSubmit: (
    data: CreateReportGeneralCommentPayload | UpdateReportGeneralCommentPayload,
  ) => Promise<boolean>
  onCancel: () => void
  onDelete: () => Promise<boolean>
  apiError: ApiError | null
}

export function GeneralCommentItem({
  content,
  isEditing,
  onSubmit,
  onDelete,
  onCancel,
  onEdit,
  apiError,
}: Props) {
  const deleteCommentThreadConfirm = () => {
    return confirm('このレポートコメントを削除しますか？')
  }

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // NOTE: リンクをクリックした場合は編集モードに遷移させない
    const target = e.target
    if (
      target instanceof HTMLElement &&
      target.closest('a') instanceof HTMLAnchorElement
    ) {
      return
    }
    onEdit()
  }

  if (isEditing) {
    return (
      <GeneralCommentForm
        apiError={apiError}
        initialValue={content || ''}
        onSubmit={onSubmit}
        onCancel={onCancel}
      ></GeneralCommentForm>
    )
  } else {
    return (
      <div className="bg-white shadow rounded-lg p-8 pr-2">
        <div className="flex items-start flex-nowrap mb-2 space-x-1">
          <div
            className="flex-auto break-all max-h-60 overflow-y-auto"
            onClick={handleOnClick}
          >
            <GeneralCommentView content={content || ''} />
          </div>
          <div className="flex-none">
            <GeneralCommentActionDropdown
              onEdit={onEdit}
              onDelete={() => {
                deleteCommentThreadConfirm() && onDelete()
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
