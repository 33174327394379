import { lazyGetter, numberWithDelimiter, stringToInteger } from '../utils'

export default class Cell extends EventTarget {
  constructor ({ element, cellFormatter }) {
    super()
    this.element = element
    this.cellFormatter = cellFormatter
    this.inComposition = false
    this.initializeAmount()
    this.addEventListeners()
  }

  initializeAmount () {
    if (this.isDebit) {
      this.debitAmount = this.displayedBalance
      this.creditAmount = 0
    } else {
      this.debitAmount = 0
      this.creditAmount = this.displayedBalance
    }
  }

  addEventListeners () {
    this.element.addEventListener('compositionstart', this.onCompositionStart.bind(this))
    this.element.addEventListener('compositionend', this.onCompositionEnd.bind(this))
    this.element.addEventListener('input', this.onInput.bind(this))
    this.element.addEventListener('change', this.onChange.bind(this))
  }

  onCompositionStart (e) {
    this.inComposition = true
  }

  onCompositionEnd (e) {
    const changeAmount = this.updateBalance()
    const event = new CustomEvent('input', { detail: { changeAmount } })
    this.dispatchEvent(event)
    this.inComposition = false
  }

  onInput (e) {
    if (this.inComposition) return
    const changeAmount = this.updateBalance()
    const event = new CustomEvent('input', { detail: { changeAmount } })
    this.dispatchEvent(event)
  }

  onChange (e) {
    this.updateHtml()
    const event = new CustomEvent('change')
    this.dispatchEvent(event)
  }

  updateBalance () {
    const val = this.inputElement.value
    const int = stringToInteger(val)
    if (isNaN(int)) return { debit: 0, credit: 0 }

    const formattedInt = this.cellFormatter.format(this.rowId, int)

    const changeAmount = this.calcChangeAmount(formattedInt)
    this.addAmount(changeAmount)

    return changeAmount
  }

  addAmount ({ debit, credit }) {
    this.debitAmount += debit
    this.creditAmount += credit
  }

  calcChangeAmount (val) {
    const changedAmount = val - this.balance

    return {
      debit: this.isDebit ? changedAmount : 0,
      credit: this.isDebit ? 0 : changedAmount
    }
  }

  updateHtml () {
    const balanceForDisplay = numberWithDelimiter(this.balance)

    if (this.isInput) {
      return this.inputElement.value = balanceForDisplay
    } else {
      return this.element.textContent = balanceForDisplay
    }
  }

  get balance () {
    return this.isDebit ? this.debitAmount - this.creditAmount : this.creditAmount - this.debitAmount
  }

  get displayedBalance () {
    const str = this.isInput ? this.inputElement.value : this.element.textContent
    return parseInt(str.replace(/,/g, ''))
  }

  get isInput () {
    return !!this.inputElement
  }

  get inputElement () {
    return lazyGetter(this, 'inputElement', () => {
      return this.element.querySelector('input')
    })
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-cash-flow')
      return JSON.parse(jsonString)
    })
  }

  get columnId () {
    return this.data.columnId
  }

  get rowId () {
    return this.data.rowId
  }

  get transactionType () {
    return this.data.transactionType
  }

  get isDebit () {
    return this.transactionType === 'debit'
  }

  get isCredit () {
    return this.transactionType === 'credit'
  }
}
