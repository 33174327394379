import React, { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FileRejection, FileWithPath } from 'react-dropzone'
import { useForm } from 'react-hook-form'

import { ApiError } from '@/frontend/api'
import {
  GeneralCsvSourceAccountItemPayload,
  generalCsvSourceAccountItemSchema,
} from '@/frontend/api/importer'
import { ApiAlert, InputGroup, InputText } from '@/frontend/components'
import { Alert } from '@/frontend/components/Alert/Alert'
import { Button } from '@/frontend/components/buttons/Button'
import { AlertStatus } from '@/frontend/features/importers/hooks/useAlertStatus'
import Dropzone from '../components/Dropzone'
import { ImportStatus } from '../components/ImportStatus'
import LastImportedAt from '../components/LastImportedAt'
import { LastImportStatus } from '../components/LastImportStatus'
import { ImportFileStatus, JobLogWithAttachedFile } from '../types'

type Props = {
  importFileStatus: ImportFileStatus | null
  onFileChange: (file: FileWithPath) => void
  onSubmit: (data: GeneralCsvSourceAccountItemPayload) => void
  initialValues?: Partial<
    Pick<GeneralCsvSourceAccountItemPayload, 'headerRowNumber'>
  >
  isLoading: boolean
  apiError?: ApiError
  alertStatus: AlertStatus | null
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
}

export default function MasterImportForm({
  importFileStatus,
  onFileChange,
  onSubmit,
  initialValues,
  isLoading,
  apiError,
  alertStatus,
  jobLogWithAttachedFile,
}: Props) {
  const [fileRejections, setFileRejections] = React.useState<FileRejection[]>(
    [],
  )
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    handleSubmit,
  } = useForm<GeneralCsvSourceAccountItemPayload>({
    resolver: zodResolver(generalCsvSourceAccountItemSchema),
    defaultValues: {
      headerRowNumber: initialValues?.headerRowNumber ?? 1,
      csvFile: null,
    },
  })

  const csvFile = watch('csvFile')

  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length <= 0) return
      const acceptedFile = acceptedFiles[0]
      onFileChange(acceptedFile as FileWithPath)
      setValue('csvFile', acceptedFile as FileWithPath)
      setFileRejections(fileRejections)
    },
    [setValue],
  )

  const _onSubmit = handleSubmit((data) => {
    onSubmit(data)
    setValue('csvFile', null)
  })

  const errorMessage =
    fileRejections.length > 0 ? 'CSVファイルを選択してください' : ''

  return (
    <form onSubmit={_onSubmit}>
      <div className="space-y-4">
        {apiError && (
          <div className="py-4">
            <ApiAlert apiError={apiError} />
          </div>
        )}
        <div className="w-1/5">
          <InputGroup
            label="ヘッダー開始行"
            errorMsg={errors.headerRowNumber?.message}
          >
            {(props) => (
              <InputText
                {...props}
                invalid={!!errors.headerRowNumber}
                {...register('headerRowNumber')}
              />
            )}
          </InputGroup>
        </div>
        <div className="font-bold">勘定科目のインポート</div>

        {errorMessage && <Alert title={errorMessage} />}
        <Dropzone onDrop={onDrop} disabled={isLoading} />
        {importFileStatus && (
          <LastImportStatus
            importFileStatus={importFileStatus}
            loading={isLoading}
          />
        )}
        {alertStatus && (
          <ImportStatus
            status={alertStatus.status}
            errorDetail={alertStatus.errorDetail}
          />
        )}
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <LastImportedAt
              lastImportedAt={jobLogWithAttachedFile?.createdAt}
            />
          </div>
          <div className="mt-auto">
            <Button
              type="submit"
              variant="primary"
              loading={isLoading}
              disabled={csvFile == null}
            >
              アップロード
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
