import snakeCaseKeys from 'snakecase-keys'

import { axios } from '@/frontend/utils'

export type SaveNonFinancialValuesParams = {
  budgetDetailItemId: number
  firstDate: string
  lastDate: string
  amount: number
}[]

// {"params":[{"budget_detail_item_id":13,"first_date":"2017-05-01","last_date":"2017-05-31","amount":1100}]}
export function saveNonFinancialValues(
  url: string,
  data: {
    fiscalPeriodId: number
    params: SaveNonFinancialValuesParams
  },
) {
  return axios.put(url, snakeCaseKeys(data))
}
