import { AccountingSoftwareKey } from './types'

export type ImportFormatMap = {
  api: { value: ApiImportFormat; label: string }[]
  csv: { value: CsvImportFormat; label: string }[]
}

type ApiImportFormat = AccountingSoftwareKey
type CsvImportFormat = 'transitionTable' | AccountingSoftwareKey

export type ImportFormat = ApiImportFormat | CsvImportFormat

// NOTE: 会計ソフト毎に選択できる項目
// https://knowledgelabo.atlassian.net/browse/APP-3460
export function getImportFormatMap(
  accountingSoftwareKey: AccountingSoftwareKey,
) {
  switch (accountingSoftwareKey) {
    case 'mfc':
      return mfcImportFormatMap
    case 'mfc_plus':
      return mfcPlusImportFormatMap
    case 'freee':
      return freeePlusImportFormatMap
    case 'yayoi':
      return yayoiImportFormatMap
    case 'bugyo_i':
      return bugyoIImportFormatMap
    case 'bugyo_cloud':
      return bugyoCloudImportFormatMap
    case 'general':
      return generalImportFormatMap
    default:
      throw new Error(
        `Unknown accounting software key: ${accountingSoftwareKey}`,
      )
  }
}

const mfcImportFormatMap = {
  api: [{ value: 'mfc', label: 'マネーフォワードクラウド会計' }],
  csv: [{ value: 'mfc', label: 'マネーフォワードクラウド会計' }],
} as ImportFormatMap

const mfcPlusImportFormatMap = {
  api: [{ value: 'mfc_plus', label: 'マネーフォワードクラウド会計Plus' }],
} as ImportFormatMap

const freeePlusImportFormatMap = {
  api: [{ value: 'freee', label: 'freee会計' }],
} as ImportFormatMap

const yayoiImportFormatMap = {
  csv: [{ value: 'yayoi', label: '弥生会計' }],
} as ImportFormatMap

const bugyoIImportFormatMap = {
  csv: [{ value: 'bugyo_i', label: '奉行i11シリーズ' }],
} as ImportFormatMap

const bugyoCloudImportFormatMap = {
  api: [{ value: 'bugyo_cloud', label: '奉行クラウド' }],
} as ImportFormatMap

const generalImportFormatMap = {
  csv: [{ value: 'general', label: 'その他（仕訳帳）' }],
} as ImportFormatMap
