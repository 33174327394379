import Field from './Field'

export default class Form {
  constructor(element) {
    this.element = element
    this.fields = this.buildFields()
    this.addEventListeners()
  }

  report() {
    if (this.hasMsg) this.element.reportValidity()
  }

  get hasMsg() {
    return this.fields.some((f) => f.hasMsg)
  }

  buildFields() {
    return Array.from(
      this.element.querySelectorAll('[data-invalid]'),
      (e) => new Field(e),
    )
  }

  addEventListeners() {
    this.element.addEventListener('submit', this.formatValidity.bind(this))
  }

  formatValidity() {
    this.fields.forEach((f) => f.formatValidity())
  }
}
