import { useContext } from 'react'

import { ImportSettingContext } from '../context/ImportSettingProvider'

export const useImportSettingContext = () => {
  const context = useContext(ImportSettingContext)
  if (context) return context

  throw new Error(
    'useImportSetting must be used within an ImportSettingProvider',
  )
}
