import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'checkbox',
  ];

  check() {
    if (this._isCheckedMaxCount()) {
      this.checkboxTargets.forEach((e) => {
        e.closest('label').classList.remove('text-gray-300');
        e.disabled = false;
      });
    } else {
      this.checkboxTargets.filter((e) => !e.checked).forEach((e) => {
        e.closest('label').classList.add('text-gray-300');
        e.disabled = true;
      });
    }
  }

  _isCheckedMaxCount() {
    return this.checkboxTargets.filter((e) => e.checked).length < 100;
  }
}
