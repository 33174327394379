import { createErr, createOk, Result } from 'option-t/PlainResult'

import { importSourcesPendingCheckerUrl } from '@/frontend/api/routes'
import type { ApiError, PendingChecker } from '@/frontend/api/types'
import { handleAxiosError } from '@/frontend/api/utils'
import { axios } from '@/frontend/utils'

export async function apiPendingChecker(
  orgId: number,
): Promise<Result<PendingChecker, ApiError>> {
  const url = importSourcesPendingCheckerUrl(orgId)
  try {
    const res = await axios.get(url)
    const { pendingChecker } = res.data
    return createOk(pendingChecker)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
