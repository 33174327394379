import { createErr, createOk, Result } from 'option-t/esm/PlainResult'

import { axios } from '@/frontend/utils'
import { updateCommentThreadUrl } from './routes'
import { ApiError, CommentThread } from './types'
import { handleAxiosError } from './utils'

export type UpdateCommentThreadPayload = {
  resolved: boolean
}
export async function updateCommentThread(
  orgId: number,
  commentThreadId: number,
  payload: UpdateCommentThreadPayload,
): Promise<Result<CommentThread, ApiError>> {
  const url = updateCommentThreadUrl(orgId, commentThreadId)
  try {
    const res = await axios.put(url, {
      ...payload,
    })
    const commentThread = res.data as CommentThread
    return createOk(commentThread)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
