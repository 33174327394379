import React, { useState } from 'react'
import groupBy from 'lodash/groupBy'

import { Button } from '@/frontend/components'
import { DateSelect } from './DateSelect'
import { InputTable } from './InputTable'
import { FiscalPeriod, FormValue, Item, ReservedItem } from './types'

type Props = {
  formAuthenticityToken: string
  formAction: string
  formMethod: string
  fiscalPeriods: FiscalPeriod[]
  leftItems: Item[]
  rightItems: Item[]
  defaultFormValue: FormValue
  fiscalPeriodsPath: string
}

export function Form({
  formAuthenticityToken,
  formAction,
  formMethod,
  fiscalPeriods,
  leftItems,
  rightItems,
  defaultFormValue,
  fiscalPeriodsPath,
}: Props) {
  const [formValue, setFormValue] = useState<FormValue>(defaultFormValue)

  const handleChange = (v: Partial<FormValue>) => {
    setFormValue({ ...formValue, ...v })
  }

  const handleDateChange = (date: string) => {
    handleChange({ date })
  }

  const handleBalanceChange = (accountItemId: number, amount: number) => {
    const newBalances = { [accountItemId]: amount }
    const balances = { ...formValue.balances, ...newBalances }
    handleChange({ balances })
  }

  const items = [...leftItems, ...rightItems]

  const itemsGroupedByParentId = groupBy(items, (item) => {
    switch (item.type) {
      case 'ReservedItem': {
        return item.parentId
      }
      case 'AccountItem': {
        return item.reservedItemId
      }
    }
  })

  const getChildren = (item: ReservedItem): Item[] => {
    return itemsGroupedByParentId[item.id] || []
  }

  const getAmount = (item: Item): number => {
    switch (item.type) {
      case 'ReservedItem': {
        const children = getChildren(item)

        return children.reduce((prev, child) => {
          const childAmount = getAmount(child)
          if (item.transactionType == child.transactionType) {
            return prev + childAmount
          } else {
            return prev - childAmount
          }
        }, 0)
      }
      case 'AccountItem': {
        return formValue.balances[item.id] || 0
      }
    }
  }

  const assetsItem = leftItems[0] // 最初のアイテムが資産になるはず
  const liabilitiesAndNetWorthItem = rightItems[0] // 最初のアイテムが負債・純資産になるはず

  if (!assetsItem || !liabilitiesAndNetWorthItem) throw new Error('Unexpected')

  const leftAmount = getAmount(assetsItem) // 最初のアイテムが資産になるはず
  const rightAmount = getAmount(liabilitiesAndNetWorthItem) // 最初のアイテムが負債・純資産になるはず
  const isDatePresent = !!formValue.date
  const isBalanced = leftAmount == rightAmount
  const isValid = isDatePresent && isBalanced

  return (
    <div className="mt-8 max-w-4xl mx-auto">
      <form action={formAction} method={formMethod}>
        <div
          className="bg-white rounded-lg border border-gray-300 pt-6 overflow-y-scroll relative"
          style={isDatePresent ? { height: 'calc(100vh - 200px' } : {}}
        >
          <div className="px-8">
            <h3 className="font-medium text-gray-900">開始残高</h3>
          </div>
          <div className="px-16 mt-8">
            <input
              type="hidden"
              readOnly
              name="authenticity_token"
              value={formAuthenticityToken}
            />
            <div>
              <label className="text-sm text-gray-700 mr-4">日付</label>
              <DateSelect
                fiscalPeriods={fiscalPeriods}
                value={formValue.date}
                onChange={handleDateChange}
              />
            </div>
            <div className="text-xs text-red-400 mt-1 mb-6">
              ※ 登録したい日付がない場合は、その日付が開始日の
              <a
                href={fiscalPeriodsPath}
                className="link underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                会計期間を登録
              </a>
              し、画面をリロードして下さい。
            </div>
            <div className={isDatePresent ? '' : 'hidden'}>
              <div className="mt-4 flex space-x-16">
                <div className="flex-1">
                  <div className="text-sm text-gray-600">資産の部</div>
                  <div className="mt-2">
                    <InputTable
                      items={leftItems}
                      getAmount={getAmount}
                      onChange={handleBalanceChange}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="text-sm text-gray-500">負債・純資産の部</div>
                  <div className="mt-2">
                    <InputTable
                      items={rightItems}
                      getAmount={getAmount}
                      onChange={handleBalanceChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 flex space-x-16 bg-white sticky bottom-0 pb-12 text-sm">
                <div className="flex-1 bg-blue-100 text-gray-900 flex p-2 justify-between">
                  <div>資産の部 合計</div>
                  <div>{numberWithDelimiter(leftAmount)}円</div>
                </div>
                <div className="flex-1 bg-blue-100 text-gray-900 flex p-2 justify-between">
                  <div>負債・純資産の部 合計</div>
                  <div>{numberWithDelimiter(rightAmount)}円</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HiddenFields formValue={formValue} />
        {/* [PBI-693]デザインシステムから外れた配色をボタンに反映するするためclass(.magic-irregular-disable-button-color-scheme)をつけて一時対応しました */}
        <div className="flex justify-end items-center space-x-4 mt-4 magic-irregular-disable-button-color-scheme">
          <div className="text-red-700 text-sm">
            <ValidationMsg
              isDatePresent={isDatePresent}
              isBalanced={isBalanced}
            />
          </div>
          <Button variant="primary" type="submit" disabled={!isValid}>
            保存
          </Button>
        </div>
      </form>
    </div>
  )
}

type HiddenFieldsProps = {
  formValue: FormValue
}
function HiddenFields({ formValue }: HiddenFieldsProps) {
  return (
    <>
      <input
        type="hidden"
        readOnly
        name="trading_date"
        value={formValue.date}
      />
      {Object.entries(formValue.balances).map(([accountItemId, amount]) => {
        return (
          <div key={accountItemId}>
            <input
              type="hidden"
              readOnly
              name="balances[][account_item_id]"
              value={accountItemId}
            />
            <input
              type="hidden"
              readOnly
              name="balances[][amount]"
              value={amount}
            />
          </div>
        )
      })}
    </>
  )
}

type ValidationMsgProps = {
  isDatePresent: boolean
  isBalanced: boolean
}
function ValidationMsg({ isDatePresent, isBalanced }: ValidationMsgProps) {
  if (!isDatePresent) {
    return <>日付を選択してください</>
  }
  if (!isBalanced) {
    return <>資産の部合計と負債・純資産の部合計が一致していません</>
  }

  return null
}

function numberWithDelimiter(n: number): string {
  return n.toLocaleString('ja')
}
