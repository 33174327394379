import React, { useState } from 'react'

import { Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import { getSourceAccountItemUrl } from '../getSourceAccountItemUrl'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { AccountingSoftware, JobLogWithAttachedFile } from '../types'
import JournalImportFormContainer from './JournalImportFormContainer'
import MasterImportFormContainer from './MasterImportFormContainer'

export type DataSourceSectionProps = {
  org: Org
  accountingSoftware: AccountingSoftware
  lastCsvAccountItemsImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvAccountItemsImportJobId: number | null
  lastCsvJournalImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvJournalImportJobId: number | null
  isFirstImportedAccountItems: boolean
  pendingChecker: PendingChecker
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  accountingSoftware,
  lastCsvAccountItemsImportJobLog,
  lastInProgressCsvAccountItemsImportJobId,
  lastCsvJournalImportJobLog,
  lastInProgressCsvJournalImportJobId,
  isFirstImportedAccountItems,
  pendingChecker,
  setPendingChecker,
}: DataSourceSectionProps) {
  const { importSetting } = useImportSettingContext()
  const [isJournalImportable, setIsJournalImportable] = useState(
    isFirstImportedAccountItems,
  )

  const sourceAccountItemUrl = getSourceAccountItemUrl(
    org.id,
    accountingSoftware.key,
    importSetting.dataFormat,
  )

  return (
    <Section onGray>
      <Section.Title>
        <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
      </Section.Title>

      <div className="p-6 border space-y-4 text-sm rounded-md">
        <div>
          <MasterImportFormContainer
            org={org}
            sourceAccountItemUrl={sourceAccountItemUrl}
            jobLogWithAttachedFile={lastCsvAccountItemsImportJobLog}
            initialInProgressJobLogId={lastInProgressCsvAccountItemsImportJobId}
            setIsJournalImportable={setIsJournalImportable}
            pendingChecker={pendingChecker}
            setPendingChecker={setPendingChecker}
          />
        </div>
        <div>
          <JournalImportFormContainer
            org={org}
            jobLogWithAttachedFile={lastCsvJournalImportJobLog}
            initialInProgressJobLogId={lastInProgressCsvJournalImportJobId}
            isJournalImportable={isJournalImportable}
          />
        </div>
      </div>
    </Section>
  )
}
