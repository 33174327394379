import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Control, useController, useForm } from 'react-hook-form'

import {
  ApiError,
  BudgetTagCategory,
  createNonFinancialItem,
  NonFinancialItemPayload,
  nonFinancialItemSchema,
  Org,
} from '@/frontend/api'
import {
  ApiAlert,
  InputGroup,
  InputText,
  Radio,
  Select,
  SelectionGroup,
  SlideOver,
  TextArea,
} from '@/frontend/components'
import { NonFinancialItemSlideOverFormActions } from './NonFinancialItemSlideOverFormActions'
import { useBudgetTagsSuspense } from './useBudgetTagsSuspense'
import { useNonFinancialItemSuspense } from './useNonFinancialItemSuspense'

export function NonFinancialItemSlideOverForm({
  title,
  description,
  org,
  nonFinancialItemId,
  primaryBudgetTagCategory,
  onClose,
  onSubmit,
}: {
  title: string
  description: string
  org: Org
  nonFinancialItemId: number | null // nullなら新規作成
  primaryBudgetTagCategory: BudgetTagCategory | null
  onClose: () => void
  onSubmit: (
    data: NonFinancialItemPayload,
  ) => ReturnType<typeof createNonFinancialItem>
}) {
  const budgetTags = useBudgetTagsSuspense(org, primaryBudgetTagCategory)
  const nonFinancialItem = useNonFinancialItemSuspense(org, nonFinancialItemId)

  const formMethods = useForm<NonFinancialItemPayload>({
    mode: 'onChange',
    resolver: zodResolver(nonFinancialItemSchema),
    defaultValues: nonFinancialItem || { sumUp: true },
  })
  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    register,
    formState: { errors },
    control,
  } = formMethods

  const [apiError, setApiError] = useState<ApiError | null>(null)

  // どうにかして useEffect 使わなくて済むようにしたい
  useEffect(() => {
    reset(nonFinancialItem || undefined)
    setApiError(null)
  }, [reset, nonFinancialItem, setApiError])

  const _onSubmit = async (data: NonFinancialItemPayload) => {
    setApiError(null)
    const result = await onSubmit(data)
    if (result.ok) {
      onClose()
      reset()
    } else {
      setApiError(result.err)
    }
  }

  return (
    <form onSubmit={handleSubmit(_onSubmit)} className="h-full">
      <SlideOver.Panel
        title={title}
        description={description}
        onClose={onClose}
        actions={
          <NonFinancialItemSlideOverFormActions
            onClose={onClose}
            loading={isSubmitting}
          />
        }
      >
        {apiError && (
          <div className="mt-4 px-4">
            <ApiAlert apiError={apiError} autoScroll />
          </div>
        )}
        {/* Divider container */}
        <div className="space-y-6 pt-6 pb-5 px-6">
          {/* budgetTagId */}
          <InputGroup
            label={
              primaryBudgetTagCategory
                ? `全社共通または${primaryBudgetTagCategory.name}別`
                : '全社共通またはタグ別'
            }
            errorMsg={errors.budgetTagId?.message}
          >
            {(props) => (
              <Select
                {...props}
                {...register('budgetTagId')}
                disabled={!!nonFinancialItem}
              >
                <option value="">全社共通</option>
                {budgetTags.map((budgetTag) => {
                  return (
                    <option key={budgetTag.id} value={budgetTag.id}>
                      {budgetTag.name}
                    </option>
                  )
                })}
              </Select>
            )}
          </InputGroup>

          {/* name */}
          <InputGroup label="名称" errorMsg={errors.name?.message}>
            {(props) => (
              <InputText
                {...props}
                invalid={!!errors.name}
                {...register('name')}
              />
            )}
          </InputGroup>

          {/* numberType */}
          <InputGroup label="数値タイプ" errorMsg={errors.numberType?.message}>
            {(props) => (
              <Select
                {...props}
                {...register('numberType')}
                disabled={!!nonFinancialItem}
              >
                <option value="integer">整数</option>
                <option value="decimal">小数</option>
                <option value="percentage">百分率</option>
              </Select>
            )}
          </InputGroup>

          {/* roundType */}
          <InputGroup label="端数処理" errorMsg={errors.roundType?.message}>
            {(props) => (
              <Select {...props} {...register('roundType')}>
                <option value="round_down">切り捨て</option>
                <option value="round">四捨五入</option>
                <option value="round_up">切り上げ</option>
              </Select>
            )}
          </InputGroup>

          {/* sumUp */}
          <SumUpRadio control={control} />

          {/* stockFlowType */}
          <InputGroup
            label="期間の合算"
            errorMsg={errors.stockFlowType?.message}
          >
            {(props) => (
              <Select {...props} {...register('stockFlowType')}>
                <option value="undefined">表示しない</option>
                <option value="flow">期間の合算値</option>
                <option value="stock">末時点の数値</option>
              </Select>
            )}
          </InputGroup>

          {/* memo */}
          <InputGroup label="メモ" optional errorMsg={errors.memo?.message}>
            {(props) => <TextArea {...props} {...register('memo')} rows={2} />}
          </InputGroup>
        </div>
      </SlideOver.Panel>
    </form>
  )
}

// NOTE: 応急処置。あとでForm全体の取り回しについて考える。
function SumUpRadio({
  control,
}: {
  control: Control<NonFinancialItemPayload>
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: 'sumUp' })

  return (
    <InputGroup
      label={'タグ別の値の合算'}
      helpText="従業員数などタグ別の値を合算できる場合は”する”を選択してください"
      errorMsg={error?.message}
    >
      {({ id, ...props }) => (
        <SelectionGroup horizontal>
          <Radio
            id={`${id}_true`}
            {...field}
            {...props}
            checked={!!field.value}
            value="true"
            onChange={() => field.onChange(true)}
          >
            する
          </Radio>
          <Radio
            id={`${id}_false`}
            {...field}
            {...props}
            checked={!field.value}
            value="false"
            onChange={() => field.onChange(false)}
          >
            しない
          </Radio>
        </SelectionGroup>
      )}
    </InputGroup>
  )
}
