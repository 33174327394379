import React from 'react'
import { RiAlertFill } from 'react-icons/ri'

import { Breadcrumbs, LegacyTabs, Tabs } from '@/frontend/components'

type TabItem = {
  label: string
  href: string
  active?: boolean
  attention?: boolean
}

type SubTabItem = {
  label: string
  href: string
  active?: boolean
  attention?: boolean
}

type Props = {
  breadcrumbItems?: { label: string }[]
  tabItems?: TabItem[]
  subTabItems?: SubTabItem[]
}

export default function Header({
  breadcrumbItems,
  tabItems,
  subTabItems,
}: Props) {
  const tabItemsWithIcon =
    tabItems?.map((item) => {
      return {
        label: item.label,
        href: item.href,
        active: item.active,
        Icon: item.attention ? (
          <RiAlertFill
            className={`${item.active ? 'text-white' : 'text-orange-600'}`}
          />
        ) : undefined,
      }
    }) || []

  return (
    <div>
      <div className="bg-white border-b border-gray-200">
        {breadcrumbItems && (
          <div className="px-8 lg:flex lg:items-center lg:justify-between">
            <div className="flex-1 min-w-0">
              <div className="py-2">
                <Breadcrumbs items={breadcrumbItems} />
              </div>
            </div>
          </div>
        )}
        {tabItems && <Tabs items={tabItemsWithIcon} />}
      </div>
      {subTabItems && (
        <div className="px-8">
          <SubTabs items={subTabItems} />
        </div>
      )}
    </div>
  )
}

function SubTabs({ items }: { items: SubTabItem[] }) {
  const convertedItems = items.map((item) => {
    return {
      label: (
        <div className="inline-flex items-center justify-center">
          {item.label}

          {item.attention && (
            <span className="ml-1">
              <RiAlertFill className="text-orange-600" />
            </span>
          )}
        </div>
      ),
      href: item.href,
      active: item.active,
    }
  })

  return (
    <div className="pt-4">
      <LegacyTabs items={convertedItems} />
    </div>
  )
}
