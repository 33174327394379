import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['jobLogButton']

  connect() {
    const url = new URL(location.href)
    if (url.searchParams.has('open_modal_job_logs')) {
      this.jobLogButtonTarget.click()
    }
  }
}
