import React from 'react'
import { RiExternalLinkLine } from 'react-icons/ri'

import { JobLog, Org } from '@/frontend/api'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import FiscalPeriodLink from '../components/FiscalPeriodLink'
import { JobLogPollingContainer } from '../components/JobLogPollingContainer'
import { getInitialImportFileStatus } from '../getInitialImportFileStatus'
import useAlertStatus from '../hooks/useAlertStatus'
import useImportFileStatus from '../hooks/useImportFileStatus'
import useStoredFormValues, {
  BaseCsvFormValuesType,
} from '../hooks/useStoredFormValues'
import { JobLogWithAttachedFile } from '../types'
import JournalImportForm from './JournalImportForm'
import { useImportBugyoICsvJournal } from './useImportBugyoICsvJournal'

type Props = {
  org: Org
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  isJournalImportable: boolean
}

export default function JournalImportFormContainer({
  org,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  isJournalImportable,
}: Props) {
  const {
    importFileStatus,
    updateOnlyStatusForImportFile,
    updateImportFileToInProgress,
  } = useImportFileStatus(getInitialImportFileStatus(jobLogWithAttachedFile))

  const { storedFormValues, storeImportFormValues } =
    useStoredFormValues<BaseCsvFormValuesType>(org, 'BugyoICsvJournal')

  const { alertStatus, updateAlertStatusFromJobLog, resetAlertStatus } =
    useAlertStatus(jobLogWithAttachedFile)

  const { submit, isSubmitting, jobLog, apiError } =
    useImportBugyoICsvJournal(org)

  const onFileChange = (
    journalCsvFile: File | null,
    openingBalanceCsvFile: File | null,
  ) => {
    const attachedFiles = [journalCsvFile, openingBalanceCsvFile]
      .filter((f): f is Exclude<typeof f, null> => f !== null)
      .map((file) => ({ name: file.name })) as File[]

    updateImportFileToInProgress(attachedFiles)
    resetAlertStatus()
  }

  return (
    <JobLogPollingContainer
      org={org}
      pollingJobLog={jobLog}
      initJobLogId={initialInProgressJobLogId}
      onStopPolling={(jobLog: JobLog) => {
        updateOnlyStatusForImportFile(jobLog.status)
        updateAlertStatusFromJobLog(jobLog)
      }}
    >
      {({ isPolling }) => (
        <div className="space-y-4">
          <SectionTitle>仕訳インポート</SectionTitle>
          <div>
            会計ソフトから出力した仕訳データをインポートし、会計実績データを取り込みます。仕訳インポート前に奉行11シリーズと同じ会計期間をManageboardに登録してください。
            <BugyoIManualLink />
            <FiscalPeriodLink org={org} />
          </div>
          <JournalImportForm
            importFileStatus={importFileStatus}
            onFileChange={onFileChange}
            onSubmit={(data) => {
              submit(data)
              const { firstDate, lastDate } = data
              storeImportFormValues({ firstDate, lastDate })
            }}
            isLoading={isSubmitting || isPolling}
            apiError={apiError}
            isJournalImportable={isJournalImportable}
            initialValues={storedFormValues}
            alertStatus={alertStatus}
            jobLogWithAttachedFile={jobLogWithAttachedFile}
            org={org}
          />
        </div>
      )}
    </JobLogPollingContainer>
  )
}

function BugyoIManualLink() {
  return (
    <a
      href="https://intercom.help/manageboard/ja/articles/5927692-%E5%8B%98%E5%AE%9A%E5%A5%89%E8%A1%8C-csv%E3%81%A7%E4%BC%9A%E8%A8%88%E3%83%87%E3%83%BC%E3%82%BF%E3%82%92%E5%8F%96%E3%82%8A%E8%BE%BC%E3%82%80-%E8%A9%B3%E7%B4%B0%E5%8F%96%E8%BE%BC"
      target="_blank"
      rel="noreferrer"
      className="text-blue-600 flex flex-row items-center"
    >
      奉行i11シリーズの仕訳データおよび開始残高データ（CSV）の出力方法
      <RiExternalLinkLine />
    </a>
  )
}
