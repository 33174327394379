import React from 'react'
import { RiAlertFill } from 'react-icons/ri'

import { Org } from '@/frontend/api'
import { PendingChecker } from '@/frontend/api/types'
import { Tabs } from '@/frontend/components'
import { formattedOpeningBalanceUrl } from '@/frontend/features/importers/formattedOpeningBalanceUrl'
import { getSourceAccountItemUrl } from '../getSourceAccountItemUrl'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { AccountingSoftware } from '../types'

type Props = {
  org: Org
  accountingSoftware: AccountingSoftware
  openingBalanceUrl: string
  pendingChecker: PendingChecker
}

export default function ImportPageTabs({
  org,
  accountingSoftware,
  openingBalanceUrl,
  pendingChecker,
}: Props) {
  const { importSetting } = useImportSettingContext()

  const sourceAccountItemUrl = getSourceAccountItemUrl(
    org.id,
    accountingSoftware.key,
    importSetting.dataFormat,
  )
  const isShownAlert =
    (importSetting.dataFormat === 'api' && pendingChecker.isApiMasterPending) ||
    (importSetting.dataFormat === 'csv' && pendingChecker.isCsvMasterPending)

  const tabItems = [
    { label: 'インポート', href: '#', active: true },
    {
      label: 'マスタ割当',
      href: sourceAccountItemUrl,
      active: false,
      Icon: isShownAlert ? (
        <RiAlertFill className="text-orange-600" />
      ) : undefined,
    },
    {
      label: '開始残高',
      href: formattedOpeningBalanceUrl(
        openingBalanceUrl,
        importSetting.dataFormat,
      ),
      active: false,
    },
  ]

  return <Tabs items={tabItems} />
}
