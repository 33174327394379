import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    bottomOffset: Number,
    offsetTopElementId: String
  }

  connect () {
    this.timerId
    this.initialSize = this.element.clientHeight
    this._initOffsetTop()
    this.adjust()
  }

  adjust () {
    const maxHeight = window.innerHeight - this.offsetTop - this.bottomOffsetValue
    if (this.initialSize >= maxHeight) {
      this.element.style.height = maxHeight + 'px'
    }
  }

  debouncedAdjust() {
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.adjust()
    }, 10);
  }

  _initOffsetTop() {
    this.offsetTop =
      this.offsetTopElementIdValue ?
        document.getElementById(this.offsetTopElementIdValue).offsetHeight :
        this.element.offsetTop
  }
}
