import Sortable from 'sortablejs'
import axios from './axios'

document.addEventListener('turbolinks:load', init)

function init() {
  document.querySelectorAll('[data-table-sortable]').forEach((el) => {
    const updatePositionUrl = el.dataset.tableSortableUrl

    const options = {
      dataIdAttr: 'data-table-sortable-item-id',
      handle: '[data-table-sortable-handle]',
      onEnd: function (_event) {
        if (updatePositionUrl) {
          axios.put(updatePositionUrl, {
            ids: this.toArray(),
          })
        }
      },
      animation: 150,
      ghostClass: 'sortable-ghost',
    }

    new Sortable(el, options)
  })
}
