import { createErr, createOk, Result } from 'option-t/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { axios, z } from '@/frontend/utils'
import { importMfcCsvSourceAccoutItemUrl } from '../../routes'
import { ApiError, JobLog } from '../../types'
import { handleAxiosError } from '../../utils'

export const mfcCsvSourceAccountItemSchema = z.object({
  file: z.custom<FileWithPath>().nullable(),
})

export type MfcCsvSourceAccountItemPayload = z.infer<
  typeof mfcCsvSourceAccountItemSchema
>

export async function importMfcCsvSourceAccountItems(
  orgId: number,
  payload: MfcCsvSourceAccountItemPayload,
): Promise<Result<JobLog, ApiError>> {
  const url = importMfcCsvSourceAccoutItemUrl(orgId)
  try {
    const formData = new FormData()
    formData.append('csv_file', payload.file as FileWithPath)

    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })
    const jobLog = res.data as JobLog
    return createOk(jobLog)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
