import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'budgetInput',
    'budgetName',
    'dropdownBudgetItem',
    'fiscalPeriodInput',
    'fiscalPeriodName',
    'dropdownFiscalPeriodItem'
  ]

  updateBudget(e) {
    this.budgetInputTarget.value = e.currentTarget.dataset.value
    this.budgetNameTarget.textContent = e.currentTarget.textContent
    this.removeActiveClass(this.dropdownBudgetItemTargets)
    e.currentTarget.classList.add('active')
  }

  updateFiscalPeriod(e) {
    this.fiscalPeriodInputTarget.value = e.currentTarget.dataset.value
    this.fiscalPeriodNameTarget.textContent = e.currentTarget.textContent
    this.removeActiveClass(this.dropdownFiscalPeriodItemTargets)
    e.currentTarget.classList.add('active')
  }

  removeActiveClass(targets) {
    targets.forEach((target) => {
      target.classList.remove('active')
    }) 
  }
}
