import { Controller } from "stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'selectedLabel', // 選択中のラベル
    'nodeTrigger', // ノードを選択するボタン
    'leafTrigger', // リーフを選択するボタン
    'nodeContent', // 各ノードの子コンテンツ
    'dropdownTrigger', // ドロップダウンのトリガーボタン
    'dropdownContent', // ドロップダウンのコンテンツ
    'activeNodeIcon', // ノードのアクティブ状態を表すアイコン
    'activeLeafIcon', // リーフのアクティブ状態を表すアイコン
    'nodeField', // ノードの値をセットするフィールド
    'leafField' // リーフの値をセットするフィールド
  ]

  static values = {
    selected: Array,
    activeNode: String
  }

  connect () {
    this.bindDropdown()
  }

  onNodeClicked (e) {
    const nodeId = e.currentTarget.dataset.nodeId

    // activeNodeValue を更新する
    this.activeNodeValue = nodeId

    this.updateActiveNodeStyle()
    this.toggleNodeContent()
  }

  onLeafClicked (e) {
    const nodeId = e.currentTarget.dataset.nodeId
    const leafId = e.currentTarget.dataset.leafId

    // selectedValue を更新する
    this.selectedValue = [nodeId, leafId]

    this.updateFormFieldValue()
    this.updateSelectedLabel()
    this.updateActiveLeafStyle()
    this.hideDropdown()
  }

  // 表示するノードのコンテンツを切り替える
  toggleNodeContent () {
    this.nodeContentTargets.forEach((element) => {
      element.hidden = element.dataset.nodeId !== this.activeNodeValue
    })
  }

  // 選択中のラベルを更新する
  updateSelectedLabel () {
    this.selectedLabelTargets.forEach((element) => {
      element.hidden = !(
        element.dataset.nodeId == this.selectedValue[0] &&
        element.dataset.leafId == this.selectedValue[1]
      )
    })
  }

  // フィールドの値を更新する
  updateFormFieldValue () {
    this.nodeFieldTarget.value = this.selectedValue[0]
    this.leafFieldTarget.value = this.selectedValue[1]

    const event = new CustomEvent('change')
    this.nodeFieldTarget.dispatchEvent(event)
    this.leafFieldTarget.dispatchEvent(event)
  }

  // スタイルを更新する
  updateActiveNodeStyle () {
    this.nodeTriggerTargets.forEach((element) => {
      this.toggleActiveClass(
        element,
        element.dataset.nodeId === this.activeNodeValue
      )
    })

    this.activeNodeIconTargets.forEach((element) => {
      element.hidden = element.dataset.nodeId !== this.activeNodeValue
    })
  }

  // スタイルを更新する
  updateActiveLeafStyle () {
    this.leafTriggerTargets.forEach((element) => {
      this.toggleActiveClass(
        element,
        element.dataset.nodeId == this.selectedValue[0] &&
        element.dataset.leafId == this.selectedValue[1]
      )
    })

    this.activeLeafIconTargets.forEach((element) => {
      element.hidden = !(
        element.dataset.nodeId == this.selectedValue[0] &&
        element.dataset.leafId == this.selectedValue[1]
      )
    })
  }

  toggleActiveClass (element, isActive) {
    if (isActive) {
      element.classList.add('active')
    } else {
      element.classList.remove('active')
    }
  }

  onDropdownShow () {
    this.updateActiveNodeStyle()
    this.updateActiveLeafStyle()

    this.toggleNodeContent()
  }

  // ドロップダウンのバインド
  bindDropdown () {
    const appendTo = this.element
    const element = this.dropdownContentTarget
    const onMount = this.onDropdownShow.bind(this)

    this.tippy = tippy(this.dropdownTriggerTarget, {
      content: element,
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      animation: 'perspective',
      duration: 100,
      placement: 'bottom-start',
      maxWidth: 'none',
      offset: [0, 4],
      appendTo,
      onMount
    })
  }

  // ドロップダウンを閉じる
  hideDropdown () {
    this.tippy.hide()
  }
}
