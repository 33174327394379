document.addEventListener('input', e => {
  const input = e.target
  if (!input.hasAttribute('data-filter-input')) return

  const val = input.value
  const targetSelector = input.getAttribute('data-filter-input')
  const target = document.querySelector(targetSelector)
  const items = target.querySelectorAll('[data-filter-input-item]')

  if (val.length > 0) {
    const event = new CustomEvent('treeFilter:start')
    items.forEach(item => item.dispatchEvent(event))

    items.forEach(item => {
      const keyword = item.getAttribute('data-filter-input-item')

      if (keyword.indexOf(val) !== -1) {
        const event = new CustomEvent('treeFilter:hit')
        item.dispatchEvent(event)
      }
    })
  } else {
    const event = new CustomEvent('treeFilter:reset')
    items.forEach(item => item.dispatchEvent(event))
  }

})
