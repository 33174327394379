import { useCallback, useState } from 'react'

import { ApiError, JobLog, Org } from '@/frontend/api'
import {
  BugyoICsvJournalImportPayload,
  importBugyoICsvJournal,
} from '@/frontend/api/importer'

export function useImportBugyoICsvJournal(org: Org) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [jobLog, setJobLog] = useState<JobLog | null>(null)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const submit = useCallback(
    async (data: BugyoICsvJournalImportPayload) => {
      setIsSubmitting(true)
      const result = await importBugyoICsvJournal(org.id, data)
      if (result.ok) {
        setJobLog(result.val)
        setApiError(undefined)
      } else {
        setApiError(result.err)
      }
      setIsSubmitting(false)
    },
    [org],
  )
  return {
    submit,
    jobLog,
    isSubmitting,
    apiError,
  }
}
