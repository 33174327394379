import useSWR from 'swr'

import { getNonFinancialItemUrl, NonFinancialItem, Org } from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useNonFinancialItemSuspense(org: Org, id: number | null) {
  const url = id == null ? null : getNonFinancialItemUrl(org.id, id)
  const { data } = useSWR<NonFinancialItem, Error>(url, fetcher, {
    suspense: true,
  })
  return data || null
}
