import React, { useEffect, useState } from 'react'
import { RiArrowRightSLine, RiCheckLine } from 'react-icons/ri'

import {
  ApiError,
  checkAllComments,
  Comment,
  CommentThread,
  CreateCommentPayload,
} from '@/frontend/api'
import { CommentForm } from './CommentForm'
import { CommentList } from './CommentList'
import { useComments, usePostComment, useUpdateCommentThread } from './hooks'
import { MentionItem } from './lexicalPlugins/MentionsPlugin'
import { ReportMeta } from './types'

type Props = {
  userId: number
  memberId: number
  title: string
  orgId: number
  reportMeta: ReportMeta
  commentThreadId: number | null
  commentThreadResolved: boolean | null
  onPostComment: (comment: Comment) => void
  onUpdateCommentThread: (comment: CommentThread) => void
  onDeleteCommentThread: () => void
  creatableComment: boolean
  suggestionContainerRef: React.RefObject<HTMLElement>
  commentMentionMembers: MentionItem[]
}

export function CommentPanel({
  userId,
  memberId,
  title,
  orgId,
  reportMeta,
  commentThreadId,
  commentThreadResolved,
  onPostComment,
  onUpdateCommentThread,
  onDeleteCommentThread,
  creatableComment,
  suggestionContainerRef,
  commentMentionMembers,
}: Props) {
  const commentsRes = useComments(orgId, commentThreadId)
  const postComment = usePostComment(
    orgId,
    reportMeta,
    commentThreadId,
    (comment) => {
      commentsRes.mutate((prev) => ({
        ...prev,
        comments: [comment, ...(prev?.comments || [])],
      }))
      onPostComment(comment)
    },
  )
  const [apiError, setApiError] = useState<ApiError | null>(null)

  const comments = [...(commentsRes.data?.comments || [])].reverse()

  const onSubmit = async (data: CreateCommentPayload) => {
    setApiError(null)
    const result = await postComment(data)
    if (result.ok) {
      return true
    } else {
      setApiError(result.err)
      return false
    }
  }

  const onUpdate = async (comment: Comment) => {
    commentsRes.mutate(
      (prev) => {
        if (prev == undefined) return

        const i = prev.comments.findIndex((c) => c.id === comment.id)
        if (i === -1) return

        return {
          ...prev,
          comments: [
            ...prev.comments.slice(0, i),
            comment,
            ...prev.comments.slice(i + 1),
          ],
        }
      },
      { revalidate: false },
    )
  }

  const onDelete = async (comment: Comment) => {
    commentsRes.mutate(
      (prev) => {
        if (prev == undefined) return

        return {
          ...prev,
          comments: prev.comments.filter((c) => c.id !== comment.id),
        }
      },
      { revalidate: false },
    )
  }

  const onDeleteThread = async () => {
    commentsRes.mutate(
      (prev) => {
        return { ...prev, comments: [] }
      },
      { revalidate: false },
    )
    onDeleteCommentThread()
  }

  const updateCommentThread = useUpdateCommentThread(
    orgId,
    commentThreadId,
    onUpdateCommentThread,
  )

  const onToggleResolve = async () => {
    setApiError(null)
    const result = await updateCommentThread({
      resolved: !commentThreadResolved,
    })

    // commentThreadIdがnullの場合はresultがnullになるので判定
    if (result) {
      if (result.ok) {
        return true
      } else {
        setApiError(result.err)
        return false
      }
    }
  }

  useEffect(() => {
    if (commentsRes.error) return

    const unCheckComments = comments.filter((comment) => {
      if (!comment.notification) return false
      return !comment.notification.checked
    })
    if (commentThreadId && unCheckComments.length) {
      const checkAll = async () => {
        const result = await checkAllComments(orgId, commentThreadId)
        if (result.ok) return
        alert('通信に失敗しました')
      }
      checkAll()
    }
  }, [comments, commentThreadId, commentsRes, orgId])

  return (
    <div
      className="w-72 bg-white rounded border border-gray-300 shadow-lg px-4 overflow-y-auto overscroll-y-contain"
      style={{ maxHeight: 'calc(100vh - 4rem)' }}
    >
      <div className="flex justify-between pt-3 pb-2">
        <div className="min-w-0">
          <div className="text-gray-900 font-bold truncate">{title}</div>
        </div>
        <div className="flex items-center justify-center">
          {comments.length > 0 && (
            <>
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                {comments.length}
              </span>
              {creatableComment && (
                <button
                  onClick={onToggleResolve}
                  className={
                    commentThreadResolved
                      ? 'ml-2 text-green-500'
                      : 'ml-2 text-gray-500'
                  }
                >
                  <RiCheckLine className="text-xl" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <CommentList
        userId={userId}
        memberId={memberId}
        orgId={orgId}
        reportMetaId={reportMeta}
        comments={comments}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onDeleteThread={onDeleteThread}
        creatableComment={creatableComment}
        suggestionContainerRef={suggestionContainerRef}
        commentMentionMembers={commentMentionMembers}
        status={
          commentsRes.isLoading
            ? 'loading'
            : commentsRes.error
            ? 'error'
            : 'success'
        }
        withLink={false}
      />
      {creatableComment && (
        <div className="py-3 border-t border-gray-200">
          <CommentForm
            apiError={apiError}
            onSubmit={onSubmit}
            suggestionContainerRef={suggestionContainerRef}
            memberId={memberId}
            commentMentionMembers={commentMentionMembers}
          />
        </div>
      )}
      <div className="pt-3 pb-4 border-t border-gray-200 text-right">
        <button
          className="inline-flex text-sm text-gray-400 hover:text-blue-500"
          data-comment-list-toggle
        >
          すべてのコメントを見る
          <RiArrowRightSLine className="text-xl ml-2" />
        </button>
      </div>
    </div>
  )
}
