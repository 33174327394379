import React from 'react'

import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import ApiAuthButton from '../components/ApiAuthButton'
import ApiAuthContainer from '../components/ApiAuthContainer'
import AuthedAccountingSoftwareLabel from '../components/AuthedAccountingSoftwareLabel'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import { useImportSettingContext } from '../hooks/useImportSettingContext'
import { AccountingSoftware } from '../types'
import ApiImportFormContainer from './ApiImportFormContainer'
import RevokeApiAuthButton from './RevokeApiAuthButton'

export type DataSourceSectionProps = {
  org: Org
  accountingSoftware: AccountingSoftware
  apiAuthorizeUrl: string
  revokeApiAuthUrl: string
  lastApiImportJobLog: JobLog | null
  lastInProgressApiImportJobId: number | null
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  accountingSoftware,
  apiAuthorizeUrl,
  revokeApiAuthUrl,
  lastApiImportJobLog,
  lastInProgressApiImportJobId,
  setPendingChecker,
}: DataSourceSectionProps) {
  const {
    importSetting: { dataFormat },
  } = useImportSettingContext()

  return (
    <Section onGray>
      <Section.Title>
        <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
      </Section.Title>

      {/* APIインポート */}
      {dataFormat === 'api' && (
        <div className="p-6 border space-y-4 text-sm rounded-md">
          <ApiAuthContainer description="会計ソフトとAPI連携を行います。">
            <ApiAuthButton
              accountingSoftware={accountingSoftware}
              apiAuthorizeUrl={apiAuthorizeUrl}
            />
            {accountingSoftware.isApiAuthed && (
              <RevokeApiAuthButton revokeApiAuthUrl={revokeApiAuthUrl} />
            )}
            <AuthedAccountingSoftwareLabel
              accountingSoftware={accountingSoftware}
            />
          </ApiAuthContainer>

          {accountingSoftware.isApiAuthed && (
            <ApiImportFormContainer
              org={org}
              lastApiImportJobLog={lastApiImportJobLog}
              lastInProgressApiImportJobId={lastInProgressApiImportJobId}
              setPendingChecker={setPendingChecker}
            />
          )}
        </div>
      )}
    </Section>
  )
}
