import useSWR from 'swr'

import {
  BudgetTag,
  BudgetTagCategory,
  getBudgetTagsUrl,
  Org,
} from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useBudgetTagsSuspense(
  org: Org,
  primaryBudgetTagCategory: BudgetTagCategory | null,
): BudgetTag[] {
  const url = primaryBudgetTagCategory
    ? getBudgetTagsUrl(org.id, primaryBudgetTagCategory.id)
    : null
  const { data } = useSWR<BudgetTag[], Error>(url, fetcher, { suspense: true })
  return data || []
}
