import useSWR from 'swr'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  getMembersUrl,
  Org,
} from '@/frontend/api'
import type { Member } from '@/frontend/api/types'
import { fetcher } from '@/frontend/utils'

export function useMembers(org: Org) {
  const url = getMembersUrl(org.id)
  return useSWR<Member[], Error>(url, fetcher)
}
