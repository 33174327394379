import { tv } from 'tailwind-variants'

export const buttonClassName = tv({
  base: [
    'relative whitespace-nowrap',
    'inline-flex items-center justify-center',
    'ring-inset rounded-md focus:outline-offset-0 focus:outline-[3px]',
    'font-medium text-sm leading-5',
    'transition ease-in-out duration-150',
    'px-4 py-2',
    'z-10',
  ].join(' '),
  defaultVariants: {
    variant: 'outlined',
  },
  variants: {
    variant: {
      outlined: [
        'ring-1 ring-gray-400 text-gray-800 bg-white shadow-sm',
        'hover:text-blue-600',
        'focus:text-blue-600 focus:outline focus:outline-blue-100',
        'active:text-blue-600 active:bg-gray-100',
      ].join(' '),
      secondary: [
        'ring-1 ring-blue-600 text-blue-600 bg-white',
        'hover:bg-blue-50',
        'focus:outline focus:outline-blue-100',
        'active:bg-blue-100',
      ].join(' '),
      primary: [
        'text-white bg-blue-600',
        'hover:bg-blue-700',
        'focus:ring-1 focus:ring-blue-700 focus:outline focus:outline-blue-100',
        'active:bg-blue-800',
      ].join(' '),
      dangerSecondary: [
        'text-red-500',
        'hover:bg-red-50',
        'focus:bg-red-100',
        'active:bg-red-200',
      ].join(' '),
      dangerPrimary: [
        'text-white bg-red-500',
        'hover:bg-red-600',
        'focus:ring-1 focus:ring-red-600 focus:outline focus:outline-red-100',
        'active:bg-red-700',
      ].join(' '),
    },
    disabled: {
      true: 'shadow-none ring-0 text-gray-400 bg-gray-100 pointer-events-none',
    },
    loading: {
      true: 'shadow-none ring-0 text-gray-400 bg-gray-100 pointer-events-none',
    },
    iconOnly: {
      true: 'px-2',
    },
  },
})
