import React, { forwardRef } from 'react'
import {
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
} from 'lexical-beautiful-mentions'
import { RiTeamLine } from 'react-icons/ri'

import { Avatar } from '@/frontend/components'

/**
 * Menu component for the BeautifulMentionsPlugin.
 */
export const Menu = forwardRef<any, BeautifulMentionsMenuProps>(function Menu(
  { loading, ...other },
  ref,
) {
  if (loading) {
    return (
      <div
        ref={ref}
        className={`m-0 mt-6 min-w-[8rem] overflow-hidden rounded-md border bg-popover
            p-2.5 text-sm text-popover-foreground shadow-md`}
      >
        Loading...
      </div>
    )
  }
  return (
    <ul
      ref={ref}
      style={{
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      }}
      className={`z-[1000] absolute top-6 m-0 min-w-[8rem] overflow-hidden
        rounded-md border bg-white py-1 text-popover-foreground shadow-md`}
      {...other}
    />
  )
})

/**
 * MenuItem component for the BeautifulMentionsPlugin.
 */
export const MenuItem = forwardRef<
  HTMLLIElement,
  BeautifulMentionsMenuItemProps
>(function MenuItem({ selected, avatarThumbUrl, children, id, ...props }, ref) {
  const className = `relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none
    ${selected ? 'bg-gray-100 text-accent-foreground' : ''}`
  return (
    <li ref={ref} className={className} {...props}>
      <div className="w-6 h-6">
        {id === 'all' ? (
          <Avatar Icon={RiTeamLine} />
        ) : (
          <Avatar imgSrc={avatarThumbUrl} />
        )}
      </div>
      <span className="ml-2 whitespace-nowrap">{children}</span>
    </li>
  )
})
