import { createErr, createOk, Result } from 'option-t/PlainResult'
import snakeCaseKeys from 'snakecase-keys'

import { axios, z } from '@/frontend/utils'
import { getClientsOrderUrl, getClientUrl } from './routes'
import { ApiError, Client } from './types'
import { handleAxiosError } from './utils'

export type ClientsResponse = {
  clients: Client[]
  meta: {
    total: number
  }
}

export type SearchUserId = number | ''

export type ClientsSearchParamsPayload = {
  searchUserId?: SearchUserId
}

export async function deleteClient(
  orgId: number,
  id: number,
): Promise<Result<void, ApiError>> {
  const url = getClientUrl(orgId, id)
  try {
    await axios.delete(url)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export const createAssignMembersSchema = z.object({
  userIds: z.array(z.number()).optional(),
})
export type CreateAssignMembersPayload = z.infer<
  typeof createAssignMembersSchema
>

export async function assignMembers(
  orgId: number,
  clientId: number,
  payload: CreateAssignMembersPayload,
): Promise<Result<void, ApiError>> {
  const url = getClientUrl(orgId, clientId) + '/assign_members'
  try {
    await axios.post(url, { ...payload })
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}

export type ClientsOrderParam = {
  id: number
  type: 'client' | 'client_folder'
  parentFolderId: number | null
  position: number
}

export type UpdateClientsOrderParams = {
  orderParams: ClientsOrderParam[]
}

export async function updateClientsOrder(
  orgId: number,
  payload: UpdateClientsOrderParams,
): Promise<Result<void, ApiError>> {
  const url = getClientsOrderUrl(orgId)
  try {
    await axios.put(url, { params: snakeCaseKeys(payload) })
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
