import { Controller } from "stimulus"

export default class extends Controller {
  connect () {
    this.timerId
    this.initialSize = this.element.clientHeight
    this.adjust()
  }

  adjust () {
    const maxHeight = window.innerHeight - this.element.offsetTop - 32 - 38
    const height = Math.min(this.initialSize, maxHeight)
    this.element.style.height = height + 'px'
  }

  debouncedAdjust() {
    if (this.timerId) clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.adjust()
    }, 10);
  }
}
