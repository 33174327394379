import React, { forwardRef } from 'react'

import { ButtonContent } from './ButtonContent'
import { ButtonLoadingOverlay } from './ButtonLoadingOverlay'
import { ButtonProps } from './types'
import { buttonClassName } from './utils'

type Props = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> &
  ButtonProps

export const ButtonLink = forwardRef<HTMLAnchorElement, Props>(
  function ButtonLink(
    { variant, disabled = false, loading = false, icon, children, ...props },
    ref,
  ) {
    const iconOnly = !!icon
    return (
      <a
        ref={ref}
        {...props}
        className={buttonClassName({ variant, disabled, loading, iconOnly })}
      >
        <ButtonLoadingOverlay loading={loading}>
          <ButtonContent icon={icon}>{children}</ButtonContent>
        </ButtonLoadingOverlay>
      </a>
    )
  },
)
