import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'item',
    'label',
    'debitAmountItems',
    'creditAmountItems',
    'submitButton',
    'submitButtonErrorMessage',
  ]

  connect() {
    this._updateItemsStatus()
  }

  submit(e) {
    this._updateDebitAmountSelectionStatus()
    this._updateCreditAmountSelectionStatus()
    this._updateSubmitButtonErrorMessageStatusWhenSubmit()
    this._preventDefault(e)
  }

  selectBoxChange() {
    this._updateItemsStatus()
  }

  debitAmountSelectBoxChange() {
    this._updateDebitAmountSelectionStatus()
    this._updateSubmitButtonErrorMessageStatusWhenChangeSelect()
  }

  creditAmountSelectBoxChange() {
    this._updateCreditAmountSelectionStatus()
    this._updateSubmitButtonErrorMessageStatusWhenChangeSelect()
  }

  _preventDefault(e) {
    const NoneOfDebitAmountIsSelected = this._NoneOfDebitAmountIsSelected()
    const NoneOfCreditAmountIsSelected = this._NoneOfCreditAmountIsSelected()
    if (NoneOfDebitAmountIsSelected || NoneOfCreditAmountIsSelected) {
      e.preventDefault()
    }
  }

  _updateDebitAmountSelectionStatus() {
    const NoneOfDebitAmountIsSelected = this._NoneOfDebitAmountIsSelected()
    const errorMessageAreaElem = this.debitAmountItemsTarget.querySelector(
      "[data-general-csv-journals-import-target='errorMessageArea']",
    )
    const errorMessageTextAreaElme = errorMessageAreaElem.querySelector(
      "[data-general-csv-journals-import-target='errorMessageTextArea']",
    )
    if (NoneOfDebitAmountIsSelected) {
      errorMessageAreaElem.hidden = false
      errorMessageTextAreaElme.textContent =
        '借方金額（税込）または借方金額（税抜）のいずれかを選択してください。'
    } else {
      errorMessageAreaElem.hidden = true
      errorMessageTextAreaElme.textContent = ''
    }
  }

  _updateCreditAmountSelectionStatus() {
    const NoneOfCreditAmountIsSelected = this._NoneOfCreditAmountIsSelected()
    const errorMessageAreaElem = this.creditAmountItemsTarget.querySelector(
      "[data-general-csv-journals-import-target='errorMessageArea']",
    )
    const errorMessageTextAreaElme = errorMessageAreaElem.querySelector(
      "[data-general-csv-journals-import-target='errorMessageTextArea']",
    )
    if (NoneOfCreditAmountIsSelected) {
      const errorMessageAreaElem = this.creditAmountItemsTarget.querySelector(
        "[data-general-csv-journals-import-target='errorMessageArea']",
      )
      const errorMessageTextAreaElme = errorMessageAreaElem.querySelector(
        "[data-general-csv-journals-import-target='errorMessageTextArea']",
      )
      errorMessageAreaElem.hidden = false
      errorMessageTextAreaElme.textContent =
        '貸方金額（税込）または貸方金額（税抜）のいずれかを選択してください。'
    } else {
      errorMessageAreaElem.hidden = true
      errorMessageTextAreaElme.textContent = ''
    }
  }

  _updateSubmitButtonErrorMessageStatusWhenSubmit() {
    const NoneOfDebitAmountIsSelected = this._NoneOfDebitAmountIsSelected()
    const NoneOfCreditAmountIsSelected = this._NoneOfCreditAmountIsSelected()
    if (NoneOfDebitAmountIsSelected || NoneOfCreditAmountIsSelected) {
      this.submitButtonErrorMessageTarget.hidden = false
    } else {
      this.submitButtonErrorMessageTarget.hidden = true
    }
  }

  _updateSubmitButtonErrorMessageStatusWhenChangeSelect() {
    const NoneOfDebitAmountIsSelected = this._NoneOfDebitAmountIsSelected()
    const NoneOfCreditAmountIsSelected = this._NoneOfCreditAmountIsSelected()
    if (NoneOfDebitAmountIsSelected || NoneOfCreditAmountIsSelected) {
      return
    }
    this.submitButtonErrorMessageTarget.hidden = true
  }

  _updateItemsStatus() {
    this.itemTargets.forEach((item) => {
      const labelElem = item.querySelector(
        "[data-general-csv-journals-import-target='label']",
      )
      const labelText = labelElem.textContent
      const selectElem = item.querySelector('select')
      const selectedText = selectElem.options[selectElem.selectedIndex].value
      const warningMessage = this._warningMessageAboutDebitCredit(
        labelText,
        selectedText,
      )
      const warningMessageAreaElem = item.querySelector(
        "[data-general-csv-journals-import-target='warningMessageArea']",
      )
      const warningMessageTextAreaElme = warningMessageAreaElem.querySelector(
        "[data-general-csv-journals-import-target='warningMessageTextArea']",
      )
      if (warningMessage) {
        warningMessageAreaElem.hidden = false
        warningMessageTextAreaElme.textContent = warningMessage
      } else {
        warningMessageAreaElem.hidden = true
        warningMessageTextAreaElme.textContent = warningMessage
      }
    });
  }

  _warningMessageAboutDebitCredit(word1, word2) {
    if (word1.match(/借方/) && word2.match(/貸方/)) {
      return '借方項目に貸方の列が選ばれているようですが間違いないですか?'
    } else if (word1.match(/貸方/) && word2.match(/借方/)) {
      return '貸方項目に借方の列が選ばれているようですが間違いないですか?'
    }
  }

  _NoneOfDebitAmountIsSelected() {
    return [...this.debitAmountItemsTarget.querySelectorAll('select')].every(
      (select) => {
        return select.selectedIndex === 0
      },
    )
  }

  _NoneOfCreditAmountIsSelected() {
    return [...this.creditAmountItemsTarget.querySelectorAll('select')].every(
      (select) => {
        return select.selectedIndex === 0
      },
    )
  }
}
