import { Controller } from "stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'trigger',
    'options'
  ]

  static values = {
  }

  connect () {
    this.bindDropdown()
  }

  // ドロップダウンのバインド
  bindDropdown () {
    this.tippy = tippy(this.triggerTargets, {
      content: this.optionsTarget.innerHTML,
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      animation: 'perspective',
      duration: 100,
      placement: 'bottom-start',
      maxWidth: 'none',
      offset: [0, 4],
      onMount: this.onMount.bind(this),
    })
  }

  onMount (instance) {
    this.tippyInstance = instance
    this.updateActiveNodeStyle()
  }

  // スタイルを更新する
  updateActiveNodeStyle () {
    this.nodeTriggerTargets.forEach((element) => {
      this.toggleActiveClass(
        element,
        element.dataset.nodeValue === this.activeNodeValue
      )
    })

    this.activeNodeIconTargets.forEach((element) => {
      element.hidden = element.dataset.nodeValue !== this.activeNodeValue
    })
  }

  // スタイルを更新する
  updateActiveLeafStyle () {
    this.leafTriggerTargets.forEach((element) => {
      this.toggleActiveClass(
        element,
        element.dataset.nodeId == this.selectedValue[0] &&
        element.dataset.leafId == this.selectedValue[1]
      )
    })

    this.activeLeafIconTargets.forEach((element) => {
      element.hidden = !(
        element.dataset.nodeId == this.selectedValue[0] &&
        element.dataset.leafId == this.selectedValue[1]
      )
    })
  }

  toggleActiveClass (element, isActive) {
    if (isActive) {
      element.classList.add('active')
    } else {
      element.classList.remove('active')
    }
  }

  get nodeTriggerTargets () {
    return this.tippyInstance.popper.querySelectorAll('[data-target="nodeTrigger"]')
  }

  get activeNodeIconTargets () {
    return this.tippyInstance.popper.querySelectorAll('[data-target="activeNodeIcon"]')
  }

  get activeNodeValue () {
    return this.tippyInstance.reference.dataset.nodeValue
  }
}
