import Alpine from 'alpinejs'
import initFormula from './alpine/formula'
import storedState from './alpine/storedState'

let initialized = false

document.addEventListener('turbolinks:load', () => {
  if (initialized) return

  window.Alpine = Alpine
  window.AlpineDatas = {
    // なぜかx-dataでの関数呼び出し(↓リンク)が動かないのでwindowに入れて回避。
    // 実装を確認するも原因がよくわからず (turbolinksのせい?)
    // https://alpinejs.dev/globals/alpine-data#initial-parameters
    storedState,
  }
  initFormula(Alpine)
  Alpine.start()

  initialized = true
})
