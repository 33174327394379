import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'tr',
    'budgetTagCategory',
    'submitButton'
  ]

  static values = {
    bannedSameCategoryOnCurrentPage: Object,
    categoryNameMap: Object
  }

  connect () {
    this.sourceJournalTagsBannedSameCategoryTagIdsMap = this.bannedSameCategoryOnCurrentPageValue
    this.sourceJournalTagsCategoryNameMap = this.categoryNameMapValue
    this.validate()
    document.addEventListener('click', (e) => {
      // 当該のボタンにdata-action属性があったのでやむなくこうした
      const nested_select_leaf_btn = e.target.closest('[data-nested-select--options-target="leafTrigger"]')
      if (nested_select_leaf_btn) {
        this.validate()
      }
    })
    document.addEventListener('change', (e) => {
      // data-action属性がないcheckboxもあったが、管理のしやすさから当該3種類のチェックボックスはすべてこの書き方にした
      const bulkImportCheckBox = e.target.closest('[data-bulk-check-target="trigger"]')
      const importCheckBox = e.target.closest('[data-bulk-check-target="target"]')
      const createCheckBox = e.target.closest('[data-action="change->source-tag-map#onCheckCreate"]')
      if (bulkImportCheckBox || importCheckBox || createCheckBox) {
        this.validate()
      }
    })
  }

  validate() {
    const submitButton = this.submitButtonTarget
    submitButton.classList.remove('disabled')
    submitButton.removeAttribute('tabindex')
    this.trTargets.forEach((tr) => {
      const errorElem = tr.querySelector('[data-error]')
      const errorMessageElem = errorElem.querySelector('[data-error-message')
      const disableSameCategoryTagNames = this.disableSameCategoryTagNames(tr) //取り込むボタンにチェックがはいってないと空にする
      if (disableSameCategoryTagNames.length === 0) {
        errorElem.hidden = true
      } else {
        errorElem.hidden = false
        errorMessageElem.innerHTML = ''
        disableSameCategoryTagNames.forEach((categoryName) => {
          errorMessageElem.innerHTML += `<li>${categoryName}と異なるタグカテゴリにしてください。</li>`
        })
        submitButton.classList.add('disabled')
        submitButton.setAttribute('tabindex', -1)
      }
    })
  }

  disableSameCategoryTagNames(tr) {
    const selfCategoryName = this.getCategoryNameById(tr.dataset.id)
    const importCheckBox = tr.querySelector('[data-bulk-check-target="target"]') 
    // ここが無くても[]が返るはずだが後続処理を走らせずに、取り込むチェックの無の場合に[]を返している
    if (!importCheckBox.checked) {
      return []
    } 
    const bannedSameCategoryTagIds = this.sourceJournalTagsBannedSameCategoryTagIdsMap[tr.dataset.id]
    if (!bannedSameCategoryTagIds){
      return []
    }
    const alertedTagIds = bannedSameCategoryTagIds.filter((id) => {
      return selfCategoryName === this.getCategoryNameById(id)
    })
    const alertedTagNames = alertedTagIds.map((id) => {
      return this.getTagNameById(id)
    })
    return alertedTagNames
  }

  getTagNameById(id) {
    const sourceJournalTagOnCurrentPage = this.sourceJournalTagObjOnCurrentPage(id)
    if (sourceJournalTagOnCurrentPage) {
      return sourceJournalTagOnCurrentPage['name']
    }
    const sourceJournalTag = this.sourceJournalTagsCategoryNameMap[id]
    return sourceJournalTag['name']
  }

  getCategoryNameById(id) {
    const sourceJournalTagOnCurrentPage = this.sourceJournalTagObjOnCurrentPage(id)
    if (sourceJournalTagOnCurrentPage) {
      // 取り込むにチェックが入ってない場合はをnull返す
      if (sourceJournalTagOnCurrentPage['importCheck']) {
        return sourceJournalTagOnCurrentPage['budgetTagCategoryName']
      } else {
        return null
      }
    }
    const sourceJournalTag = this.sourceJournalTagsCategoryNameMap[id]
    if (sourceJournalTag) {
      return sourceJournalTag['budget_tag_category_name']
    } else {
      return null
    }
  }

  sourceJournalTagObjOnCurrentPage(id) {
    const tr = this.trTargets.find(tr => id == tr.dataset.id )
    if (!tr) {
      return null
    }
    const obj = {}
    obj['name'] = tr.querySelector('[data-validation-category-of-source-journal-tags-target="sourceJournalTag"]').textContent
    const importCheckBox = tr.querySelector('[data-bulk-check-target="target"]') 
    obj['importCheck'] = importCheckBox.checked
    const persistedTarget = tr.querySelector('[data-source-tag-map-target="persisted"]')
    const newRecordTarget = tr.querySelector('[data-source-tag-map-target="newRecord"]')
    if (persistedTarget.hidden === false) {
      const category_select_btn = persistedTarget.querySelector('[data-nested-select--select-target="trigger"]')
      obj['budgetTagCategoryName'] = category_select_btn.querySelector('[data-nested-select--select-target="nodeLabel"]').textContent
    } else if (newRecordTarget.hidden === false) {
      const category_select = newRecordTarget.querySelector('[data-validation-category-of-source-journal-tags-target="budgetTagCategory"]')
      const select_indext = category_select.selectedIndex
      obj['budgetTagCategoryName'] = category_select.options[select_indext].text
    }
    return obj
  }
}
