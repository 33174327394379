import React, { useRef } from 'react'

import { ClientFolder } from '@/frontend/api/types'
import { Modal } from '@/frontend/components/modals'

type Props = {
  open: boolean
  onClose: () => void
  clientFolders: ClientFolder[]
  formAuthenticityToken: string
  uri: string
  title: string
  folderName?: string
  edit: boolean
}

type InputNameFieldsProps = {
  folderName?: string
}

type SelectParentFolderFieldProps = {
  clientFolders: ClientFolder[]
}

type ActionFieldsProps = {
  onSubmit: () => void
  onClose: () => void
}

export function FolderFormDialog({
  open,
  onClose,
  clientFolders,
  formAuthenticityToken,
  uri,
  title,
  folderName,
  edit,
}: Props) {
  const folderForm = useRef<HTMLFormElement>(null)
  const onSubmit = () => {
    folderForm.current?.submit()
  }
  const inputFolderName = edit ? folderName || '' : ''

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      actions={<ActionFields onSubmit={onSubmit} onClose={onClose} />}
    >
      <div className="p-8 pt-0 space-y-6">
        <form action={uri} acceptCharset="UTF-8" method="post" ref={folderForm}>
          {edit && (
            <input type="hidden" readOnly={true} name="_method" value="patch" />
          )}
          <input
            type="hidden"
            readOnly
            name="authenticity_token"
            value={formAuthenticityToken}
          />
          <InputNameField folderName={inputFolderName} />
          {!edit && <SelectParentFolderField clientFolders={clientFolders} />}
        </form>
      </div>
    </Modal>
  )
}

const InputNameField = ({ folderName }: InputNameFieldsProps) => (
  <div>
    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <div className="flex justify-between">
        <label
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          htmlFor="client_folder[name]"
        >
          名称
        </label>
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="w-64 text-black">
          <div className="rounded-md shadow-sm">
            <input
              className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 "
              required={true}
              type="text"
              name="client_folder[name]"
              id="client_folder_name"
              defaultValue={folderName}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const SelectParentFolderField = ({
  clientFolders,
}: SelectParentFolderFieldProps) => (
  <div>
    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <div className="flex justify-between">
        <label
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          htmlFor="client_folder[parent_id]"
        >
          親階層のフォルダ
        </label>
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="w-64">
            <select
              className="w-full block form-select transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              name="client_folder[parent_id]"
              id="client_folder_parent_id"
            >
              <option value="" label=" "></option>
              {clientFolders.map((folder) => (
                <option key={folder.id} value={folder.id}>
                  {folder.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const ActionFields = ({ onSubmit, onClose }: ActionFieldsProps) => (
  <div className="flex justify-end">
    <div className="btn-wrapper">
      <button
        className="btn btn-white"
        data-modal-close=""
        type="button"
        onClick={onClose}
      >
        キャンセル
      </button>
    </div>
    <div className="ml-3">
      <div className="btn-wrapper">
        <input
          type="submit"
          name="commit"
          value="保存"
          className="btn btn-primary"
          data-disable-with="保存"
          onClick={onSubmit}
        />
      </div>
    </div>
  </div>
)
