import { useCallback, useState } from 'react'

import { ApiError, JobLog, Org } from '@/frontend/api'
import {
  JournalsUpdateFromSourcePayload,
  updateJournalsFromSource,
} from '@/frontend/api/importer'

export function useJournalsUpdateFromSource(
  org: Org,
  accountingSoftwareKey: string,
  format: string,
) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [jobLog, setJobLog] = useState<JobLog | null>(null)
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)

  const submit = useCallback(async () => {
    const payload: JournalsUpdateFromSourcePayload = {
      accountingSoftwareKey,
      format,
    }

    setIsSubmitting(true)
    const result = await updateJournalsFromSource(org.id, payload)
    if (result.ok) {
      setJobLog(result.val)
      setApiError(undefined)
    } else {
      setApiError(result.err)
    }
    setIsSubmitting(false)
  }, [org, accountingSoftwareKey, format])
  return {
    submit,
    jobLog,
    isSubmitting,
    apiError,
  }
}
