import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['link', 'field']

  connect() {
    const paramFields = this.fieldTargets
    this._addEventListeners(paramFields)
    this._initializeLinkParam(paramFields)
  }

  _addEventListeners(fields) {
    fields.forEach((field) => {
      field.addEventListener('change', this._change.bind(this))
    })
  }

  _initializeLinkParam(paramFields) {
    const haveValueFields = this._filterHaveValueFields(paramFields)
    haveValueFields.forEach((target) => {
      this._update_query_param_by_select_target(target)
    })
  }

  _change(e) {
    this._update_query_param_by_select_target(e.target)
  }

  _update_query_param_by_select_target(target) {
    const param = this._create_param_by_target(target)
    this._update_query_param(param)
  }

  _filterHaveValueFields(fields) {
    return Array.from(fields).filter((field) => {
      return !this._isBlank(field.value)
    })
  }

  _update_query_param(param) {
    const url = this._url()
    url.searchParams.delete(param.name)

    if (!this._isBlank(param.value)) {
      url.searchParams.append(param.name, param.value)
    }
    this.linkTarget.href = url.href
  }

  _url() {
    return new URL(this.linkTarget.href)
  }

  _isBlank(value) {
    return value === ''
  }

  _clear_search_param(url) {
    url.search = ''
    return url
  }

  _create_param_by_target(target) {
    return {
      name: target.name,
      value: target.value,
    }
  }
}
