import React from 'react'
import dayjs from 'dayjs'

type Props = {
  lastImportedAt?: string
}
export default function LastImportedAt({ lastImportedAt }: Props) {
  return (
    <>
      {lastImportedAt && (
        <div className="text-gray-500 text-xs py-1">
          最終インポート日時：
          {dayjs(new Date(lastImportedAt)).format('YYYY/MM/DD HH:mm')}
        </div>
      )}
    </>
  )
}
