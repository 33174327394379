// コメントアウトしているものは岩壁さん案のもの
// 大きく見た目が変わりそうなので検証してから反映したい
// export const BUDGET2_OPACITY = 1
// export const BAR_OPACITY_WITH_LINE = 1
// export const SIMPLE_BACKGROUND_COLORS = [
//   //推移表
//   'rgba(0,34,82,1)',
//   'rgba(0,68,163,1)',
//   'rgba(87,147,241,1)',
//   'rgba(0,157,126,1)',
//   'rgba(255,168,0,1)',
//   'rgba(226,99,97,1)'
// ]
// export const STACKED_BAR_BACKGROUND_COLORS = [
//   // ref: https://docs.google.com/presentation/d/18K6uoSepkDFvSgCLLm_0tYcUNT1-hy5OIB9-qbczaa8/edit?pli=1#slide=id.g99411942fc_0_9
//   [//積み上げ青
//   'rgba(87,147,241,1)',
//   'rgba(150,187,244,1)',
//   'rgba(0,68,163,1)',
//   'rgba(214,231,255,1)',
//   'rgba(0,34,82,1)',
//   'rgba(172,172,172,1)',
//   ],
//   [//オレンジ
//   'rgba(255,168,0,1)',
//   'rgba(246,194,93,1)',
//   'rgba(163,124,42,1)',
//   'rgba(252,229,185,1)',
//   'rgba(114,85,23,1)',
//   'rgba(172,172,172,1)',
//   ],
//   [//パープル
//   'rgba(136,78,190,1)',
//   'rgba(177,133,218,1)',
//   'rgba(92,47,134,1)',
//   'rgba(223,200,246,1)',
//   'rgba(54,15,91,1)',
//   'rgba(172,172,172,1)',
//   ],
//   [//緑
//   'rgba(0,157,126,1)',
//   'rgba(93,200,166,1)',
//   'rgba(5,136,101,1)',
//   'rgba(176,234,202,1)',
//   'rgba(5,83,57,1)',
//   'rgba(172,172,172,1)',
//   ],
//   [//赤
//   'rgba(217,63,60,1)',
//   'rgba(241,133,130,1)',
//   'rgba(154,16,13,1)',
//   'rgba(247,188,187,1)',
//   'rgba(98,5,3,1)',
//   'rgba(172,172,172,1)',
//   ],
//   [//黄
//   'rgba(213,199,0,1)',
//   'rgba(236,222,19,1)',
//   'rgba(147,136,0,1)',
//   'rgba(245,238,130,1)',
//   'rgba(68,67,2,1)',
//   'rgba(172,172,172,1)',
//   ],
// ]

export const BUDGET2_OPACITY = 0.3
export const BAR_OPACITY_WITH_LINE = 0.5
export const SIMPLE_BACKGROUND_COLORS = [
  'rgba(0, 34, 82, 1)',
  'rgba(0, 93, 224, 1)',
  'rgba(133, 184, 255, 1)',
  'rgba(162, 231, 73, 1)',
  'rgba(255, 168, 0, 1)',
  'rgba(240, 96, 96, 1)',
]
export const STACKED_BAR_BACKGROUND_COLORS = [
  // ref: https://docs.google.com/presentation/d/18K6uoSepkDFvSgCLLm_0tYcUNT1-hy5OIB9-qbczaa8/edit?pli=1#slide=id.g99411942fc_0_9
  [
    'rgba(0,34,82,1)',
    'rgba(0,68,163,1)',
    'rgba(45,111,204,1)',
    'rgba(89,147,229,1)',
    'rgba(177,206,248,1)',
    'rgba(206,225,252,1)',
  ],
  [
    'rgba(166,65,65,1)',
    'rgba(202,81,81,1)',
    'rgba(240,96,96,1)',
    'rgba(244,132,132,1)',
    'rgba(251,204,204,1)',
    'rgba(254,240,240,1)',
  ],
  [
    'rgba(145,135,0,1)',
    'rgba(195,183,0,1)',
    'rgba(235,221,0,1)',
    'rgba(255,240,0,1)',
    'rgba(255,246,99,1)',
    'rgba(255,252,198,1)',
  ],
  [
    'rgba(84,119,37,1)',
    'rgba(110,156,49,1)',
    'rgba(145,206,65,1)',
    'rgba(162,231,73,1)',
    'rgba(204,242,154,1)',
    'rgba(225,247,195,1)',
  ],
  [
    'rgba(145,95,0,1)',
    'rgba(200,132,0,1)',
    'rgba(255,168,0,1)',
    'rgba(255,188,58,1)',
    'rgba(255,218,144,1)',
    'rgba(255,247,230,1)',
  ],
  [
    'rgba(38,38,38,1)',
    'rgba(77,77,77,1)',
    'rgba(137,137,137,1)',
    'rgba(172,172,172,1)',
    'rgba(225,225,225,1)',
    'rgba(248,248,248,1)',
  ],
]

export function findMaxDataset(chart, datasets, yAxisID) {
  let maxDataset = null
  let maxDate = Number.NEGATIVE_INFINITY
  datasets.forEach((dataset, i) => {
    if (chart.getDatasetMeta(i).hidden === true) return
    if (!(dataset.yAxisID === yAxisID)) return
    dataset.data.forEach((e) => {
      const num = Number(e)
      if (num > maxDate) {
        maxDate = num
        maxDataset = dataset
      }
    })
  })
  return maxDataset
}

export function formatNumber(number, unitType) {
  const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (unitType === 'percentage') {
    return `${numberText}%`
  }
  return numberText
}
