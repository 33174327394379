import React, { useEffect, useState } from 'react'
import { Popover } from '@headlessui/react'
import { RiNotification2Line } from 'react-icons/ri'
import { usePopper } from 'react-popper'

import { Notification } from '@/frontend/api'
import { useActionCable, useChannel } from '@/frontend/hooks/useActionCable'
import { useNotifications } from './hooks'
import { NotificationList } from './NotificationList'

type Props = {
  orgId: number
}

export function NavBarButton({ orgId }: Props) {
  // 未読件数を取得
  const res = useNotifications(orgId)
  const unchecked = res.data ? res.data.meta.uncheckedCount > 0 : false

  const onNotificationReceived = (receivedData: Notification) => {
    res.mutate((data) => {
      if (data == undefined) return
      let uncheckedCount = null
      if (receivedData.checked) {
        uncheckedCount = data.meta.uncheckedCount
      } else {
        uncheckedCount = data.meta.uncheckedCount + 1
      }
      return {
        ...data,
        meta: { ...data.meta, uncheckedCount },
      }
    })
  }

  const { actionCable } = useActionCable()
  const { subscribe } = useChannel(actionCable)
  const [receivedNotifications, setReceivedNotifications] = useState<
    Notification[]
  >([])

  useEffect(() => {
    subscribe(
      {
        channel: 'NotificationChannel',
        org_id: orgId,
      },
      {
        received: (data: Notification) => {
          onNotificationReceived(data)
          setReceivedNotifications((prev) => [data, ...prev])
        },
      },
    )
  }, [])

  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(triggerElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })

  return (
    <>
      <Popover className="relative h-full navbar-link navbar-link--border-none">
        <Popover.Button
          className="h-full flex items-center text-left"
          ref={setTriggerElement}
        >
          <div className="relative">
            <RiNotification2Line className="block navbar-link-icon text-base mr-2" />
            {unchecked && (
              <div
                className="absolute h-2 w-2 rounded-full bg-red-500"
                style={{ top: '-2px', right: '6px' }}
              />
            )}
          </div>
          <span>通知</span>
        </Popover.Button>

        <Popover.Panel
          className="z-10 mt-1 w-screen transform max-w-sm"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <NotificationList
            orgId={orgId}
            receivedNotifications={receivedNotifications}
            onCheckAll={() => res.mutate()}
          />
        </Popover.Panel>
      </Popover>
    </>
  )
}
