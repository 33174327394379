import React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { AiFillFileAdd } from 'react-icons/ai'

import { Button } from '@/frontend/components'

type AcceptFileTypes = 'csv' | 'txt'

const acceptFileTypeMap = {
  csv: { 'text/csv': ['.csv'] },
  txt: { 'text/plain': ['.txt'] },
} as const

type Props = {
  onDrop: (files: File[], fileRejections: FileRejection[]) => void
  disabled?: boolean
  acceptFileTypes?: AcceptFileTypes[]
}

const defaultAccept = acceptFileTypeMap['csv']

export default function Dropzone({ onDrop, acceptFileTypes, disabled }: Props) {
  const accept = acceptFileTypes
    ?.map((type) => acceptFileTypeMap[type])
    .reduce((acc, accept) => ({ ...acc, ...accept }), {})
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    accept: accept || defaultAccept,
    disabled: disabled,
    noClick: true,
  })

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dotted h-48 flex items-center justify-center"
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center">
        <AiFillFileAdd className="text-gray-500 text-4xl" />
        <span className="text-gray-500">ファイルをドロップ</span>
        <div className="mt-4">
          <Button onClick={open}>ファイルを選択</Button>
        </div>
      </div>
    </div>
  )
}
