export default function init (Alpine) {
  Alpine.data('formula', () => ({
    formula: [],
    offset: '0',
    hoverIndexList: [],
    formulaJson () {
      const elements = this.formula.map(element => {
        // 演算子
        if (element.type === 'operator') {
          return element.value
        }

        // 被演算子
        if (element.type === 'operand') {
          switch (element.kind) {
            // 指標の時
            case 'indicator':
              return {
                custom_report_item_id: element.value,
                offset: element.offset
              }
            // カスタムレポート数式列の時
            case 'comparison_table_column_formula':
              return {
                budget_column: element.value
              }
            // 詳細計画の時
            default:
              return {
                budget_detail_item_id: element.value,
                month: element.offset
              }
          }
        }
      })

      return JSON.stringify(elements)
    },
    isValid () {
      return validateFormula(this.formula)
    },
  }))
}

// 数式のバリデーション
function validateFormula (formula) {
  // 空白チェック
  if (formula.length === 0) return false

  return validateFormulaFirstElement(formula) &&
         validateFormulaLastElement(formula) &&
         validateFormulaRightSide(formula) &&
         validateFormulaBrackets(formula)
}

function validateFormulaFirstElement (formula) {
  const element = formula[0]
  if (!element) return true

  return element.type === 'operand' || element.value === '('
}

function validateFormulaLastElement (formula) {
  const element = formula[formula.length - 1]
  if (!element) return true

  return element.type === 'operand' || element.value === ')'
}

// 右隣の要素を確認
// - 右隣にこれるもの
//   - 開カッコ
//     - 開カッコ
//     - 被演算子
//   - 閉カッコ
//     - 閉カッコ
//     - カッコ以外の演算子
//   - カッコ以外の演算子
//     - 開カッコ
//     - 被演算子
//   - 被演算子
//     - 演算子
function validateFormulaRightSide (formula) {
  return formula.every((element, index) => {
    const nextElement = formula[index + 1]
    if (!nextElement) return true

    if (element.type === 'operator') {
      if (element.value === ')') {
        return nextElement.type === 'operator' &&
               nextElement.value !== '('
      }

      return nextElement.value === '(' ||
             nextElement.type === 'operand'
    }

    if (element.type === 'operand') {
      return nextElement.type === 'operator'
    }

    throw new Error(`${element} is unexpected`)
  })
}

// カッコが対応しているか確認
function validateFormulaBrackets (formula) {
  const stack = []

  for (const element of formula) {
    if (element.value === '(') {
      stack.push(element)
    }

    if (element.value === ')') {
      if (stack.length === 0) {
        return false
      }
      stack.pop()
    }
  }

  return stack.length === 0
}
