import { lazyGetter } from '../utils'
import Table from './Table'
import Column from './Column'
import Row from './Row'
import Cell from './Cell'
import CellFormatter from './CellFormatter'
import axios from '../axios'
import snakeCaseKeys from 'snakecase-keys'

export default class Main {
  constructor () {
    if (this.hasTable) {
      this.table = this.buildTable()
      this.columns = this.buildColumns()
      this.rows = this.buildRows()
      this.cellFormatter = this.buildCellFormatter(this.table, this.rowMap)
      this.cells = this.buildCells()
      this.addEventListeners()
    }
  }

  get hasTable () {
    return !!this.tableElement
  }

  get tableElement () {
    return lazyGetter(this, 'tableElement', () => {
      return document.querySelector('[data-dynamic-cash-flow-type="Table"]')
    })
  }

  get columnMap () {
    return lazyGetter(this, 'columnMap', () => {
      return this.columns.reduce((map, column) => {
        map[column.id] = column
        return map
      }, {})
    })
  }

  get rowMap () {
    return lazyGetter(this, 'rowMap', () => {
      return this.rows.reduce((map, row) => {
        map[row.id] = row
        return map
      }, {})
    })
  }

  get cellMap () {
    return lazyGetter(this, 'cellMap', () => {
      return this.cells.reduce((map, cell) => {
        map[[cell.columnId, cell.rowId]] = cell
        return map
      }, {})
    })
  }

  buildTable () {
    const element = document.querySelector('[data-dynamic-cash-flow-type="Table"]')

    return new Table({ element: this.tableElement })
  }

  buildColumns () {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-cash-flow-type="Column"]'),
      element => new Column({ element })
    )
  }

  buildRows () {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-cash-flow-type="Row"]'),
      element => new Row({ element })
    )
  }

  buildCells () {
    const cellFormatter = this.cellFormatter
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-cash-flow-type="Cell"]'),
      (element) => new Cell({ element, cellFormatter }),
    )
  }

  buildCellFormatter (table, rowMap) {
    return new CellFormatter({ table, rowMap })
  }

  addEventListeners () {
    this.cells.forEach(cell => {
      cell.addEventListener('input', this.onCellInput.bind(this))
      cell.addEventListener('change', this.onCellChange.bind(this))
    })
  }

  onCellInput ({ target: cell, detail: { changeAmount } }) {
    this.propagateToParentRecursive({ cell, changeAmount })
    this.propagateToChangeCell({ cell, changeAmount })
    this.propagateToOpeningCells({ cell, changeAmount })
    this.propagateToEndingCells({ cell, changeAmount })
  }

  onCellChange ({ target: cell }) {
    const column = this.findColumn(cell.columnId)
    const row = this.findRow(cell.rowId)

    this.submit({
      firstDate: column.firstDate,
      lastDate: column.lastDate,
      accountItemId: row.accountItemId,
      accountItemIds: row.accountItemIds,
      debitable: row.transactionTypes.includes('debit'),
      creditable: row.transactionTypes.includes('credit'),
      amount: cell.balance,
      borderDate: this.table.borderDate,
      fiscalPeriodId: this.table.fiscalPeriodId,
      borrowingId: row.borrowingId
    })
  }

  // 親へ値を再帰的に伝搬する
  propagateToParentRecursive ({ cell, changeAmount }) {
    const parentCell = this.parentCellOf(cell)

    if (parentCell) {
      parentCell.addAmount(changeAmount)
      parentCell.updateHtml()
      this.propagateToParentRecursive({ cell: parentCell, changeAmount })
    }
  }

  // 現預金の増減額へ反映する
  propagateToChangeCell({ cell, changeAmount }) {
    const changeCell = this.changeCellOf(cell)

    if (changeCell) {
      changeCell.addAmount(changeAmount)
      changeCell.updateHtml()
    }
  }

  // 現預金の月初残高へ反映する
  propagateToOpeningCells({ cell, changeAmount }) {
    const openingCells = this.relatedOpeningCellsOf(cell)

    openingCells.forEach(openingCell => {
      openingCell.addAmount(changeAmount)
      openingCell.updateHtml()
    })
  }

  // 現預金の月末残高へ反映する
  propagateToEndingCells({ cell, changeAmount }) {
    const endingCells = this.relatedEndingCellsOf(cell)

    endingCells.forEach(endingCell => {
      endingCell.addAmount(changeAmount)
      endingCell.updateHtml()
    })
  }

  submit (params) {
    axios.put(this.table.action, {
      updater: snakeCaseKeys(params)
    })
      .catch(error => {
        let msgs = []
        if (error.response && error.response.status === 422) {
          msgs.push('不正な値が送信されたため保存に失敗しました。')
          msgs.push(...error.response.data.errors)
        } else {
          msgs.push('値の保存に失敗しました。')
          msgs.push('画面を再読み込みする、しばらく時間を置いてから試す、等の操作をしても解決されない場合はサポートまでお問い合わせください。')
        }
        alert(msgs.join('\n'))
      })
  }

  parentCellOf (cell) {
    const row = this.findRow(cell.rowId)
    const parentRow = this.findRow(row.parentId)

    if (parentRow) {
      return this.findCell(cell.columnId, parentRow.id)
    } else {
      return null
    }
  }

  changeCellOf (cell) {
    return this.findCell(cell.columnId, 'change_amount')
  }

  nextCellOf (cell) {
    const column = this.findColumn(cell.columnId)
    return this.findCell(column.nextColumnId, cell.rowId)
  }

  relatedOpeningCellsOf (cell) {
    const openingCell = this.nextOpeningCellOf(cell)
    if (openingCell) {
      return [openingCell, ...this.relatedOpeningCellsOf(openingCell)]
    } else {
      return []
    }
  }

  nextOpeningCellOf (cell) {
    const column = this.findColumn(cell.columnId)
    return this.findCell(column.nextColumnId, 'opening_amount')
  }

  relatedEndingCellsOf (cell) {
    const endingCell = this.endingCellOf(cell)
    const nextCell = this.nextCellOf(cell)
    const afterCells = nextCell ? this.relatedEndingCellsOf(nextCell) : []

    if (endingCell) {
      return [endingCell, ...afterCells]
    } else {
      return []
    }
  }

  endingCellOf (cell) {
    return this.findCell(cell.columnId, 'ending_amount')
  }

  findColumn (id) {
    return this.columnMap[id]
  }

  findRow (id) {
    return this.rowMap[id]
  }

  findCell (columnId, rowId) {
    return this.cellMap[[columnId, rowId]]
  }
}
