import { Controller } from 'stimulus'

const MAX_DISPLAY_ITEM_SIZE = 7

export default class extends Controller {
  static targets = [
    'selectedItemAreaBlankMessage',
    'availableItemAreaBlankMessage',
    'baseArea',
    'selectedItemArea',
    'availableItemArea',
    'submitButton',
  ]

  connect() {
    this._adjustSortableAreaHeight()
    this._displayBaseArea()
  }

  onDropEnd(e) {
    this._toggleMovedItemDisabled()
    this._toggleSubmitButtonDisabled()
  }

  onItemMove(e) {
    this._controlBlankMessage(e)
  }

  _controlBlankMessage(e) {
    const dragStartArea = e.detail.from
    const dropEndArea = e.detail.to

    // 移動先にメッセージが既に表示されている場合は消してあげる
    this._controlBlankMessageInDropEndArea(dropEndArea)

    // 移動元のitemがなくなった時はメッセージを表示してあげる
    this._controlBlankMessageInDragStartArea(dragStartArea)

    if (dragStartArea !== dropEndArea) return
    // 移動した後に一度も要素をDropせずに元のエリアに要素を戻した時の制御
    this._controlBlankMessageWhenMovingSameArea(dragStartArea, dropEndArea)
  }

  _controlBlankMessageInDropEndArea(dropEndArea) {
    if (this._isDisplayBlankMessage(dropEndArea)) {
      this._messageElByArea(dropEndArea).classList.add('hidden')
    }
  }
  _controlBlankMessageInDragStartArea(dragStartArea) {
    const dragStartAreaItems = this._itemsByArea(dragStartArea)
    const rejectedSortableItems = this._rejectIgnoreItems(dragStartAreaItems)
    if (rejectedSortableItems.length === 0) {
      this._messageElByArea(dragStartArea).classList.remove('hidden')
    }
  }

  _controlBlankMessageWhenMovingSameArea(dragStartArea, dropEndArea) {
    // 移動元も移動先も同じ要素となるのでどちらでも良い
    const selfArea = dragStartArea || dropEndArea

    // 移動元にメッセージが表示されるときに一度移動してから再度元のエリアに要素を戻した時
    if (this._isDisplayBlankMessage(selfArea)) {
      this._messageElByArea(selfArea).classList.add('hidden')
    }

    // 移動先にメッセージが表示されているときに一度移動してから再度元のエリアに要素を戻した時
    const otherSideArea = this._otherSideArea(selfArea)
    const otherSideAreaItems = this._itemsByArea(otherSideArea)
    const rejectedSortableItems = this._rejectIgnoreItems(otherSideAreaItems)
    if (rejectedSortableItems.length === 0) {
      this._messageElByArea(otherSideArea).classList.remove('hidden')
    }
  }

  _isDisplayBlankMessage(area) {
    return !this._messageElByArea(area).classList.contains('hidden')
  }

  _itemsByArea(area) {
    return area === this.selectedItemAreaTarget
      ? this._selectedItems()
      : this._availableItems()
  }

  _messageElByArea(area) {
    return area === this.selectedItemAreaTarget
      ? this.selectedItemAreaBlankMessageTarget
      : this.availableItemAreaBlankMessageTarget
  }

  _otherSideArea(area) {
    return area === this.selectedItemAreaTarget
      ? this.availableItemAreaTarget
      : this.selectedItemAreaTarget
  }

  // 移動中は移動元にsortable-ghostまたは、sortable-selectedクラスが付与され
  // メッセージ表示制御でエリア内のsortable-itemの個数が期待通り取得できないので除外する
  _rejectIgnoreItems(items) {
    return items.filter((item) => {
      const isRejectItem =
        !item.classList.contains('sortable-ghost') &&
        !item.classList.contains('sortable-selected')
      return isRejectItem
    })
  }

  _adjustSortableAreaHeight() {
    const sortableAreaMaxHeight = this._sortableAreaMaxHeight()
    this.selectedItemAreaTarget.style.maxHeight = sortableAreaMaxHeight
    this.availableItemAreaTarget.style.maxHeight = sortableAreaMaxHeight
  }

  _displayBaseArea() {
    // リサイズ時に一瞬更新前の要素が表示されてしまう。
    // stimulusではturbolinks:loadやDOMContentLoadedが拾えなかったので可視性で誤魔化している。
    this.baseAreaTarget.classList.remove('invisible')
  }

  _sortableAreaMaxHeight() {
    return this._itemHeight() * MAX_DISPLAY_ITEM_SIZE + 'px'
  }

  _itemHeight() {
    const criteriaItem =
      this._availableItems().length === 0
        ? this._selectedItems()[0]
        : this._availableItems()[0]
    return criteriaItem.offsetHeight
  }

  _selectedItems() {
    const selectedItemArea = this.selectedItemAreaTarget
    const selectedItemNodeList = selectedItemArea.querySelectorAll(
      '[data-sortable-item]',
    )
    return Array.from(selectedItemNodeList)
  }

  _availableItems() {
    const availableItemArea = this.availableItemAreaTarget
    const availableItemNodeList = availableItemArea.querySelectorAll(
      '[data-sortable-item]',
    )
    return Array.from(availableItemNodeList)
  }

  _toggleSubmitButtonDisabled() {
    this._selectedItems().length === 0
      ? this.submitButtonTarget.setAttribute('disabled', true)
      : this.submitButtonTarget.removeAttribute('disabled')
  }

  _toggleMovedItemDisabled() {
    this.selectedItemAreaTarget.querySelectorAll('input').forEach((e) => {
      e.removeAttribute('disabled')
    })
    this.availableItemAreaTarget.querySelectorAll('input').forEach((e) => {
      e.setAttribute('disabled', true)
    })
  }
}
