import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'formulaColumnFormsContent',
    'formulaColumnForm',
    'addFormulFormButtonContent',
    'submitErrorMesasge'
  ]

  static values = {
    newColumnTemplate: String,
    circledNumbers: String
  }

  connect() {
    this.formulaColumnFormTemplate = JSON.parse(this.newColumnTemplateValue)
    this.circledNumbers = JSON.parse(this.circledNumbersValue)
    this.updateAttributes()
    this.updateAddFormulFormButton()
  }

  // 数式列設定を追加
  addFormulaColumnForm() {
    const template = this.formulaColumnFormTemplate
    this.formulaColumnFormsContentTarget.insertAdjacentHTML('beforeend', template)
    this.updateAttributes()
    this.updateAddFormulFormButton()
  }

  // 数式列設定を削除
  removeFormulaColumnForm(e) {
    const formulaColumnForm = e.target.closest("[data-formula-columns-target='formulaColumnForm']")
    if(!formulaColumnForm) return

    const msg = '列設定を削除しますか？'
    const result = confirm(msg)
    if(result) {
      formulaColumnForm.remove()
      this.updateAttributes()
      this.updateAddFormulFormButton()
    }
  }

  // 全数式列設定のinputのname属性を更新する
  updateAttributes() {
    this.formulaColumnFormTargets.forEach((target, index) => {
      // 想定されるものは、名称のinput、百分率(%)のラジオボタン、計算式のinput
      target.querySelectorAll('input, select').forEach(input => {
        this.updateAttribute(input, index)
      })
    })
  }

  // name属性を更新する汎用メソッド
  updateAttribute(targetInput, index) {
    const currentAttribute = targetInput.getAttribute('name')
    const newAttribute = currentAttribute.replace(/[[0-9]+]/,`[${index+1}]`)
    targetInput.setAttribute('name', newAttribute)
  }

  // 数式列の追加ボタンの表示・非表示を制御する
  updateAddFormulFormButton() {
    if(this.formulaColumnFormTargets.length < 5) {
      this.addFormulFormButtonContentTargets.forEach((e) => {
        e.style.display = 'block';
      });
    } else {
      this.addFormulFormButtonContentTargets.forEach((e) => {
        e.style.display = 'none';
      });
    }
  }

  // 下記の場合submitできないようにしている
  // 不正な数式の場合
  // 表示データ2列の時に表示データ③を参照してる場合
  // 分母を選んでいない場合
  submit(e) {
    const invalid = this.formulaColumnFormTargets.some((row) => {
      const formulaFieldset = row.querySelector('[data-formula-fieldset]')
      const componentPercentageFieldset = row.querySelector(
        '[data-component-percentage-fieldset]',
      )
      if (formulaFieldset._x_isShown) {
        const errorMessages = formulaFieldset.querySelectorAll(
          '[data-error-message]',
        )
        return Array.from(errorMessages).some((error) => {
          return error._x_isShown
        })
      } else if (componentPercentageFieldset._x_isShown) {
        const errorMessages = componentPercentageFieldset.querySelectorAll('[data-error-message]')
        const denominatorInput = componentPercentageFieldset.querySelector('[data-denominator]')
        return (
          Array.from(errorMessages).some((error) => {
            return error._x_isShown
          }) || denominatorInput.value === ''
        )
      }
    })
    if (invalid) {
      this.submitErrorMesasgeTarget.classList.remove('hidden')
      e.preventDefault()
    }
  }
}
