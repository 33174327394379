import React, { useEffect, useRef } from 'react'

import { dropdownContext } from './dropdownContext'

type Props = {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  disabled?: boolean
  menuZIndex?: number
  trigger: React.ReactNode
  children: React.ReactNode
}
export function Dropdown({
  isOpen,
  onOpen,
  disabled,
  onClose,
  menuZIndex,
  trigger,
  children,
}: Props) {
  const dropDownRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)

  // NOTE: ドロップダウンを閉じる処理
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        isOpen &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(e.target as Node)
      ) {
        onClose()
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen, dropDownRef, triggerRef, onClose])

  const handleClickTrigger = () => {
    if (disabled) return

    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }

  return (
    <dropdownContext.Provider value={{ isOpen, disabled: disabled || false }}>
      <div ref={triggerRef} className="relative">
        <div onClick={handleClickTrigger}>{trigger}</div>
        {isOpen && (
          <Menu ref={dropDownRef} zIndex={menuZIndex}>
            {children}
          </Menu>
        )}
      </div>
    </dropdownContext.Provider>
  )
}

type DropdownChildrenProps = JSX.IntrinsicElements['div'] & {
  zIndex?: number
}

const Menu = React.forwardRef(function Menu(
  { zIndex, ...props }: DropdownChildrenProps,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className="absolute bg-white border border-color-gray-300 rounded shadow-md focus:outline-none w-full mt-1"
      style={{ zIndex }}
      {...props}
    />
  )
})
