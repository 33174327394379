import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import {
  BudgetTagCategory,
  createNonFinancialItem,
  NonFinancialItemPayload,
  Org,
} from '@/frontend/api'
import { SlideOver } from '@/frontend/components'
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback'
import { NonFinancialItemSlideOverForm } from './NonFinancialItemSlideOverForm'
import { NonFinancialItemSlideOverFormSkeleton } from './NonFinancialItemSlideOverFormSkeleton'

type Props = {
  open: boolean
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  nonFinancialItemId?: number | null // nullなら新規作成
  onClose: () => void
  onSubmit: (
    data: NonFinancialItemPayload,
  ) => ReturnType<typeof createNonFinancialItem>
}

export function NonFinancialItemSlideOver({
  open,
  org,
  nonFinancialItemId = null,
  primaryBudgetTagCategory,
  onClose,
  onSubmit,
}: Props) {
  const title = nonFinancialItemId ? '非財務項目の編集' : '非財務項目の新規登録'
  const description = nonFinancialItemId
    ? '登録されている非財務項目の情報を更新します'
    : '非財務項目を新しく登録します'

  return (
    <SlideOver open={open} onClose={onClose}>
      <ErrorBoundary
        fallbackRender={() => (
          <div className="h-full flex items-center justify-center">
            <ErrorBoundaryFallback />
          </div>
        )}
      >
        <Suspense
          fallback={
            <NonFinancialItemSlideOverFormSkeleton
              title={title}
              description={description}
              onClose={onClose}
            />
          }
        >
          <NonFinancialItemSlideOverForm
            title={title}
            description={description}
            org={org}
            nonFinancialItemId={nonFinancialItemId}
            primaryBudgetTagCategory={primaryBudgetTagCategory}
            onClose={onClose}
            onSubmit={onSubmit}
          />
        </Suspense>
      </ErrorBoundary>
    </SlideOver>
  )
}
