import React, { ReactElement, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { tv } from 'tailwind-variants'

type Props = {
  content: string | ReactElement | null
  variant?: 'short' | 'long'
  children: (
    ref: React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  ) => React.ReactNode
  disabled?: boolean
  mouseDownClosed?: boolean
}

const tooltip = tv({
  base: 'max-w-xs text-sm leading-5 rounded',
  defaultVariants: {
    variant: 'short',
  },
  variants: {
    variant: {
      short: 'px-3 py-2 bg-gray-600 text-white',
      long: 'p-1 bg-gray-200 text-gray-800',
    },
  },
})

export function Tooltip({
  content,
  variant,
  children,
  disabled,
  mouseDownClosed,
}: Props) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start', // TODO: 指定できるように
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 8] },
      },
    ],
  })

  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  useEffect(() => {
    if (referenceElement) {
      referenceElement.addEventListener('mouseenter', open)
      referenceElement.addEventListener('mouseleave', close)
      if (mouseDownClosed) {
        referenceElement.addEventListener('mousedown', close)
      }

      referenceElement.addEventListener('focus', open)
      referenceElement.addEventListener('blur', close)
      return () => {
        referenceElement.removeEventListener('mouseenter', open)
        referenceElement.removeEventListener('mouseleave', close)
        if (mouseDownClosed) {
          referenceElement.removeEventListener('mousedown', close)
        }

        referenceElement.removeEventListener('focus', open)
        referenceElement.removeEventListener('blur', close)
      }
    }
  }, [referenceElement])

  if (content == null || disabled) {
    return <>{children(setReferenceElement)}</>
  }

  return (
    <>
      {children(setReferenceElement)}
      {createPortal(
        <Transition
          show={isOpen}
          enter="transition-opacity duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="relative z-50"
        >
          <div
            ref={setPopperElement}
            className={tooltip({ variant })}
            style={styles.popper}
            {...attributes.popper}
          >
            {content}
          </div>
        </Transition>,
        document.body,
      )}
    </>
  )
}
