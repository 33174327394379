import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  RiCheckboxCircleFill,
  RiCloseFill,
  RiErrorWarningFill,
} from 'react-icons/ri'

type Props = {
  open: boolean
  onClose: () => void
  initialFocus?: React.MutableRefObject<null>
  children: React.ReactNode
  variant: 'success' | 'danger'
  title: string
  actions: React.ReactNode
  errorAlert?: React.ReactNode
}

export function ModalWithIcon({
  open,
  onClose,
  initialFocus,
  children,
  variant,
  title,
  actions,
  errorAlert,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={initialFocus}
        onClose={() => onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full max-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all my-8 w-full max-w-lg">
                <button
                  type="button"
                  onClick={onClose}
                  className="absolute top-2 right-2 block text-gray-800"
                >
                  <RiCloseFill
                    className="w-6 h-6 cursor-pointer"
                    onClick={onClose}
                  />
                </button>
                <div className="p-8 text-gray-800">
                  <div className="flex items-start">
                    <div className="flex shrink-0 items-center justify-center">
                      <Icon variant={variant} />
                    </div>
                    <div className="mt-px ml-4 text-left">
                      <Dialog.Title as="h3" className="text-lg leading-7">
                        {title}
                      </Dialog.Title>
                      <div className="mt-4 text-sm">{children}</div>
                    </div>
                  </div>
                  {errorAlert && <div className="mt-4">{errorAlert}</div>}
                  <div className="mt-6 flex justify-end space-x-4">
                    {actions}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Icon({ variant }: Pick<Props, 'variant'>) {
  switch (variant) {
    case 'success':
      return <RiCheckboxCircleFill className="w-7 h-7 text-green-500" />
    case 'danger':
      return <RiErrorWarningFill className="w-7 h-7 text-red-500" />
  }
}
