import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { tv } from 'tailwind-variants'

type Props = {
  items: ItemProps[]

  // https://headlessui.com/react/radio-group#radio-group
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
  name?: string
}

type ItemProps = {
  label: string
  value: string
}

const radioGroupOption = tv({
  base: [
    'relative cursor-pointer',
    'inline-flex items-center justify-center',
    'ring-inset ring-1 ring-gray-300',
    'focus:outline-offset-0 focus:outline-[3px]',
    'text-sm leading-5',
    'transition ease-in-out duration-150',
    'px-4 py-2 -ml-px',
    'bg-white text-gray-800',
  ].join(' '),
  variants: {
    active: {
      true: '',
    },
    checked: {
      true: 'bg-blue-600 text-white',
    },
    first: {
      true: 'rounded-l-md ml-0',
    },
    last: {
      true: 'rounded-r-md',
    },
  },
})

export function RadioGroupButtons({ items, ...props }: Props) {
  return (
    <RadioGroup className="isolate inline-flex rounded-md shadow-sm" {...props}>
      {items.map((item, i) => (
        <RadioGroup.Option
          key={item.value}
          value={item.value}
          className={({ active, checked }) =>
            radioGroupOption({
              active,
              checked,
              first: i === 0,
              last: i === items.length - 1,
            })
          }
        >
          {item.label}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}
