import React from 'react'
import { RiFolderAddLine } from 'react-icons/ri'

import { Button, Tooltip } from '@/frontend/components'

type Props = {
  creatable: boolean
  onClick: () => void
}

export function FolderRestrationButton({ creatable, onClick }: Props) {
  const tooltipContent = creatable
    ? null
    : 'フォルダを新規登録するためにはマネージャー以上の権限が必要です。'

  return (
    <>
      <Tooltip content={tooltipContent}>
        {(ref) => (
          <div className="btn-wrapper" ref={ref}>
            <Button variant="secondary" disabled={!creatable} onClick={onClick}>
              <div className="mr-2">
                <RiFolderAddLine />
              </div>
              フォルダの新規登録
            </Button>
          </div>
        )}
      </Tooltip>
    </>
  )
}
