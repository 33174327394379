import { lazyGetter } from '../utils'

export default class Row extends EventTarget {
  constructor({ element }) {
    super()
    this.element = element
  }

  get data() {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-pl-state')
      return JSON.parse(jsonString)
    })
  }

  get id() {
    return this.data.id
  }

  get parentId() {
    return this.data.parentId
  }

  get accountItemId() {
    return this.data.accountItemId
  }

  get costTypeId() {
    return this.data.costTypeId
  }

  get rootAccountItemId() {
    return this.data.rootAccountItemId
  }

  get relatedCostTypeId() {
    return this.data.relatedCostTypeId
  }

  get isSales() {
    return !!this.data.isSales
  }
}
