import { createErr, createOk, Result } from 'option-t/PlainResult'

import { axios, z } from '@/frontend/utils'
import { freeeCompaniesApiUrl } from '../../routes'
import { ApiError } from '../../types'
import { handleAxiosError } from '../../utils'
import { createApiUpdateCompanySchema } from '../apiUpdateCompanyTypes'

export const freeeApiUpdateCompanySchema = createApiUpdateCompanySchema(
  z.number(),
)
export type FreeeApiUpdateCompanyPayload = z.infer<
  typeof freeeApiUpdateCompanySchema
>

export async function updateFreeeCompany(
  orgId: number,
  payload: FreeeApiUpdateCompanyPayload,
): Promise<Result<undefined, ApiError>> {
  const url = freeeCompaniesApiUrl(orgId)
  try {
    await axios.put(url, payload)
    return createOk(undefined)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
