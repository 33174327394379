import Main from './Main'

document.addEventListener('turbolinks:load', () => {
  const main = new Main()
  if (main.hasTable) {
    handleBeforeUnload(main)
  }
})

function handleBeforeUnload(main) {
  const msg =
    'このページを離れますか？行った変更が保存されない可能性があります。'

  document.addEventListener('turbolinks:before-visit', (e) => {
    if (main.isIncomplete) {
      const result = confirm(msg)
      if (result) {
        main.initParamsPool()
      } else {
        e.preventDefault()
      }
    }
  })

  window.onbeforeunload = (e) => {
    if (main.isIncomplete) {
      e.returnValue = msg // Google Chrome
      return msg // Google Chrome以外
    }
  }
}
