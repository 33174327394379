import { AccountingSoftwareKey } from './types'

export function getTagCategories(accountingSoftwareKey: AccountingSoftwareKey) {
  switch (accountingSoftwareKey) {
    case 'mfc':
      return MfcTagCategories
    case 'mfc_plus':
      return MfcPlusTagCategories
    case 'freee':
      return FreeeTagCategories
    case 'bugyo_cloud':
      return BugyoCloudTagCategories
    default:
      throw new Error(
        `Unknown accounting software key: ${accountingSoftwareKey}`,
      )
  }
}

const MfcTagCategories = [
  { value: 'department', label: '部門' },
  { value: 'sub_account', label: '補助科目' },
  { value: 'partner', label: '取引先' },
  { value: 'tag', label: 'MFタグ' },
] as const

const MfcPlusTagCategories = [
  { value: 'department', label: '部門' },
  { value: 'sub_account', label: '補助科目' },
  { value: 'partner', label: '取引先' },
  { value: 'project', label: 'プロジェクト' },
  { value: 'tag', label: 'MFタグ' },
] as const

const FreeeTagCategories = [
  { value: 'department', label: '部門' },
  { value: 'item', label: '品目' },
  { value: 'partner', label: '取引先' },
  { value: 'memo', label: 'メモタグ' },
  { value: 'segment_1', label: 'セグメント1' },
  { value: 'segment_2', label: 'セグメント2' },
  { value: 'segment_3', label: 'セグメント3' },
] as const

const BugyoCloudTagCategories = [
  { value: 'department', label: '部門' },
  { value: 'sub_account', label: '補助科目' },
  { value: 'partner', label: '取引先' },
] as const
