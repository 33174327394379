import React from 'react'

import { ReportMetaId } from '@/frontend/features/customReports'
import { CommentPanel } from '@/frontend/features/customReports/CommentList/index'
import { MentionItem } from '@/frontend/features/customReports/lexicalPlugins/MentionsPlugin'

type Props = {
  userId: number
  memberId: number
  orgId: number
  reportMetaId: ReportMetaId
  creatableComment: boolean
  commentMentionMembers: MentionItem[]
}

// NOTE: useEffectでeventListerを用いて開閉していたが,
//       turbolinksの影響でeventListerが複数登録されてしまうため
//       コメント一覧の開閉はcommentListToggle.jsに移動した。
export default function CommentList(props: Props) {
  return <CommentPanel {...props} />
}
