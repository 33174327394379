import React from 'react'

import { JobLogErrorDetail } from '@/frontend/api'
import { Button, ModalWithIcon } from '@/frontend/components'

type Props = {
  isOpen: boolean
  onClose: () => void
  errorDetail: JobLogErrorDetail
}

export default function JobLogErrorDetailModal({
  isOpen,
  onClose,
  errorDetail,
}: Props) {
  return (
    <ModalWithIcon
      open={isOpen}
      onClose={onClose}
      variant="danger"
      title={errorDetail.title}
      actions={
        <Button onClick={onClose} variant="outlined">
          閉じる
        </Button>
      }
    >
      <div dangerouslySetInnerHTML={{ __html: errorDetail.body }} />
    </ModalWithIcon>
  )
}
