import snakecaseKeys from 'snakecase-keys'
import useSWR from 'swr'

import {
  Comment,
  getCustomReportCommentsUrl,
  getPreparedReportCommentsUrl,
  SearchCommentPayload,
} from '@/frontend/api'
import { axios, fetcher } from '@/frontend/utils'
import { ReportMetaId } from '..'

type CommentsResponse = {
  comments: Comment[]
}

export function useComments(
  orgId: number,
  reportMetaId: ReportMetaId,
  searchCommentPayload: SearchCommentPayload,
) {
  const searchQueryToken = getReportSearchQueryToken()
  const url = getCommentsUrl(orgId, reportMetaId)
  const preparedParams =
    reportMetaId.type === 'PreparedReport'
      ? { reportType: reportMetaId.reportType }
      : {}
  const urlWithQuery = axios.getUri({
    url: url,
    params: {
      ...snakecaseKeys({
        ...preparedParams,
        ...searchCommentPayload,
        searchQueryToken,
      }),
    },
  })
  return useSWR<CommentsResponse, Error>(urlWithQuery, fetcher)
}

function getCommentsUrl(orgId: number, reportMetaId: ReportMetaId) {
  switch (reportMetaId.type) {
    case 'CustomReport':
      return getCustomReportCommentsUrl(orgId, reportMetaId.id)
    case 'PreparedReport':
      return getPreparedReportCommentsUrl(orgId)
  }
}

const getReportSearchQueryToken = () => {
  const tokenElement = document.getElementById(
    'report-search-query-token',
  ) as HTMLFormElement
  return tokenElement.dataset.reportSearchQueryToken
}
