import { lazyGetter } from '../utils'

export default class Field {
  constructor(element) {
    this.element = element
    if (this.hasMsg) this.customValidity = this.msgs[0]
    this.addEventListeners()
  }

  set customValidity(v) {
    this.element.setCustomValidity(v)
  }

  get msgs() {
    return lazyGetter(this, 'msgs', () => {
      return JSON.parse(this.element.getAttribute('data-invalid'))
    })
  }

  get hasMsg() {
    return lazyGetter(this, 'hasMsg', () => {
      return this.msgs.length > 0
    })
  }

  addEventListeners() {
    this.element.addEventListener('input', this.formatValidity.bind(this))
    this.element.addEventListener('change', this.formatValidity.bind(this))
  }

  formatValidity() {
    this.customValidity = ''
  }
}
