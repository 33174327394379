import { lazyGetter, numberWithDelimiter } from '../utils'
import Table from './Table'
import Column from './Column'
import Row from './Row'
import Cell from './Cell'
import InstantSimulatorCell from './InstantSimulatorCell'
import axios from '../axios'
import snakeCaseKeys from 'snakecase-keys'
import { debounce } from 'throttle-debounce'

const DEBOUNCE_DELAY = 500

export default class Main {
  constructor() {
    if (this.hasTable) {
      this.table = this.buildTable()
      this.columns = this.buildColumns()
      this.rows = this.buildRows()
      this.cells = this.buildCells()
      this.instantSimulatorCells = this.buildInstantSimulatorCells()
      this.addEventListeners()
    }

    this.requestParamsPool = []
    this.requestQueue = []
    this.isRequesting = false
  }

  get hasTable() {
    return !!this.tableElement
  }

  get tableElement() {
    return lazyGetter(this, 'tableElement', () => {
      return document.querySelector('[data-dynamic-pl-state-type="Table"]')
    })
  }

  get instantSimulatorTableElement() {
    return lazyGetter(this, 'instantSimulatorTableElement', () => {
      return document.querySelector(
        '[data-dynamic-pl-state-type="InstantSimulatorTable"]',
      )
    })
  }

  get columnMap() {
    return lazyGetter(this, 'columnMap', () => {
      return this.columns.reduce((map, column) => {
        map[column.id] = column
        return map
      }, {})
    })
  }

  get rowMap() {
    return lazyGetter(this, 'rowMap', () => {
      return this.rows.reduce((map, row) => {
        map[row.id] = row
        return map
      }, {})
    })
  }

  get salesRowId() {
    return lazyGetter(this, 'salesRowId', () => {
      const salesRow = this.rows.find((row) => row.isSales)
      return salesRow.id
    })
  }

  get cellMap() {
    return lazyGetter(this, 'cellMap', () => {
      return this.cells.reduce((map, cell) => {
        map[[cell.columnId, cell.rowId]] = cell
        return map
      }, {})
    })
  }

  get childRowsMap() {
    return lazyGetter(this, 'childRowsMap', () => {
      return this.rows.reduce((map, row) => {
        map[row.parentId] ||= []
        map[row.parentId].push(row)
        return map
      }, {})
    })
  }

  buildTable() {
    return new Table({ element: this.tableElement })
  }

  buildColumns() {
    return Array.from(
      this.tableElement.querySelectorAll(
        '[data-dynamic-pl-state-type="Column"]',
      ),
      (element) => new Column({ element }),
    )
  }

  buildRows() {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-pl-state-type="Row"]'),
      (element) => new Row({ element }),
    )
  }

  buildCells() {
    return Array.from(
      this.tableElement.querySelectorAll('[data-dynamic-pl-state-type="Cell"]'),
      (element) => new Cell({ element }),
    )
  }

  buildInstantSimulatorCells() {
    if (!this.instantSimulatorTableElement) return []

    return Array.from(
      this.instantSimulatorTableElement.querySelectorAll(
        '[data-dynamic-pl-state-type="InstantSimulatorCell"]',
      ),
      (element) => new InstantSimulatorCell({ element }),
    )
  }

  addEventListeners() {
    this.cells.forEach((cell) => {
      cell.addEventListener('input', this.onCellInput.bind(this))
      cell.addEventListener('change', this.onCellChange.bind(this))
    })
  }

  onCellInput({ target: cell, detail: { changeAmount } }) {
    this.afterUpdateCell({ cell, changeAmount })
  }

  onCellChange({ target: cell }) {
    const column = this.findColumn(cell.columnId)
    const row = this.findRow(cell.rowId)

    // 予算が入力された場合
    if (row.accountItemId) {
      this.poolRequest({
        plStateItemId: row.accountItemId,
        budgetTagCategoryId: this.table.budgetTagCategoryId,
        budgetTagId: this.table.budgetTagId,
        firstDate: column.firstDate,
        lastDate: column.lastDate,
        amount: cell.balance,
        primaryTagCategoryId: this.table.primaryTagCategoryId,
        primaryTagId: this.table.primaryTagId,
      })
      // 変動費率が入力された場合
    } else if (row.costTypeId) {
      this.afterUpdateCostRateCell(cell)
      this.poolRequest({
        costTypeId: row.costTypeId,
        budgetTagCategoryId: this.table.budgetTagCategoryId,
        budgetTagId: this.table.budgetTagId,
        firstDate: column.firstDate,
        lastDate: column.lastDate,
        rate: cell.rate,
        primaryTagCategoryId: this.table.primaryTagCategoryId,
        primaryTagId: this.table.primaryTagId,
      })
    }
  }

  afterUpdateCell({ cell, changeAmount }) {
    this.propagateToParentRecursive({ cell: cell, changeAmount })
    this.propagateToTotal({ cell, changeAmount })
    // 売上高の場合だけ変動費率の科目を更新する
    if (cell.rowId == this.salesRowId) {
      this.afterUpdateRiSalesCell(cell)
    }
  }

  afterUpdateRiSalesCell(riSalesCell) {
    this.cells.forEach((cell) => {
      if (
        cell.isVariableCostAccountItem &&
        cell.columnId == riSalesCell.columnId
      ) {
        // cellは変動費として設定された勘定科目のセル
        const costRateCell = this.relatedCostRateCellOf(cell)
        this.updateVariableCostAccountItemCell(cell, costRateCell, riSalesCell)
      }
    })
  }

  relatedCostRateCellOf(variableCostAccountItemCell) {
    const row = this.findRow(variableCostAccountItemCell.rowId)
    const relatedCostTypeRow = this.findRow(row.relatedCostTypeId)
    if (relatedCostTypeRow) {
      return this.findCell(
        variableCostAccountItemCell.columnId,
        relatedCostTypeRow.id,
      )
    } else {
      return null
    }
  }

  // 変動費率を元に変動費を計算する
  afterUpdateCostRateCell(costRateCell) {
    costRateCell.data.rateForCalculate = costRateCell.rate
    const rootAccountItemCell = this.rootAccountItemCellOf(costRateCell)
    const riSalesCell = this.findCell(costRateCell.columnId, this.salesRowId)
    this.updateVariableCostAccountItemCell(
      rootAccountItemCell,
      costRateCell,
      riSalesCell,
    )
  }

  updateVariableCostAccountItemCell(
    variableCostAccountItemCell,
    costRateCell,
    riSalesCell,
  ) {
    const calcBalance = Math.round(
      (riSalesCell.displayedBalance * costRateCell.data.rateForCalculate) / 100,
    )
    const diffBalance =
      calcBalance - variableCostAccountItemCell.displayedBalance
    variableCostAccountItemCell.displayedBalance =
      numberWithDelimiter(calcBalance)
    const changeAmount = this.setChangeAmount(
      variableCostAccountItemCell,
      diffBalance,
    )
    this.afterUpdateCell({
      cell: variableCostAccountItemCell,
      changeAmount: changeAmount,
    })
  }

  setChangeAmount(rootAccountItemCell, diffBalance) {
    if (rootAccountItemCell.isDebit) {
      return { debit: diffBalance, credit: 0 }
    } else {
      return { debit: 0, credit: diffBalance }
    }
  }

  rootAccountItemCellOf(costRateCell) {
    const row = this.findRow(costRateCell.rowId)
    const rootAccountItemRow = this.findRow(row.rootAccountItemId)
    if (rootAccountItemRow) {
      return this.findCell(costRateCell.columnId, rootAccountItemRow.id)
    } else {
      return null
    }
  }

  // 親へ値を再帰的に伝搬する
  propagateToParentRecursive({ cell, changeAmount }) {
    const parentCell = this.parentCellOf(cell)

    if (parentCell) {
      parentCell.addAmount(changeAmount)
      parentCell.updateHtml()
      this.afterUpdateCell({ cell: parentCell, changeAmount })
    }
  }

  // 合計行へ反映する
  propagateToTotal({ cell, changeAmount }) {
    const totalCell = this.totalCellOf(cell)

    if (totalCell) {
      totalCell.addAmount(changeAmount)
      totalCell.updateHtml()
    }
  }

  revaluateAncestors(cell) {
    const parentCell = this.parentCellOf(cell)
    if (!parentCell) return

    const childCells = this.childCellsOf(parentCell)
    const debit = childCells.reduce((a, c) => a + c.debitAmount, 0)
    const credit = childCells.reduce((a, c) => a + c.creditAmount, 0)

    parentCell.setAmount({ debit, credit })
    parentCell.updateHtml()

    this.revaluateAncestors(parentCell)
  }

  revaluateTotal(row) {
    const totalCell = this.findCell('total', row.id)
    if (!totalCell) return

    const commonColumns = this.columns.filter((column) => column.id != 'total')
    const commonCells = commonColumns.map((column) =>
      this.findCell(column.id, row.id),
    )

    const debit = commonCells.reduce((a, c) => a + c.debitAmount, 0)
    const credit = commonCells.reduce((a, c) => a + c.creditAmount, 0)

    totalCell.setAmount({ debit, credit })
    totalCell.updateHtml()

    this.revaluateAncestors(totalCell)
  }

  poolRequest(params) {
    this.requestParamsPool.push(params)
    this.debounceRequest()
  }

  get debounceRequest() {
    // キャッシュ
    if (this._debounceRequest) return this._debounceRequest

    this._debounceRequest = debounce(DEBOUNCE_DELAY, () => {
      const params = [...this.requestParamsPool]
      this.queueRequest(() => {
        return this.submit(params)
      })
      this.requestParamsPool = []
    })

    return this._debounceRequest
  }

  queueRequest(requester) {
    this.requestQueue.push(requester)
    this.triggerRequest()
  }

  // リクエストが直列で実行されるように制御している
  // 数式の計算が並列で走るのを防ぐため
  // 本来はサーバーサイドでロックなりすべき？でないと本質的には防げないので。
  // 一旦、一人のユーザーが連続で送信した時に変なことにならようにだけしておく
  async triggerRequest() {
    // すでにキューを消化中の場合は何もしない
    if (this.isRequesting) return

    // キューが空の場合も何もしない
    if (this.requestQueue.length === 0) return

    this.isRequesting = true

    // キューが空になるまで実行
    while (true) {
      const request = this.requestQueue.shift()
      await request()

      // キューが空になったら終了
      if (this.requestQueue.length === 0) {
        this.isRequesting = false
        break
      }
    }
  }

  submit(params) {
    return axios
      .put(this.table.action, {
        bulk: snakeCaseKeys(params),
      })
      .then(
        (res) => {
          this.onSubmitted(res.data)
        },
        (error) => {
          let msgs = []
          if (error.response && error.response.status === 422) {
            msgs.push('不正な値が送信されたため保存に失敗しました。')
            msgs.push(...error.response.data.errors)
          } else {
            msgs.push('値の保存に失敗しました。')
            msgs.push(
              '画面を再読み込みする、しばらく時間を置いてから試す、等の操作をしても解決されない場合はサポートまでお問い合わせください。',
            )
          }
          alert(msgs.join('\n'))
        },
      )
  }

  parentCellOf(cell) {
    const row = this.findRow(cell.rowId)
    const parentRow = this.findRow(row.parentId)

    if (parentRow) {
      return this.findCell(cell.columnId, parentRow.id)
    } else {
      return null
    }
  }

  totalCellOf(cell) {
    return this.findCell('total', cell.rowId)
  }

  findColumn(id) {
    return this.columnMap[id]
  }

  findRow(id) {
    return this.rowMap[id]
  }

  findCell(columnId, rowId) {
    return this.cellMap[[columnId, rowId]]
  }

  childCellsOf(parent) {
    const childRows = this.childRowsMap[parent.rowId]
    return childRows.map((row) => {
      return this.findCell(parent.columnId, row.id)
    })
  }

  // 値の更新後、返却されたJSONからセルの値を更新する
  // CalculationResultExporterForAccountItem で整形された結果が返ってくる想定
  //
  // {
  //   [account_item_id: number] => [
  //     ['2020-01-01', 10_100], ['2020-02-01', 10_200], ['2020-03-01', 10_300], ['2020-04-01', 10_400],
  //     ['2020-05-01', 10_500], ['2020-06-01', 10_600], ['2020-07-01', 10_700], ['2020-08-01', 10_800],
  //     ['2020-09-01', 10_900], ['2020-10-01', 11_000], ['2020-11-01', 11_100], ['2020-12-01', 11_200]
  //   ]
  // }
  onSubmitted(data) {
    Object.keys(data).forEach((accountItemId) => {
      const rowId = `i-${accountItemId}`
      const row = this.findRow(rowId)

      data[accountItemId].forEach((tuple) => {
        const [columnId, balance] = tuple
        const cell = this.findCell(columnId, rowId)

        if (cell) {
          cell.setBalance(balance)
          cell.updateHtml()
          this.revaluateAncestors(cell)
        }
      })

      this.revaluateTotal(row)
    })
  }
}
