import Column from './Column'
import Row from './Row'
import { lazyGetter } from '../utils'

export default class Table extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-cash-flow')
      return JSON.parse(jsonString)
    })
  }

  get action () {
    return this.data.action
  }

  get fiscalPeriodId () {
    return this.data.fiscalPeriodId
  }

  get borderDate () {
    return this.data.borderDate
  }
}
