import React, { forwardRef } from 'react'
import { tv } from 'tailwind-variants'

type Props = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'className'
> & {
  invalid?: boolean
}

const textarea = tv({
  base: [
    'ReactComponentInput', // 全体の disabled スタイルを回避するため app/javascript/src/scss/_input.scss
    'block w-full p-2 text-sm leading-5 rounded-md',
    'border-0 ring-inset ring-1 ring-gray-300',
    'transition duration-150 ease-in-out',
    'focus:outline focus:outline-blue-100 focus:outline-offset-0 focus:outline-[3px] focus:ring-gray-300 focus:ring-inset',
  ].join(' '),
  variants: {
    invalid: {
      true: 'outline outline-red-50 outline-offset-0 outline-[3px]',
    },
    disabled: {
      true: 'bg-gray-100 text-gray-400 ring-0 pointer-events-none',
    },
  },
})

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  function TextArea({ invalid, ...props }, ref) {
    return (
      <textarea
        className={textarea({ invalid, disabled: props.disabled })}
        ref={ref}
        {...props}
      />
    )
  },
)
