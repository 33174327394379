import { useEffect, useState } from 'react'

export const usePageBack = () => {
  const [isPageBack, setIsPageBack] = useState(false)

  const buildPageBackHistory = () => {
    if (window.history.state?.isPageBack === undefined) {
      window.history.replaceState({ isPageBack: true }, '')
    }

    if (window.history.state?.isPageBack) {
      window.history.pushState({ isPageBack: false }, '')
    }
  }

  useEffect(() => {
    if (window === undefined) return

    const state = window.history.state

    // Turbolinksが履歴を書き替えるのでORで対応
    if (!state || state['turbolinks']) {
      buildPageBackHistory()
    }

    const exit = (e: PopStateEvent) => {
      setIsPageBack(e?.state?.isPageBack || false)
    }

    window.addEventListener('popstate', exit)
    return () => {
      window.removeEventListener('popstate', exit)
    }
  }, [])

  useEffect(() => {
    if (!isPageBack) {
      buildPageBackHistory()
    }
  }, [isPageBack])

  const cancelPageBack = () => {
    setIsPageBack(false)
  }

  return [isPageBack, cancelPageBack] as const
}
