import { JobLogErrorDetail, JobLogStatus } from '@/frontend/api'
import { ImportFormat } from './getImportFormatMap'

export type Budget = {
  id: number
  name: string
}

export type AccountingSoftwareKey =
  | 'mfc'
  | 'mfc_plus'
  | 'freee'
  | 'yayoi'
  | 'bugyo_i'
  | 'bugyo_cloud'
  | 'general'

export type AccountingSoftware = {
  key: AccountingSoftwareKey
  name: string
  isApiAuthed: boolean
}
export type DataFormat = 'api' | 'csv'

export type ImportSetting = {
  budgetId: string
  dataFormat: DataFormat
  importFormat: ImportFormat
}

export type ImportSettingsByAccountingSoftware = {
  [key in AccountingSoftwareKey]?: ImportSetting
}

export type OrgImportSettings = {
  [orgId: number]: ImportSettingsByAccountingSoftware
}

export const STORED_IMPORT_SETTINGS_STORAGE_KEY = 'ImportSettings' as const

export type FreeeCompany = {
  id: number
  name: string
}

export type BugyoCloudCompany = {
  id: string
  name: string
}

export type JobLogWithAttachedFile = {
  id: number
  status: JobLogStatus
  createdAt: string
  attachedFiles: AttachedFile[]
  errorDetail?: JobLogErrorDetail
}

export type AttachedFile = {
  name: string
}

export type ImportFileStatus = Pick<
  JobLogWithAttachedFile,
  'status' | 'attachedFiles'
>
