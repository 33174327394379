import { createErr, createOk, Result } from 'option-t/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { axios, z } from '@/frontend/utils'
import { uploadGeneralCsvJournalsUrl } from '../../routes'
import { ApiError } from '../../types'
import { handleAxiosError } from '../../utils'

const headerRowNumberErrorMessage = '1以上の数値を入力してください' as const

export const generalCsvJournalsSchema = z.object({
  budgetId: z.number(),
  headerRowNumber: z.preprocess(
    (v) => Number(v),
    z
      .number({
        invalid_type_error: headerRowNumberErrorMessage,
      })
      .min(1, { message: headerRowNumberErrorMessage })
      .nonnegative({ message: headerRowNumberErrorMessage }),
  ),
  csvFile: z.custom<FileWithPath>().nullable(),
})

export type GeneralCsvJournalsPayload = z.infer<typeof generalCsvJournalsSchema>

export async function uploadGeneralCsvJournals(
  orgId: number,
  payload: GeneralCsvJournalsPayload,
): Promise<Result<string, ApiError>> {
  const url = uploadGeneralCsvJournalsUrl(orgId)
  try {
    const formData = new FormData()
    formData.append('budget_id', payload.budgetId.toString())
    formData.append('header_row_number', payload.headerRowNumber.toString())
    formData.append('csv_file', payload.csvFile as FileWithPath)

    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })
    const uploadId = res.data
    return createOk(uploadId)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
