import React, { createContext } from 'react'

import { ImportSetting } from '../types'

export type ImportSettingContextValue = {
  importSetting: ImportSetting
}

export const ImportSettingContext = createContext<
  ImportSettingContextValue | undefined
>(undefined)

type Props = {
  importSetting: ImportSetting
  children: React.ReactNode
}

export default function ImportSettingProvider({
  children,
  importSetting,
}: Props) {
  return (
    <ImportSettingContext.Provider value={{ importSetting }}>
      {children}
    </ImportSettingContext.Provider>
  )
}
