import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static values = {
    maxItemCount: Number,
  }

  connect() {
    const maxItemCountValue = this.maxItemCountValue || -1
    new Choices(this.element, {
      maxItemCount: maxItemCountValue,
      removeItemButton: true,
      shouldSort: false,
      itemSelectText: '',
      resetScrollPosition: false,
      maxItemText: (maxItemCount) => {
        return `${maxItemCount}個まで選択できます`
      },
    })
  }
}
