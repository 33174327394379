import React, { useEffect, useRef } from 'react'

import { ApiError } from '@/frontend/api'
import { Alert } from './Alert'

type Props = {
  apiError: ApiError
  autoScroll?: boolean // 表示時にこの要素までスクロールするかどうか
}
export function ApiAlert({ apiError, autoScroll = false }: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (autoScroll && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [autoScroll])

  return (
    <div ref={ref}>
      <AlertVariant apiError={apiError} />
    </div>
  )
}

function AlertVariant({ apiError }: { apiError: ApiError }) {
  switch (apiError.type) {
    case 'Unauthorized':
      return (
        <Alert title="認証に失敗しました">
          アカウントの再認証を行ってください
        </Alert>
      )
    case 'Forbidden':
      return (
        <Alert title="権限がありません">
          この機能へのアクセスは禁止されています
        </Alert>
      )
    case 'ValidationFailed':
      return (
        <Alert title={`${apiError.messages.length}件のエラーがあります`}>
          <ul role="list" className="list-disc space-y-1 pl-5">
            {apiError.messages.map((message, i) => (
              <li key={i}>{message}</li>
            ))}
          </ul>
        </Alert>
      )
    case 'Unexpected':
      return (
        <Alert title="不明なエラー">
          <p>接続に失敗したか、一時的なエラーが起きている可能性があります。</p>
          <p>
            しばらく経っても問題が解決しない場合、お手数ですがサポートまでお問い合わせください。
          </p>
        </Alert>
      )
  }
}
