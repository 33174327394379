import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['all', 'category', 'allActiveIcon', 'categoryActiveIcon', 'categoryContent']

  selectCategory (e) {
    const clicked = e.currentTarget
    const categoryId = e.currentTarget.dataset.id

    this.updateActive(categoryId)
  }

  updateActive (categoryId) {
    this.updateAllActive(!categoryId)
    this.updateCategoryActive(categoryId)
  }

  updateAllActive (isActive) {
    if (isActive) {
      this.allTarget.classList.add('active')
    } else {
      this.allTarget.classList.remove('active')
    }

    this.allActiveIconTarget.hidden = !isActive
  }

  updateCategoryActive (categoryId) {
    this.categoryTargets.forEach((element) => {
      if (element.dataset.id == categoryId) {
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })

    this.categoryActiveIconTargets.forEach((element) => {
      element.hidden = element.dataset.id != categoryId
    })

    this.categoryContentTargets.forEach((element) => {
      element.hidden = element.dataset.id != categoryId
    })
  }
}
