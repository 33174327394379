import React, { useContext } from 'react'

import { dropdownContext } from './dropdownContext'

type Props = {
  children: React.ReactNode
}

export function TriggerContainer({ children }: Props) {
  const { isOpen, disabled } = useContext(dropdownContext)
  const borderColorClass = isOpen
    ? 'border-color-blue-200'
    : 'border-color-gray-300'
  const bgColorClass = disabled ? 'bg-gray-100' : 'bg-white'
  const pointerClass = disabled ? '' : 'cursor-pointer'

  return (
    <div
      className={`flex items-center border px-2 py-1.5 rounded ${bgColorClass} ${borderColorClass} ${pointerClass}`}
    >
      {children}
    </div>
  )
}
