import { Controller } from "stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'trigger', // ドロップダウンのトリガーボタン
    'content', // ドロップダウンのコンテンツ
  ]

  static values = {
    tippy: Object,
    trigger: String,
  }

  connect () {
    // NOTE : see https://knowledgelabo.slack.com/archives/CF0UG68MC/p1644287090786589?thread_ts=1644284517.445739&cid=CF0UG68MC
    if (this._isExistParentDropdown()) { return }

    this.bindDropdown()
  }


  // ドロップダウンのバインド
  bindDropdown () {
    const appendTo = this.element
    const element = this.contentTarget

    this.tippy = tippy(this.triggerTarget, {
      content: element,
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      animation: 'perspective',
      duration: 100,
      placement: 'bottom-end',
      maxWidth: 'none',
      offset: [0, 4],
      appendTo,
    })
  }

  // ドロップダウンを閉じる
  hide () {
    this.tippy.hide()
  }

  _isExistParentDropdown() {
    return this.element.closest('.hidden')
      && this.element.closest('.simple-dropdown')
  }
}
