import useSWRMutation from 'swr/mutation'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  ClientFolder,
  getClientFolderUrl,
  Org,
} from '@/frontend/api'

export function useDeleteFolder(
  org: Org,
  folder: ClientFolder,
  formAuthenticityToken: string,
) {
  const deleteClientFolder = async (url: string) => {
    return await fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': formAuthenticityToken,
      },
    })
  }

  const url = getClientFolderUrl(org.id, folder.id)
  return useSWRMutation(url, deleteClientFolder)
}
