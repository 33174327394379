import { useCallback, useState } from 'react'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  ApiError,
  Client,
  Org,
} from '@/frontend/api'
import { useClients } from '@/frontend/features/clients/hooks/useClients'

export function useDeleteClient(
  org: Org,
  ClientsResponse: ReturnType<typeof useClients>['data'],
  mutateClients: ReturnType<typeof useClients>['mutate'],
) {
  const [clientForDeletion, setClientForDeletion] = useState<
    Client | undefined
  >(undefined)
  const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteApiError, setDeleteApiError] = useState<ApiError | undefined>(
    undefined,
  )

  const confirmDeletion = useCallback((client: Client) => {
    setDeleteApiError(undefined)
    setClientForDeletion(client)
    setIsConfirmingDeletion(true)
  }, [])

  const deleteClient = useCallback(
    async (client: Client) => {
      setIsDeleting(true)
      const result = await _deleteClient(org.id, client.id)
      if (result.ok) {
        setDeleteApiError(undefined)
        setIsConfirmingDeletion(false)
        mutateClients(
          {
            clients: ClientsResponse?.clients.filter(
              (c) => c.id !== client.id,
            ) as Client[],
            meta: {
              total: ClientsResponse?.meta.total
                ? ClientsResponse.meta.total - 1
                : 0,
            },
          },
          { revalidate: false },
        )
      } else {
        setDeleteApiError(result.err)
      }
      setIsDeleting(false)
    },
    [org, ClientsResponse, mutateClients],
  )

  return {
    isConfirmingDeletion,
    isDeleting,
    clientForDeletion,
    confirmDeletion,
    cancelDeletion: () => setIsConfirmingDeletion(false),
    deleteClient,
    deleteApiError,
  }
}
