import { useState } from 'react'
import useSWR from 'swr'

import { getJobLogUrl, JobLog, Org } from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

const pollingInterval = 10000 as const

export function useJobLog(org: Org, id: number | null) {
  const [isPolling, setIsPolling] = useState(false)
  const url = id == null ? null : getJobLogUrl(org.id, id)
  const { data } = useSWR<JobLog, Error>(url, fetcher, {
    refreshInterval: isPolling ? pollingInterval : 0,
  })
  return { data, isPolling, setIsPolling }
}
