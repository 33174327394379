import { MemberInvitation } from '@/frontend/api'

export type roleNames = 'マネージャー' | 'スタッフ'

export const defaultValue: MemberInvitation = {
  email: '',
  role: 'accounting_firm_staff',
} as const

export const roleOptions = [
  { value: 'accounting_firm_manager', label: 'マネージャー' },
  { value: 'accounting_firm_staff', label: 'スタッフ' },
] as const

export const roleMap = {
  マネージャー: 'accounting_firm_manager',
  スタッフ: 'accounting_firm_staff',
} as const
