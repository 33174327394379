import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = [
    'label',
    'btn',
    'multiSelectWrapper',
    'multiSelect'
  ]

  static values = {
    maxItemCount: Number
  }

  connect () {
    const maxItemCountValue = this.maxItemCountValue || -1
    const choices = new Choices(this.multiSelectTarget, {
      maxItemCount: maxItemCountValue,
      removeItemButton: true,
      shouldSort: false,
      itemSelectText: '',
      maxItemText: (maxItemCount) => {
        return `${maxItemCount}個まで選択できます`;
      },
    })
    this.initializeLable(choices)
    this.showDropdown(choices) //choice.jsのセレクトボックスがアクティブになって、選択肢が表示されている
    this.hideDropdown() //choice.jsのセレクトボックスからカーソルが外れたとき
    this.closeDropdown()
  }

  openDropdown () {
    this.btnTarget.hidden = true
    this.multiSelectWrapperTarget.hidden = false
    const customEvent = new CustomEvent('multiSelectDropdownOpen')
    document.dispatchEvent(customEvent)
  }

  initializeLable(choices) {
    const multiOptions = choices._currentState.items.map(e => e.label)
    this.updateLabel(multiOptions)
  }

  showDropdown(choices) {
    document.addEventListener('multiSelectDropdownOpen', (_e) => {
      choices.showDropdown()
    })
  }

  hideDropdown() {
    this.multiSelectTarget.addEventListener('hideDropdown',function(e) {
      const multiOptions =  Array.from(e.currentTarget.options).map(option => option.innerHTML)
      this.updateLabel(multiOptions)
      const customEvent = new CustomEvent('multiSelectDropdownClose')
      document.dispatchEvent(customEvent)
    }.bind(this))
  }

  closeDropdown() {
    document.addEventListener('multiSelectDropdownClose', (_e) => {
      this.btnTarget.hidden = false
      this.multiSelectWrapperTarget.hidden = true
    })
  }

  updateLabel(multiOptions) {
    if (multiOptions.length === 0) {
      this.labelTarget.textContent = 'すべて'
    } else {
      this.labelTarget.textContent = multiOptions.join(',')
    }
  }
}
