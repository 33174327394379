import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { BudgetTagCategory, Org } from '@/frontend/api'
import { Breadcrumbs } from '@/frontend/components'
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback'
import { NewNonFinancialItemButton } from './NewNonfinancialItemButton'
import { Table } from './Table'
import { TableSkeleton } from './TableSkeleton'

const breadCrumbItems = [{ label: 'マスタ' }, { label: '非財務項目' }]

type Props = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  creatable: boolean
  editableById: { [id: number]: boolean }
  destroyableById: { [id: number]: boolean }
}

export function NonFinancialItemsPage({
  org,
  primaryBudgetTagCategory,
  creatable,
  editableById,
  destroyableById,
}: Props) {
  return (
    <>
      <div className="py-2 px-8">
        <Breadcrumbs items={breadCrumbItems} />
      </div>
      <div className="py-4 px-8 flex items-center justify-between">
        <h1 className="leading-9 text-xl font-bold text-gray-800">
          非財務項目
        </h1>
        {creatable && (
          <ErrorBoundary fallbackRender={() => null}>
            <Suspense>
              <NewNonFinancialItemButton
                org={org}
                primaryBudgetTagCategory={primaryBudgetTagCategory}
              />
            </Suspense>
          </ErrorBoundary>
        )}
      </div>
      <div className="px-8 mb-4">
        <ErrorBoundary
          fallbackRender={() => (
            <div className="py-8 text-center">
              <ErrorBoundaryFallback />
            </div>
          )}
        >
          <Suspense fallback={<TableSkeleton />}>
            <Table
              org={org}
              primaryBudgetTagCategory={primaryBudgetTagCategory}
              editableById={editableById}
              destroyableById={destroyableById}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    </>
  )
}
