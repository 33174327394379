import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'selectAllCheckbox',
    'bulkCheckboxTd',
    'itemCheckboxTd',
    'caution',
    'submit',
  ]

  connect() {
    this.isIncomplete = false
    this.handleBeforeUnload()
    // 勘定科目権限全部を初期化
    this.selectAllCheckboxTargets.forEach((selectAllCheckbox) => {
      const customizedAccountItemId =
        selectAllCheckbox.dataset.customizedAccountItemId
      this.updateAccountItemCheckBox(selectAllCheckbox)
      // 全選択チェクボックスの初期化
      this.updateBulkCheckbox(customizedAccountItemId, true)
    })
  }

  // すべての勘定科目トグルボタン（selectAll)のスイッチ
  onClickSelectAllCheckbox(e) {
    this.changeIncomplete()
    const selectAllCheckbox = e.target
    this.updateAccountItemCheckBox(selectAllCheckbox)
  }

  updateAccountItemCheckBox(selectAllCheckbox) {
    const customizedAccountItemId =
      selectAllCheckbox.dataset.customizedAccountItemId
    const bulkCheckboxTds = this.bulkCheckboxTds(customizedAccountItemId)
    const itemCheckboxTds = this.itemCheckboxTds(customizedAccountItemId)
    if (selectAllCheckbox.checked) {
      bulkCheckboxTds.forEach((bulkCheckboxTd) => {
        bulkCheckboxTd.querySelector('[data-checkbox-wrapper]').hidden = true
      })
      itemCheckboxTds.forEach((itemCheckboxTd) => {
        itemCheckboxTd.querySelector(
          '[data-genuine-checkbox-wrapper]',
        ).hidden = true
        itemCheckboxTd.querySelector(
          '[data-mock-checkbox-wrapper]',
        ).hidden = false
      })
    } else {
      bulkCheckboxTds.forEach((bulkCheckboxTd) => {
        bulkCheckboxTd.querySelector('[data-checkbox-wrapper]').hidden = false
      })
      itemCheckboxTds.forEach((itemCheckboxTd) => {
        itemCheckboxTd.querySelector(
          '[data-genuine-checkbox-wrapper]',
        ).hidden = false
        itemCheckboxTd.querySelector(
          '[data-mock-checkbox-wrapper]',
        ).hidden = true
      })
    }
  }

  onClickBulkCheckbox(e) {
    this.changeIncomplete()
    const bulkCheckbox = e.target
    const customizedAccountItemId =
      bulkCheckbox.closest('td').dataset.customizedAccountItemId
    const itemCheckboxTds = this.itemCheckboxTds(customizedAccountItemId)
    itemCheckboxTds.forEach((itemCheckboxTd) => {
      itemCheckboxTd.querySelector('input[type="checkbox"]').checked =
        bulkCheckbox.checked
    })
  }

  onClickItemCheckbox(e) {
    const itemCheckbox = e.target
    const customizedAccountItemId =
      itemCheckbox.closest('td').dataset.customizedAccountItemId
    this.updateBulkCheckbox(customizedAccountItemId, false)
  }

  updateBulkCheckbox(customizedAccountItemId, init) {
    if (!init) {
      this.changeIncomplete()
    }
    const itemCheckboxes = this.itemCheckboxes(customizedAccountItemId)
    const bulkCheckboxTds = this.bulkCheckboxTds(customizedAccountItemId)
    if (itemCheckboxes.every((checkbox) => checkbox.checked)) {
      bulkCheckboxTds.forEach((bulkCheckboxTd) => {
        bulkCheckboxTd.querySelector(
          'input[type="checkbox"]',
        ).indeterminate = false
        bulkCheckboxTd.querySelector('input[type="checkbox"]').checked = true
      })
    } else if (itemCheckboxes.every((checkbox) => !checkbox.checked)) {
      bulkCheckboxTds.forEach((bulkCheckboxTd) => {
        bulkCheckboxTd.querySelector(
          'input[type="checkbox"]',
        ).indeterminate = false
        bulkCheckboxTd.querySelector('input[type="checkbox"]').checked = false
      })
    } else {
      bulkCheckboxTds.forEach((bulkCheckboxTd) => {
        bulkCheckboxTd.querySelector(
          'input[type="checkbox"]',
        ).indeterminate = true
      })
    }
  }

  changeIncomplete() {
    this.isIncomplete = true
    this.cautionTarget.classList.remove('hidden')
    this.submitTarget.disabled = false
  }

  bulkCheckboxTds(customizedAccountItemId) {
    return this.bulkCheckboxTdTargets.filter((target) => {
      return target.dataset.customizedAccountItemId === customizedAccountItemId
    })
  }

  itemCheckboxTds(customizedAccountItemId) {
    return this.itemCheckboxTdTargets.filter((target) => {
      return target.dataset.customizedAccountItemId === customizedAccountItemId
    })
  }

  itemCheckboxes(customizedAccountItemId) {
    const itemCheckboxTds = this.itemCheckboxTds(customizedAccountItemId)
    return itemCheckboxTds.map((td) =>
      td.querySelector('input[type="checkbox"]'),
    )
  }

  onSubmit() {
    this.isIncomplete = false
  }

  handleBeforeUnload() {
    const msg =
      'このページを離れますか？行った変更が保存されない可能性があります。'

    document.addEventListener('turbolinks:before-visit', (e) => {
      if (this.isIncomplete) {
        const result = confirm(msg)
        if (result) {
          this.isIncomplete = false
        } else {
          e.preventDefault()
        }
      }
    })

    window.onbeforeunload = (e) => {
      if (this.isIncomplete) {
        e.returnValue = msg // Google Chrome
        return msg // Google Chrome以外
      }
    }
  }
}
