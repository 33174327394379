import { useMemo } from 'react'
import { $generateHtmlFromNodes } from '@lexical/html'
import { createEditor } from 'lexical'

import EditorNodes from '../nodes/EditorNodes'
import EditorTheme from '../themes/EditorTheme'

export function useRender(data: string) {
  const editor = useMemo(
    () =>
      createEditor({
        nodes: [...EditorNodes],
        onError: (error) => {
          throw error
        },
        theme: EditorTheme,
      }),
    [],
  )
  const editorState = useMemo(() => {
    if (isJsonString(data)) {
      return editor.parseEditorState(data)
    }
    return editor.getEditorState()
  }, [editor, data])
  const html = editorState.read(() => $generateHtmlFromNodes(editor))
  return html
}

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
