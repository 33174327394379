import { createErr, createOk, Result } from 'option-t/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { importBugyoICsvSourceAccoutItemUrl } from '@/frontend/api/routes'
import { ApiError, JobLog } from '@/frontend/api/types'
import { handleAxiosError } from '@/frontend/api/utils'
import { axios, z } from '@/frontend/utils'

export const bugyoICsvSourceAccountItemSchema = z.object({
  csvFile: z.custom<FileWithPath>().nullable(),
})

export type BugyoICsvSourceAccountItemPayload = z.infer<
  typeof bugyoICsvSourceAccountItemSchema
>

export async function importBugyoICsvSourceAccountItems(
  orgId: number,
  payload: BugyoICsvSourceAccountItemPayload,
): Promise<Result<JobLog, ApiError>> {
  const url = importBugyoICsvSourceAccoutItemUrl(orgId)
  try {
    const formData = new FormData()
    formData.append('csv_file', payload.csvFile as FileWithPath)

    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })
    return createOk(res.data)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
