import { createErr, createOk, Result } from 'option-t/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { axios, z } from '@/frontend/utils'
import { importYayoiCsvSourceAccoutItemUrl } from '../../routes'
import { ApiError, ApiSuccessWithMessages, JobLog } from '../../types'
import { handleAxiosError } from '../../utils'

export const yayoiCsvSourceAccountItemSchema = z.object({
  csvFile: z.custom<FileWithPath>().nullable(),
})

export type YayoiCsvSourceAccountItemPayload = z.infer<
  typeof yayoiCsvSourceAccountItemSchema
>

export async function importYayoiCsvSourceAccountItems(
  orgId: number,
  payload: YayoiCsvSourceAccountItemPayload,
): Promise<Result<ApiSuccessWithMessages<JobLog>, ApiError>> {
  const url = importYayoiCsvSourceAccoutItemUrl(orgId)
  try {
    const formData = new FormData()
    formData.append('csv_file', payload.csvFile as FileWithPath)

    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })
    const { jobLog, messages } = res.data
    return createOk({ type: 'SuccessWithMessages', data: jobLog, messages })
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
