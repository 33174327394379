document.addEventListener('click', (e) => {
  const target = e.target
  const clicked = target.closest('[data-comment-list-toggle]')
  if (!clicked) {
    return
  }

  const wrapperDom = document.querySelector('[data-comment-list-wrapper]')
  if (!wrapperDom) {
    return
  }
  wrapperDom.classList.toggle('hidden')
})
