import React from 'react'

import { Alert } from '@/frontend/components'
import { useSearchParams } from '@/frontend/hooks/useSearchParams'

export default function ApiLinkageAlert() {
  const authorizedParam = useSearchParams('authorized')
  const isLinkageActive = authorizedParam !== null
  const isLinkageSuccessful = authorizedParam === 'true'

  if (!isLinkageActive) return null

  return isLinkageSuccessful ? (
    <Alert
      variant="info"
      title="連携が完了しました。インポート設定後、インポートボタンを押してください。"
    />
  ) : (
    <Alert
      variant="error"
      title="連携に失敗しました。会計ソフトの設定を確認の上、 再度連携の設定を行ってください。"
    />
  )
}
