/*
 * 以下のようなUIで
 * チェックボックやラジオボタンを押した時に行選択させる
 * |     | プラン | 説明 |
 * | [x] | フリー | ...  |
 * | [ ] | プロ   | ...  |
 *
 * .check-list-ui   : リスト全体を指定するクラス
 * .check-list-line : リストの1行1行。選択時にハイライトさせる単位のクラス
 * .selected-line   : 行選択時のスタイルが適用されているクラス
 */
document.addEventListener('change', (e) => {
  const list = e.target.closest('.check-list-ui')
  if(!list) {
    return
  }
  list.querySelectorAll('.check-list-line').forEach(
    (el) => el.classList.remove('selected-line')
  )
  e.target.closest('.check-list-line').classList.add('selected-line')
})
