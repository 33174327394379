import React from 'react'

import { Org } from '@/frontend/api'

type Props = {
  org: Org
  settingAccessible?: boolean
  memberInvitationAccessible?: boolean
  contractAccessible?: boolean
  chargeAccessible?: boolean
  receiptAccessible?: boolean
}

// NOTE: 新デザインになったら削除してください。
export function Tabs({
  org,
  settingAccessible,
  memberInvitationAccessible,
  contractAccessible,
  chargeAccessible,
  receiptAccessible,
}: Props) {
  return (
    <nav className="-mb-px flex space-x-8">
      <a
        className="whitespace-nowrap py-1 px-4 border-b-2 border-blue-500 font-medium text-xs leading-5 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700"
        href={`/orgs/${org.id}/clients`}
      >
        クライアント一覧
      </a>
      <a
        className="whitespace-nowrap py-1 px-4 border-b-2 border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
        href={`/orgs/${org.id}/members`}
      >
        メンバー
      </a>
      {memberInvitationAccessible && (
        <a
          className="whitespace-nowrap py-1 px-4 border-b-2 border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
          href={`/orgs/${org.id}/member_invitations`}
        >
          招待中のメンバー
        </a>
      )}
      {settingAccessible && (
        <a
          className="whitespace-nowrap py-1 px-4 border-b-2 border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
          href={`/orgs/${org.id}/setting`}
        >
          基本設定
        </a>
      )}
      {contractAccessible && (
        <a
          className="whitespace-nowrap py-1 px-4 border-b-2 border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
          href={`/orgs/${org.id}/contract`}
        >
          契約
        </a>
      )}
      {chargeAccessible && (
        <a
          className="whitespace-nowrap py-1 px-4 border-b-2 border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
          href={`/orgs/${org.id}/charges/credit_card`}
        >
          支払情報の設定
        </a>
      )}
      {receiptAccessible && (
        <a
          className="whitespace-nowrap py-1 px-4 border-b-2 border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
          href={`/orgs/${org.id}/receipts`}
        >
          領収書発行
        </a>
      )}
    </nav>
  )
}
