import React from 'react'
import classNames from 'classnames'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { RiAddLine, RiCloseLine } from 'react-icons/ri'

import { Tooltip } from '@/frontend/components'
import { AccountItem, ReservedItem } from '../types'
import { AccountItemSelect } from './AccountItemSelect'
import { DefinitionsForm } from './formSchema'

export function AccountItemRow({
  index,
  accountingFirmPlan,
  accountItem,
  getReservedItemByKey,
  getAccountItems,
  isAccountItemIdCash,
  isAddable,
  onAdd,
  isRemovable,
  onRemove,
  onNonfundChange,
}: {
  index: number
  accountingFirmPlan: boolean
  accountItem: AccountItem
  getReservedItemByKey: (x: string) => ReservedItem
  getAccountItems: (x: ReservedItem) => AccountItem[]
  isAccountItemIdCash: (x: string | null) => boolean
  isAddable: boolean
  onAdd: () => void
  isRemovable: boolean
  onRemove: (index: number) => void
  onNonfundChange: () => void
}) {
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<DefinitionsForm>()

  const cashingMonthOffset = useWatch({
    control,
    name: `definitions.${accountItem.id}.ratios.${index}.cashingMonthOffset`,
  })
  const nonfund = useWatch({
    control,
    name: `definitions.${accountItem.id}.nonfund`,
  })
  const isCash = useWatch({
    control,
    name: `definitions.${accountItem.id}.ratios.${index}.isCash`,
  })
  const isDisabledRow = nonfund && index !== 0

  const fieldName = (
    index: number,
    name: string,
  ): `definitions.${string}.ratios.${string}.${string}` => {
    return `definitions.${accountItem.id}.ratios.${index}.${name}`
  }

  const ratiosErrors = errors.definitions?.[accountItem.id]?.ratios
  const ratioErrors = ratiosErrors?.[index]
  const cashingMonthOffsetText = (() => {
    const offset = cashingMonthOffset
    if (offset == null) return ''
    if (offset == 0) return '当月'
    if (offset > 0) {
      return `${offset}か月後`
    } else {
      return `${-offset}か月前`
    }
  })()

  const zenkakuToHankaku = (s: string) => {
    return s.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    })
  }

  const toInt = (v: number | string | null) => {
    if (typeof v == 'number') return v
    if (v === '' || v == null) return null

    const s = zenkakuToHankaku(v)
    const n = parseInt(s)
    return isNaN(n) ? null : n
  }

  return (
    <>
      <tr
        className={(() => {
          if (index === 0) {
            return 'border-t border-gray-200'
          }
        })()}
      >
        <td className="p-1 pl-4 truncate" style={{ maxWidth: '0' }}>
          {index === 0 && (
            <>
              {accountItem.name}
              <input
                type="hidden"
                {...register(`definitions.${accountItem.id}.id`)}
              />
              <input
                type="hidden"
                {...register(`definitions.${accountItem.id}.accountItemId`)}
              />
            </>
          )}
          <input
            type="hidden"
            {...register(`definitions.${accountItem.id}.ratios.${index}.id`)}
          />
        </td>
        <td className="p-1">
          {index === 0 && (
            <input
              type="checkbox"
              className="block mx-auto"
              {...register(`definitions.${accountItem.id}.nonfund`, {
                deps: [`definitions.${accountItem.id}.ratios`],
                onChange: onNonfundChange,
              })}
            />
          )}
        </td>
        <td className="p-1">
          <div className="w-5">
            {index === 0 && isAddable && (
              <button
                type="button"
                onClick={onAdd}
                className="inline-flex items-center justify-center p-1 border rounded transition ease-in-out duration-150 focus:outline-none border-gray-300 text-gray-700 bg-white transition ease-in-out hover:text-blue-500 focus:border-blue-300 focus:ring-blue-200 active:text-blue-600 active:bg-gray-50"
              >
                <RiAddLine />
              </button>
            )}
          </div>
        </td>
        {!accountingFirmPlan && (
          <td className="p-1">
            <Tooltip content={ratioErrors?.accountItemId?.message || null}>
              {(ref) => (
                <Controller
                  control={control}
                  name={fieldName(index, `accountItemId`)}
                  rules={{
                    deps: [
                      `definitions.${accountItem.id}.ratios.${index}.cashingMonthOffset`,
                    ],
                  }}
                  render={({ field: { onChange, value, name } }) => (
                    <AccountItemSelect
                      ref={ref}
                      nonfund={nonfund}
                      getReservedItemByKey={getReservedItemByKey}
                      getAccountItems={getAccountItems}
                      onChange={(val) => {
                        onChange(val)
                        const accountItemId = getValues(
                          `definitions.${accountItem.id}.ratios.${index}.accountItemId`,
                        )
                        setValue(
                          `definitions.${accountItem.id}.ratios.${index}.isCash`,
                          isAccountItemIdCash(accountItemId),
                        )
                      }}
                      value={value}
                      name={name}
                      disabled={isDisabledRow}
                      className={classNames(
                        'form-select block w-full pl-2 pr-5 py-1 focus:outline-none text-xs truncate',
                        {
                          'border-gray-300 focus:ring-blue-200 focus:border-blue-300':
                            !ratioErrors?.accountItemId?.message,
                          'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red-200':
                            ratioErrors?.accountItemId?.message,
                        },
                      )}
                    />
                  )}
                />
              )}
            </Tooltip>
          </td>
        )}
        <td className="py-1">
          <div className="flex items-center">
            <Tooltip content={ratioErrors?.cashingMonthOffset?.message || null}>
              {(tooltipRef) => {
                const { ref, ...rest } = register(
                  fieldName(index, `cashingMonthOffset`),
                  {
                    setValueAs: toInt,
                    onBlur: (e) => {
                      e.target.value = toInt(e.target.value)
                    },
                  },
                )
                return (
                  <input
                    {...rest}
                    ref={(x) => {
                      ref(x)
                      tooltipRef(x)
                    }}
                    type="number"
                    min={-12}
                    max={12}
                    disabled={nonfund || isCash || isDisabledRow}
                    className={classNames(
                      'form-input block w-12 pl-2 pr-1 py-1 focus:outline-none text-xs',
                      {
                        'border-gray-300 focus:ring-blue-200 focus:border-blue-300':
                          !ratioErrors?.cashingMonthOffset?.message,
                        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red-200':
                          ratioErrors?.cashingMonthOffset?.message,
                      },
                    )}
                  />
                )
              }}
            </Tooltip>
            <div className="flex-0 ml-1">{cashingMonthOffsetText}</div>
          </div>
        </td>
        <td className="p-1">
          <div className="flex items-center">
            <Tooltip content={ratioErrors?.percentage?.message || null}>
              {(tooltipRef) => {
                const { ref, ...rest } = register(
                  fieldName(index, `percentage`),
                  {
                    setValueAs: toInt,
                    onBlur: (e) => {
                      e.target.value = toInt(e.target.value)
                    },
                    deps: [`definitions.${accountItem.id}.ratios`],
                  },
                )
                return (
                  <input
                    {...rest}
                    ref={(x) => {
                      ref(x)
                      tooltipRef(x)
                    }}
                    type="text"
                    disabled={nonfund || isDisabledRow}
                    className={classNames(
                      'form-input block w-10 px-2 py-1 focus:outline-none text-xs text-right',
                      {
                        'border-gray-300 focus:ring-blue-200 focus:border-blue-300':
                          !ratioErrors?.percentage?.message,
                        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red-200':
                          ratioErrors?.percentage?.message,
                      },
                    )}
                  />
                )
              }}
            </Tooltip>
            <div className="flex-0 ml-1">%</div>
          </div>
        </td>
        <td className="p-1">
          <Tooltip content={ratioErrors?.memo?.message || null}>
            {(tooltipRef) => {
              const { ref, ...rest } = register(fieldName(index, `memo`))
              return (
                <input
                  {...rest}
                  ref={(x) => {
                    ref(x)
                    tooltipRef(x)
                  }}
                  type="text"
                  disabled={isDisabledRow}
                  className={classNames(
                    'form-input block w-full px-2 py-1 focus:outline-none text-xs',
                    {
                      'border-gray-300 focus:ring-blue-200 focus:border-blue-300':
                        !ratioErrors?.memo?.message,
                      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red-200':
                        ratioErrors?.memo?.message,
                    },
                  )}
                />
              )
            }}
          </Tooltip>
        </td>
        <td>
          {/* 最後の一つは消せない */}
          {isRemovable && (
            <button
              type="button"
              onClick={() => onRemove(index)}
              className="mx-auto flex items-center p-0.5 border border-gray-300 rounded text-gray-400 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue-200 active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              <RiCloseLine className="w-3 h-3" />
            </button>
          )}
        </td>
      </tr>
    </>
  )
}
