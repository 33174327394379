import React from 'react'

import { Org, PendingChecker } from '@/frontend/api'
import ImportSettingProvider from '../context/ImportSettingProvider'
import { getImportFormatMap } from '../getImportFormatMap'
import { useImportSetting } from '../hooks/useImportSetting'
import { AccountingSoftware, Budget } from '../types'
import ContentContainer from './ContentContainer'
import DataSyncSection from './DataSyncSection'
import ImportPageTabs from './ImportPageTabs'
import ImportSettingForm from './ImportSettingForm'

export type ImportPageContentProps = {
  org: Org
  budgets: Budget[]
  accountingSoftware: AccountingSoftware
  openingBalanceUrl: string
  pendingChecker: PendingChecker
  children: React.ReactNode
}

export default function ImportPageContent({
  org,
  budgets,
  accountingSoftware,
  openingBalanceUrl,
  pendingChecker,
  children,
}: ImportPageContentProps) {
  const importFormatMap = getImportFormatMap(accountingSoftware.key)
  const { importSetting, onChange } = useImportSetting(
    org,
    accountingSoftware,
    budgets,
    importFormatMap,
  )
  return (
    <ImportSettingProvider importSetting={importSetting}>
      <ImportPageTabs
        org={org}
        accountingSoftware={accountingSoftware}
        openingBalanceUrl={openingBalanceUrl}
        pendingChecker={pendingChecker}
      />

      <ContentContainer>
        <ImportSettingForm
          budgets={budgets}
          importFormatMap={importFormatMap}
          onChange={onChange}
        />

        {children}

        <DataSyncSection
          org={org}
          accountingSoftware={accountingSoftware}
          pendingChecker={pendingChecker}
        />
      </ContentContainer>
    </ImportSettingProvider>
  )
}
