import { MutableRefObject, useEffect } from 'react'

export default function useMutationObserver(
  elements: Array<
    MutableRefObject<HTMLElement | Element> | HTMLElement | Element | null
  >,
  callback: (mutations: MutationRecord[]) => void,
  config: MutationObserverInit = {
    attributes: true,
    childList: true,
    subtree: true,
  },
  rerun?: any,
) {
  useEffect(() => {
    const mutationObserver = new MutationObserver((mutations) => {
      mutationObserver.disconnect()

      callback(mutations)
      for (const elm of elements) {
        if (!elm) continue
        mutationObserver.observe(
          elm instanceof HTMLElement || elm instanceof Element
            ? elm
            : elm.current,
          config,
        )
      }
    })

    for (const elm of elements) {
      if (!elm) continue
      mutationObserver.observe(
        elm instanceof HTMLElement || elm instanceof Element
          ? elm
          : elm.current,
        config,
      )
    }

    return () => mutationObserver.disconnect()
  }, [rerun, config, elements])
}
