import React from 'react'

type Props = {
  children: React.ReactNode
}

export function CardTitle({ children }: Props) {
  return (
    <div className="text-lg font-bold leading-none border-l-[3px] border-l-blue-800 pl-2 text-gray-800 mb-4">
      {children}
    </div>
  )
}
