import React from 'react'
import { nanoid } from 'nanoid'
import { RiErrorWarningLine } from 'react-icons/ri'

type Props = {
  label: string | React.ReactNode
  optional?: boolean
  helpText?: string
  errorMsg?: string
  children: (props: { id: string; invalid: boolean }) => React.ReactNode
}
export function HorizontalInputGroup({
  label,
  optional = false,
  helpText,
  errorMsg,
  children,
}: Props) {
  const id = nanoid()
  const invalid = !!errorMsg

  return (
    <div className="flex items-center space-x-8">
      <div className="flex justify-between">
        <label
          htmlFor={id}
          className="block text-sm font-bold text-gray-700 leading-5"
        >
          {label}
        </label>
        {optional && (
          <span className="text-sm leading-5 text-gray-500">任意</span>
        )}
      </div>
      <div>
        {children({ id, invalid })}
        {helpText && (
          <div className="mt-2 text-xs text-gray-500">{helpText}</div>
        )}
        {errorMsg && (
          <div className="flex items-center mt-2 text-red-500 text-xs">
            <RiErrorWarningLine className="mr-1" />
            <div>{errorMsg}</div>
          </div>
        )}
      </div>
    </div>
  )
}
