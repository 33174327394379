import React from 'react'

import { Org } from '@/frontend/api'
import JobLogLink from '../components/JobLogLink'

export default function TitleWithJobLogLink({
  org,
  children,
}: {
  org: Org
  children: React.ReactNode
}) {
  return (
    <div className="flex justify-between items-center">
      {children}
      <div className="font-normal text-sm">
        <JobLogLink org={org} />
      </div>
    </div>
  )
}
