import Decimal from 'decimal.js'

export function lazyGetter (object, name, getter) {
  const value = getter()
  Object.defineProperty(object, name, { value, writable: false, configurable: true })
  return value
}

// 数値をカンマ区切りの文字列に変換
export function numberWithDelimiter (num) {
  return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

// 文字列を整数値に変換
export function stringToInteger (str) {
  let normalizedStr = str

  // 全角数字を半角数字に変換
  // ハイフンに似た文字列を正しいハイフンに変換
  normalizedStr = Array.prototype.map.call(normalizedStr, (s) => {
    return numericMap[s] || s
  }).join('')

  // カンマを取り除く
  normalizedStr = normalizedStr.replace(/,/g, '')

  return parseInt(normalizedStr)
}

// 文字列を小数値に変換
export function stringToDecimal (str) {
  let normalizedStr = str

  // 全角数字を半角数字に変換
  // ハイフンに似た文字列を正しいハイフンに変換
  normalizedStr = Array.prototype.map.call(normalizedStr, (s) => {
    return numericMap[s] || s
  }).join('')

  // カンマを取り除く
  normalizedStr = normalizedStr.replace(/,/g, '')

  return new Decimal(normalizedStr).toFixed(2)
}

const numericMap = {
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
  'ー':	'-', // E383BC	U+30FC	全角の長音
  '‐':	'-', // E28090	U+2010	別のハイフン
  '‑':	'-', // E28091	U+2011	改行しないハイフン
  '–':	'-', // E28093	U+2013	ENダッシュ
  '—':	'-', // E28094	U+2014	EMダッシュ
  '―':	'-', // E28095	U+2015	全角のダッシュ
  '−':	'-', // E28892	U+2212	全角のマイナス
  'ｰ':	'-' // EFBDB0	U+FF70	半角カナの長音
}
