import { lazyGetter } from '../utils'

export default class ConditionalItem extends EventTarget {
  constructor (element) {
    super()
    this.element = element
  }

  get displayCondition () {
    return lazyGetter(this, 'displayCondition', () => {
      return this.element.getAttribute('data-tree-accordion-when')
    })
  }

  onCollapse () {
    switch (this.displayCondition) {
      case 'collapsed':
        return this.show()
      case 'uncollapsed':
        return this.hide()
    }
  }

  onOpen () {
    switch (this.displayCondition) {
      case 'collapsed':
        return this.hide()
      case 'uncollapsed':
        return this.show()
    }
  }

  hide () {
    this.element.style.display = 'none'
  }

  show () {
    this.element.style.display = ''
  }
}
