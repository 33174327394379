// data: { confirmation_input: input_id, confirmation_value: org.name }
// data-confirmation-input="#INPUT_ID" data-confirmation-value="株式会社ナレッジラボ"

document.addEventListener('input', (e) => {
  const input = e.target
  const targetSelector = input.dataset.activationTarget

  if (targetSelector) {
    const activationValue = input.dataset.activationValue
    const targets = document.querySelectorAll(targetSelector)

    if (input.value === activationValue) {
      targets.forEach((target) => {
        // .disabled クラスを取り除く
        target.classList.remove('disabled')
        target.removeAttribute('tabindex')
      })
    } else {
      targets.forEach((target) => {
        // .disabled クラスを取り除く
        target.classList.add('disabled')
        target.setAttribute('tabindex', -1)
      })
    }
  }
})
