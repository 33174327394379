import React from 'react'
import { tv } from 'tailwind-variants'

type Props = {
  href: string
  active?: boolean
  children: React.ReactNode
}

const tab = tv({
  base: ['whitespace-nowrap', 'py-1', 'px-4', 'border-b-2'].join(' '),
  variants: {
    active: {
      true: 'border-blue-500 font-medium text-xs leading-5 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700',
      false:
        'border-transparent font-medium text-xs leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300',
    },
  },
})

export function LegacyTab({ href, active, children }: Props) {
  return (
    <a className={tab({ active })} href={href}>
      {children}
    </a>
  )
}
