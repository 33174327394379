import consumer from "./consumer"
import { Channel } from "actioncable"

type ResultRow = { [key: string]: [string, number | null][] }
type Errors = { messages: string[] | null }
type Metadata = { request_key: string }
type Data = {
  success: boolean
  errors: Errors
  result: ResultRow
  metadata: Metadata | null
}
type Callback = (data: Data) => void
type Callbacks = Callback[]

export default class UpdateBudgetDetailChannel {
  receivedCallbacks: Callbacks
  subscription: Channel

  constructor ({ orgId = '', budgetId = '', budgetDetailId = '' }){
    this.receivedCallbacks = []

    const self = this

    this.subscription =
      consumer.subscriptions.create({
        channel: "UpdateBudgetDetailChannel",
        org_id: orgId,
        budget_id: budgetId,
        budget_detail_id: budgetDetailId,
      }, {
        connected() { console.log('UpdateBudgetDetailChannel connected') },
        disconnected() {},
        received(data: Data) {
          console.log('UpdateBudgetDetailChannel received:', data)
          self._messageReceived(data)
        },
      })
  }

  subscribeReceived(callback: Callback) {
    this.receivedCallbacks.push(callback)
  }

  _messageReceived(data: Data) {
    this.receivedCallbacks.forEach(callback => {
      callback(data)
    })
  }
}
