import React, { useContext } from 'react'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'

import { dropdownContext } from './dropdownContext'

type Props = {
  children: React.ReactNode
}

export function DropdownIndicatorContainer({ children }: Props) {
  const { isOpen } = useContext(dropdownContext)

  return (
    <div className="inline-flex justify-between items-center w-full">
      <div className="w-full">{children}</div>
      <div className="flex-none text-lg text-gray-800">
        {isOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
      </div>
    </div>
  )
}
