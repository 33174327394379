import Column from './Column'
import Row from './Row'
import { lazyGetter } from '../utils'

export default class Table extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-pl-state')
      return JSON.parse(jsonString)
    })
  }

  get action () {
    return this.data.action
  }

  get budgetTagCategoryId () {
    return this.data.budgetTagCategoryId
  }

  get budgetTagId () {
    return this.data.budgetTagId
  }

  get primaryTagCategoryId () {
    return this.data.primaryTagCategoryId
  }

  get primaryTagId () {
    return this.data.primaryTagId
  }
}
