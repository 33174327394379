import React from 'react'
import { RiSearchLine } from 'react-icons/ri'
import Select, {
  components,
  ControlProps,
  IndicatorsContainerProps,
  InputProps,
  OptionProps,
} from 'react-select'

import { CheckBox } from '@/frontend/components'

export type Option = { label: string; value: number }

function DefaultNoOptionsMessage() {
  return <span className="text-sm">該当なし</span>
}

function OptionWithCheckbox(props: OptionProps<Option>) {
  const clickHandler = () => {
    props.selectOption(props.data)
  }

  return (
    <div
      className="px-4 py-2 cursor-pointer hover:bg-blue-50"
      onClick={clickHandler}
    >
      <CheckBox
        key={Math.random()}
        id={props.data.value.toString()}
        disabled={false}
        checked={props.isSelected}
        readOnly
      >
        <div className="max-w-xs truncate cursor-pointer">
          {props.data.label}
        </div>
      </CheckBox>
    </div>
  )
}

function Input(props: InputProps<Option, true>) {
  // NOTE: なぜか特定のキーだけ検索ボックスに入力するとフォーカスが外れる問題があったため
  //       イベント伝播を停止して対処したよ
  return (
    <components.Input
      {...props}
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
    />
  )
}

function IndicatorsContainer(props: IndicatorsContainerProps<Option, true>) {
  const stopClickPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  return (
    // NOTE: 上位コンポーネントとイベントが競合してしまい、
    //       ドロップダウンが開いた瞬間閉じてしまう問題があったため、イベントの伝搬を停止した。
    <div onClick={stopClickPropagation}>
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

function ControlWithSearchIcon({
  children,
  ...props
}: ControlProps<Option, true>) {
  return (
    <components.Control {...props}>
      <div className="inline-flex items-center">
        <span className="pl-4 text-lg">
          <RiSearchLine className="text-gray-400" />
        </span>
        {children}
      </div>
    </components.Control>
  )
}

export function OptionSelect({
  value,
  name,
  options,
  onChange,
  disabled,
  placeholder,
  noOptionsMessage,
}: {
  name?: string
  value: Array<number> | undefined
  options: Option[]
  onChange: (value: number[] | undefined) => void
  disabled?: boolean
  placeholder?: React.ReactNode
  noOptionsMessage?: () => React.ReactNode
}) {
  const filterOptions = (
    candidate: { label: string; value: string; data: Option },
    input: string,
  ) => {
    if (!input) return true
    return candidate.label.includes(input)
  }
  return (
    <Select
      isMulti
      menuIsOpen={true}
      controlShouldRenderValue={false}
      backspaceRemovesValue={false}
      isClearable={false}
      isDisabled={disabled}
      name={name}
      options={options}
      filterOption={filterOptions}
      noOptionsMessage={noOptionsMessage || DefaultNoOptionsMessage}
      value={options.filter((option) => value?.includes(option.value))}
      placeholder={placeholder}
      classNamePrefix="select"
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={(val) => {
        onChange(val?.map((v: Option) => v.value))
      }}
      components={{
        Control: ControlWithSearchIcon,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: OptionWithCheckbox,
        MultiValueRemove: () => null,
        IndicatorsContainer: IndicatorsContainer,
        Input: Input,
      }}
      classNames={{
        control: () => 'border-b border-color-gray-300 rounded-t-md',
        menu: () => 'relative',
        menuList: () => 'flex flex-col gap-y-1',
        multiValue: () =>
          `${disabled ? 'bg-gray-200' : 'bg-blue-50'} rounded-xl`,
        multiValueLabel: () => 'px-2 py-0.5',
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: undefined,
          borderWidth: undefined,
          borderRadius: undefined,
          borderColor: undefined,
        }),
        menu: (provided) => ({
          ...provided,
          boxShadow: undefined,
          backgroundColor: undefined,
          position: undefined,
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: undefined,
          borderRadius: undefined,
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          padding: undefined,
          paddingLeft: undefined,
        }),
        input: (provided) => ({
          ...provided,
          // NOTE: フォーカス時の枠線を消すよ。
          //       全体的(application.css)に当っているcssが悪さしているからだよ
          '& > input': { ':focus': { boxShadow: '0 0' } },
        }),
      }}
    />
  )
}
