import { useEffect, useState } from 'react'

import { JobLog, Org } from '@/frontend/api'
import { useJobLog } from './useJobLog'

type Callbacks = {
  onStopPolling?: (jobLog: JobLog) => void
}
export function usePollingJobLog(
  org: Org,
  initJobLogId?: number | null,
  callbacks?: Callbacks,
) {
  const [pollingJobLogId, setPollingJobLogId] = useState<number | null>(null)
  const {
    data: jobLog,
    isPolling,
    setIsPolling,
  } = useJobLog(org, pollingJobLogId)

  const shouldPolling = (jobLog: JobLog | null) => {
    const isInProgress = (status: string) => {
      return ['queued', 'in_progress'].includes(status)
    }
    const isExpired = (updatedAt: string) => {
      return new Date(updatedAt) < new Date(Date.now() - 1000 * 60 * 60)
    }
    return jobLog && isInProgress(jobLog.status) && !isExpired(jobLog.updatedAt)
  }

  useEffect(() => {
    if (jobLog && shouldPolling(jobLog)) {
      setIsPolling(true)
    } else {
      setIsPolling(false)
      if (!jobLog) return
      callbacks?.onStopPolling?.(jobLog)
    }
  }, [jobLog, setIsPolling])

  // NOTE: ページ表示時用
  useEffect(() => {
    if (initJobLogId) setPollingJobLogId(initJobLogId)
  }, [])

  // NOTE: JobLogIdがセットされたときにポーリングを開始する
  // Job登録APIからJobLogIdを取得したときなど用
  useEffect(() => {
    if (pollingJobLogId) setIsPolling(true)
  }, [pollingJobLogId])

  return { data: jobLog, isPolling, setIsPolling, setPollingJobLogId }
}
