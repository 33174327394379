import { createErr, createOk, Result } from 'option-t/PlainResult'

import { ApiError, JobLog } from '@/frontend/api/types'
import { handleAxiosError } from '@/frontend/api/utils'
import { axios, z } from '@/frontend/utils'
import { updateJournalsFromSourceUrl } from '../routes'

export const journalsUpdateFromSourceSchema = z.object({
  accountingSoftwareKey: z.string(),
  format: z.string(),
})

export type JournalsUpdateFromSourcePayload = z.infer<
  typeof journalsUpdateFromSourceSchema
>

export async function updateJournalsFromSource(
  orgId: number,
  payload: JournalsUpdateFromSourcePayload,
): Promise<Result<JobLog, ApiError>> {
  const url = updateJournalsFromSourceUrl(orgId)
  try {
    const res = await axios.post(url, payload)
    const jobLog = res.data as JobLog
    return createOk(jobLog)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
