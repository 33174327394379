// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode, useMemo, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import dayjs from 'dayjs'
import { Dictionary, keyBy } from 'lodash'
import {
  MoveHandler,
  NodeApi,
  NodeRendererProps,
  SimpleTree,
  Tree,
} from 'react-arborist'
import { RiArrowDownSLine, RiDeleteBinLine } from 'react-icons/ri'
import { Tooltip, TooltipProvider } from 'react-tooltip'

import { FiscalPeriod } from '@/frontend/features/allocationVersions/types'
import { Tooltip as ComponentTooltip } from '../../../components'
import { OrderParams, updateOrder } from '../api'
import { DeleteModal } from './DeleteModal'
import {
  AllocationPeriod,
  AllocationVersion,
  Data,
  Item,
  SubProps,
} from './type'

type Props = {
  updateOrderUrl: string
  fiscalPeriods: FiscalPeriod[]
  items: Item[]
  subProps: SubProps
  isSortable: boolean
}

const ROW_HEIGHT = 60
const DROPDOWN_HEIGHT_OFFSET = 150

export function IndexList({
  updateOrderUrl,
  fiscalPeriods,
  items,
  subProps,
  isSortable,
}: Props) {
  const initialData = itemsToData(items, subProps)
  const [data, setData] = useState(initialData)
  const tree = useMemo(() => new SimpleTree(data), [data])
  const fiscalPeriodById = keyBy(fiscalPeriods, (x) => x.id)

  const onMove: MoveHandler<Data> = (args: {
    dragIds: string[]
    parentId: null | string
    index: number
    parentNode: NodeApi<Data> | null
  }) => {
    for (const id of args.dragIds) {
      tree.move({ id, parentId: args.parentId, index: args.index })
    }
    setData(tree.data)

    // APIしばき
    const updateParams = dataToApiParams(tree.data)
    updateOrder(updateOrderUrl, {
      orderParams: updateParams,
    })
  }

  const NodeWithSubProps = (props: NodeRendererProps<Data>) => (
    <Node {...props} subProps={subProps} fiscalPeriodById={fiscalPeriodById} />
  )

  return (
    <TooltipProvider>
      <Container>
        <Header />
        <Tree
          data={data}
          onMove={onMove}
          width={'100%'}
          rowHeight={ROW_HEIGHT}
          height={ROW_HEIGHT * items.length + DROPDOWN_HEIGHT_OFFSET}
          disableDrag={!isSortable}
        >
          {NodeWithSubProps}
        </Tree>
      </Container>
      <Tooltip />
    </TooltipProvider>
  )
}

function Container({ children }: { children: ReactNode }) {
  return (
    <div className="align-middle inline-block min-w-full overflow-hidden">
      {children}
    </div>
  )
}

function Header() {
  return (
    <div className="flex px-6 py-3 border-b border-gray-200 bg-gray-50 leading-4 tracking-wider font-medium text-left text-sm text-gray-500">
      <div className="flex-none w-2/12">名称</div>
      <div className="flex-none w-2/12">配賦期間</div>
      <div className="flex-none w-1/12">配賦対象</div>
      <div className="flex-none w-2/12">配賦方法</div>
      <div className="flex-none w-1/12">作成日</div>
      <div className="flex-none w-1/12">コピー元</div>
      <div className="flex-none w-2/12">メモ</div>
    </div>
  )
}

function Node({
  style,
  node,
  dragHandle,
  fiscalPeriodById,
  subProps,
}: NodeRendererProps<Data> & {
  subProps: SubProps
  fiscalPeriodById: Dictionary<FiscalPeriod>
}) {
  const { paddingLeft, ...rowStyle } = style
  const [, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  return (
    <>
      <div style={rowStyle}>
        <div
          style={{ height: ROW_HEIGHT }}
          className={
            'flex px-6 items-center whitespace-nowrap border-b border-gray-200 text-sm leading-5 bg-white text-gray-900 ' +
            (node.willReceiveDrop ? 'bg-gray-100' : '')
          }
          ref={dragHandle}
        >
          <div className="w-full">
            <Row
              node={node}
              onEditModalOpen={() => setEditModalOpen(true)}
              onDeleteModalOpen={() => setDeleteModalOpen(true)}
              style={{ paddingLeft }}
              fiscalPeriodById={fiscalPeriodById}
              subProps={subProps}
            />
          </div>
        </div>
      </div>
      <DeleteModal
        node={node}
        deleteModalOpen={deleteModalOpen}
        onDeleteModalClose={() => setDeleteModalOpen(false)}
      />
    </>
  )
}

function Row({
  node,
  onDeleteModalOpen,
  style,
  fiscalPeriodById,
}: {
  node: NodeApi<Data>
  onEditModalOpen: () => void
  onDeleteModalOpen: () => void
  style: React.CSSProperties
  subProps: SubProps
  fiscalPeriodById: Dictionary<FiscalPeriod>
}) {
  const item = node.data.item
  const handleKeyDownEventPropagationCancel = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
  }
  const handleClickEventPropagationCancel = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const isAnyControllable = (item: AllocationVersion) => {
    return item.isUpdatable || item.isDestroyable
  }
  return (
    <div className="flex">
      <div style={style} className="w-2/12 flex pr-3 leading-9 text-gray-700">
        <a
          className="truncate font-medium text-blue-600 transition ease-in-out duration-150 hover:text-blue-500 focus:outline-none focus:underline"
          href={item.editUrl}
          data-turbolinks="false"
        >
          {item.name}
        </a>
      </div>
      <div className="w-2/12 flex-none leading-9 text-gray-700">
        {allocationPeriodCell(item, fiscalPeriodById)}
      </div>
      <div className="w-1/12 flex-none leading-9 text-gray-700">
        {allocationTargetCell(item)}
      </div>
      <div className="w-2/12 flex-none leading-9 text-gray-700">
        {allocationMethodCell(item)}
      </div>
      <div className="w-1/12 flex-none leading-9 text-gray-700">
        {dayjs(item.createdAt).format('YYYY/MM/DD')}
      </div>
      <div className="w-1/12 flex-none leading-9 text-gray-700">-</div>
      <div
        className="w-2/12 truncate flex-none leading-9 text-gray-700"
        onKeyDown={handleKeyDownEventPropagationCancel}
        onClick={handleClickEventPropagationCancel}
      >
        <span className="truncate">{item.memo || '-'}</span>
      </div>
      <div className="flex-auto">
        <div className="flex justify-end space-x-4">
          {isAnyControllable(item) && (
            <Popover className="relative">
              <div className="btn-wrapper">
                <Popover.Button className="btn btn-sm btn-white">
                  操作
                  <RiArrowDownSLine className="text-gray-500 transition ease-in-out ml-2" />
                </Popover.Button>
              </div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className="absolute z-50 mt-1 right-0"
              >
                <Popover.Panel>
                  <div className="simple-dropdown">
                    <div className="simple-dropdown-content">
                      <div className="py-1">
                        {item.isDestroyable && (
                          <button
                            className="simple-dropdown-link-item"
                            disabled={!item.isDestroyable}
                            onClick={onDeleteModalOpen}
                          >
                            <span className="inline-flex items-center text-sm">
                              <RiDeleteBinLine className="mr-2 text-gray-500" />
                              削除
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          )}
        </div>
      </div>
    </div>
  )
}

function itemsToData(items: Item[], subProps: SubProps) {
  return items.map((item) => itemToData(item, subProps))
}

function itemToData(item: Item, subProps: SubProps): Data {
  return {
    id: String(item.id),
    name: item.name,
    subProps: subProps,
    item,
  }
}

function dataToApiParams(data: readonly Data[]): OrderParams {
  const convert = (item: Item, position: number) => {
    return {
      id: item.id,
      position,
    }
  }

  const params: OrderParams = []
  data.forEach((d, index) => {
    params.push(convert(d.item, index))
  })

  return params
}

function allocationPeriodCell(
  item: Item,
  fiscalPeriodById: Dictionary<FiscalPeriod>,
) {
  const allocationPeriods = item.allocationPeriods
  const firstAllocationPeriod = allocationPeriods[0]
  if (!firstAllocationPeriod) {
    return <>-</>
  }

  const displayPeriod = (allocationPeriod: AllocationPeriod): string => {
    switch (allocationPeriod.periodType) {
      case 'indefinite':
        return '全期間'
      case 'fiscal_period': {
        const fiscalPeriod = fiscalPeriodById[
          allocationPeriod.fiscalPeriodId
        ] as FiscalPeriod
        return toPeriodStr(fiscalPeriod.firstDate, fiscalPeriod.lastDate)
      }
      case 'any':
        return toPeriodStr(
          allocationPeriod.firstYearMonth,
          allocationPeriod.lastYearMonth,
        )
    }
  }

  const toPeriodStr = (firstDate: string, lastDate: string) => {
    return [
      dayjs(firstDate, 'YYYY-MM-DD').format('YYYY/MM'),
      dayjs(lastDate, 'YYYY-MM-DD').format('YYYY/MM'),
    ].join(' - ')
  }

  return (
    <>
      {allocationPeriods.length > 1 ? (
        <ComponentTooltip
          content={tooltipAllocationPeriod(allocationPeriods, displayPeriod)}
        >
          {(ref) => (
            <span ref={ref}>{displayPeriod(firstAllocationPeriod)}...</span>
          )}
        </ComponentTooltip>
      ) : (
        <>{displayPeriod(firstAllocationPeriod)}</>
      )}
    </>
  )
}
function tooltipAllocationPeriod(
  allocationPeriods: AllocationPeriod[],
  displayPeriod: (allocationPeriod: AllocationPeriod) => string,
) {
  return (
    <>
      {allocationPeriods.map((allocationPeriod, i) => {
        return (
          <React.Fragment key={allocationPeriod.id}>
            {displayPeriod(allocationPeriod)}
            {i < allocationPeriods.length - 1 && <br />}
          </React.Fragment>
        )
      })}
    </>
  )
}

const allocationTargets = {
  both: '計画および実績',
  budget: '計画のみ',
  result: '実績のみ',
} as const

function allocationTargetCell(item: Item) {
  const labels = item.allocationPeriods.map((allocationPeriod) => {
    return allocationTargets[allocationPeriod.allocationTarget]
  })

  return (
    <>
      {labels.length > 1 ? (
        <ComponentTooltip content={tooltipAllocationTarget(labels)}>
          {(ref) => <span ref={ref}>{labels[0]}...</span>}
        </ComponentTooltip>
      ) : (
        <>{labels[0]}</>
      )}
    </>
  )
}

function tooltipAllocationTarget(labels: string[]) {
  return (
    <>
      {labels.map((label, i) => {
        return (
          <React.Fragment key={i}>
            {label}
            {i < labels.length - 1 && <br />}
          </React.Fragment>
        )
      })}
    </>
  )
}

const allocationMethods = {
  individually: '各勘定科目に個別配賦',
  in_bulk: '共通費科目に一括配賦',
} as const

function allocationMethodCell(item: Item) {
  const labels = item.allocationPeriods.map((allocationPeriod) => {
    return allocationMethods[allocationPeriod.allocationMethod]
  })

  return (
    <>
      {labels.length > 1 ? (
        <ComponentTooltip content={tooltipAllocationMethod(labels)}>
          {(ref) => <span ref={ref}>{labels[0]}...</span>}
        </ComponentTooltip>
      ) : (
        <>{labels[0]}</>
      )}
    </>
  )
}

function tooltipAllocationMethod(labels: string[]) {
  return (
    <>
      {labels.map((label, i) => {
        return (
          <React.Fragment key={i}>
            {label}
            {i < labels.length - 1 && <br />}
          </React.Fragment>
        )
      })}
    </>
  )
}
