import React, { useEffect, useRef, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { RiArrowUpSLine } from 'react-icons/ri'

import { JobLog, Org, PendingChecker } from '@/frontend/api'
import { GeneralCsvSourceAccountItemPayload } from '@/frontend/api/importer'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { useNavigate } from '@/frontend/hooks/useNavigate'
import { getInitialImportFileStatus } from '../getInitialImportFileStatus'
import useAlertStatus from '../hooks/useAlertStatus'
import { useApiPendingChecker } from '../hooks/useApiPendingChecker'
import useImportFileStatus from '../hooks/useImportFileStatus'
import { usePollingJobLog } from '../hooks/usePollingJobLog'
import useReturnedPendingCheckerEffect from '../hooks/useReturnedPendingCheckerEffect'
import { JobLogWithAttachedFile } from '../types'
import AccountItemsMappingMessageWithLink from './AccountItemsMappingMessageWithLink'
import MasterImportForm from './MasterImportForm'
import { useUploadGeneralCsvSourceAccountItems } from './useUploadGeneralCsvSourceAccountItems'

type Props = {
  org: Org
  sourceAccountItemUrl: string
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  setIsJournalImportable: (isJournalImportable: boolean) => void
  pendingChecker: PendingChecker
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

const STORED_VALUES_STORAGE_KEY = 'GeneralCsvMasterImportSettings' as const

type storeValues = { headerRowNumber?: number; accordionOpen: boolean }

type storedValueStorageType = {
  [id: number]: storeValues
}

function getGeneralAccountItemsImportUrl(id: number, uploadId: string): string {
  return `/orgs/${id}/result/general/csv/account_items_import?token=${uploadId}`
}

export default function MasterImportFormContainer({
  org,
  sourceAccountItemUrl,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  setIsJournalImportable,
  pendingChecker,
  setPendingChecker,
}: Props) {
  const { navigate } = useNavigate()
  const {
    importFileStatus,
    setImportFileStatus,
    updateOnlyStatusForImportFile,
  } = useImportFileStatus(getInitialImportFileStatus(jobLogWithAttachedFile))

  const {
    submit: pendingCheckerSubmit,
    apiError: pendingCheckerApiError,
    returnedPendingChecker,
  } = useApiPendingChecker(org)

  const [storedValues, setStoreValues] =
    useLocalStorage<storedValueStorageType>(STORED_VALUES_STORAGE_KEY)

  const { alertStatus, setAlertStatus } = useAlertStatus(jobLogWithAttachedFile)

  const { submit, isSubmitting, apiError } =
    useUploadGeneralCsvSourceAccountItems(org)

  const onStopPolling = (jobLog: JobLog) => {
    updateOnlyStatusForImportFile(jobLog.status)
    setAlertStatus({
      status: jobLog.status,
      errorDetail: jobLog.errorDetail,
    })
    pendingCheckerSubmit()
  }

  const { data: pollingJobLog, isPolling } = usePollingJobLog(
    org,
    initialInProgressJobLogId,
    { onStopPolling },
  )

  const onFileChange = (file: File) => {
    setImportFileStatus({
      attachedFiles: [{ name: file.name }],
      status: 'in_progress',
    })
    setAlertStatus(null)
  }

  useEffect(() => {
    if (pollingJobLog?.status === 'succeeded') {
      setIsJournalImportable(true)
    }
  }, [pollingJobLog, setIsJournalImportable])

  useReturnedPendingCheckerEffect(returnedPendingChecker, setPendingChecker)

  // NOTE: headless ui から open/close が取れなかったので自前で管理しているよ
  const [accordionOpen, setAccordionOpen] = useState<boolean>(
    storedValues?.[org.id]?.accordionOpen ?? true,
  )
  const handleAccordionClick = () => {
    setStoreValues((prev) => {
      return {
        ...prev,
        [org.id]: {
          headerRowNumber: storedValues?.[org.id]?.headerRowNumber,
          accordionOpen: !accordionOpen,
        },
      }
    })
    setAccordionOpen(!accordionOpen)
  }

  const onSubmit = async (data: GeneralCsvSourceAccountItemPayload) => {
    const uploadId = await submit(data)
    if (uploadId) {
      setStoreValues((prev) => {
        return {
          ...prev,
          [org.id]: {
            headerRowNumber: data.headerRowNumber,
            accordionOpen,
          },
        }
      })

      const url = getGeneralAccountItemsImportUrl(org.id, uploadId)
      navigate(url)
    }
  }

  const isLoading = isPolling || isSubmitting

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const hashSave = window.location.hash
    if (hashSave == '#master-import-form' && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  return (
    <Disclosure defaultOpen={accordionOpen}>
      {({ open }) => (
        <>
          <div ref={ref} className="space-y-4">
            <Disclosure.Button as="div" onClick={handleAccordionClick}>
              <div className="flex items-center justify-between bg-gray-100">
                <SectionTitle>マスタインポート</SectionTitle>
                <RiArrowUpSLine
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } mr-4 h-4 w-4`}
                />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="space-y-4 pb-4">
                <div>会計ソフトから出力した勘定科目データを取り込みます。</div>
                <MasterImportForm
                  importFileStatus={importFileStatus}
                  onFileChange={onFileChange}
                  onSubmit={onSubmit}
                  initialValues={storedValues?.[org.id]}
                  isLoading={isLoading}
                  apiError={apiError || pendingCheckerApiError}
                  alertStatus={alertStatus}
                  jobLogWithAttachedFile={jobLogWithAttachedFile}
                />
              </div>
              <AccountItemsMappingMessageWithLink
                sourceAccountItemUrl={sourceAccountItemUrl}
                pendingChecker={pendingChecker}
              />
            </Disclosure.Panel>
          </div>
        </>
      )}
    </Disclosure>
  )
}
