import UpdateCsvBudgetDetailJobChannel from '@/channels/update_csv_budget_detail_job_channel'
import { Controller } from 'stimulus'

const MSG = 'このページを離れますか？行った変更が保存されない可能性があります。'

export default class extends Controller {
  static values = { orgId: Number, budgetId: Number, budgetDetailId: Number, processingText: String }
  static targets = ['errorMessageContainer', 'errorMessages', 'submitButton']

  connect() {
    this.initSubmitTextValue = this.submitButtonTarget.innerText
    this.isIncomplete = false
    this.channel = new UpdateCsvBudgetDetailJobChannel({
      orgId: this.orgIdValue, budgetId: this.budgetIdValue, budgetDetailId: this.budgetDetailIdValue
    })
    this.channel.onJobStatusChange(this.handleJobComplete.bind(this))
    this._registerFormEvents()
    this._registerPageVisitEvents()
  }

  handleJobComplete(data) {
    this.isIncomplete = false
    if (data.success) {
      this._dataAction(data.action)
    }else{
      // エラーメッセージを出す処理
      this.errorMessageContainerTarget.classList.remove('hidden')
      this.errorMessagesTarget.innerHTML = ''
      data.error.messages.forEach((message) => {
        this.errorMessagesTarget.insertAdjacentHTML('beforeend', `<p>${message}</p>`)
      })
      this._dataAction(data.action)
      this.submitButtonTarget.removeAttribute('disabled')
      this.submitButtonTarget.innerText = this.initSubmitTextValue
    }
  }

  _dataAction(action) {
    if (!action) return

    switch (action.type) {
      case 'redirect':
        window.location.assign(action.redirect_to)
        break
    }
  }

  _registerFormEvents() {
    this.submitButtonTarget.addEventListener('click', () => {
      this.element.requestSubmit()
    })
    this.element.addEventListener('ajax:send', () => {
      this.isIncomplete = true
      this.submitButtonTarget.setAttribute('disabled', true)
      this.submitButtonTarget.innerText = this.processingTextValue
    })
  }

  _registerPageVisitEvents() {
    document.addEventListener('turbolinks:before-visit', this._handleBeforeVisit.bind(this))
    window.addEventListener('beforeunload', this._handleBeforeUnload.bind(this))
  }

  _handleBeforeVisit(e) {
    if (this.isIncomplete) {
      const result = confirm(MSG)
      if (!result) {
        e.preventDefault()
      }
    }
  }

  _handleBeforeUnload(e) {
    if (this.isIncomplete) {
      e.returnValue = MSG
      return MSG
    }
  }
}
