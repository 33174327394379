import React from 'react'
import { tv } from 'tailwind-variants'

type Props = {
  href: string
  active?: boolean
  Icon?: React.ReactNode
  children: React.ReactNode
}

const tab = tv({
  base: [
    'relative',
    'rounded-t-md',
    'bg-gray-100 text-gray-800',
    'inline-flex items-center justify-center',
    'text-sm leading-5',
    'transition ease-in-out duration-150',
    'px-4 py-2',
    'hover:bg-blue-100',
    'focus-visible:outline-offset-0 focus-visible:outline-[3px] focus-visible:outline focus-visible:outline-blue-100',
  ].join(' '),
  variants: {
    active: {
      true: 'bg-blue-800 text-white hover:bg-blue-800',
    },
  },
})

export function Tab({ href, active, Icon, children }: Props) {
  return (
    <a className={tab({ active })} href={href}>
      {children}
      {Icon && <span className="ml-1">{Icon}</span>}
    </a>
  )
}
