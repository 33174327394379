import { useCallback } from 'react'

import {
  NonFinancialItem,
  Org,
  updateNonFinancialItem,
  UpdateNonFinancialItemPayload,
} from '@/frontend/api'
import { useNonFinancialItemsSuspense } from './useNonFinancialItemsSuspense'

export function useUpdateNonFinancialItem(
  org: Org,
  nonFinancialItem: NonFinancialItem,
) {
  const { data: nonFinancialItems, mutate } = useNonFinancialItemsSuspense(org)

  return useCallback(
    async (data: UpdateNonFinancialItemPayload) => {
      const result = await updateNonFinancialItem(
        org.id,
        nonFinancialItem.id,
        data,
      )
      if (result.ok) {
        mutate(
          nonFinancialItems.map((x) =>
            x.id === result.val.id ? result.val : x,
          ),
        )
      }
      return result
    },
    [org, nonFinancialItem, mutate, nonFinancialItems],
  )
}
