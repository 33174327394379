import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { AccountItem, PlItemDefinition, ReservedItem } from '../types'
import { Form } from './Form'

type Props = {
  formAuthenticityToken: string
  formAction: string
  formMethod: string
  open: boolean
  onClose: () => void
  accountingFirmPlan: boolean
  reservedItems: ReservedItem[]
  accountItems: AccountItem[]
  plItemDefinitions: PlItemDefinition[]
}

// TODO: 非財務項目のReact化終わってからコンポーネント分割
export function PlItemDefinitionsSlideOver({ open, ...props }: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => props.onClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="ease-in-out duration-200"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="transform transition pointer-events-auto w-screen max-w-3xl">
                  <Form {...props} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
