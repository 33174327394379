import React, { useState } from 'react'
import classNames from 'classnames'
import { sortBy, uniqBy } from 'lodash'
import {
  RiArrowDownSLine,
  RiErrorWarningLine,
  RiLoaderLine,
} from 'react-icons/ri'

import {
  checkAllNotifications,
  getNotifications,
  Notification,
  ReportSearchQuery,
} from '@/frontend/api'
import { FadeInOut, Spinner } from '@/frontend/components'
import { useNotifications } from './hooks'
import { NotificationListItem } from './NotificationListItem'

type Props = {
  orgId: number
  receivedNotifications: Notification[]
  onCheckAll: () => void
}

export function NotificationList({
  orgId,
  receivedNotifications,
  onCheckAll,
}: Props) {
  const res = useNotifications(orgId)

  return (
    <div
      className="rounded-sm p-4 pb-2 shadow-lg ring-1 ring-black ring-opacity-5 bg-white overflow-y-auto overscroll-contain"
      style={{ maxHeight: 'calc(100vh - 75px)' }}
    >
      <SWRContainer res={res}>
        {(notifications) => (
          <List
            orgId={orgId}
            initialNotifications={notifications}
            receivedNotifications={receivedNotifications}
            onCheckAll={onCheckAll}
          />
        )}
      </SWRContainer>
    </div>
  )
}

function List({
  orgId,
  initialNotifications,
  receivedNotifications,
  onCheckAll,
}: {
  orgId: number
  initialNotifications: Notification[]
  receivedNotifications: Notification[]
  onCheckAll: () => void
}) {
  const [notifications, setNotifications] = useState(initialNotifications)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasMore, setHasMore] = useState(initialNotifications.length == 5)
  const [isCheckingAll, setIsCheckingAll] = useState(false)

  const more = async () => {
    setIsLoadingMore(true)
    const last = notifications[notifications.length - 1]
    if (last == null) {
      setIsLoadingMore(false)
      throw new Error('no notifications')
    }

    const result = await getNotifications(orgId, { limit: 5, cursor: last.id })

    if (result.ok) {
      setHasMore(result.val.meta.hasMore)
      setNotifications((prev) => [...prev, ...result.val.notifications])
    } else {
      alert('通信に失敗しました')
    }
    setIsLoadingMore(false)
  }

  const checkAll = async () => {
    setIsCheckingAll(true)
    const result = await checkAllNotifications(orgId)

    if (result.ok) {
      // 既読にした通知だけを更新する
      // 後からWebsocketで追加されたやつも既読にしてしまわないように注意
      const map = new Map(result.val.map((id) => [id, true]))
      setNotifications(
        notifications.map((n) => (map.has(n.id) ? { ...n, checked: true } : n)),
      )
      onCheckAll()
    } else {
      alert('通信に失敗しました')
    }
    setIsCheckingAll(false)
  }

  const allNotifications = uniqBy(
    sortBy([...receivedNotifications, ...notifications], (n) => -n.id),
    (n) => n.id,
  )

  const getImgSrc = (notification: Notification) => {
    return notification.comment.user.avatarThumbUrl
  }

  const getTitle = (notification: Notification) => {
    const user = notification.comment.user
    const userName = user.name
      ? `${notification.comment.user.name}さん`
      : '削除済みのユーザー'
    return `${userName}があなたにメンションしました。`
  }

  const getHref = (notification: Notification) => {
    const reportSearchQuery = notification.comment.reportSearchQuery
    if (reportSearchQuery) {
      const baseUrl = getBaseUrl(reportSearchQuery)
      const url = new URL(
        baseUrl,
        window.location.protocol + '//' + window.location.host,
      )
      const urlSearchParams = new URLSearchParams({
        token: reportSearchQuery.token,
        initial_opening_comment_id: notification.comment.id.toString(),
      })
      url.search = urlSearchParams.toString()
      return url.toString()
    }
    return '#'
  }

  const getBaseUrl = (reportSearchQuery: ReportSearchQuery) => {
    switch (reportSearchQuery.type) {
      case 'CustomReport':
        return `/orgs/${orgId}/reports/custom_reports/${reportSearchQuery.customReport.id}`
      case 'PreparedReport': {
        switch (reportSearchQuery.preparedReport.reportType) {
          case 'cash_flow':
            return `/orgs/${orgId}/reports/cash_flow`
          case 'comparison_table':
            return `/orgs/${orgId}/reports/comparison_table`
          case 'simulation_table':
            return `/orgs/${orgId}/reports/simulation_table`
        }
      }
    }
  }

  const getReportName = (notification: Notification) => {
    const reportSearchQuery = notification.comment.reportSearchQuery

    if (reportSearchQuery) {
      switch (reportSearchQuery.type) {
        case 'CustomReport':
          return reportSearchQuery.customReport.name
        case 'PreparedReport': {
          return reportSearchQuery.preparedReport.name
        }
      }
    }
    return ''
  }

  return (
    <>
      <div className="text-gray-900 font-bold text-lg">通知</div>
      {allNotifications.length === 0 ? (
        <div className="pb-8 pt-4 text-gray-500 text-center text-sm">
          通知はありません
        </div>
      ) : (
        <>
          <div className="flex justify-end">
            <button
              className="text-sm text-gray-500 hover:text-gray-600 cursor-pointer"
              disabled={isCheckingAll}
              onClick={checkAll}
            >
              {isCheckingAll ? <>送信中...</> : <>すべて既読にする</>}
            </button>
          </div>
          <div className="mt-2">
            <div className="divide-y divide-gray-200 border-y border-gray-200">
              {allNotifications.map((notification, i) => (
                <NotificationListItem
                  key={i}
                  href={getHref(notification)}
                  imgSrc={getImgSrc(notification)}
                  title={getTitle(notification)}
                  subtext={`${getReportName(notification)}`}
                  date={notification.notifiedAt}
                  unchecked={!notification.checked}
                />
              ))}
            </div>
            {hasMore && <MoreButton isLoading={isLoadingMore} onClick={more} />}
          </div>
        </>
      )}
    </>
  )
}

function MoreButton({
  isLoading,
  onClick,
}: {
  isLoading: boolean
  onClick: () => void
}) {
  return (
    <button
      className="relative block w-full text-gray-500 hover:bg-gray-100 hover:text-gray-600 cursor-pointer"
      disabled={isLoading}
      onClick={onClick}
    >
      <div
        className={classNames({
          'flex justify-center items-center space-x-1 py-4 text-sm': true,
          'opacity-0': isLoading,
        })}
      >
        <span>さらに表示</span>
        <RiArrowDownSLine className="text-xl" />
      </div>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <RiLoaderLine className="animate-spin" />
        </div>
      )}
    </button>
  )
}

function SWRContainer({
  res,
  children,
}: {
  res: ReturnType<typeof useNotifications>
  children: (data: Notification[]) => React.ReactNode
}) {
  if (res.isLoading) {
    return <Loading />
  }

  if (res.error) {
    return <ErrorMsg />
  }

  if (res.data == null) {
    throw new Error('data must not be null')
  }

  return <>{children(res.data.notifications)}</>
}

function Loading() {
  return (
    <FadeInOut appear>
      <div className="flex h-64 items-center justify-center">
        <Spinner />
        <span className="sr-only">Loading...</span>
      </div>
    </FadeInOut>
  )
}

function ErrorMsg() {
  return (
    <div className="flex h-64 items-center justify-center">
      <div className="text-red-600">
        <RiErrorWarningLine className="text-red-400 mr-1" />
        データの取得に失敗しました
      </div>
    </div>
  )
}
