import useSWR from 'swr'

import {
  assignMembers as _assignMembers,
  deleteClient as _deleteClient,
  ClientsResponse,
  ClientsSearchParamsPayload,
  getClientsUrl,
  Org,
} from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useClients(
  org: Org,
  searchParams: ClientsSearchParamsPayload = {},
) {
  const url = getClientsUrl(org.id, searchParams)
  return useSWR<ClientsResponse, Error>(url, fetcher)
}
