import { lazyGetter, numberWithDelimiter, stringToInteger } from '../utils'
import Decimal from 'decimal.js'
import tippy from 'tippy.js'

export default class Cell extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
    this.inComposition = false
    this.initializeAmount()
    this.updateTooltip()
    this.addEventListeners()
  }

  initializeAmount () {
    if (this.isDebit) {
      this.debitAmount = this.displayedBalance
      this.creditAmount = 0
    } else {
      this.debitAmount = 0
      this.creditAmount = this.displayedBalance
    }
  }

  updateTooltip () {
    const content = this.tooltipContent()
    if (!content) return

    if (this.element._tippy) {
      this.element._tippy.setContent(content)
    } else {
      tippy(this.element, { theme: 'tooltip', allowHTML: true, content })
    }
  }

  tooltipContent () {
    const contentArr = []

    if (this.formulaHuman) {
      contentArr.push(this.formulaHuman)
    }

    if (isNaN(this.balance)) {
      contentArr.push('ゼロ除算が発生しているため、計算結果を表示できません')
    }

    return contentArr.join("<br />")
  }

  addEventListeners () {
    this.element.addEventListener('compositionstart', this.onCompositionStart.bind(this))
    this.element.addEventListener('compositionend', this.onCompositionEnd.bind(this))
    this.element.addEventListener('input', this.onInput.bind(this))
    this.element.addEventListener('change', this.onChange.bind(this))
  }

  onCompositionStart (e) {
    this.inComposition = true
  }

  onCompositionEnd (e) {
    const changeAmount = this.updateBalance()
    const event = new CustomEvent('input', { detail: { changeAmount } })
    this.dispatchEvent(event)
    this.inComposition = false
  }

  onInput (e) {
    if (this.inComposition) return
    const changeAmount = this.updateBalance()
    const event = new CustomEvent('input', { detail: { changeAmount } })
    this.dispatchEvent(event)
  }

  onChange (e) {
    this.displayAlert(e)
    this.updateHtml()
    this.changeToDirectCell(e)
    const event = new CustomEvent('change')
    this.dispatchEvent(event)
  }

  updateBalance () {
    const val = this.inputElement.value
    const int = stringToInteger(val)
    if (isNaN(int)) return { debit: 0, credit: 0 }


    const changeAmount = this.calcChangeAmount(int)
    this.addAmount(changeAmount)

    return changeAmount
  }

  setBalance (num) {
    if (num == null) {
      this.setNaN()
    } else {
      if (this.isDebit) {
        this.debitAmount = num
        this.creditAmount = 0
      } else {
        this.debitAmount = 0
        this.creditAmount = num
      }
    }
  }

  setAmount ({ debit, credit }) {
    if (debit == null || credit == null) {
      this.setNaN()
    } else {
      this.debitAmount = debit
      this.creditAmount = credit
    }
  }

  setNaN () {
    this.debitAmount = NaN
    this.creditAmount = NaN
  }

  addAmount ({ debit, credit }) {
    this.debitAmount += debit
    this.creditAmount += credit
  }

  calcChangeAmount (val) {
    const changedAmount = val - this.balance

    return {
      debit: this.isDebit ? changedAmount : 0,
      credit: this.isDebit ? 0 : changedAmount
    }
  }

  updateHtml () {
    this.updateTooltip()
    if (this.isVariable) {
      var number = this.rate
    } else {
      var number = this.balance
    }
    const numberForDisplay = numberWithDelimiter(number)

    if (this.isInput) {
      return this.inputElement.value = numberForDisplay
    } else if (this.isLink) {
      return this.linkElement.textContent = numberForDisplay
    } else {
      return this.element.textContent = numberForDisplay
    }
  }

  changeToDirectCell (e) {
    // 数式セルの場合は直接入力セルに変わるため下記のスタイルを外す
    e.target.classList.remove('formula_calculated_input')
    // 数式セルの場合は直接入力セルに変わるため数式表示のツールチップも表示されないようにする
    const tippyInstance = e.target.closest('.input-cell')._tippy;
    if (tippyInstance) {
      tippyInstance.disable();
    }
  }

  displayAlert (e) {
    const formula_calculated_input = e.currentTarget.querySelector('.formula_calculated_input');
    if (formula_calculated_input) {
      alert('数式が設定されていますが、この操作により直接入力となります。数式を再設定する場合は、項目の編集から再設定してください。')
    }
  }

  get balance () {
    return this.isDebit ? this.debitAmount - this.creditAmount : this.creditAmount - this.debitAmount
  }

  get rate () {
    const str = this.isInput ? this.inputElement.value : this.element.textContent
    return new Decimal(str.replace(/,/g, '')).toFixed(1)
  }

  get displayedBalance () {
    const str = this.isInput ? this.inputElement.value : this.element.textContent
    return parseInt(str.replace(/,/g, ''))
  }

  set displayedBalance (val) {
    if (this.isInput) {
      return this.inputElement.value = val
    } else if (this.isLink) {
      return this.linkElement.textContent = val
    } else {
      return this.element.textContent = val
    }
  }

  get isInput () {
    return !!this.inputElement
  }

  get inputElement () {
    return lazyGetter(this, 'inputElement', () => {
      return this.element.querySelector('input')
    })
  }

  get isLink () {
    return !!this.linkElement
  }

  get linkElement () {
    return lazyGetter(this, 'linkElement', () => {
      return this.element.querySelector('a')
    })
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-budget-detail')
      return JSON.parse(jsonString)
    })
  }

  get columnId () {
    return this.data.columnId
  }

  get rowId () {
    return this.data.rowId
  }

  get transactionType () {
    return this.data.transactionType
  }

  get isDebit () {
    return this.transactionType === 'debit'
  }

  get isCredit () {
    return this.transactionType === 'credit'
  }

  get isVariable () {
    return this.data.variable
  }

  get isCostRate() {
    return this.date.costTypeCell
  }

  get isVariableCostAccountItem () {
    return this.data.variableCostAccountItemCell
  }

  get formulaHuman () {
    return this.data.formulaHuman
  }
}
