import * as React from 'react'
import {
  Cell,
  CellTemplate,
  Compatible,
  Span,
  Uncertain,
} from '@silevis/reactgrid'

export interface RenderCell extends Cell, Span {
  type: 'render'
  render?: React.ReactNode
}

export class RenderCellTemplate implements CellTemplate<RenderCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<RenderCell>,
  ): Compatible<RenderCell> {
    const text = ''
    const value = parseFloat(text)
    return { ...uncertainCell, text, value }
  }

  render(cell: Compatible<RenderCell>): React.ReactNode {
    return cell.render || null
  }

  isFocusable = (): boolean => false

  getClassName(cell: Compatible<RenderCell>): string {
    return cell.className ? cell.className : ''
  }
}
