import { Controller } from 'stimulus'

const disableUpdateTextFieldKeys = [
  'same_period_of_last_year',
  'last_period_average',
  'current_period_average',
  'current_period_last_month_value',
]

export default class extends Controller {
  static targets = ['tr', 'bulkSelectBox']

  connect() {
    this._bulkCheckBoxReset()
    this._updateBulkSelectBox()
  }

  // マスタードロップダウン（セレクトボックス）を変更した時
  bulkChange(e) {
    this._updateSelectBox(e)
    this._updateTextField()
  }

  // マスターチェックボックスを変更した時
  bulkCheck(e) {
    this._updateCheckBox(e)
    this._updateBulkSelectBox()
    this._bulkCheckBoxReset()
  }

  // 個別ドロップダウン（セレクトボックス）を変更した時
  change() {
    this._updateTextField()
  }

  // 個別チェックボックスを変更した時
  check() {
    this._updateBulkSelectBox()
    this._bulkCheckBoxReset()
  }

  _updateBulkSelectBox() {
    if (this._isAllUnckecked()) {
      this.bulkSelectBoxTarget.disabled = true
    } else {
      this.bulkSelectBoxTarget.disabled = false
    }
  }

  _updateCheckBox(e) {
    this.trTargets.forEach((tr) => {
      const checkBoxTarget = tr.querySelector(
        '[data-instant-simulator-check-box]',
      )
      if (!checkBoxTarget.disabled) {
        checkBoxTarget.checked = e.currentTarget.checked
      }
    })
  }

  _updateSelectBox(e) {
    this.trTargets.forEach((tr) => {
      const checkBoxTarget = tr.querySelector(
        '[data-instant-simulator-check-box]',
      )
      if (checkBoxTarget.checked) {
        const selectBox = tr.querySelector(
          '[data-instant-simulator-select-box]',
        )
        if (selectBox.disabled) {
          return
        }
        Array.from(selectBox.options).forEach((option) => {
          if (option.value === e.currentTarget.value) {
            option.selected = true
          }
        })
      }
    })
  }

  _updateTextField() {
    this.trTargets.forEach((tr) => {
      const selectBoxTarget = tr.querySelector(
        '[data-instant-simulator-select-box]',
      )
      const textFieldTarget = tr.querySelector(
        '[data-instant-simulator-text-field]',
      )
      if (
        selectBoxTarget.value === '' ||
        disableUpdateTextFieldKeys.includes(selectBoxTarget.value)
      ) {
        textFieldTarget.disabled = true
      } else {
        textFieldTarget.disabled = false
      }
    })
  }

  _isAllUnckecked() {
    return this.trTargets.every(
      (tr) => !tr.querySelector('[data-instant-simulator-check-box]').checked,
    )
  }

  _bulkCheckBoxReset() {
    // マスターチェックボックスを不定にする
    this.bulkSelectBoxTarget.selectedIndex = -1
  }
}
