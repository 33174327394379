import React, { useState } from 'react'

import { NonFinancialItem, Org } from '@/frontend/api'
import { Button } from '@/frontend/components'
import { DeleteModal } from './DeleteModal'

export function TableRowActionDelete({
  org,
  nonFinancialItem,
}: {
  org: Org
  nonFinancialItem: NonFinancialItem
}) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        variant="dangerSecondary"
        type="button"
        onClick={() => setOpen(true)}
      >
        削除
      </Button>
      <DeleteModal
        org={org}
        nonFinancialItem={nonFinancialItem}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
