import React, { useState } from 'react'

import { Org, PendingChecker } from '@/frontend/api'
import { Card as Section } from '@/frontend/components'
import TitleWithJobLogLink from '../components/TitleWithJobLogLink'
import { JobLogWithAttachedFile } from '../types'
import JournalImportFormContainer from './JournalImportFormContainer'
import MasterImportFormContainer from './MasterImportFormContainer'

export type DataSourceSectionProps = {
  org: Org
  lastCsvAccountItemsImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvAccountItemsImportJobId: number | null
  lastCsvJournalImportJobLog: JobLogWithAttachedFile | null
  lastInProgressCsvJournalImportJobId: number | null
  isFirstImportedAccountItems: boolean
  setPendingChecker: (pendingChecker: PendingChecker) => void
}

export default function DataSourceSection({
  org,
  lastCsvAccountItemsImportJobLog,
  lastInProgressCsvAccountItemsImportJobId,
  lastCsvJournalImportJobLog,
  lastInProgressCsvJournalImportJobId,
  isFirstImportedAccountItems,
  setPendingChecker,
}: DataSourceSectionProps) {
  const [isJournalImportable, setIsJournalImportable] = useState(
    isFirstImportedAccountItems,
  )
  return (
    <Section onGray>
      <Section.Title>
        <TitleWithJobLogLink org={org}>データ取得</TitleWithJobLogLink>
      </Section.Title>
      <div className="p-6 border space-y-12 text-sm rounded-md">
        {/* マスタインポート */}
        <MasterImportFormContainer
          org={org}
          jobLogWithAttachedFile={lastCsvAccountItemsImportJobLog}
          initialInProgressJobLogId={lastInProgressCsvAccountItemsImportJobId}
          setIsJournalImportable={setIsJournalImportable}
          setPendingChecker={setPendingChecker}
        />

        {/* 仕訳インポート */}
        <JournalImportFormContainer
          org={org}
          jobLogWithAttachedFile={lastCsvJournalImportJobLog}
          initialInProgressJobLogId={lastInProgressCsvJournalImportJobId}
          isJournalImportable={isJournalImportable}
        />
      </div>
    </Section>
  )
}
