import { useState } from 'react'

import { Org } from '@/frontend/api'
import { ImportFormatMap } from '../getImportFormatMap'
import { AccountingSoftware, Budget, DataFormat, ImportSetting } from '../types'
import useStoredImportSettingValue from './useStoredImportSettingValue'

export const useImportSetting = (
  org: Org,
  accountingSoftware: AccountingSoftware,
  budgets: Budget[],
  importFormatMap: ImportFormatMap,
) => {
  const { storedImportSettingValue, updateStoredImportSettingValue } =
    useStoredImportSettingValue(org.id, accountingSoftware.key)

  const initialImportSetting: ImportSetting = {
    budgetId:
      storedImportSettingValue?.budgetId ||
      (budgets[0]?.id.toString() as string),
    dataFormat:
      storedImportSettingValue?.dataFormat ||
      (Object.keys(importFormatMap)[0] as DataFormat),
    importFormat:
      storedImportSettingValue?.importFormat || accountingSoftware.key,
  }

  const [importSetting, setImportSetting] =
    useState<ImportSetting>(initialImportSetting)

  const onChange = (key: string, value: string | DataFormat) => {
    setImportSetting((prev) => ({ ...prev, [key]: value }))
    updateStoredImportSettingValue(key, value)
  }
  return {
    importSetting,
    onChange,
  }
}
