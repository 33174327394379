import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  COMMAND_PRIORITY_LOW,
  CommandListener,
  KEY_DOWN_COMMAND,
} from 'lexical'

type Props = { onKeyDown: CommandListener<KeyboardEvent> }

export function OnKeyDownPlugin({ onKeyDown }: Props) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      KEY_DOWN_COMMAND,
      onKeyDown,
      COMMAND_PRIORITY_LOW,
    )
  }, [editor, onKeyDown])

  return null
}
