import React from 'react'

import { CommentOverlay, ReportMeta } from '@/frontend/features/customReports'

type Props = {
  userId: number
  memberId: number
  title: string
  orgId: number
  reportMeta: ReportMeta
  initialCommentThreadId: number | null
  initialCommentThreadResolved: boolean | null
  creatableComment: boolean
  commentMentionMembers: [{ id: number; value: string }]
  initialOpening: boolean
}

export default function CellComment(props: Props) {
  return <CommentOverlay {...props} />
}
