import React, { ComponentPropsWithoutRef, forwardRef, useMemo } from 'react'

import { LazyLoadSelect } from '@/frontend/components'
import { AccountItem, ReservedItem } from '../types'

// 流動資産
const reservedItemKeysOfCurrentAssets = [
  'cash_and_deposits', // 現金及び預金
  'trade_receivables', // 売上債権
  'securities', // 有価証券
  'inventories', // 棚卸資産
  'other_current_assets', // その他流動資産
]

// 固定資産
const reservedItemKeysOfFixedAssets = [
  'tangible_fixed_assets', // 有形固定資産
  'intangible_assets', // 無形固定資産
  'investments', // 投資その他の資産
]

// 資産・繰延資産
const reservedItemKeysOfAssets = [
  ...reservedItemKeysOfCurrentAssets,
  ...reservedItemKeysOfFixedAssets,
  'deferred_assets', // 繰延資産
  'sundries', // 雑資産(諸口)
]

// 流動負債
const reservedItemKeysOfCurrentLiabilities = [
  'trade_payables', // 仕入債務
  'other_current_liabilities', // その他流動負債
]

// 固定負債
const reservedItemKeysOfFixedLiabilities = ['fixed_liabilities'] // 固定負債

// 負債
const reservedItemKeysOfLiabilities = [
  ...reservedItemKeysOfCurrentLiabilities,
  ...reservedItemKeysOfFixedLiabilities,
]

// 株主資本
const reservedItemKeysOfCapitalStock = [
  'initial_capital', // 資本金
  'deposit_for_subscriptions_to_shares', // 新株式申込証拠金
  'legal_capital_surpluses', // 資本準備金
  'other_capital_surpluses', // その他資本剰余金
  'legal_retained_earnings', // 利益準備金
  'voluntary_retained_earnings', // 任意積立金
  'retained_earnings_brought_forward', // 繰延利益剰余金
  'treasury_stock', // 自己株式
  'deposit_for_subscriptions_to_treasury_stock', // 自己株式申込証拠金
]

const reservedItemKeysOfNetWorth = [
  ...reservedItemKeysOfCapitalStock,
  'valuation_and_translation_adjustments', // 評価・換算差額等
  'subscription_rights_to_shares', // 株式予約権
]

const reservedItemKeysOfLiabilitiesAndNetWorth = [
  ...reservedItemKeysOfLiabilities,
  ...reservedItemKeysOfNetWorth,
]

const reservedItemKeysOfBalanceSheet = [
  ...reservedItemKeysOfAssets,
  ...reservedItemKeysOfLiabilitiesAndNetWorth,
]

type Props = {
  nonfund: boolean
  getReservedItemByKey: (key: string) => ReservedItem
  getAccountItems: (reservedItem: ReservedItem) => AccountItem[]
}

type ComponentProps = ComponentPropsWithoutRef<'select'> & Props

export const AccountItemSelect = forwardRef<HTMLSelectElement, ComponentProps>(
  function AccountItemSelect(
    { nonfund, getReservedItemByKey, getAccountItems, ...props },
    ref,
  ) {
    // 選択肢生成
    const memorizedOptions = useMemo(
      () =>
        reservedItemKeysOfBalanceSheet
          .map(getReservedItemByKey)
          .filter((r) => !(nonfund && r.key === 'cash_and_deposits')) // 非資金項目の時は現金を選ばせない
          .map((reservedItem) => {
            return {
              label: reservedItem.name,
              options: getAccountItems(reservedItem).map((accountItem) => {
                return {
                  label: accountItem.name,
                  value: accountItem.id,
                  hidden: false,
                }
              }),
            }
          }),
      [getAccountItems, getReservedItemByKey, nonfund],
    )

    return (
      <LazyLoadSelect
        options={memorizedOptions}
        placeholder="選択してください"
        ref={ref}
        {...props}
      />
    )
  },
)
