import React, { forwardRef } from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'

// https://headlessui.com/react/switch#switch
type Props = {
  checked?: boolean
  defaultChecked?: boolean
  onChange?: (value: boolean) => void
  name?: string
  value?: string
}

export const Switch = forwardRef<HTMLButtonElement, Props>(function Switch(
  props,
  ref,
) {
  return (
    <HeadlessSwitch
      ref={ref}
      {...props}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-[3px] focus:text-blue-600 focus:outline focus:outline-blue-100"
    >
      {({ checked }) => (
        <>
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className="pointer-events-none absolute h-full w-full rounded-md bg-white"
          />
          <span
            aria-hidden="true"
            className={[
              checked ? 'bg-blue-600' : 'bg-gray-200',
              'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
            ].join(' ')}
          />
          <span
            aria-hidden="true"
            className={[
              checked ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
            ].join(' ')}
          />
        </>
      )}
    </HeadlessSwitch>
  )
})
