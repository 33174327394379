import React, { useCallback, useEffect, useRef } from 'react'

import { JobLog, Org } from '@/frontend/api'
import { GeneralCsvJournalsPayload } from '@/frontend/api/importer'
import { SectionTitle } from '@/frontend/features/importers/components/SectionTitle'
import useLocalStorage from '@/frontend/hooks/useLocalStorage'
import { useNavigate } from '@/frontend/hooks/useNavigate'
import TagCategoryLink from '../components/TagCategoryLink'
import { getInitialImportFileStatus } from '../getInitialImportFileStatus'
import useAlertStatus from '../hooks/useAlertStatus'
import useImportFileStatus from '../hooks/useImportFileStatus'
import { usePollingJobLog } from '../hooks/usePollingJobLog'
import { JobLogWithAttachedFile } from '../types'
import JournalImportForm from './JournalImportForm'
import { useUploadGeneralCsvJournals } from './useUploadGeneralCsvJournals'

type Props = {
  org: Org
  jobLogWithAttachedFile: JobLogWithAttachedFile | null
  initialInProgressJobLogId: number | null
  isJournalImportable: boolean
}

const STORED_VALUES_STORAGE_KEY = 'GeneralCsvJournalImportSettings' as const

type storedValueStorageType = {
  [id: number]: Pick<GeneralCsvJournalsPayload, 'headerRowNumber'>
}

function getGeneralJournalsImportUrl(id: number, uploadId: string): string {
  return `/orgs/${id}/result/general/csv/journals_import?token=${uploadId}`
}

export default function JournalImportFormContainer({
  org,
  jobLogWithAttachedFile,
  initialInProgressJobLogId,
  isJournalImportable,
}: Props) {
  const { navigate } = useNavigate()
  const {
    importFileStatus,
    setImportFileStatus,
    updateOnlyStatusForImportFile,
  } = useImportFileStatus(getInitialImportFileStatus(jobLogWithAttachedFile))

  const [storedValues] = useLocalStorage<storedValueStorageType>(
    STORED_VALUES_STORAGE_KEY,
  )

  const { alertStatus, setAlertStatus } = useAlertStatus(jobLogWithAttachedFile)

  const { submit, isSubmitting, apiError } = useUploadGeneralCsvJournals(org)

  const onStopPolling = useCallback(
    (jobLog: JobLog) => {
      updateOnlyStatusForImportFile(jobLog.status)
      setAlertStatus({
        status: jobLog.status,
        errorDetail: jobLog.errorDetail,
      })
    },
    [updateOnlyStatusForImportFile, setAlertStatus],
  )

  const { isPolling } = usePollingJobLog(org, initialInProgressJobLogId, {
    onStopPolling,
  })

  const onFileChange = (file: File) => {
    setImportFileStatus({
      attachedFiles: [{ name: file.name }],
      status: 'in_progress',
    })
    setAlertStatus(null)
  }

  const onSubmit = async (data: GeneralCsvJournalsPayload) => {
    const uploadId = await submit(data)
    if (uploadId) {
      const url = getGeneralJournalsImportUrl(org.id, uploadId)
      navigate(url)
    }
  }

  const isLoading = isSubmitting || isPolling

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const hashSave = window.location.hash
    if (hashSave == '#journal-import-form' && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [])

  return (
    <div ref={ref} className="space-y-4">
      <SectionTitle>仕訳インポート</SectionTitle>
      <div>
        <p>
          会計ソフトから出力した仕訳データをインポートし、会計実績データを取り込みます。
        </p>
        <p>
          インポート前に、部門、補助科目、取引先など、必要な
          <TagCategoryLink org={org} />
          してください。
        </p>
      </div>
      <JournalImportForm
        importFileStatus={importFileStatus}
        onFileChange={onFileChange}
        onSubmit={onSubmit}
        isLoading={isLoading}
        apiError={apiError}
        isJournalImportable={isJournalImportable}
        initialValues={storedValues?.[org.id]}
        alertStatus={alertStatus}
        jobLogWithAttachedFile={jobLogWithAttachedFile}
        org={org}
      />
    </div>
  )
}
