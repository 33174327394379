import { BeautifulMentionNode } from 'lexical-beautiful-mentions'

import { MentionItem } from './MentionsPluginProps'
import { StyledExportDomMentionNode } from './StyledExportDomMentionNode'

// Node Override API: https://lexical.dev/docs/concepts/node-replacement
export function refreshBeautifulMentionNode(
  mentions: MentionItem[],
  myMentionIds?: number[],
) {
  const mentionsDict = mentions.reduce(
    (l: Record<number, MentionItem>, v: MentionItem) =>
      Object.assign(l, { [v.id]: v }),
    {},
  )

  const mentionNameReplacer = (
    node: BeautifulMentionNode | StyledExportDomMentionNode,
  ) => {
    const member_id = node.getData()?.id
    const member_name =
      member_id === 'all'
        ? 'すべてのメンバー'
        : (member_id !== true && mentionsDict[Number(member_id)]?.value) ||
          '削除済みのユーザー'
    const isMemberExists = !!(
      member_id !== true && mentionsDict[Number(member_id)]
    )
    const isMemberMe =
      isMemberExists && myMentionIds?.includes(Number(member_id))
    const mentionVariation =
      member_id === 'all'
        ? 'me'
        : isMemberExists
        ? isMemberMe
          ? 'me'
          : undefined
        : 'deleted'

    return new StyledExportDomMentionNode(
      node.getTrigger(),
      member_name,
      node.getData(),
      mentionVariation,
    )
  }

  return [
    StyledExportDomMentionNode,
    {
      replace: BeautifulMentionNode,
      with: mentionNameReplacer,
    },
    {
      replace: StyledExportDomMentionNode,
      with: mentionNameReplacer,
    },
  ]
}
