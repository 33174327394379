import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'

const tokenElement = document.querySelector('meta[name=csrf-token]')
const anAxios = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': tokenElement ? tokenElement.getAttribute('content') : null,
  },
})

export type OrderParams = {
  id: number
  position: number
}[]

export type UpdateOrderParams = {
  orderParams: OrderParams
}
export async function updateOrder(url: string, params: UpdateOrderParams) {
  return anAxios
    .put(url, {
      params: snakeCaseKeys(params),
    })
    .then(null, (error) => {
      if (error.response && error.response.status < 500) {
        alert('不正な値が送信されたため並び順の保存に失敗しました。')
      } else {
        alert(
          '並び順の保存に失敗しました。繰り返し同様の操作をしても改善されない場合、お手数ですがサポートまでお問い合わせください。',
        )
      }
      throw error
    })
}
