import { SetStateAction, useCallback, useRef } from 'react'

type ValueType<T> = T | undefined

type LocalStorageState<T> = [
  ValueType<T>,
  React.Dispatch<React.SetStateAction<ValueType<T>>>,
]

function useLocalStorage<T>(
  key: string,
  initialValue?: T,
): LocalStorageState<T> {
  const getStoredValue = (): ValueType<T> => {
    try {
      const item = localStorage.getItem(key)
      return item ? (JSON.parse(item) as ValueType<T>) : initialValue
    } catch (error) {
      return initialValue
    }
  }

  const storedValue = useRef<ValueType<T>>(getStoredValue())

  const setValue = useCallback(
    (value: SetStateAction<ValueType<T>>): void => {
      try {
        const newValue =
          value instanceof Function ? value(storedValue.current) : value
        localStorage.setItem(key, JSON.stringify(newValue))
        storedValue.current = newValue
      } catch (error) {
        return
      }
    },
    [key],
  )

  return [storedValue.current, setValue]
}

export default useLocalStorage
