import React from 'react'

type Props = {
  placeholder: React.ReactNode
  value: string | undefined
}

export function SingleValueBadge({ value, placeholder }: Props) {
  const valuesPresent = value

  return (
    <div className="grow-1 flex items-center text-gray-700">
      {valuesPresent ? (
        <div key={value}>{value}</div>
      ) : (
        <div className="py-0.5">{placeholder}</div>
      )}
    </div>
  )
}
