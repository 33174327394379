import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['category', 'categoryActiveIcon', 'categoryContent']

  selectCategory (e) {
    const clicked = e.currentTarget
    const categoryId = e.currentTarget.dataset.id
    this.updateActive(categoryId)
  }

  updateActive (categoryId) {
    this.updateCategoryActive(categoryId)
  }


  updateCategoryActive (categoryId) {
    this.categoryTargets.forEach((element) => {
      if (element.dataset.id == categoryId) {
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })

    this.categoryActiveIconTargets.forEach((element) => {
      element.hidden = element.dataset.id != categoryId
    })

    this.categoryContentTargets.forEach((element) => {
      element.hidden = element.dataset.id != categoryId
    })
  }
}
