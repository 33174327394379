import { createErr, createOk, Result } from 'option-t/PlainResult'
import { FileWithPath } from 'react-dropzone'

import { axios, dayjs, z } from '@/frontend/utils'
import { importYayoiCsvJournalUrl } from '../../routes'
import { ApiError, JobLog } from '../../types'
import { handleAxiosError } from '../../utils'

export const yayoiCsvJournalImportSchema = z
  .object({
    budgetId: z.preprocess(
      (v) => (v === '' ? null : Number(v)),
      z.number().nullable(),
    ),
    firstDate: z.string().min(1, '開始日と終了日は入力必須です'),
    lastDate: z.string().min(1, '開始日と終了日は入力必須です'),
    journalCsvFile: z.custom<FileWithPath>().nullable(),
    trialBalanceCsvFile: z.custom<FileWithPath>().nullable(),
  })
  .refine(
    (values) => {
      if (values.firstDate && values.lastDate) {
        return values.firstDate <= values.lastDate
      }
      return true
    },
    {
      message: '開始日が終了日より後になっています',
      path: ['period'],
    },
  )
  .refine(
    (values) => {
      if (values.firstDate && values.lastDate) {
        const firstDate = dayjs(values.firstDate)
        const lastDate = dayjs(values.lastDate)

        const oneYearLater = firstDate.add(1, 'year')
        const oneYearBeforeLast = lastDate.subtract(1, 'year')

        return !(
          oneYearLater.isBefore(lastDate) &&
          firstDate.isBefore(oneYearBeforeLast)
        )
      }
      return true
    },
    {
      message: '期間の終了日は開始日から1年以内の日付を指定してください',
      path: ['period'],
    },
  )

export type YayoiCsvJournalImportPayload = z.infer<
  typeof yayoiCsvJournalImportSchema
>

export async function importYayoiCsvJournal(
  orgId: number,
  payload: YayoiCsvJournalImportPayload,
): Promise<Result<JobLog, ApiError>> {
  const url = importYayoiCsvJournalUrl(orgId)

  try {
    const formData = new FormData()
    formData.append('budget_id', String(payload.budgetId))
    formData.append('first_date', payload.firstDate)
    formData.append('last_date', payload.lastDate)
    formData.append('journal_csv_file', payload.journalCsvFile as FileWithPath)
    if (payload.trialBalanceCsvFile !== null) {
      formData.append(
        'trial_balance_csv_file',
        payload.trialBalanceCsvFile as FileWithPath,
      )
    }

    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      // transformRequestするとmultipartが正しく送られないため空にする
      transformRequest: [],
    })

    const jobLog = res.data as JobLog
    return createOk(jobLog)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
