export default function storedState (initialStates, identifier, storeAdapter = 'sessionStorage') {
  const storeAdpt = (storageAdapters[storeAdapter] || storageFallbackAdapter)(identifier)

  return {
    ...initialStates,
    init() {
      Object.keys(initialStates).forEach((key) => {
        this.$watch(key, (value) => {
          // 値をウォッチしてページ遷移しても状態を持つためにストレージに保存
          storeAdpt.storeValue(key, value) || console.warn('[storedState] Save states failed.')
        })

        const storedValue = storeAdpt.getValue(key)
        this[key] = storedValue === undefined ? initialStates[key] : storedValue
      })
    },
  }
}

const storageFallbackAdapter = (_) => ({
  storeValue(_, __) {
    console.warn('[storedState] storeValue: Storage adapter has been fallbacked.')
    return false
  },
  getValue(_) {
    console.warn('[storedState] getValue: Storage adapter has been fallbacked.')
    return undefined
  }
})

// Web storage interface用
const webStorageImpl = (storageIns) => ((identifier) => {
  const getStoredValues = () => {
      try {
        const raw = storageIns.getItem(identifier)
        return raw === null ? {} : JSON.parse(raw)
      } catch (e) {
        console.log(e);
        return {}
      }
  }

  const storeValues = (value) => {
    try {
      storageIns.setItem(identifier, JSON.stringify(value))
      return true
    } catch (e) {
      console.log(e);
      return false
    }
  }

  return {
    storeValue(key, value) {
      const oldValues = getStoredValues()
      return storeValues({ ...oldValues, ...{[key]: value}})
    },
    getValue(key) {
      const values = getStoredValues()
      return values[key]
    }
  }
})

const storageAdapters = {
  // 削除しない限りきえないやつ
  localStorage: webStorageImpl(localStorage),
  // タブ閉じると消えるやつ
  sessionStorage: webStorageImpl(sessionStorage),
}
