import { Controller } from "stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['choices', 'removingAccountItems', 'removeOrNotSetting', 'radioButton', 'submitButton']

  static values = {
    persistedAccountItems: Array
  }

  connect () {
    const choices = new Choices(this.choicesTarget, {
      removeItemButton: true,
      shouldSort: false,
      itemSelectText: ''
    })
    this.addEventListener(choices)
  }

  addEventListener(choices) {
    this.choicesTarget.addEventListener(
      'change',
      () => {
        const selectedAccountItemIds = choices.getValue().map((e) => e.value);
        const removingAccountItems = this.persistedAccountItemsValue.filter(element => !selectedAccountItemIds.includes(String(element.id)) )
        // 削除対象の勘定科目をカンマ区切りで表示する
        this.removingAccountItemsTarget.textContent = removingAccountItems.map((e) => e.name).join(',')
        // 削除しようとしている登録済勘定科目があるかないかで、当該箇所の表示・非表示を切り替えている
        if (removingAccountItems.length === 0) {
          this.removeOrNotSettingTarget.hidden = true
          this.removeOrNotSettingTarget.disabled = true
          this.enableSubmitButton()
        } else {
          this.removeOrNotSettingTarget.hidden = false
          this.removeOrNotSettingTarget.disabled = false
          this.updateSubmitButtonDisabledStatus()
        }
      },
      false,
    );
  }

  onRadioButtonChange() {
    this.updateSubmitButtonDisabledStatus()
  }

  updateSubmitButtonDisabledStatus() {
    if (this.radioButtonTargets.some((radioButton) => radioButton.checked)) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  enableSubmitButton() {
    this.submitButtonTarget.classList.remove('disabled')
    this.submitButtonTarget.removeAttribute('tabindex')
  }

  disableSubmitButton() {
    this.submitButtonTarget.classList.add('disabled')
    this.submitButtonTarget.setAttribute('tabindex', -1)
  }
}
