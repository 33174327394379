import { useMemo } from 'react'
import { $generateHtmlFromNodes } from '@lexical/html'
import { createEditor } from 'lexical'

import { commentEditorNodes } from './CommentEditor'
import { MentionItem, mentionsTheme } from './lexicalPlugins/MentionsPlugin'
import ExampleTheme from './LexicalTheme'

export function useCommentRender(
  data: string,
  mentionItems: MentionItem[],
  myMentionIds: number[],
) {
  const editor = useMemo(
    () =>
      createEditor({
        nodes: commentEditorNodes(mentionItems, myMentionIds),
        onError: (error) => {
          throw error
        },
        theme: {
          ...ExampleTheme,
          beautifulMentions: mentionsTheme,
        },
      }),
    [mentionItems],
  )
  const editorState = useMemo(() => {
    if (isJsonString(data)) {
      return editor.parseEditorState(data)
    }
    return editor.getEditorState()
  }, [editor, data])
  const html = editorState.read(() => $generateHtmlFromNodes(editor))
  return html
}

function isJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
