import { useEffect } from 'react'

import { PendingChecker } from '@/frontend/api'

type SetPendingChecker = (pendingChecker: PendingChecker) => void

function useReturnedPendingCheckerEffect(
  returnedPendingChecker: PendingChecker | null,
  setPendingChecker: SetPendingChecker,
) {
  useEffect(() => {
    if (returnedPendingChecker !== null) {
      setPendingChecker(returnedPendingChecker)
    }
  }, [returnedPendingChecker])
}

export default useReturnedPendingCheckerEffect
