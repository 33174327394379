import { DataFormat } from './types'

export function formattedOpeningBalanceUrl(
  openingBalanceUrl: string,
  dataFormat: DataFormat,
) {
  const search = new URLSearchParams()
  search.append('data_format', dataFormat)
  return openingBalanceUrl + '?' + search.toString()
}
