import axios from 'axios'

document.addEventListener('click', async (e) => {
  const clicked = e.target.closest('[data-remote-replace]')

  if (clicked) {
    const data = JSON.parse(clicked.getAttribute('data-remote-replace'))
    const target = document.getElementById(data.id)

    // いらない子を削除
    target.innerHTML = '<div class="py-8 text-gray-600 text-center">読み込み中...</div>'

    const response = await axios.get(data.url,{
      params: {
        remote: true
      }
    })

    target.innerHTML = response.data
  }
})

