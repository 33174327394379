import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['memberRole', 'accountItemRole', 'accountItemCaution']

  connect() {
    this.memberRoleTarget.addEventListener('change', () => {
      this.onChangeRole()
    })
    this.onChangeRole()
  }

  onChangeRole() {
    const memberRole = this.memberRoleTarget
    const accountRoleInput = this.accountItemRoleTarget
    if (memberRole.value == 'manager') {
      // 全権ユーザーには勘定科目権限をかけられない
      const option = Array.prototype.slice
        .call(accountRoleInput.options)
        .find((e) => e.value === '')
      option.selected = true
      accountRoleInput.disabled = true
      this.accountItemCautionTarget.classList.remove('hidden')
    } else {
      accountRoleInput.disabled = false
      this.accountItemCautionTarget.classList.add('hidden')
    }
  }
}
