import { lazyGetter, numberWithDelimiter, stringToInteger, stringToDecimal } from '../utils'
import tippy from 'tippy.js'

export default class Cell extends EventTarget {
  constructor ({ element }) {
    super()
    this.element = element
    this.inComposition = false
    this.value = this.displayedValue
    this.updateTooltip()
    this.addEventListeners()
  }

  updateTooltip () {
    const content = this.tooltipContent()
    if (!content) return

    if (this.element._tippy) {
      this.element._tippy.setContent(content)
    } else {
      tippy(this.element, { theme: 'tooltip', allowHTML: true, content })
    }
  }

  tooltipContent () {
    const contentArr = []

    if (this.formulaHuman) {
      contentArr.push(this.formulaHuman)
    }
    // NOTE: 合計列'-'の時もゼロ除算ツールチップが出るので修正
    // https://github.com/knowledgelabo/manageboard/pull/7155#issuecomment-1179659174
    if (this.value === undefined) return

    if (isNaN(this.value)) {
      contentArr.push('ゼロ除算が発生しているため、計算結果を表示できません')
    }

    return contentArr.join("<br />")
  }

  addEventListeners () {
    this.element.addEventListener('compositionstart', this.onCompositionStart.bind(this))
    this.element.addEventListener('compositionend', this.onCompositionEnd.bind(this))
    this.element.addEventListener('input', this.onInput.bind(this))
    this.element.addEventListener('change', this.onChange.bind(this))
  }

  onCompositionStart (_e) {
    this.inComposition = true
  }

  onCompositionEnd (_e) {
    this.updateValue()
    const event = new CustomEvent('input')
    this.dispatchEvent(event)
    this.inComposition = false
  }

  onInput (_e) {
    if (this.inComposition) return
    this.updateValue()
    const event = new CustomEvent('input')
    this.dispatchEvent(event)
  }

  onChange (e) {
    this.displayAlert(e)
    this.updateHtml()
    this.changeToDirectCell(e)
    const event = new CustomEvent('change')
    this.dispatchEvent(event)
  }

  updateValue () {
    if (!this.validInput()) return
    const strVal = this.inputValue
    const value = this.stringToValue(strVal)

    this.value = value
  }

  applyValue (value) {
    this.value = value == null ? NaN : value
  }

  updateHtml () {
    this.updateTooltip()
    const numberForDisplay = numberWithDelimiter(this.value)

    if (this.isInput) {
      return this.inputElement.value = numberForDisplay
    // NOTE: [PBI-1155]対応した時は合計列を想定してます。
    } else {
      return this.element.textContent = numberForDisplay
    }
  }

  changeToDirectCell (e) {
    // 数式セルの場合は直接入力セルに変わるため下記のスタイルを外す
    e.target.classList.remove('formula_calculated_input')
    // 数式セルの場合は直接入力セルに変わるため数式表示のツールチップも表示されないようにする
    const tippyInstance = e.target.closest('.input-cell')._tippy;
    if (tippyInstance) {
      tippyInstance.disable();
    }
  }

  displayAlert (e) {
    const formula_calculated_input = e.currentTarget.querySelector('.formula_calculated_input');
    if (formula_calculated_input) {
      alert('数式が設定されていますが、この操作により直接入力となります。数式を再設定する場合は、項目の編集から再設定してください。')
    }
  }

  stringToValue (string) {
    if (this.numberType === 'integer') {
      return stringToInteger(string)
    } else {
      const str = string.replace('%', '')
      return stringToDecimal(str)
    }
  }

  validInput () {
    const strVal = this.inputValue
    if (this.numberType === 'integer') {
      return !isNaN(stringToInteger(strVal))
    } else {
      // 整数、小数の判定。整数のみ全角でも判定。カンマも許容。先頭のマイナスも許容。
      return strVal.match(/^[-]?([1-9１-９][0-9０-９]*|0|([1-9１-９][0-9０-９]{0,2}(,[0-9０-９]{3})*))(\.\d+)?$/)
    }
  }

  get displayedValue () {
    const str = this.isInput ? this.inputElement.value : this.element.textContent
    // stock_flow_typeがundefinedの合計列は'-'で、this.stringToValue(str)で落ちてしまうので回避させた
    if ( this.columnId === 'total' && this.stockFlowType === 'undefined') {
      return
    }
    return this.stringToValue(str)
  }

  get inputValue() {
    return this.inputElement.value
  }

  get isInput () {
    return !!this.inputElement
  }

  get inputElement () {
    return lazyGetter(this, 'inputElement', () => {
      return this.element.querySelector('input')
    })
  }

  get data () {
    return lazyGetter(this, 'data', () => {
      const jsonString = this.element.getAttribute('data-dynamic-budget-detail')
      return JSON.parse(jsonString)
    })
  }

  get columnId () {
    return this.data.columnId
  }

  get rowId () {
    return this.data.rowId
  }

  get numberType () {
    return this.data.numberType
  }

  get formulaHuman () {
    return this.data.formulaHuman
  }

  get stockFlowType () {
    return this.data.stockFlowType
  }

  // 4月~3月の列があった場合3月の時にtrueを返す
  // stockの時の合計列への反映に使ってます
  get isEndCell () {
    return this.data.endCell
  }
}
