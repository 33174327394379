import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  Path,
  PathValue,
  useController,
} from 'react-hook-form'

import { ApiError } from '@/frontend/api'
import { ApiUpdateCompanyFieldValues } from '@/frontend/api/importer'
import { ApiAlert, Button, InputGroup, Modal } from '@/frontend/components'
import { AssignCompaniesSelect } from '../components/AssignCompaniesSelect'

type Company<T> = { id: PathValue<T, Path<T>>; name: string }

type Props<T extends ApiUpdateCompanyFieldValues> = {
  control: Control<T>
  formId: string
  isOpen: boolean
  isLoading: boolean
  apiError?: ApiError
  onClose: () => void
  companies: Company<T>[]
}

type ActionFieldsProps = {
  formId: string
  isLoading: boolean
  onClose: () => void
}

export function SelectCompanyModal<T extends ApiUpdateCompanyFieldValues>({
  formId,
  control,
  isOpen,
  isLoading,
  apiError,
  onClose,
  companies,
}: Props<T>) {
  const {
    formState: { errors },
  } = useController({ name: 'companyId' as Path<T>, control })

  const handleOnCloseModal = () => {
    // NOTE: 送信中は閉じない
    if (!isLoading) {
      onClose()
    }
  }

  const getErrorMsg = (errors: FieldErrors) => {
    if (errors.companyId) {
      return errors.companyId.message as string
    }
    return undefined
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleOnCloseModal}
      scrollable={false}
      title="連携する事業所を選択"
      actions={
        <ActionFields formId={formId} isLoading={isLoading} onClose={onClose} />
      }
    >
      <div className="p-8 pt-8 space-y-6">
        {apiError && <ApiAlert apiError={apiError} />}
        <InputGroup label={'事業所'} errorMsg={getErrorMsg(errors)}>
          {() => (
            <Controller
              control={control}
              name={'companyId' as Path<T>}
              render={({ field: { value, onChange } }) => (
                <AssignCompaniesSelect
                  value={value}
                  companies={companies}
                  onChange={(value) => {
                    onChange(value)
                  }}
                />
              )}
            />
          )}
        </InputGroup>
      </div>
    </Modal>
  )
}

function ActionFields({ isLoading, onClose, formId }: ActionFieldsProps) {
  return (
    <div className="flex justify-end">
      <div className="btn-wrapper">
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          キャンセル
        </Button>
      </div>
      <div className="ml-3">
        <div className="btn-wrapper">
          <Button
            variant="primary"
            type="submit"
            form={formId}
            loading={isLoading}
          >
            保存する
          </Button>
        </div>
      </div>
    </div>
  )
}
