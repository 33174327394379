import useSWR from 'swr'

import { getNonFinancialItemsUrl, NonFinancialItem, Org } from '@/frontend/api'
import { fetcher } from '@/frontend/utils'

export function useNonFinancialItemsSuspense(org: Org) {
  const url = getNonFinancialItemsUrl(org.id)
  const { data, mutate } = useSWR<NonFinancialItem[], Error>(url, fetcher, {
    suspense: true,
  })
  return { data: data || [], mutate }
}
