import React from 'react'

import { Budget, BudgetFolder, IndexList, SubProps } from './components'

type Props = {
  orgId: number
  updateOrderUrl: string
  isSortable: boolean
  items: Item[]
  subProps: SubProps
  isBudgetsShowableById: { [id: number]: boolean }
}

type Item = BudgetFolder | Budget

export default function IndexPage({
  orgId,
  updateOrderUrl,
  items,
  subProps,
  isSortable,
  isBudgetsShowableById,
}: Props) {
  return (
    <IndexList
      orgId={orgId}
      updateOrderUrl={updateOrderUrl}
      isSortable={isSortable}
      items={items}
      subProps={subProps}
      isBudgetsShowableById={isBudgetsShowableById}
    />
  )
}
