import React from 'react'
import { Control, FieldValues, Path, useController } from 'react-hook-form'

import {
  InputCheckBox,
  InputGroup,
  SelectionGroup,
} from '@/frontend/components'

type Category = {
  value: string
  label: string
}

type Props<T extends FieldValues> = {
  control: Control<T>
  disabled?: boolean
  categories: Readonly<Category[]>
  initialValues?: string[]
}

// NOTE: インポート画面かつRHFを使うことを前提として、RHFに依存しています。
export default function TagCategoriesSelect<T extends FieldValues>({
  control,
  disabled,
  categories,
  initialValues,
}: Props<T>) {
  const {
    field: tagCategoriesField,
    fieldState: { error: tagCategoriesError },
  } = useController({ name: 'tagCategories' as Path<T>, control })

  const { name, ref, onChange, onBlur, value } = tagCategoriesField

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value
    let newValue

    if (event.target.checked) {
      newValue = value.includes(selectedValue)
        ? value
        : [...value, selectedValue]
    } else {
      newValue = (value as string[]).filter((val) => val !== selectedValue)
    }

    onChange(newValue)
  }

  return (
    <InputGroup
      disabled={disabled}
      label={'インポートするタグカテゴリ'}
      name="tagCategories"
      errorMsg={tagCategoriesError?.message}
    >
      {({ disabled }) => (
        <SelectionGroup>
          {categories.map((category, index) => (
            <label key={index} className="flex items-center">
              <InputCheckBox
                disabled={disabled}
                name={name}
                onChange={handleCheckboxChange}
                onBlur={onBlur}
                ref={ref}
                value={category.value}
                defaultChecked={initialValues?.includes(category.value)}
              />
              <span className="ml-2 text-gray-900 text-sm">
                {category.label}
              </span>
            </label>
          ))}
        </SelectionGroup>
      )}
    </InputGroup>
  )
}
