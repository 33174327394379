import { Controller } from "stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [
    'trigger',
    'nodeField',
    'leafField',
    'label',
    'nodeLabel',
    'leafLabel',
    'placeholder',
  ]

  static values = {
    optionsId: String,
    node: String,
    leaf: String,
  }

  connect () {
    this.element.addEventListener('change', this._onChange.bind(this))

    this._updateVisibility()
  }

  fire () {
    if (this.tippy) return

    this._bindDropdown()
    this.tippy.show()
  }

  // ドロップダウンのバインド
  _bindDropdown () {
    this.tippy = tippy(this.triggerTarget, {
      content: document.getElementById(this.optionsIdValue).innerHTML,
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      animation: 'perspective',
      duration: 100,
      placement: 'bottom-start',
      maxWidth: 'none',
      offset: [0, 4],
      onMount: this._onMount.bind(this),
    })
  }

  _onMount (instance) {
    const target = instance.popper.querySelector('[data-controller="nested-select--options"]')
    const event = new CustomEvent('mount', {
      detail: {
        element: this.element,
        nodeValue: this.nodeValue,
        leafValue: this.leafValue,
      }
    })
    target.dispatchEvent(event)
  }

  _onChange ({ detail: { nodeValue, leafValue, nodeLabel, leafLabel } }) {
    this._setValue(nodeValue, leafValue, nodeLabel, leafLabel)
    this.tippy.hide()
  }

  _setValue (nodeValue, leafValue, nodeLabel, leafLabel) {
    this._updateLabel(nodeLabel, leafLabel)
    this._setFieldValues(nodeValue, leafValue)

    this.nodeValue = nodeValue
    this.leafValue = leafValue

    this._updateVisibility()
  }

  _setFieldValues (nodeValue, leafValue) {
    this.nodeFieldTarget.value = nodeValue
    this.leafFieldTarget.value = leafValue

    const event = new CustomEvent('change')
    this.nodeFieldTarget.dispatchEvent(event)
    this.leafFieldTarget.dispatchEvent(event)
  }

  _updateLabel (nodeLabel, leafLabel) {
    this.nodeLabelTarget.innerHTML = nodeLabel
    this.leafLabelTarget.innerHTML = leafLabel
  }

  _updateVisibility () {
    if (this.leafValue) {
      this.labelTarget.hidden = false
      this.placeholderTarget.hidden = true
    } else {
      this.labelTarget.hidden = true
      this.placeholderTarget.hidden = false
    }
  }
}
