import React, { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { Org } from '@/frontend/api'
import {
  FreeeApiUpdateCompanyPayload,
  freeeApiUpdateCompanySchema,
} from '@/frontend/api/importer'
import ApiCompanyButton from '../components/ApiCompanyButton'
import { SelectCompanyModal } from '../components/SelectCompanyModal'
import SelectedCompanyLabel from '../components/SelectedCompanyLabel'
import { FreeeCompany } from '../types'
import { useUpdateFreeeCompanyApi } from './useUpdateFreeeCompanyApi'

type Props = {
  org: Org
  freeeCompany: FreeeCompany | undefined
  freeeCompanies: FreeeCompany[]
  onChangeCompany: (company: FreeeCompany | undefined) => void
}

export default function ApiCompanySelector({
  org,
  freeeCompany,
  freeeCompanies,
  onChangeCompany,
}: Props) {
  const [sendingId, setSendingId] = useState<number | null>(
    freeeCompany?.id || null,
  )
  const [isSelectCompanyModalOpen, setIsSelectCompanyModalOpen] =
    useState(false)
  const {
    submit: submitUpdateCompany,
    isSubmitting,
    apiError,
  } = useUpdateFreeeCompanyApi(org)

  const { control, handleSubmit } = useForm<FreeeApiUpdateCompanyPayload>({
    resolver: zodResolver(freeeApiUpdateCompanySchema),
    defaultValues: {
      companyId: freeeCompany?.id,
    },
  })

  useEffect(() => {
    // NOTE: APIが成功したら事業所の変更を反映&モーダルを閉じる
    if (!isSubmitting && !apiError) {
      onChangeCompany(
        freeeCompanies.find((company) => company.id === sendingId),
      )
      setIsSelectCompanyModalOpen(false)
    }
  }, [apiError, isSubmitting])

  const handleOnSubmit = (data: FreeeApiUpdateCompanyPayload) => {
    setSendingId(data.companyId as number)
    submitUpdateCompany(data)
  }

  const formId = 'update-freee-api-company-form'

  return (
    <div>
      <div className="flex items-center space-x-4">
        <ApiCompanyButton
          companySelected={!!freeeCompany}
          onClick={() => {
            setIsSelectCompanyModalOpen(true)
          }}
        />
        <SelectedCompanyLabel
          companySelected={!!freeeCompany}
          companyName={freeeCompany?.name || ''}
        />
      </div>

      <form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
        <SelectCompanyModal
          formId={formId}
          control={control}
          isOpen={isSelectCompanyModalOpen}
          isLoading={isSubmitting}
          apiError={apiError}
          onClose={() => {
            setIsSelectCompanyModalOpen(false)
          }}
          companies={freeeCompanies}
        />
      </form>
    </div>
  )
}
