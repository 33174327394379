import { Controller } from 'stimulus'

// body に mount してね
// data-react-component-lazy-target="trigger" にマウスオーバー時
// data-react-class-lazy を data-react-class に置換して
// Reactのコンポーネントをマウントする
// ハッキーだが一気にマウントするとメモリが枯渇するケースがあったのでその対策
export default class extends Controller {
  static targets = ['trigger']
  static values = {
    name: String,
  }

  connect() {
    document.addEventListener('mouseover', (e) => {
      const triggerElement = e.target.closest(
        '[data-react-component-lazy-target="trigger"]',
      )
      if (triggerElement) {
        triggerElement
          .querySelectorAll('[data-react-class-lazy]')
          .forEach((el) => {
            el.setAttribute(
              'data-react-class',
              el.getAttribute('data-react-class-lazy'),
            )
          })
        if (!triggerElement.dataset.isMounted) {
          window.ReactRailsUJS.mountComponents(triggerElement)
        }
        triggerElement.dataset.isMounted = true
      }
    })
  }
}
