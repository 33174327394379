import { useEffect, useState } from 'react'

function getValue(search: string, param: string) {
  return new URLSearchParams(search).get(param)
}

export function useSearchParams(paramName: string) {
  const location = window.location
  const [value, setValue] = useState(() => getValue(location.search, paramName))

  useEffect(() => {
    setValue(getValue(location.search, paramName))
  }, [])

  return value
}
