import React from 'react'

import { Avatar } from '@/frontend/components'
import { dayjs } from '@/frontend/utils'

type Props = {
  href: string
  imgSrc?: string | null
  title: string
  subtext: string
  date: Date
  unchecked: boolean
}

export function NotificationListItem({
  href,
  imgSrc,
  title,
  subtext,
  date,
  unchecked,
}: Props) {
  return (
    <a
      href={href}
      className="block py-4 px-2 space-y-1 hover:bg-gray-100"
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex justify-between items-center">
        <div className="text-xs font-bold text-gray-900 flex items-center space-x-2">
          <div className="w-8 h-8">
            <Avatar imgSrc={imgSrc} />
          </div>
          <div>{title}</div>
        </div>
        {unchecked && <div className="h-2 w-2 rounded-full bg-red-500" />}
      </div>
      <div className="text-sm text-gray-600">{subtext}</div>
      <div className="text-xs text-gray-500">{dateText(date)}</div>
    </a>
  )
}

function dateText(date: Date) {
  const currentDay = dayjs()
  const day = dayjs(date)

  if (day.add(1, 'month').isBefore(currentDay)) {
    return day.format('YYYY/MM/DD')
  } else {
    return day.fromNow()
  }
}
