import React, { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { NodeApi } from 'react-arborist'

import { Data } from './type'

type Props = {
  node: NodeApi<Data>
  duplidateModalOpen: boolean
  onDuplicateModalClose: () => void
}

export function DuplicateModal({
  node,
  duplidateModalOpen,
  onDuplicateModalClose,
}: Props) {
  const type = node.data.item.type
  const subProps = node.data.subProps
  if (type !== 'CustomReport') {
    throw new Error(`Unexpected: ${type}`)
  }
  const [name, setName] = useState(`${node.data.name}_コピー`)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(() => e.target.value)
  }
  const handleKeyDownEventPropagationCancel = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    e.stopPropagation()
  }
  return (
    <Dialog
      open={duplidateModalOpen}
      onClose={onDuplicateModalClose}
      className="relative z-50"
      // InputText要素内にてアローキーでカーソル移動ができなくなるため
      // キー押下イベント伝播をキャンセル
      onKeyDown={handleKeyDownEventPropagationCancel}
    >
      <div
        className="fixed inset-0 bg-gray-600 opacity-75"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
          <form
            action={node.data.item.duplicationUrl}
            acceptCharset="UTF-8"
            method="post"
          >
            <input
              type="hidden"
              readOnly
              name="authenticity_token"
              value={subProps.formAuthenticityToken}
            />
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  カスタムレポートの複製
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                  更新する項目に新しい値を入力してください。
                </p>
              </div>
              <div>
                <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div className="flex justify-between">
                    <label
                      className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                      htmlFor="custom_report_name"
                    >
                      複製元のカスタムレポート
                    </label>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="mt-1">{node.data.name}</div>
                  </div>
                </div>
                <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div className="flex justify-between">
                    <label
                      className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                      htmlFor="name"
                    >
                      名称
                    </label>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="w-64 text-black">
                      <div className="rounded-md shadow-sm">
                        <input
                          className="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 "
                          onChange={handleChange}
                          required={true}
                          type="text"
                          value={name}
                          name="name"
                          id="custom_report_name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 border-t border-gray-200 pt-5">
              <div className="flex justify-end">
                <div className="btn-wrapper">
                  <button
                    className="btn btn-white"
                    data-modal-close=""
                    type="button"
                    onClick={onDuplicateModalClose}
                  >
                    キャンセル
                  </button>
                </div>
                <div className="ml-3">
                  <div className="btn-wrapper">
                    <input
                      type="submit"
                      name="commit"
                      value="保存"
                      className="btn btn-primary"
                      data-disable-with="保存"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
