import React from 'react'

import { JobLogErrorDetail, JobLogStatus } from '@/frontend/api'
import { JobLogStatusAlert } from './JobLogStatusAlert'

type Props = {
  status: JobLogStatus
  errorDetail?: JobLogErrorDetail
}

export const ImportStatus = ({ status, errorDetail }: Props) => {
  return (
    <JobLogStatusAlert
      status={status}
      errorDetail={errorDetail}
      successMessage="データのインポートが完了しました。"
      failedMessage="データのインポートが失敗しました。"
    />
  )
}
