// simple_tableと言いつつ比較表も面倒見てますのでご注意お願いします。
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'formTag',
    'budgetName',
    'budgetIdsInput',
    'dropdownBudgetItem',
    'periodSubject',
    'periodType',
    'fiscalPeriodIdInput',
    'firstMonthPeriodInput',
    'lastMonthPeriodInput',
    'borderDateInput',
    'borderDateLable',
    'dropdownBorderDateItem',
    'fiscalPeriod',
    'firstMonthPeriod',
    'lastMonthPeriod',
    'tagSelectField',
    'allocationVersionIdInput',
    'allocationVersionName',
    'dropdownAllocationVersionItem',
    'submit',
    'error'
  ]

  static values = {
    monthLimit: Number
  }

  connect() {
    // 期間のlabelを初期化
    this.updatePeriodSubject()
    this._initialErrorMessages()
    this._changeBorderDate()
    this.submitted = false
  }

  // 表示データが変わった時(推移表)
  updateBudget(e){
    this.updateBudgetsParam(e)
    this.toggleTagSelectField(e)
  }

  toggleTagSelectField(e){
    const wholeCompanyBudgets = this.budgetNameTargets.filter((el) => el.dataset.budgetType === 'whole_company')
    if (wholeCompanyBudgets.length > 0){
      this.tagSelectFieldTarget.classList.add('hidden')
      this.tagSelectFieldTarget.disabled = true
    } else {
      this.tagSelectFieldTarget.classList.remove('hidden')
      this.tagSelectFieldTarget.disabled = false
    }
  }

  // 表示データ①~③が変わった時
  updateBudgetsParam(e) {
    const currentBudgets =  JSON.parse(this.budgetIdsInputTarget.getAttribute('value'))
    const idx = e.currentTarget.dataset.idx
    const budgetId = e.currentTarget.dataset.value
    const selectedBudget = {[idx]: {"id": budgetId, type: 'normal'}}
    const updatedBudgets = Object.assign(currentBudgets, selectedBudget)
    this.budgetIdsInputTarget.setAttribute('value', JSON.stringify(updatedBudgets))
    this.updateBudgetsLable(e)
    this.updateActiveBudgets(e)
  }

  // 表示データのラベルを更新
  updateBudgetsLable(e) {
    const idx = e.currentTarget.dataset.idx
    this.budgetNameTargets.forEach((el) => {
      if (el.dataset.idx === idx) {
        el.textContent = e.currentTarget.textContent
        el.dataset.budgetType = e.currentTarget.dataset.budgetType
      }
    })
  }

  // Budgetsって複数になってるけど、表示データ①~③のうち変わるのは1つの表示データだけ(比較表)
  updateActiveBudgets(e) {
    const idx = e.currentTarget.dataset.idx
    const targetBudgetItems = this.dropdownBudgetItemTargets.filter(el => el.dataset.idx == idx)
    targetBudgetItems.forEach((el) => {
      el.classList.remove('active')
    })
    e.currentTarget.classList.add('active')
  }

  // ラジオボタンを切り替えた時
  updatePeriodType(e) {
    // 会計期間
    if (e.currentTarget.value === 'fiscal_period') {
      this.updateFiscalPeriodDisabled(false)
      this.updateMonthPeriodDisabled(true)
    // 年/月
    } else {
      this.updateFiscalPeriodDisabled(true)
      this.updateMonthPeriodDisabled(false)
    }
    this.updatePeriodSubject()
    this._validate()
  }

  // 会計期間のセレクトボックスを切り替えた時
  updateFiscalPeriod(e) {
    const fiscalPeriodId = e.currentTarget.value
    this.fiscalPeriodIdInputTarget.setAttribute('value', fiscalPeriodId)
    this.updatePeriodSubject()
    this._validate()
  }

  // 開始月を変更した時
  updateFirstMonthPeriod(e) {
    const firstMonthPeriod = e.currentTarget.value
    this.firstMonthPeriodInputTarget.setAttribute('value', firstMonthPeriod)
    this.updatePeriodSubject()
    this._validate()
  }

  // 終了月を変更した時
  updateLastMonthPeriod(e) {
    const lastMonthPeriod = e.currentTarget.value
    this.lastMonthPeriodInputTarget.setAttribute('value', lastMonthPeriod)
    this.updatePeriodSubject()
    this._validate()
  }

  // 表示データ②の範囲を変更した時
  updateBorderDate(e) {
    const borderDate = e.currentTarget.dataset.value
    if (borderDate) {
      this.borderDateInputTarget.disabled = false
      this.borderDateInputTarget.setAttribute('value', borderDate)
    } else {
      // なしの時はhiddenのinputをdisabledにする
      this.borderDateInputTarget.disabled = true
    }
    this._updateBorderDateLabel(e)
    this._updateActiveeBorderDate(e)
  }


  updateFiscalPeriodDisabled(bool) {
    this.fiscalPeriodIdInputTarget.disabled = bool
    this.fiscalPeriodTarget.disabled = bool
  }

  updateMonthPeriodDisabled(bool) {
    this.firstMonthPeriodInputTarget.disabled = bool
    this.lastMonthPeriodInputTarget.disabled = bool
    this.firstMonthPeriodTarget.disabled = bool
    this.lastMonthPeriodTarget.disabled = bool
  }

  // 期間のlabelを更新
  updatePeriodSubject() {
    this.periodTypeTargets.forEach(el => {
      if(el.checked) {
        if (el.value === 'fiscal_period') {
          const options = this.fiscalPeriodTarget.options
          const index = this.fiscalPeriodTarget.selectedIndex
          this.periodSubjectTarget.textContent = options[index].text
        } else {
          const firstMonthPeriodValue = this.firstMonthPeriodTarget.value
          const lastMonthPeriodValue = this.lastMonthPeriodTarget.value
          const firstText = (firstMonthPeriodValue != '') ? this._year_month_label(firstMonthPeriodValue) : "選択してください"
          const lastText = (lastMonthPeriodValue != '') ? this._year_month_label(lastMonthPeriodValue) : "選択してください"
          this.periodSubjectTarget.textContent = `${firstText}~${lastText}`
        }
      }
    })
  }

  _updateBorderDateLabel(e) {
    this.borderDateLableTarget.textContent = e.currentTarget.textContent
  }

  _updateActiveeBorderDate(e) {
    this.dropdownBorderDateItemTargets.forEach((el) => {
      el.classList.remove('active')
    })
    e.currentTarget.classList.add('active')
  }

  _year_month_label(yearMonthValue) {
    const date = new Date(yearMonthValue)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return `${year}年${month}月`
  }

  updateAllocationVersionParam(e) {
    const value = e.currentTarget.dataset.value
    if (value) {
      this.allocationVersionIdInputTarget.setAttribute('value', value)
    } else {
      this.allocationVersionIdInputTarget.removeAttribute('value')
    }
    this._updateAllocationVersionLable(e)
    this._updateActiveAllocationVersion(e)
  }

  _updateAllocationVersionLable(e) {
    this.allocationVersionNameTarget.textContent = e.currentTarget.textContent
  }

  _updateActiveAllocationVersion(e) {
    this.dropdownAllocationVersionItemTargets.forEach((el) => {
      el.classList.remove('active')
    })
    e.currentTarget.classList.add('active')
  }

  // 条件の適用
  submitSearching(e) {
    this.submitted = true
    if (!(this._validate())) {
      e.preventDefault()
      return
    }
  }

  // 初期条件の保存
  submitSavingInitialSearchQuery(e) {
    this.submitted = true
    if (!(this._validate())) {
      e.preventDefault()
      return
    }
  }

  _initialErrorMessages() {
    this.errorMessages = []
  }

  _validate() {
    this._initialErrorMessages()
    this._validateMonthPeriod()
    this._validateBorderDate()
    this.errorTarget.innerHTML = this.errorMessages.join(" ")
    const isValidate = this.errorMessages.length == 0

    this.submitTargets.forEach((e) => {
      e.disabled = !isValidate
    })
    return isValidate
  }


  _validateMonthPeriod() {
    if (!this.fiscalPeriodIdInputTarget.disabled) { return }

    const firstValue = this.firstMonthPeriodInputTarget.value
    const lastValue = this.lastMonthPeriodInputTarget.value

    const isAllInputed = firstValue != '' && lastValue != ''
    // NOTE : 初回入力時に必須チェックが走るとうるさいのでsubmit以降だけ走るようにした。
    if (this.submitted && !isAllInputed) {
      this.errorMessages.push('期間を全て入力してください。')
    }

    if (isAllInputed) {
      const firstDate = new Date(firstValue)
      const lastDate = new Date(lastValue)
      const firstMonth = firstDate.getFullYear() * 12 + firstDate.getMonth()
      const lastMonth = lastDate.getFullYear() * 12 + lastDate.getMonth()

      const diffMonth = lastMonth - firstMonth
      if (diffMonth < 0) {
        this.errorMessages.push('期間の日付が逆転しています。')
      }
      if (diffMonth >= this.monthLimitValue) {
        this.errorMessages.push(`期間を${this.monthLimitValue}ヶ月以内で入力してください。`)
      }
    }
  }

  _validateBorderDate() {
    const budgetsInput = document.querySelector('#budgets')
    const budgetParams = JSON.parse(budgetsInput.value)
    const label = ['表示データ①', '表示データ②', '表示データ③']
    for (const key in budgetParams) {
      if ( budgetParams[key].type == 'landing_estimation' && budgetParams[key].border_date === '') {
        this.errorMessages.push(`${label[key]}の入力内容に不備があります。`)
      }
    }
  }

  _changeBorderDate() {
    document.addEventListener('change', (e) => {
      const budgetTypeRadioButton = e.target.closest('[data-custom-report-budget-type]')
      const versionsSelect = e.target.closest('[data-custom-report-version-id]')
      const budgetsSelect = e.target.closest('[data-custom-report-budget-id]')
      const borderDateInput = e.target.closest('[data-custom-report-border-date]')
      if(budgetTypeRadioButton || versionsSelect || budgetsSelect || borderDateInput) {
        this._validate()
      }
    })
  }
}
