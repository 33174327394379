import { useCallback, useMemo } from 'react'

import { AccountItem, PlItemDefinition, ReservedItem } from '../types'

export function useGetReservedItem(reservedItems: ReservedItem[]) {
  const reservedItemMapById = useMemo(() => {
    const map = new Map<number, ReservedItem>()
    reservedItems.forEach((reservedItem) => {
      map.set(reservedItem.id, reservedItem)
    })
    return map
  }, [reservedItems])

  const reservedItemMapByKey = useMemo(() => {
    const map = new Map<string | null, ReservedItem>()
    reservedItems.forEach((reservedItem) => {
      map.set(reservedItem.key, reservedItem)
    })
    return map
  }, [reservedItems])

  const getReservedItemById = useCallback(
    (id: number) => {
      const reservedItem = reservedItemMapById.get(id)
      if (!reservedItem) {
        throw new Error(`ReservedItem not found: key=${id}`)
      }
      return reservedItem
    },
    [reservedItemMapById],
  )

  const getReservedItemByKey = useCallback(
    (key: string) => {
      const reservedItem = reservedItemMapByKey.get(key)
      if (!reservedItem) {
        throw new Error(`ReservedItem not found: key=${key}`)
      }
      return reservedItem
    },
    [reservedItemMapByKey],
  )

  return {
    getReservedItemById,
    getReservedItemByKey,
  }
}

export function useGetAccountItem(accountItems: AccountItem[]) {
  const accountItemMap = useMemo(() => {
    const map = new Map<string, AccountItem>()
    accountItems.forEach((accountItem) => {
      map.set(accountItem.id.toString(), accountItem)
    })
    return map
  }, [accountItems])

  return useCallback(
    (id: string) => {
      return accountItemMap.get(id)
    },
    [accountItemMap],
  )
}

export function useGetAccountItemsByReservedItem(accountItems: AccountItem[]) {
  const accountItemMap = useMemo(() => {
    const map = new Map<string, AccountItem[]>()
    accountItems.forEach((accountItem) => {
      const arr = map.get(accountItem.reservedItemKey) || []
      map.set(accountItem.reservedItemKey, [...arr, accountItem])
    })
    return map
  }, [accountItems])

  return useCallback(
    (reservedItem: ReservedItem) => {
      return reservedItem.key ? accountItemMap.get(reservedItem.key) || [] : []
    },
    [accountItemMap],
  )
}

export function useGetPlItemDefinition(plItemDefinitions: PlItemDefinition[]) {
  const plItemDefinitionMap = useMemo(() => {
    const map = new Map<number, PlItemDefinition>()
    plItemDefinitions.forEach((plItemDefinition) => {
      map.set(plItemDefinition.accountItemId, plItemDefinition)
    })
    return map
  }, [plItemDefinitions])

  return useCallback(
    (accountItem: AccountItem) => {
      const plItemDefinition = plItemDefinitionMap.get(accountItem.id)
      if (!plItemDefinition) {
        throw new Error(
          `勘定科目ID[${accountItem.id}]に対応する設定が見つかりませんでした`,
        )
      }
      return plItemDefinition
    },
    [plItemDefinitionMap],
  )
}
