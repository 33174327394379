import { useCallback } from 'react'

import { Org } from '@/frontend/api'
import {
  createMemberInvitations as _createMemberInvitations,
  MemberInvitationsPayload,
} from '@/frontend/api/memberInvitations/bulkImport'

export function useCreateMemberInvitations(org: Org) {
  const createMemberInvitations = useCallback(
    async (data: MemberInvitationsPayload) => {
      return await _createMemberInvitations(org.id, data)
    },
    [org],
  )

  return {
    createMemberInvitations,
  }
}
