import { AccountingSoftwareKey, DataFormat } from './types'

export function getSourceAccountItemUrl(
  orgId: number,
  accountingSoftwareKey: AccountingSoftwareKey,
  dataFormat: DataFormat,
) {
  const baseUrl = `/orgs/${orgId}/result/${accountingSoftwareKey}`

  const url = {
    mfc: `${baseUrl}/${dataFormat}/source_account_items`,
    mfc_plus: `${baseUrl}/api/source_account_items`,
    freee: `${baseUrl}/api/source_account_items`,
    yayoi: `${baseUrl}/csv/source_account_items`,
    bugyo_i: `${baseUrl}/csv/source_account_items`,
    bugyo_cloud: `${baseUrl}/api/source_account_items`,
    general: `${baseUrl}/csv/source_account_items`,
  }

  if (url[accountingSoftwareKey]) {
    return url[accountingSoftwareKey]
  }

  throw new Error(`Unknown accounting software key: ${accountingSoftwareKey}`)
}
