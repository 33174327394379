import React from 'react'

import { BudgetTagCategory, Org } from '@/frontend/api'
import { NonFinancialItemsPage } from '@/frontend/features/nonFinancialItems'

type Props = {
  org: Org
  primaryBudgetTagCategory: BudgetTagCategory | null
  creatable: boolean
  editableById: { [id: number]: boolean }
  destroyableById: { [id: number]: boolean }
}

export default function IndexPage(props: Props) {
  return <NonFinancialItemsPage {...props} />
}
