import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Popover, Portal } from '@headlessui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { groupBy } from 'lodash'
import { Result } from 'option-t/esm/PlainResult'
import { useForm, useWatch } from 'react-hook-form'
import {
  RiArrowUpSLine,
  RiCloseLine,
  RiErrorWarningLine,
  RiFilterLine,
  RiSearchLine,
} from 'react-icons/ri'
import { usePopper } from 'react-popper'

import {
  ApiError,
  CommentThread,
  SearchCommentPayload,
  searchCommentSchema,
} from '@/frontend/api'
import {
  ApiAlert,
  InputCheckBox,
  Radio,
  Spinner,
  Tooltip,
} from '@/frontend/components'
import { useDebounce } from '@/frontend/hooks/useDebounce'
import { ReportMetaId } from '..'
import { MentionItem } from '.././lexicalPlugins/MentionsPlugin'
import { CommentThreadBox } from './CommentThreadBox'
import { useComments } from './hooks'

const ResolveTypes = [
  { label: 'すべて', value: 'all' },
  { label: '未解決', value: 'unresolved' },
  { label: '解決済み', value: 'resolved' },
] as const

type Props = {
  userId: number
  memberId: number
  orgId: number
  reportMetaId: ReportMetaId
  creatableComment: boolean
  commentMentionMembers: MentionItem[]
}

export function CommentPanel({
  userId,
  memberId,
  orgId,
  reportMetaId,
  creatableComment,
  commentMentionMembers,
}: Props) {
  const suggestionContainerRef = useRef<HTMLDivElement>(null)

  const defaultValues = {
    contentSearchText: '',
    resolveType: 'all' as 'all' | 'resolved' | 'unresolved',
    mensionMemberIds: [],
    senderMemberIds: [],
    currentSearchFilter: true,
  }

  const { register, control, handleSubmit } = useForm<SearchCommentPayload>({
    resolver: zodResolver(searchCommentSchema),
    defaultValues: defaultValues,
  })

  const [searchPayload, setSearchPayload] =
    useState<SearchCommentPayload>(defaultValues)
  const commentsRes = useComments(orgId, reportMetaId, searchPayload)
  const comments = [...(commentsRes.data?.comments || [])].reverse()

  const [apiError, setApiError] = useState<ApiError | null>(null)

  const _onSubmit = async (data: SearchCommentPayload) => {
    setSearchPayload(data)
  }

  const watchControl = useWatch({ control })
  const debouncedWatchControl = useDebounce(watchControl, 300)
  useEffect(() => {
    handleSubmit(_onSubmit)()
  }, [handleSubmit, debouncedWatchControl])

  const onToggleResolve = async (
    apiResult: Result<CommentThread, ApiError> | undefined,
  ) => {
    setApiError(null)

    if (apiResult) {
      if (apiResult.ok) {
        return true
      } else {
        setApiError(apiResult.err)
        return false
      }
    }
  }

  const onUpdate = async () => {
    commentsRes.mutate()
  }

  const onDelete = async () => {
    commentsRes.mutate()
  }

  const onDeleteThread = async () => {
    commentsRes.mutate()
  }

  const onUpdateCommentThread = () => {
    commentsRes.mutate()
  }

  const commentsByCommentThreadId = groupBy(comments, (x) => x.commentThreadId)
  const commentThreads = Object.keys(commentsByCommentThreadId)
    .map((commentThreadId) => {
      const comments = commentsByCommentThreadId[commentThreadId]
      if (!comments) {
        throw new Error()
      }

      const comment = comments[0]
      if (!comment) {
        throw new Error()
      }

      const lastComment = comments[comments.length - 1]
      if (!lastComment) {
        throw new Error()
      }

      const commentThread = comment.commentThread
      return {
        commentThread,
        lastCommentCreatedAt: lastComment.createdAt,
      }
    })
    .sort((a, b) => (a.lastCommentCreatedAt > b.lastCommentCreatedAt ? -1 : 1))
    .map((x) => x.commentThread)

  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles } = usePopper(triggerElement, popperElement, {
    placement: 'right-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  })

  const getCommentsByCommentThreadId = (commentThreadId: number) => {
    const comments = commentsByCommentThreadId[commentThreadId]
    if (!comments) {
      throw new Error()
    }
    return comments
  }

  const myMember = useMemo(
    () => commentMentionMembers.find((e) => e.id === memberId),
    [commentMentionMembers, memberId],
  )

  return (
    <>
      <div className="bg-white border rounded p-4 flex flex-col max-h-[calc(90vh)] w-72 sticky top-14">
        <div>
          {apiError && <ApiAlert apiError={apiError} />}
          <form onSubmit={handleSubmit(_onSubmit)}>
            <div className="flex justify-between">
              <div className="min-w-0">
                <Popover>
                  <Tooltip content="条件で絞り込み">
                    {(ref) => (
                      <Popover.Button
                        ref={(x) => {
                          setTriggerElement(x)
                          ref(x)
                        }}
                      >
                        <RiFilterLine className="text-xl" />
                      </Popover.Button>
                    )}
                  </Tooltip>
                  <Portal>
                    <Popover.Panel
                      ref={setPopperElement}
                      style={styles.popper}
                      className="absolute z-40"
                    >
                      <div className="bg-white border rounded p-4 overflow-y-scroll max-h-screen min-w-[18rem]">
                        <div className="relative">
                          <div className="absolute top-0 right-0">
                            <Popover.Button>
                              <RiArrowUpSLine className="text-xl" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="divide-y">
                          <div className="pt-3">
                            <div className="text-sm">表示条件</div>
                            <div className="pl-3">
                              <div>
                                <label>
                                  <InputCheckBox
                                    {...register('currentSearchFilter')}
                                  />
                                  <span className="ml-2 text-gray-900 text-sm">
                                    現在の表示条件
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 pt-3">
                            <div className="text-sm">ステータス</div>
                            <div className="pl-3">
                              {ResolveTypes.map((resolveType) => (
                                <Radio
                                  id={`${resolveType.value}`}
                                  {...register('resolveType')}
                                  value={resolveType.value}
                                  key={resolveType.value}
                                >
                                  <span className="font-normal">
                                    {resolveType.label}
                                  </span>
                                </Radio>
                              ))}
                            </div>
                          </div>
                          <div className="mt-3 pt-3">
                            <div className="text-sm">
                              発信したユーザー(from)
                            </div>
                            <div className="pl-3">
                              <div>
                                <label>
                                  <InputCheckBox
                                    {...register('senderMemberIds')}
                                    value={memberId}
                                  />
                                  <span className="ml-2 text-gray-900 text-sm">
                                    {myMember?.value || '自分'}
                                  </span>
                                </label>
                              </div>
                              {commentMentionMembers.map(
                                (commentMentionMember) => (
                                  <div key={commentMentionMember.id}>
                                    {commentMentionMember.id === memberId ? (
                                      <></>
                                    ) : (
                                      <label>
                                        <InputCheckBox
                                          {...register('senderMemberIds')}
                                          value={commentMentionMember.id}
                                        />
                                        <span className="ml-2 text-gray-900 text-sm">
                                          {commentMentionMember.value}
                                        </span>
                                      </label>
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                          <div className="mt-3 pt-3">
                            <div className="text-sm">受信したユーザー(to)</div>
                            <div className="pl-3">
                              <div>
                                <label>
                                  <InputCheckBox
                                    {...register('mensionMemberIds')}
                                    value={memberId}
                                  />
                                  <span className="ml-2 text-gray-900 text-sm">
                                    {myMember?.value || '自分'}
                                  </span>
                                </label>
                              </div>
                              {commentMentionMembers.map(
                                (commentMentionMember) => (
                                  <div key={commentMentionMember.id}>
                                    {commentMentionMember.id === memberId ? (
                                      <></>
                                    ) : (
                                      <label>
                                        <InputCheckBox
                                          {...register('mensionMemberIds')}
                                          value={commentMentionMember.id}
                                        />
                                        <span className="ml-2 text-gray-900 text-sm">
                                          {commentMentionMember.value}
                                        </span>
                                      </label>
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Portal>
                </Popover>
              </div>
              <div className="flex justify-center">
                <button className="cursor-pointer" data-comment-list-toggle>
                  <RiCloseLine className="text-xl" />
                </button>
              </div>
            </div>
            <div className="mt-3">
              <div className="relative text-gray-600">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <RiSearchLine className="text-xl" />
                </span>
                <input
                  {...register('contentSearchText')}
                  type="search"
                  className="w-full rounded-md pl-8"
                  placeholder="コメント検索"
                  name="contentSearchText"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                    }
                  }}
                />
              </div>
            </div>
          </form>
        </div>
        {commentsRes.error ? (
          <div className="py-6 flex text-red-600 items-center justify-center text-sm">
            <RiErrorWarningLine className="text-red-400 mr-1" />
            コメントの読み込みに失敗しました
          </div>
        ) : commentsRes.isLoading ? (
          <div className="py-6 flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            {commentThreads.length === 0 ? (
              <div className="py-6 flex items-center justify-center">
                <span className="text-sm text-gray-700">
                  一致するコメントはありません
                </span>
              </div>
            ) : (
              <div className="flex-1 overflow-y-scroll">
                <div className="mt-5">
                  <>
                    {commentThreads.map((commentThread) => (
                      <React.Fragment key={commentThread.id}>
                        <CommentThreadBox
                          userId={userId}
                          memberId={memberId}
                          orgId={orgId}
                          reportMetaId={reportMetaId}
                          commentThread={commentThread}
                          comments={getCommentsByCommentThreadId(
                            commentThread.id,
                          )}
                          onUpdate={onUpdate}
                          onDelete={onDelete}
                          onDeleteThread={onDeleteThread}
                          creatableComment={creatableComment}
                          suggestionContainerRef={suggestionContainerRef}
                          commentMentionMembers={commentMentionMembers}
                          onUpdateCommentThread={onUpdateCommentThread}
                          onToggleResolve={onToggleResolve}
                        />
                      </React.Fragment>
                    ))}
                  </>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div ref={suggestionContainerRef} />
    </>
  )
}
