import React from 'react'
import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  type NodeKey,
  type SerializedLexicalNode,
  type Spread,
} from 'lexical'
import {
  BeautifulMentionNode,
  BeautifulMentionsTheme,
} from 'lexical-beautiful-mentions'

import MentionComponent from './MentionComponent'
import { mentionsTheme } from './mentionsTheme'

export type SerializedStyledExportDomBeautifulMentionNode = Spread<
  {
    trigger: string
    value: string
    data?: { [p: string]: string | boolean | number }
  },
  SerializedLexicalNode
>

const TYPE = 'styledExportDomBeautifulMention'
export class StyledExportDomMentionNode extends BeautifulMentionNode {
  __variationOnDisplay: string | undefined

  constructor(
    trigger: string,
    value: string,
    data?: { [p: string]: string | boolean | number },
    variationOnDisplay?: string,
    key?: NodeKey,
  ) {
    super(trigger, value, data, key)
    this.__variationOnDisplay = variationOnDisplay || undefined
  }

  static getType() {
    return TYPE
  }

  static clone(node: StyledExportDomMentionNode) {
    return new StyledExportDomMentionNode(
      node.__trigger,
      node.__value,
      node.__data,
      node.__variationOnDisplay,
      node.__key,
    )
  }

  static importJSON(
    serializedNode: SerializedStyledExportDomBeautifulMentionNode,
  ) {
    return $createStyledExportDomMentionNode(
      serializedNode.trigger,
      serializedNode.value,
      serializedNode.data,
    )
  }

  getVariationOnDisplay(): string | undefined {
    const self = this.getLatest()
    return self.__variationOnDisplay
  }

  exportDOM(editor: LexicalEditor): { element: HTMLSpanElement }
  exportDOM(): { element: HTMLSpanElement }
  exportDOM(editor?: any): { element: HTMLSpanElement } {
    const { element } = super.exportDOM()
    const config = editor?.__config || { theme: {} }
    const theme = config.theme.beautifulMentions || mentionsTheme
    const { className, classNameVariation } = solveStyleClassName(
      this.__trigger,
      this.__variationOnDisplay,
      config,
      theme,
    )

    element.className = classNameVariation || className || ''

    return { element }
  }

  exportJSON(): SerializedStyledExportDomBeautifulMentionNode {
    const data = this.__data
    return {
      trigger: this.__trigger,
      value: this.__value,
      ...(data ? { data } : {}),
      type: TYPE,
      version: 1,
    }
  }

  decorate(_editor: LexicalEditor, config: EditorConfig) {
    const { className, classNameVariation, classNameFocused, themeValues } =
      solveStyleClassName(this.__trigger, this.__variationOnDisplay, config)

    return (
      <MentionComponent
        nodeKey={this.getKey()}
        trigger={this.getTrigger()}
        value={this.getValue()}
        data={this.getData()}
        className={classNameVariation || className}
        classNameFocused={classNameFocused}
        themeValues={themeValues}
        component={this.component()}
      />
    )
  }
}

function solveStyleClassName(
  nodeTrigger: string,
  variationOnDisplay?: string | undefined,
  config?: EditorConfig,
  configTheme?: BeautifulMentionsTheme,
) {
  const theme: BeautifulMentionsTheme =
    configTheme || config?.theme?.beautifulMentions || mentionsTheme
  const entry = Object.entries(theme).find(([trigger]) =>
    new RegExp(trigger).test(nodeTrigger),
  )
  const key = entry && entry[0]
  const value = entry && entry[1]
  const className = typeof value === 'string' ? value : undefined
  const variationKey = `${key}Variation[${variationOnDisplay}]`
  const classNameVariation =
    variationOnDisplay && typeof theme[variationKey] === 'string'
      ? (theme[variationKey] as string)
      : undefined
  const classNameFocused =
    className && typeof theme[key + 'Focused'] === 'string'
      ? (theme[key + 'Focused'] as string)
      : undefined
  const themeValues = entry && typeof value !== 'string' ? value : undefined

  return {
    className,
    classNameVariation,
    classNameFocused,
    themeValues,
  }
}

export function $createStyledExportDomMentionNode(
  trigger: string,
  value: string,
  data?: { [p: string]: string | boolean | number },
): BeautifulMentionNode {
  const mentionNode = new StyledExportDomMentionNode(trigger, value, data)
  return $applyNodeReplacement(mentionNode)
}

export function $isStyledExportDomMentionNode(
  node: LexicalNode | null | undefined,
): node is BeautifulMentionNode {
  return node instanceof BeautifulMentionNode
}
