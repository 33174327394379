import { createErr, createOk, Result } from 'option-t/PlainResult'

import { axios } from '@/frontend/utils'
import { ApiError } from './types'
import { handleAxiosError } from './utils'

export async function orderBudgetDetailItems(
  url: string,
  ids: number[],
): Promise<Result<string, ApiError>> {
  try {
    const res = await axios.put(url, { ids: ids })
    return createOk(res.data as string)
  } catch (error) {
    const apiError = handleAxiosError(error)
    return createErr(apiError)
  }
}
