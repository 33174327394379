import { useCallback, useState } from 'react'

import { ApiError, Org, PendingChecker } from '@/frontend/api'
import { apiPendingChecker } from '@/frontend/api/importer'

export function useApiPendingChecker(org: Org) {
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined)
  const [returnedPendingChecker, setReturnedPendingChecker] =
    useState<PendingChecker | null>(null)

  const submit = useCallback(async () => {
    const result = await apiPendingChecker(org.id)
    if (result.ok) {
      setReturnedPendingChecker(result.val)
      setApiError(undefined)
    } else {
      setApiError(result.err)
    }
  }, [org])

  return {
    returnedPendingChecker,
    submit,
    apiError,
  }
}
