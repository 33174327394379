import React from 'react'

import { NavBarButton } from '@/frontend/features/notifications'

type Props = {
  orgId: number
}

export default function Notification({ orgId }: Props) {
  return <NavBarButton orgId={orgId} />
}
